import React, { useState, useRef, useEffect } from "react";

import { Container } from "@material-ui/core";
import { withRouter, Redirect } from "react-router-dom";
import MiniDrawer from "./../MiniDrawer";
import {IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonAlert} from '@ionic/react';
import { getTimestamp } from '../components/timeStamp.js'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { SelectionBar } from '../components/selectionBar.js';


import "./../App.css";

const TaxFormsMenu = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
    const [recoilFetched, setRecoilFetched] = useState(false)

    const [update, setUpdate] = useState(false)
    const firstTimeRender = useRef(true)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [forms, setForms] = useState([])

    const [goToForm, setGoToForm] = useState(false);
    const [showMoreText, setShowMoreText] = useState(false);
    const [chosenForm, setChosenForm] = useState();
    const [moreText, setMoreText] = useState({
        title: "",
        content: ""
    });


    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {
        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    let today = new Date();
                    let currentYearID = 0;
                    let dateFilterStart = "";
                    let dateFilterEnd = "";
                    json.years.forEach(element => {
                        const start = new Date(element.start_date);
                        const end = new Date(element.end_date);

                        if (today >= start && today <= end) {
                            currentYearID = element.id;
                            dateFilterStart = element.start_date;
                            dateFilterEnd = element.end_date;
                        }
                    });
                    setTaxYearsAtom(
                        {
                            taxYears: json.years,
                            dateFilterStart: dateFilterStart,
                            dateFilterEnd: dateFilterEnd,
                            dateFilter: currentYearID
                        });
                    if (!recoilFetched) {
                        setRecoilFetched(true)
                    }
                }
            });
    }


    useEffect(() => {
        if (recoilFetched) {
            getForms()
        }
    }, [recoilFetched])


    useEffect(() => {
        firstTimeRender.current = false;
        if (!user_atom.token) {
            let userState = getLocalStorage()
            setUserAtom(userState)
            getRecoilTaxYears(userState.token, userState.agent)
        }else if (JSON.stringify(tax_years_atom) === "{}") {
            getRecoilTaxYears()
        }else {
            setRecoilFetched(true)
        }
    }, [])



    useEffect(() => {
        if (forms.length > 0) {
           
            setPageLoaded(true)
        }
    },[forms]);

    const getForms = () => {

        fetch(`https://${global.api_url}/getTaxForms.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                setForms(json.forms)
            });
    };


    const handleFormSelect = (event) => {
        let form_id = event.target.value;
        if(form_id == "0"){
            form_id= "1"
        }
        let form = forms.filter(f => f.id == form_id )
        if(form.length>0){
        setChosenForm(form[0].code);
        setGoToForm(true);
        }
    }


    const formsSelection = () => {
        return (
            <span style={{ marginLeft: '20px', fontSize: '16px', fontWeight: 'bold', whiteSpace: "nowrap", paddingTop: "10px" }}>
                Tax form:{" "}
                
                <Select value={"1"} style={{ marginBottom: '00px' }}
                    onClick={(e) =>handleFormSelect(e)}>
                    {forms.map((element) => {

                        return (<MenuItem key={element.id} value={element.id} selected={element.code === 'SA100'}>
                            {element.code} - {element.title}
                        </MenuItem>)
                    })}
                </Select>
            </span>
        )
    }

    const displayMenuCards = () => {
        return(<div className="tax-forms-menu-container">
            {forms.map((item, index) => (<div key={index} className="tax-forms-menu-content">
                <IonCard className="tax-forms-menu-card"
                         onClick={() => {
                            setChosenForm(item.code);
                            setGoToForm(true);
                         }}>
                    <IonCardHeader>
                        <IonCardTitle className="tax-forms-card-title">{item.title}</IonCardTitle>
                        {item.moretext && <span className="tax-forms-more-text-content" onClick={(e) => {
                            e.stopPropagation();
                            setMoreText({
                                title: item.title,
                                content: item.moretext
                            });
                            setShowMoreText(true)
                        }}><div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                    <path d="M0 0h24v24H0z" fill="none"/>
                                    <path fill="#0C90B2" d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/>
                                </svg>   
                            </div></span>}
                    </IonCardHeader>

                    <IonCardContent className="tax-forms-card-content">
                        {item.helptext}
                    </IonCardContent>
                </IonCard>
            </div>))}

            {tax_years_atom.dateFilterStart >= "2020-04-06T00:00:00" && tax_years_atom.dateFilterEnd <= "2022-04-05T23:59:59" && (
                <div className="tax-forms-menu-content">
                <IonCard className="tax-forms-menu-card"
                    onClick={() => {
                        //go to /cov19
                        props.history.push("/cov19");
                    }}>
                    <IonCardHeader>
                        <IonCardTitle className="tax-forms-card-title">COVID-19 support</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent className="tax-forms-card-content">
                        Ensure your COVID support is properly included in your tax return.
                    </IonCardContent>
                </IonCard>
                </div>
            )}

            <div className="tax-forms-menu-content">
                <IonCard className="tax-forms-menu-card-help">
                    <IonCardHeader style={{height: 40}}>
                        <IonCardTitle className="tax-forms-card-title">Did we miss a form?</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent className="tax-forms-card-content">
                        If you can't find the form you need, please <a href="https://help.untied.io/contact">contact us</a>.
                    </IonCardContent>
                </IonCard>
            </div>

            <IonAlert
                cssClass="accounts-popup"
                isOpen={showMoreText}
                onDidDismiss={() => {
                    setShowMoreText(false)
                }}
                header={moreText.title}
                message={moreText.content}
                buttons={[{
                    text: 'OK',
                    role: 'OK',
                    cssClass: 'shadow text-center col-12'
                }]}
            />
        </div>)
    };

    console.log(tax_years_atom)



    return (<React.Fragment>
        <div className="tax-forms-page-container">
            <MiniDrawer />
            <Container>
                {SelectionBar(pageLoaded, update)}
                <div style = {{margin: "10px 0px"}}>
                {formsSelection()}
                </div>
                <div style={{width: "70%", minWidth: 700, margin: "auto"}}>
                    {displayMenuCards()}
                    {goToForm && <Redirect
                        to={{
                            pathname: "/taxForms",
                            state: {
                                form: chosenForm
                            }
                        }}
                    />}
                </div>
            </Container>
        </div>
    </React.Fragment>)
};

export default withRouter(TaxFormsMenu)
