import { IonLoading } from '@ionic/react';
import React, {  useState, useRef, useEffect} from "react";
import { getTaxCalc, TaxBrkdown } from "../Common"

import { Mixpanel } from "../mixpanel";
import MiniDrawer from "./../MiniDrawer";
import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {getTimestamp} from '../components/timeStamp.js'

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom, profileAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { SelectionBar } from '../components/selectionBar.js';


import './Page.css';

const TaxBreakdown = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [user_profile, setUserProfile] = useRecoilState(profileAtom);
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);      
  
    const firstTimeRender = useRef(true);
 
    // const [years, setYears] = useState(tax_years_atom.taxYears);
    const [showLoading, setShowLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(tax_years_atom.dateFilter);
    const [selectedYearData, setSelectedYearData] = useState({});

    // const [returns, setReturns] = useState([]);
    const [taxCalc, setTaxCalc] = useState({});
    const [data, setData] = useState(null);
    const [addReturn, setAddReturn] = useState(true);
    // const [testService, setTestService] = useState('N');
    
    const divStyles = { 
        display: "flex",
        marginTop: "20px", 
        width: "100%", 
        backgroundColor: "#FFFFFF", 
        minWidth: "300px", 
        flexBasis: "300px", 
        flexShrink: "0", 
        marginBottom: "50px"
    };

    
    const getProfile = (token, agentId) => {
      fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
        method: "GET",
        headers: { token, agentId }
      })
        .then(res => res.json())
        .then(json => {
            console.log('got user profile', json)
            setUserProfile(json);
        })
    }

    useEffect ( () => {
        firstTimeRender.current = false;
        if(!user_atom.token){

            let userState = getLocalStorage()
            setUserAtom(userState)
            getRecoilTaxYears(userState.token, userState.agent)

        }
        else if (JSON.stringify(tax_years_atom) === "{}"){
            getRecoilTaxYears()
        }
        else {
            setRecoilFetched(true)
        }


        
    }, [])


    useEffect(() => {
        if (user_atom.token && JSON.stringify(user_profile) === "{}"){
            getProfile(user_atom.token, user_atom.agent)
        }
    }, [user_atom])

    useEffect( () => {
        if (recoilFetched){
        // getAllData()
        getTaxCalc(selectedYear, (json) => {setTaxCalc({...json})})
        }
    }, [selectedYearData])

    useEffect(() => {
        if (recoilFetched){
        const yearElement = tax_years_atom.taxYears.find(element => {
                return element.id === tax_years_atom.dateFilter
            })
        Mixpanel.track("loaded_returns");
        setSelectedYear(tax_years_atom.dateFilter)
        setSelectedYearData({...yearElement})
        // setTestService(props.match.params.name === 'testService' ? 'Y' : 'N')
        window.onpopstate = (e) => {
            if (e.state) {
                if (data != null) {
                    setData(null)
                }
                if (addReturn) {
                    setAddReturn(false)
                }
            }
        };
        }
    }, [recoilFetched])

    // const getAllData = () => {
    //     getReturns();
    // }


    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {
        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: passed_token, agentId: passed_agentId }
        })
          .then(response => response.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                //alert('redirect to login page');
                props.history.push("/logout/logout");
              }
            } else {
              let today = new Date();
              let currentYearID = 0;
              let dateFilterStart = "";
              let dateFilterEnd = "";
              json.years.forEach(element => {
                const start = new Date(element.start_date);
                const end = new Date(element.end_date);
    
                if (today >= start && today <= end) {
                  currentYearID = element.id;
                  dateFilterStart = element.start_date;
                  dateFilterEnd = element.end_date;
                }
              });
              setTaxYearsAtom(
                { taxYears: json.years,
                  dateFilterStart: dateFilterStart,
                  dateFilterEnd: dateFilterEnd,
                  dateFilter: currentYearID
                });
              if (!recoilFetched){
                setRecoilFetched(true)
              }
            }
          });
      }

    // const getReturns = () => {
    //     const fromDate = selectedYearData.start_date;
    //     const toDate = selectedYearData.end_date + 'T23:59:59:59Z'

    //     fetch(`https://${global.api_url}/getTaxSubmissions.php?ts=${getTimestamp()}&timefrom=${fromDate}&timeto=${toDate}`, {
    //         method: "GET",
    //         headers: { token: user_atom.token, agentId: user_atom.agent }
    //     })
    //         .then(res => res.json())
    //         .then(json => {
    //             if (json.error) {
    //                 if (json.error === "unauthorized") {
    //                     props.history.push("/logout");
    //                 }
    //             } else {
    //                 setReturns(json.returns)
    //             }
    //         });
    // }

    const handleYearChange = (id) => {
        const yearElement = tax_years_atom.taxYears.find(element => {
            return element.id === id
        })
        setSelectedYear(id)
        setSelectedYearData(yearElement)
    }

    useEffect (() => {
        if (recoilFetched){
            handleYearChange(tax_years_atom.dateFilter)
        }
      }, [tax_years_atom])
    

    const fileName = (user_profile.name != '' ? user_profile.name : user_atom.email) + '_draft_tax_return_report_' + selectedYearData.hmrc_year_string + '.pdf';


    return (<React.Fragment>
        <div style={divStyles}>
            <MiniDrawer />
            <Container className="tax-breakdown-page-content">
                {SelectionBar(recoilFetched, null, true)}
                <div style={{ margin: "10px 0px" }}>
                    <a href={`https://pdfe1.untied.io/render_pdf.php?f=${fileName}&u=https://${global.api_url}/tax/viewReturnReport.php?t=${taxCalc.report_token}%26pre=1`}>Download draft tax return report</a>
                </div>
                <div style={{width: "60%", margin: "auto"}}>
                    <TaxBrkdown taxCalc={taxCalc} yearData={selectedYearData}/>
                </div>

                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={30000}
                />
            </Container>

        </div>
    </React.Fragment>)
};

  
export default withRouter(TaxBreakdown)
