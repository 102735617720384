// try
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getTaxCalc,} from "../Common"
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { withRouter } from "react-router-dom";
import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonPopover,
    IonAlert,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonLoading, IonLabel, IonDatetime
} from '@ionic/react';
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import { Modal, Fade, MenuItem, Backdrop, Toolbar, Button } from "@material-ui/core";
import MiniDrawer from "./../MiniDrawer";
import { Mixpanel } from "../mixpanel";
import moment from "moment";
import Footer from "../components/Footer";
import CustomFilter from "../components/CustomFilter";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import { getTimestamp } from '../components/timeStamp.js'
import { SelectionBar } from '../components/selectionBar.js';
import ReactTagInput from "@pathofdev/react-tag-input";
import './Page.css'

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ListItemIcon from "@material-ui/core/ListItemIcon";



let externalFilters = {
    dateFilterStart: "",
    dateFilterEnd: "",
    tagFilter: "",
    claimed: ""
};
let journeyEndDate, journeyDistance, initialDate;

const Journeys = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState(0.00)
    const [totalTracked, setTotalTracked] = useState(0.00)
    const [taxSummary, setTaxSummary] = useState()

    const [distanceError, setDistanceError] = useState(false)

    const [clearFilters, setClearFilters] = useState(false)
    const [update, setUpdate] = useState(false)
    const [journeysFetched, setJourneysFetched] = useState(false);
    const firstTimeRender = useRef(true);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [journeysRowData, setJourneysRowData] = useState([]);
    const [plan, setPlan] = useState(2);
    const [filtered, setFiltered] = useState(false)
    const [filters, setFilters] = useState({
        textFilter: "",
        dateFilter: "",
        tagFilter: "All",
        dateFilterStart: "",
        dateFilterEnd: ""
    });
    //   const [taxYears, setTaxYears] = useState(tax_years_atom.taxYears);
    const [show, setShow] = useState({
        errorBox: false,
        loading: false,
        loadingMain: false,
        addTrans: false,
        multiList: false,
        bulkRemoveTagAlert: false,
        discardAlert: false,
        restoreAlert:false,
        open: false,
        isInfoShown: false,
        isDownloadShown: false,
        isUploadShown: false,
        addJourney: false,
        first: false,
        distanceNotFound: false,
        editJourney: false
    });
    const [flags, setFlags] = useState({
        redirectForms: false,
        transactionsFetched: false,
        gridIsFiltered: false
    });
    const [counts, setCounts] = useState({
        taxCat: 0,
        rowCount: 0,
        accountsConnected: 0,
        totalVisible: 0,
        rowTotal: 0
    });
    const [mapUrl, setMapUrl] = useState("");
    const [errorText, setErrorText] = useState("");
    const [addJourney, setAddJourney] = useState({
        date: (new Date()).toISOString(),
        getDistanceBy: "",
        startPostCode: "",
        endPostCode: "",
        type: "in_vehicle",
        description: "",
        miles: ""
    });
    const milesRef = useRef(null);
    const [mapRoute, setMapRoute] = useState({});
    const [landedFromBreakdown, setLandedFromBreakdown] = useState(props.location.state !== undefined);
    const [journeyDataToEdit, setJourneyDataToEdit] = useState();
    const [bulkVehicleChange, setBulkVehicleChange] = useState("change_vehicle")
    const location = useLocation();
    const history = useHistory();

    let journeyId;

    const constants = {
        defaultColDef: {
            resizable: true,
            editable: false,
            sortable: true,
            filter: true,
            floatingFilter: true,
            suppressMenu: true,
            flex: 1,
            minWidth: 100,
            onCellDoubleClicked: (params) => {
             
                let mode;

                if(params.data.type === "on_bicycle") {
                    mode = "bicycling";
                }else{
                    mode = "driving";
                }
                let miles = params.data.claimed == "Y" ? params.data.miles_claimed : params.data.miles
                miles = Number(miles).toFixed(2).toString()
                setJourneyDataToEdit({
                    ...params.data,
                    miles_claimed: miles, 
                    type: params.data.type !== "on_bicycle" && params.data.type !== "on_motorcycle" ? "in_vehicle" : params.data.type,                                    
                });
                setMapRoute({ from: params.data.start_postcode, to: params.data.end_postcode, waypoints: params.data.waypoints, mode: mode })
                setShow((show) => ({ ...show, viewMap: true, editJourney: true }));
            }
        },
        columnDefs: [
            {
                maxWidth: 40,
                filter: false,
                sortable: false,
                resizable: false,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
            {
                headerName: "Claimed",
                field: "claimed",
                suppressMovable: true,
                width: 70,
                maxWidth: 100,
                cellStyle: (params) => {
                    if (params.data.claimed === "N") {
                        return {
                            color: "#BABABA",
                            backgroundColor: "#E7E7E7",
                            fontWeight: "bold",
                            textAlign: "center"
                        };
                    } else {
                        return {
                            color: "#0C90B2",
                            backgroundColor: "#C7FF00",
                            fontWeight: "bold",
                            textAlign: "center"
                        };
                    }
                }
            },
            {
                headerName: "Start Time",
                field: "start_time",
                suppressMovable: true,
                valueFormatter: params => services.actionsService.prettyDateTime(params.value),
                sort: "desc",
                filter: "agDateColumnFilter",
                width: 250,
                filterParams: {
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        let cellDate = new Date(cellValue.substring(0, 10));

                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                }
            },
            {
                headerName: "End Time",
                field: "end_time",
                suppressMovable: true,
                valueFormatter: params => services.actionsService.prettyDateTime(params.value),
                sort: "desc",
                filter: "agDateColumnFilter",
                width: 250,
                filterParams: {
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        let cellDate = new Date(cellValue.substring(0, 10));

                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                }
            },
            {
                headerName: "Type",
                field: "type",
                suppressMovable: true,
                maxWidth: 70,
                filter:false,
                resizable: false,
                cellRendererFramework: params => {

                    switch(params.data.type) {
                        case 'in_vehicle':
                          return  <DirectionsCarIcon className="edit-journey-icon"/>;
                        case 'on_bicycle':
                            return  <DirectionsBikeIcon className="edit-journey-icon"/>;
                        case 'on_motorcycle':
                             return  <MotorcycleIcon className="edit-journey-icon"/>;
                        default:
                          return <DirectionsCarIcon className="edit-journey-icon"/>;
                      }

    
                }

                
            },
            {
                headerName: "Duration",
                field: "end_time",
                suppressMovable: true,
                width:70,
                valueGetter: params => {
                    const startTime = moment(params.data.start_time);
                    const endTime = moment(params.data.end_time);
                    const diff = endTime.diff(startTime);
                    const diffDuration = moment.duration(diff);
                    let hours = diffDuration.asHours();
                    let h = Math.trunc(hours);
                    let m = Math.round((hours - h) * 60);
                    let durationHours = h + "h " + m + "m";
                    let durationMins = m + " mins";

                    if (diffDuration.days() >= 1) {
                        return diffDuration.asDays().toFixed(1) + " days";
                    }
                    if (diffDuration.hours() >= 1) {
                        return durationHours;
                    } else {
                        return durationMins;
                    }
                }
            },
            {
                headerName: "Miles logged",
                field: "miles",
                suppressMovable: true,
               
                valueGetter: params => {
                    let miles = params.data.miles
                    // if (params.data.claimed=="Y") {
                    //     miles = params.data.miles_claimed
                    // }
                    return Number(miles).toFixed(2).toString()
                }
            },
            {
                headerName: "Miles claimed",
                field: "miles_claimed",
                suppressMovable: true,
               
                valueGetter: params => {
                    let miles_claimed = params.data.miles_claimed
                    // if (params.data.claimed=="Y") {
                    //     miles = params.data.miles_claimed
                    // }
                    return Number(miles_claimed).toFixed(2).toString()
                }
            },
            {
                headerName: "From",
                field: "start_postcode",
                width:70,
                suppressMovable: true,
                cellStyle: { textAlign: "center" }
            },
            {
                headerName: "To",
                field: "end_postcode",
                width:70,
                suppressMovable: true,
                cellStyle: { textAlign: "center" }
            },
            {
                headerName: "Notes",
                field: "comments",
                cellClass: "cell-style",
                width: 30,               
                cellStyle: { textAlign: "center" },
        
              },
            {
                sortable: false,
                filter: false,
                headerName:"Edit",
                cellStyle: {textAlign: "center"},
                maxWidth: 70,
                resizable: false,
                cellRendererFramework: params => {
                    let mode;

                    if(params.data.type === "on_bicycle") {
                        mode = "bicycling";
                    }else{
                        mode = "driving";
                    }

                    return(
                        <button
                            className="editJourneyButton"
                            style={{width: 50}}
                            onClick={() => {
                                let miles = params.data.claimed == "Y" ? params.data.miles_claimed : params.data.miles
                                miles = Number(miles).toFixed(2).toString()
                                // console.log(miles)
                                //setJourneyDataToEdit({...params.data});
                                setJourneyDataToEdit({
                                    ...params.data,
                                    miles_claimed: miles, 
                                    type: params.data.type !== "on_bicycle" && params.data.type !== "on_motorcycle" ? "in_vehicle" : params.data.type,                                    
                                });
                                setMapRoute({ from: params.data.start_postcode, to: params.data.end_postcode, waypoints: params.data.waypoints, mode: mode })
                                setShow((show) => ({ ...show, viewMap: true, editJourney: true }));
                            }}
                        >
                            <EditIcon className="edit-journey-icon"/>
                        </button>)
                }
            }
        ],
        filterBy: [
            { tagName: "All" },
            { tagName: "Claimed" },
            { tagName: "Not claimed" }
        ]
    };

    const deleteDistanceCalc = (nodes) => {
        let claimedMiles = parseFloat(totalClaimed)
        let totalMiles = parseFloat(totalTracked)

        
        nodes.forEach(n=>{
            claimedMiles -= parseFloat(n.miles_claimed)
            totalMiles -= parseFloat(n.miles)
        })
        setTotalClaimed(claimedMiles.toFixed(2))
        setTotalTracked(totalMiles.toFixed(2))

    }

    const parseValue = (value, icon) => {
        let t = typeof value
        if(t == "number"){
        value = JSON.stringify(value.toFixed(2))
        }
        if(value && value.length > 0){
            const stringWithoutSpaces = value.trim();
            const stringWithoutCommas = stringWithoutSpaces.replace(/,"/g, '');
            value = stringWithoutCommas.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if(value.indexOf(".")>-1){
                if (icon == ""){
                    const integers = value.split(".")[0]
                    let i = integers.split("")
                    let nums = i.filter(n=>  n!=="\"").join("")
                    return icon + nums;
                    }
                else{
                const integers = value.split(".")[0]+".";
                let digits = value.split(".")[1];
                if(digits.length > 2) digits = digits.substring(0,2);
                let i = integers.split("")
                let nums = i.filter(n=>  n!=="\"").join("")
                return icon + nums + digits;
                }
                
            }else{
               
                return icon + value;
            }
        }else{
            return "";
        }
    };

    const getDistanceCalc = () => {
       
        let claimedMiles = 0
        let totalMiles = 0
        
        let nodes =[]

        gridApi.forEachNodeAfterFilter(node=>{
            nodes.push(node.data)
        })
            nodes.forEach(n => {
                let c = parseFloat(n.miles_claimed);
                claimedMiles += c;
                let t = parseFloat(n.miles);
                totalMiles += t
            })
 
        setTotalClaimed(claimedMiles.toFixed(2))
        setTotalTracked(totalMiles.toFixed(2))
    
    }

    const services = {
        fetchService: {
            getJourneys: () => {
                fetch(`https://${global.api_url}/getJourneys.php?ts=${getTimestamp()}&app=web`, {
                    method: "GET",
                    headers: { token: user_atom.token, agentId: user_atom.agent }
                })
                    .then(res => res.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } else {
                            let filteredJourneys;

                            if(props.location.state){
                                filteredJourneys = json.journeys.filter(item => {
                                    return item.claimed === "Y";
                                });
                                // console.log(filteredJourneys)
                                setJourneysRowData(filteredJourneys);
                            }else{
                                // console.log(json.journeys)
                                setJourneysRowData(json.journeys);
                            }

                            setPlan(json.plan);
                            setJourneysFetched(true);
                            if (gridApi) {
                                gridApi.onFilterChanged();
                            }
                        }
                    });
            },
            getDiscardedJourneys: () => {
                fetch(`https://${global.api_url}/getJourneys.php?ts=${getTimestamp()}&app=web&pending=N`, {
                    method: "GET",
                    headers: { token: user_atom.token, agentId: user_atom.agent }
                })
                .then(res => res.json())
                 .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } 
                        else {
                            console.log(json)
                            let currentJourneys = JSON.stringify(journeysRowData)
                            let filtered = json.journeys.filter(j => !currentJourneys.includes(JSON.stringify(j)))
                            setJourneysRowData(filtered);
                            
                            if (gridApi) {
                                    gridApi.onFilterChanged();
                                    }
                            }
                        })
            },
            addJourneys: () => {
                const showErrorIf = {
                    noDescription: addJourney.description === "",
                    noMiles: addJourney.miles === "",
                    /*noDescription: addJourney.description === "",
                    noDistanceType: addJourney.getDistanceBy === "",
                    noMiles: addJourney.miles === "" && addJourney.getDistanceBy === "Miles",
                    noPostCode: (addJourney.startPostCode === "" || addJourney.endPostCode === "") && addJourney.miles.length === 0 && addJourney.getDistanceBy === "Postcode",
                    notValidPostCode: (addJourney.startPostCode.length < 5 || addJourney.endPostCode.length < 5) && addJourney.miles.length === 0 && addJourney.getDistanceBy === "Postcode"*/
                };

                if (Object.keys(showErrorIf).filter(item => showErrorIf[item]).length > 0) {
                    if (showErrorIf.noDescription){
                        setErrorText("no Description")
                    }
                    else if (showErrorIf.noMiles){
                        setErrorText("no miles")
                    }
                    setShow((show) => ({ ...show, errorBox: true }));
                    return;
                }
                const startPostCode = addJourney.startPostCode.length > 0 ? addJourney.startPostCode : 'UNKNOWN';
                const endPostCode = addJourney.endPostCode.length > 0 ? addJourney.endPostCode : 'UNKNOWN';

                //url encode the description
                const description = encodeURIComponent(addJourney.description);

                const isoDate = moment(addJourney.date).add(1, 'ms').toISOString();
                console.log(isoDate)

                setShow((show) => ({ ...show, loading: true }));

                fetch(
                    `https://${global.api_url}/addJourney.php?ts=${getTimestamp()}&date=${isoDate}&enddate=${journeyEndDate || isoDate}&start_postcode=${startPostCode}&end_postcode=${endPostCode}&miles=${addJourney.miles}&comments=${description}&vehicle=${addJourney.type}`,
                    {
                        method: 'POST',
                        headers: {
                            token: user_atom.token
                        }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        if(json.status == "error"){ 
                         
                            setShow((show) => ({ ...show, journeyErrorBox: true }));
                            return;
                        }
                        fetch(
                            `https://${global.api_url}/claimJourney.php?ts=${getTimestamp()}&id=${json.id}&miles=${addJourney.miles}&comments=${addJourney.description}`,
                            {
                                method: 'POST',
                                headers: { token: user_atom.token }
                            }
                        )
                            .then(res => res.json())
                            .then(json => {
                                
                                Mixpanel.track("added_journey");
                                services.fetchService.getJourneys();
                                setShow((show) => ({ ...show, loading: false }));
                                setShow((show) => ({ ...show, addJourney: false }));
                                setUpdate(!update)
                            })
                    });

                setAddJourney({
                    date: services.actionsService.getInitialDate(),
                    getDistanceBy: "",
                    startPostCode: "",
                    endPostCode: "",
                    type: "in_vehicle",
                    description: "",
                    miles: ""
                });
                getDistanceCalc()
                journeyEndDate = undefined;

            },
            editJourneys: (obj) => {

 
                setShow((show) => ({ ...show, loading: true }));
                console.log(obj)

                if (obj.claimed === "N") {
                    services.fetchService.unClaimJourney(obj.id, obj.miles);
                    services.actionsService.updateRow(obj)
                    setShow((show) => ({ ...show, loading: false }));
                    setShow((show) => ({ ...show, addJourney: false }));
                    setUpdate(!update)

                } else {

                    fetch(
                        `https://${global.api_url}/claimJourney.php?ts=${getTimestamp()}&id=${obj.id}&miles=${obj.miles_claimed}&comments=${obj.comments}&mode=${obj.type}&tags=${obj.tags}`,
                        {
                            method: 'POST',
                            headers: { token: user_atom.token }
                        }
                    )
                        .then(res => res.json())
                        .then(json => {
                            Mixpanel.track("edit_journey");
                            services.actionsService.updateRow(obj)

                            setShow((show) => ({ ...show, loading: false }));
                            setShow((show) => ({ ...show, addJourney: false }));
                            setUpdate(!update)
                        });
                }
                
                setJourneyDataToEdit();
            },
            editJourney: (id, data) => {
                fetch(
                    `https://${global.api_url}/editJourney.php?ts=${getTimestamp()}&id=${id}`,
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: { token: user_atom.token }
                    }
                )
                    .then(res => res.json())
                    .then(json => { })
            },
            getDistanceAndDuration: () => {
                if (addJourney.startPostCode === "" || addJourney.endPostCode === "") {
                    setShow((show) => ({ ...show, errorBox: true }));
                    return;
                }

                const startPostCode = addJourney.startPostCode.length > 0 ? addJourney.startPostCode : 'UNKNOWN';
                const endPostCode = addJourney.endPostCode.length > 0 ? addJourney.endPostCode : 'UNKNOWN';
                const mode = addJourney.type !== "in_vehicle" ? "bicycling" : "driving";
                setShow((show) => ({ ...show, loading: true }));

                fetch(
                    `https://${global.api_url}/getDistance.php?start_postcode=${startPostCode}&end_postcode=${endPostCode}&journey_type=${mode}`,
                    {
                        headers: {
                            token: user_atom.token
                        }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        const obj = json.rows[0].elements[0];
                        if (obj.duration) {
                            journeyEndDate = services.actionsService.calculateEndDate(obj.duration.value);
                            setShow((show) => ({ ...show, distanceNotFound: false }));
                        } else {
                            setShow((show) => ({ ...show, distanceNotFound: true }));
                        }
                        if (obj.distance) {
                            journeyDistance = parseFloat(obj.distance.value * 0.00062137).toFixed(2);
                        }
                        setAddJourney((addJourney) => ({
                            ...addJourney,
                            miles: journeyDistance || ""
                        }));
                        setShow((show) => ({ ...show, loading: false }));
                    });
            },
            // fetchMap: (params) => {
            //     journeyId = params;
            //     fetch(`https://${global.api_url}/getMap.php?journey=${journeyId}`, {
            //         method: "GET",
            //         headers: { token: user_atom.token, agentId: user_atom.agent }
            //     })
            //         .then(response => response.json())
            //         .then(json => {
            //             if (json.status === "success") {
            //                 setMapUrl(json.url);
            //                 setShow((show) => ({ ...show, viewMap: true }))
            //             } else {
            //                 return;
            //             }
            //         });
            // },
             claimJourney: async (id, miles, length = 1, index = 0) => {
                const resp = await fetch(
                    `https://${global.api_url}/claimJourney.php?id=${id}&miles=${miles}`,
                    {
                        method: "POST",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                const json = await resp.json()
                    // .then(res => res.json())
                    // .then(json => {
                        gridApi.redrawRows();
                        gridApi.deselectAll();
                        Mixpanel.track("claim journey");
                        if (length-1 == index){
                        setUpdate(!update)
                        }
                    // });

            },
            unClaimJourney: async (id, length = 1, index = 0) =>  {
               
                const resp = await fetch(`https://${global.api_url}/unclaimJourney.php?id=${id}`, {
                    method: "GET",
                    headers: { token: user_atom.token, agentId: user_atom.agent }
                })
                const json = await resp.json() 
         
                    // .then(res => res.json())
                    // .then(json => {
                        gridApi.redrawRows();
                        gridApi.deselectAll();
                        Mixpanel.track("unclaim journey");
                        // if (length-1 == index){
                        setUpdate(!update)
                        // }
                    // });
            },
            deleteJourney(id) {

                let ids = [];
                let remainingData = journeysRowData;
                let nodes = []
                if(!id){
                    gridApi.getSelectedRows().forEach(node => {
                        nodes.push(node)
                        ids.push({ id: node.id });
                    });
                }else{
                    ids = [{id: id}]
                }   

         
                ids.forEach(element => {

                    fetch(`https://${global.api_url}/discardJourney.php?id=${element.id}&ts=${getTimestamp()}`, {
                        method: "POST",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    })
                        .then(res => res.json())
                        .then(json => {
                            console.log('discarded')
                            remainingData = remainingData.filter(item => item.id !== element.id);
                            setJourneysRowData(remainingData);

                            gridApi.deselectAll();
                            Mixpanel.track("discarded journey(s)");
                            
                        });
                        
                    });
                    // console.log("nodes check 2", nodes)
                    deleteDistanceCalc(nodes)
                
            },
            restoreJourney(id) {

                let ids = [];
                let remainingData = journeysRowData;
                let nodes = []
                if(!id){
                    gridApi.getSelectedRows().forEach(node => {
                        nodes.push(node)
                        ids.push({ id: node.id });
                    });
                }else{
                    ids = [{id: id}]
                }   

         
                ids.forEach(element => {

                    fetch(`https://${global.api_url}/discardJourney.php?id=${element.id}&ts=${getTimestamp()}&restore="Y"`, {
                        method: "POST",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    })
                        .then(res => res.json())
                        .then(json => {
                            console.log('restored')
                            remainingData = journeysRowData.filter(item => item.id !== element.id);
                            setJourneysRowData(remainingData);
                            gridApi.deselectAll();
                            Mixpanel.track("restored journey(s)");
                            
                        });
                        
                    });
                    // console.log("nodes check 2", nodes)
                    
                
            },
            getRecoilTaxYears: (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {
                fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
                    method: "GET",
                    headers: { token: passed_token, agentId: passed_agentId }
                })
                    .then(response => response.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                //alert('redirect to login page');
                                props.history.push("/logout/logout");
                            }
                        } else {
                            let today = new Date();
                            let currentYearID = 0;
                            let dateFilterStart = "";
                            let dateFilterEnd = "";
                            json.years.forEach(element => {
                                // const start = new Date(element.start_date);
                                // const end = new Date(element.end_date);

                                let start = new Date(element.start_date);
                                start.setUTCHours(0); start.setMinutes(0); start.setSeconds(0)
                
                                let end = new Date(element.end_date);
                                end.setUTCHours(23); end.setMinutes(59); end.setSeconds(59)

                                if (today >= start && today <= end) {
                                    currentYearID = element.id;
                                    dateFilterStart = element.start_date;
                                    dateFilterEnd = element.end_date;
                                }
                            });
                            setTaxYearsAtom(
                                {
                                    taxYears: json.years,
                                    dateFilterStart: dateFilterStart,
                                    dateFilterEnd: dateFilterEnd,
                                    dateFilter: currentYearID
                                });
                            if (!show.first) {
                                setShow((show) => ({ ...show, first: true }));
                            }
                            setFilters((filters) => ({ ...filters, dateFilter: currentYearID, dateFilterStart: dateFilterStart, dateFilterEnd: dateFilterEnd }));
                            services.actionsService.externalFilterChanged(dateFilterStart, "dateFilterStart");
                            services.actionsService.externalFilterChanged(dateFilterEnd, "dateFilterEnd");
                            services.actionsService.externalFilterChanged(filters.tagFilter, "tagFilter");
                            if (!recoilFetched) {
                                setRecoilFetched(true)
                            }
                        }
                    });
            }
        },
        actionsService: {
            prettyDateTime: (dateToProcess) => {
                let date1 = new Date(dateToProcess);
                //let longDate = date1.toDateString();
                // let longDate = moment.utc(date1).format('ddd MMM Do YYYY HH:mm')
                let longDate = moment.utc(date1).format('DD/MM/YYYY')
                return longDate
                //let timePart = date1.toLocaleTimeString();
                //return longDate + " " + timePart;
              },
            getInitialDate: () => {
                let currentDate = new Date();
                let newDate = new Date(Date.parse(filters.dateFilterEnd));
                let date;
                date = currentDate < newDate ? currentDate : newDate;
                date = moment(date).format('YYYY-MM-DDTHH:mm')
                // return date.toISOString()
                // console.log(date)
                setAddJourney((addJourney) => ({ ...addJourney, date}))
                // return date
            },
            calculateEndDate: (duration) => {
                let initialDate = moment(addJourney.date);
                return initialDate.add(duration, "seconds").toISOString();
            },
            makeYearLabel: (text, start_date, end_date) => {
                const today = new Date();
                const start = new Date(start_date);
                const end = new Date(end_date);
                if (today >= start && today <= end) {
                    return text + " (This Tax Year)";
                }
                const dateOffset = 24 * 60 * 60 * 1000 * 365;
                today.setTime(today.getTime() - dateOffset);
                if (today >= start && today <= end) {
                    return text + " (Last Tax Year)";
                }
                return text;
            },
            formatValue: (value) => {
                if (value !== null) {
                    const suffix = "";
                    if (isNaN(value)) {
                        value = 0;
                    }
                    return (
                        Math.abs(value)
                            .toFixed(0)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + suffix
                    );
                } else {
                    return "0";
                }
            },
            claimSelected: () => {
                let ids = [];
                let id = 0;
                let miles = 0;
                let nodes = []

                gridApi.getSelectedRows().forEach(node => {
                    id = node.journey_id;
                    miles = node.miles;
                    ids.push({id,miles});
                    node.claimed = "Y";
                    node.selected = false;
                    node.miles_claimed = miles
                    nodes.push(node)
                    gridApi.updateRowData({ node });
                });
                getDistanceCalc()
                let length = ids.length
                ids.forEach((el, index) => {
                    services.fetchService.claimJourney(el.id, el.miles, length, index);
                });
            },
            unClaimSelected: () => {
                const ids = [];
                let id = 0;
                let nodes = []
                //    var miles = 0;

                gridApi.getSelectedRows().forEach(node => {
                    id = node.journey_id;

                    //miles = node.miles;
                    ids.push(id);
                    node.claimed = "N";
                    node.miles_claimed = 0;
                    node.selected = false;
                    nodes.push(node)
                    gridApi.updateRowData({ node });
                });
                getDistanceCalc()
                let length = ids.length
                ids.forEach((id, index) => {
                    services.fetchService.unClaimJourney(id, length, index);
                });
            },
            bulkChangeVehicle: (vehicle_type) => {

                // setBulkVehicleChange(vehicle_type)

                if (vehicle_type==="change_vehicle") return;

                const ids = [];
                let id = 0;
                //    var miles = 0;

                gridApi.getSelectedRows().forEach(node => {
                    id = node.journey_id;

                    //miles = node.miles;
                    ids.push(id);
                    node.type = vehicle_type
                    node.selected = false;
                    gridApi.updateRowData({ node });
                });
                let length = ids.length
                ids.forEach((id, index) => {
                    services.fetchService.editJourney(id, {type:vehicle_type});
                });
                setBulkVehicleChange("change_vehicle")
            },
            updateRow: (obj) => {
              
                gridApi.forEachNode(node => {
                    const id = node.data.id;
                    if (id===obj.id) {
                        node.data.claimed = obj.claimed
                        node.data.type = obj.type
                        node.data.miles_claimed = obj.miles_claimed
                        node.data.miles = obj.miles
                        node.data.comments = obj.comments
                        gridApi.updateRowData({ node });
                    }
                })
                getDistanceCalc()
            },
            externalFilterChanged: (newValue, field) => {
                externalFilters[field] = newValue;
            }
        },
        gridService: {
            onGridReady: (params) => {
                setGridApi(params.api);
                setGridColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
                params.api.onFilterChanged();
                services.fetchService.getJourneys();
            },
            doesExternalFilterPass: (node) => {
                
                let filterPass = {
                    tagFilterPass: (externalFilters.tagFilter !== "Not claimed" && externalFilters.tagFilter !== "Claimed") ||
                        (externalFilters.tagFilter === "Not claimed" && node.data.claimed === "N") ||
                        (externalFilters.tagFilter === "Claimed" && node.data.claimed === "Y"),
                    dateFilterPass: (externalFilters.dateFilterStart === "" || externalFilters.dateFilterEnd === "") ||
                        (moment(node.data.start_time).add("1 hours").format('YYYY-MM-DDTHH:mm:ss') >= externalFilters.dateFilterStart
                            && moment(node.data.start_time).add("1 hours").format('YYYY-MM-DDTHH:mm:ss') <= externalFilters.dateFilterEnd)
                };
                return Object.keys(filterPass).filter(item => !filterPass[item]).length === 0;
            },
            filterChanged: () => {
                gridApi.deselectAll();
                setFlags((flags) => ({ ...flags, gridIsFiltered: Object.keys(gridApi.getFilterModel()).length > 0 }));
                let total = 0;
                gridApi.forEachNodeAfterFilter(node => {
                    total = total + Math.abs(node.data.miles);
                });
                getDistanceCalc()
                setCounts((counts) => ({ ...counts, totalVisible: parseFloat(total).toFixed(2), rowTotal: gridApi.getDisplayedRowCount() }));
            },
            onSelectionChanged: () => {
                let rowCount = gridApi.getSelectedRows().length;
                setCounts((counts) => ({ ...counts, rowCount: rowCount }));
                if(rowCount>0) setBulkVehicleChange("change_vehicle")
            }
        },
        externalFiltersService: {
            setTextFilter: (param) => {
                setFilters((filters) => ({ ...filters, textFilter: param }));
                gridApi.setQuickFilter(param);
                Mixpanel.track("search filter used");
            },
            setDateFilter: (param) => {

                const element = tax_years_atom.taxYears.find(element => element.id === param);
                let start_date = element.start_date;
                let end_date = element.end_date;

                //console.log(start_date, end_date)

                setFilters((filters) => ({ ...filters, dateFilter: tax_years_atom.taxYears.filter((item) => (item.id === param))[0].id, dateFilterStart: start_date, dateFilterEnd: end_date }));
                services.actionsService.externalFilterChanged(tax_years_atom.taxYears.find(element => element.id === param).start_date, "dateFilterStart");
                services.actionsService.externalFilterChanged(tax_years_atom.taxYears.find(element => element.id === param).end_date, "dateFilterEnd");
                services.actionsService.externalFilterChanged(filters.tagFilter, "tagFilter");
                gridApi.onFilterChanged();
                
                Mixpanel.track("date filter used");
            },
            setTagFilter: (param) => {
                setFilters((filters) => ({ ...filters, tagFilter: param }));
                services.actionsService.externalFilterChanged(filters.dateFilterStart, "dateFilterStart");
                services.actionsService.externalFilterChanged(filters.dateFilterEnd, "dateFilterEnd");
                services.actionsService.externalFilterChanged(param, "tagFilter");
                gridApi.onFilterChanged();
                Mixpanel.track("tag filter used");
            }
        },
        renderService: {
            displayFilterControls: () => {
                return (
                    <React.Fragment>
                        <Toolbar className="filters-container">
                            <div style={styles.filtersContainer}>
                                <div className= "mainMilesBox">
                                    <div className = "milesBox" style = {{color: "#3f90b0", border: "1px #3f90b0 solid"}}>
                                            <div style = {{}}>Total tracked</div>
                                            <div style = {{display: "flex", height: "32px"}}><div style = {{fontSize: "26px", fontWeight: "600"}}>{parseValue(totalTracked, "")}</div> <div style = {{fontSize: "12px", fontWeight: "600", alignSelf: "flex-end", paddingLeft: "3px"}}>miles</div></div>
                                    </div> 
                                    <div className = "milesBox" style = {{backgroundColor: "#c7ff00", color: "#234B5C"}}>    
                                                <div style = {{}}>Total claimed</div>
                                                <div style = {{display: "flex", height: "32px"}}><div style = {{fontSize: "26px", fontWeight: "600"}}>{parseValue(totalClaimed, "")}</div> <div style = {{fontSize: "12px", fontWeight: "600", alignSelf: "flex-end", paddingLeft: "3px"}}>miles</div></div>
                                    </div> 
                                    { taxSummary &&
                                    <div className = "milesBox" style = {{color: "#3f90b0", border: "1px #3f90b0 solid"}}>    
                                                <div style = {{}}>Total value claimed</div>
                                                <div style = {{display: "flex", height: "32px"}}><div style = {{fontSize: "26px", fontWeight: "600"}}>{parseValue(taxSummary.mileage_claimed, "£")}</div></div>
                                    </div> 
                                    }
                                </div>
                                <div style={styles.toolBar}>
                                    <CustomFilter
                                        label="Search"
                                        value={filters.textFilter}
                                        onChange={(e) => services.externalFiltersService.setTextFilter(e.target.value)}
                                    />
                                </div>

                                <div style={styles.toolBar}>
                                    <CustomFilter
                                        select
                                        label=" "
                                        value={filters.tagFilter}
                                        onChange={(e) => services.externalFiltersService.setTagFilter(e.target.value)}
                                    >
                                        {constants.filterBy.map(item => (
                                            <MenuItem key={item.tagName} value={item.tagName}>
                                                {item.tagName}
                                            </MenuItem>
                                        ))}
                                    </CustomFilter>
                                </div>

                                {clearFilters ?  <div
                                    style = {{display: "flex", width: "100%"}}>
                                <div
                                    className= "clearFilters"
                                    // style={{ marginLeft: "15px"}}
                                    onClick={ () => {
                                    setClearFilters(false)
                                    // setTaxYearsAtom({...tax_years_atom, dateFilter: tax_years_atom.taxYears[0].id, dateFilterStart: tax_years_atom.taxYears[0].start_date, dateFilterEnd: tax_years_atom.taxYears[0].end_date})
                                    services.fetchService.getJourneys();
                                    }
                                    }
                                    >
                                    Clear filters
                                    </div>
                                    </div> : null}

                                <div
                                    style={{
                                        borderWidth: 0,
                                        borderColor: "black",
                                        borderStyle: "solid",
                                        flex: 1,
                                        paddingTop: 10
                                    }}
                                >
                                    {services.renderService.displayTransitionsModal({ onDownload: () => gridApi.exportDataAsCsv() })}
                                </div>
                            </div>
                        </Toolbar>
                    </React.Fragment>
                )
            },
            displayJourneyPopover: () => {
                if (recoilFetched) {
                    const key = "AIzaSyDwVJuOGH7ihQlX5pZvHSwyV_ivrWvThac";
                    const from = mapRoute.from;
                    const to = mapRoute.to;
                    const waypoints = mapRoute.waypoints;
                    const mode = mapRoute.mode;

                    return (<>
                        <IonPopover
                            className="my-ion-journey-popover"
                            isOpen={show.addJourney}
                            onDidDismiss={() => {
                                setJourneyDataToEdit({})
                                setAddJourney({
                                    date: services.actionsService.getInitialDate(),
                                    getDistanceBy: "",
                                    startPostCode: "",
                                    endPostCode: "",
                                    type: "in_vehicle",
                                    description: "",
                                    miles: ""
                                });
                                setShow((show) => ({ ...show, addJourney: false }))
                                }}
                        >
                            <div>
                                <IonAlert
                                    isOpen={show.errorBox}
                                    onDidDismiss={() => setShow((show) => ({ ...show, errorBox: false }))}
                                    header={'Oops'}
                                    subHeader={'Information required'}
                                    message={errorText}
                                    buttons={['OK']}
                                />
                                <IonAlert
                                    isOpen={show.journeyErrorBox}
                                    onDidDismiss={() => setShow((show) => ({ ...show, journeyErrorBox: false }))}
                                    header={'Oops'}
                                    subHeader={'Journey time error'}
                                    message={"There is already a journey logged for this time period, please choose another time"}
                                    buttons={['OK']}
                                />
                                <IonAlert
                                    isOpen={distanceError}
                                    onDidDismiss={() =>setDistanceError(false)}
                                    header={'Start and endpoint required'}
                                    subHeader={''}
                                    message={"Please input both a start and end postcode to calculate distance"}
                                    buttons={['OK']}
                                />
                                  <div style = {{backgroundColor: "rgb(12, 144, 178)", width: "100%", height: "40px", display: "flex", flexDirection: "row"}}>
                                        <div style ={{paddingTop: "10px", color: "white", fontFamily:"Roboto, Helvetica, Arial, sans-serif", marginLeft: "10px", fontSize: "16px"}}>
                                        Add journey
                                        </div>
                                    <div style={{alignSelf: "flex-end", paddingRight: "10px", marginLeft: "auto", cursor: "pointer"}}>  
                                     <CloseIcon
                                        style={styles.filterButtons.closeWhite}
                                            onClick={() => {
                                                setShow((show) => ({ ...show, addJourney: false }))
                                                setAddJourney({
                                                               date: services.actionsService.getInitialDate(),
                                                               getDistanceBy: "",
                                                               startPostCode: "",
                                                               endPostCode: "",
                                                               type: "in_vehicle",
                                                               description: "",
                                                               miles: ""
                                                           })
                                            }
                                                
                                                }
                                        /> </div>
                                        </div>
                                <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px' }}>
                                <div style={{background: "#F4F6F6", borderRadius: 5, margin: "5px", marginBottom: 0, paddingBottom: 5, width: "98%"}}>            
                                    <IonRow>
                                        {/* <IonCol>
                                            <IonItem className="input-container">
                                                <IonLabel position="floating">Journey date and time</IonLabel>
                                                <IonDatetime displayFormat="DD MMM YYYY HH:mm"
                                                             placeholder="Select date and time"
                                                             display-timezone="utc"
                                                             value={addJourney.date}
                                                             onIonChange={e => setAddJourney((addJourney) => ({ ...addJourney, date: e.target !== null ? e.target.value : initialDate }))}
                                                />
                                            </IonItem>
                                        </IonCol> */}
                                    <IonCol >
                                        <IonLabel position="floating">Journey date and time</IonLabel>
                                        <IonItem 
                                        // className="input-container"
                                        className="grey-input"
                                        >
                                        <input 
                                            type= "datetime-local" 
                                            value = {addJourney.date}
                                            style = {{border: "none", width: "100%", height: "100%", font: "400 14px Arial", backgroundColor: "#F4F6F6"}}
                                            onChange={e => {
                                                let date = e.target.value.toString("dd/mm/yyyy hh:mm Z")
                                                setAddJourney((addJourney) => ({ ...addJourney, date: date !== null ? date : initialDate }))
                                                }} 

                                            />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonCol className="text-center">
                                            <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            >
                                                <IonSelect value={addJourney.type} interface="popover"
                                                           onIonChange={e => setAddJourney((addJourney) => ({ ...addJourney, type: e.target.value }))}>
                                                    <IonSelectOption value="in_vehicle" selected={true}>By car or van</IonSelectOption>
                                                    <IonSelectOption value="on_motorcycle">By motorbike or scooter</IonSelectOption>
                                                    <IonSelectOption value="on_bicycle">By bicycle</IonSelectOption>
                                                </IonSelect>
                                            </IonItem >
                                        </IonCol>
                                    {/* </IonRow>

                                    <IonRow> */}
                                        <IonCol>
                                        <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            >
                                                <IonInput value={addJourney.description}
                                                          onIonChange={e => setAddJourney((addJourney) => ({ ...addJourney, description: e.target.value }))}
                                                          placeholder="Description" inputmode="text" type="text" />
                                            </IonItem >
                                        </IonCol>
                                    </IonRow>

                                    {/*<IonRow>
                                      <IonCol>
                                          <IonItem className="input-container">
                                              <IonLabel>Get distance by:</IonLabel>
                                              <IonSelect value={addJourney.getDistanceBy}
                                                         onIonChange={e => setAddJourney((addJourney) => ({
                                                             ...addJourney,
                                                             getDistanceBy: e.target.value,
                                                             startPostCode: "",
                                                             endPostCode: "",
                                                             miles: ""
                                                         }))}
                                              >
                                                  <IonSelectOption value="Miles">Miles</IonSelectOption>
                                                  <IonSelectOption value="Postcode">Postcode</IonSelectOption>
                                              </IonSelect>
                                          </IonItem>
                                      </IonCol>
                                  </IonRow>*/}

                                    {/*{addJourney.getDistanceBy === "Postcode" && <>
                                      placeholder for the 3 rows below
                                  </>}*/}


                                    <IonRow>
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                                               <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            >
                                                <IonLabel position="stacked">Starting point postcode (optional)</IonLabel>
                                                <IonInput value={addJourney.startPostCode}
                                                          maxlength={8}
                                                          className = {addJourney.startPostCode ? "post-code" : ""}
                                                          onIonChange={e => setAddJourney((addJourney) => ({ ...addJourney, startPostCode: e.target.value }))}
                                                          placeholder={""}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    {/* </IonRow>
                                    <IonRow> */}
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                                               <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            >
                                                <IonLabel position="stacked">Destination postcode (optional)</IonLabel>
                                                <IonInput value={addJourney.endPostCode}
                                                          maxlength={8}
                                                          className = {addJourney.endPostCode ? "post-code" : ""}
                                                          onIonChange={e => setAddJourney((addJourney) => ({ ...addJourney, endPostCode: e.target.value }))}
                                                          placeholder={""}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                            <IonButton
                                                className={ addJourney.startPostCode && addJourney.endPostCode ? "ion-button-money" : "ion-button-inverse"}
                                                style = {{width: "50%"}}
                                                onClick={() => {
                                                    if (addJourney.startPostCode && addJourney.endPostCode){ 
                                                    services.fetchService.getDistanceAndDuration();
                                                    }
                                                    else{
                                                        setDistanceError(true)
                                                    }
                                                }}>
                                                Calculate Distance
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>

                                    {/*{addJourney.getDistanceBy &&
                                        the row below was in here
                                   }*/}

                                    <IonRow>
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                            <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            
                                            ref={milesRef}>
                                                {!show.distanceNotFound && <IonInput
                                                    value={addJourney.miles}
                                                    onIonChange={e => setAddJourney((addJourney) => ({
                                                        ...addJourney,
                                                        miles: e.target.value
                                                    }))}
                                                    placeholder="Miles" inputmode="numeric" type="number" />}
                                                {show.distanceNotFound && <span
                                                    style={styles.addJourneyInfoNote}
                                                    onClick={() => {
                                                        setShow((show) => ({ ...show, distanceNotFound: false }));
                                                        setAddJourney((addJourney) => ({
                                                            ...addJourney,
                                                            miles: ""
                                                        }))
                                                    }}
                                                >Could not get distance. Please check postcodes or enter mileage here.
                                                </span>}
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        {/* <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                                            <IonButton color="black" style={{ color: 'black' }}
                                                       onClick={() => {
                                                           setShow((show) => ({ ...show, addJourney: false }));
                                                           setAddJourney({
                                                               date: services.actionsService.getInitialDate(),
                                                               getDistanceBy: "",
                                                               startPostCode: "",
                                                               endPostCode: "",
                                                               type: "in_vehicle",
                                                               description: "",
                                                               miles: ""
                                                           })
                                                       }}
                                            >
                                                Cancel
                                            </IonButton>
                                        </IonCol> */}
                                        <IonCol className="ion-align-self-center" style={{ textAlign: "center"}}>
                                            <IonButton className="ion-button-money"
                                                        style = {{width: "50%"}}
                                                       onClick={() => services.fetchService.addJourneys()}>
                                                Add Journey
                                            </IonButton>
                                        </IonCol>

                                    </IonRow>

                                    </div>
                                </IonGrid>

                                <IonLoading
                                    isOpen={show.loading}
                                    onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
                                    message={'Please wait...'}
                                    duration={5000}
                                />
                            </div >
                        </IonPopover>

                        {journeyDataToEdit &&
                        <IonPopover
                            className="my-ion-journey-popover-wide"
     
                            isOpen={show.editJourney}
                            onDidDismiss={() => setShow((show) => ({ ...show, editJourney: false }))}
                        >
                        {console.log(journeyDataToEdit)}
                            <div>
                                <IonAlert
                                    isOpen={show.errorBox}
                                    onDidDismiss={() => setShow((show) => ({ ...show, errorBox: false }))}
                                    header={'Oops'}
                                    subHeader={'Information required'}
                                    message={errorText}
                                    buttons={['OK']}
                                />
                                   <div style = {{backgroundColor: "rgb(12, 144, 178)", width: "100%", height: "40px", display: "flex", flexDirection: "row"}}>
                                        <div style ={{paddingTop: "10px", color: "white", fontFamily:"Roboto, Helvetica, Arial, sans-serif", marginLeft: "10px", fontSize: "16px"}}>
                                        {filtered ? "Restore journey": journeyDataToEdit.claimed === "Y" ? "Edit journey": "Claim journey"}
                                        {/* {journeyDataToEdit.claimed === "Y" ? "Edit journey": "Claim journey"} */}
                                        </div>
                                    <div style={{alignSelf: "flex-end", paddingRight: "10px", marginLeft: "auto", cursor: "pointer"}}>  
                                     <CloseIcon
                                        style={styles.filterButtons.closeWhite}
                                            onClick={() => setShow((show) => ({ ...show, editJourney: false }))}
                                        /> </div>
                                        </div>
                                {/* <div style={{ textAlign: 'center' }}><h3>Claim journey</h3></div> */}
                                <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', margin: 0, width: "100%" }}>
                                    {journeyDataToEdit.start_postcode !== "UNKNOWN" && journeyDataToEdit.end_postcode !== "UNKNOWN" && 
                                    <IonRow>
                                        <IonCol>
                                            <div>
                                                <iframe
                                                    src={`https://www.google.com/maps/embed/v1/directions?zoom=9&key=${key}&mode=${mode}&origin=${from}&destination=${to}` + (waypoints !== "" ? `&waypoints=${waypoints}` : "")}
                                                    frameBorder="0" style={{width: "100%", height: "300px"}}
                                                    allowFullScreen/>
                                            </div>
                                        </IonCol>
                                    </IonRow>}

                                    <div style={{background: "#F4F6F6", borderRadius: 5, margin: "5px", marginBottom: 5, paddingBottom: 5, width: "98%"}}>            


                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Date and time</IonLabel>
                                            <IonItem className="grey-input">

                                                <IonDatetime displayFormat="DD/MM/YYYY - HH:mm"
                                                            
                                                             display-timezone="utc"
                                                           
                                                             value={journeyDataToEdit.start_time}
                                                             disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>

                                        <IonCol>
                                            <IonLabel>Vehicle</IonLabel>
                                            
                                            <IonItem 
                                            className="input-container item md ion-activatable ion-focusable hydrated item-interactive item-datetime item-has-placeholder item-has-value"
                                            style = {{"--background": "#F4F6F6"}}
                                            >
                                                <CustomFilter
                                                    select
                                                    style={{width: "100%", "backgroundColor": "#F4F6F6"}}
                                                    value={journeyDataToEdit.type}
                                                    onChange={e => {
                                                        const v = e.target.value;
                                                        setJourneyDataToEdit((journeyDataToEdit) => ({ ...journeyDataToEdit, type: v}))
                                                    }}
                                                >
                                                    <MenuItem value="in_vehicle">By car or van</MenuItem>
                                                    <MenuItem value="on_motorcycle">By motorbike or scooter</MenuItem>
                                                    <MenuItem value="on_bicycle">By bicycle</MenuItem>
                                                </CustomFilter>



                                            </IonItem >
                                        </IonCol>

                                        <IonCol>
                                            <IonLabel>Miles to claim</IonLabel>
                                            <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            >
                                                <IonInput
                                                    value={journeyDataToEdit.miles_claimed}
                                                    //disabled={journeyDataToEdit.claimed==="N"}
                                                    onIonChange={e => setJourneyDataToEdit((journeyDataToEdit) => ({...journeyDataToEdit, miles_claimed: e.target.value }))}
                                                    placeholder="Miles"
                                                    inputmode="numeric"
                                                    type="number"
                                                />
                                            </IonItem>
                                        </IonCol>

                                    </IonRow>

             
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Description</IonLabel>
                                            <IonItem 
                                            // className="input-container"
                                            className="grey-input"
                                            >
                                                <IonInput
                                                    //disabled={journeyDataToEdit.claimed==="N"}
                                                    value={journeyDataToEdit.comments}
                                                    onIonChange={e => setJourneyDataToEdit((journeyDataToEdit) => ({ ...journeyDataToEdit, comments: e.target.value }))}
                                                    placeholder="e.g delivery"
                                                    inputmode="text"
                                                    type="text"
                                                />
                                            </IonItem >
                                        </IonCol>




                                    </IonRow>

                                    {/* <IonRow>
                                        <IonCol>
                                            <IonLabel>Add custom tags</IonLabel>
                                            <IonItem className="input-container edit-journey-tag-selector">
                                                <ReactTagInput
                                                    placeholder="Type and press enter"
                                                    //readOnly={journeyDataToEdit.claimed==="N"}
                                                    maxTags={3}
                                                    tags={journeyDataToEdit.tags.split(",")[0].length > 0 ? journeyDataToEdit.tags.split(",") : []}
                                                    onChange={(newTags) => {
                                                        newTags = newTags.filter(item => item.trim().length > 0);
                                                        setJourneyDataToEdit((journeyDataToEdit) => ({...journeyDataToEdit, tags: newTags.join()}))
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow> */}
                                    </div>
                 

                                    <IonRow>
             
                                        {/* <IonCol style={{ textAlign: 'center' }}>
                                            <IonButton
                                                className="ion-button-money-discard"
                                                onClick={() => setShow((show) => ({ ...show, editJourney: false }))}
                                            >
                                                Close
                                            </IonButton>
                                        </IonCol> */}
                                        {!filtered ? 
                                        <IonCol style={{ textAlign: 'center' }}>
                                            <IonButton
                                                className="ion-button-inverse"
                                                onClick={() => setShow((show) => ({ ...show, discardAlert: true }))}
                                            >
                                                Discard journey
                                            </IonButton>
                                        </IonCol>
                                            :
                                        <IonCol style={{ textAlign: 'center' }}>
                                            <IonButton
                                                className="ion-button-inverse"
                                                onClick={() => setShow((show) => ({ ...show, restoreAlert: true }))}
                                            >
                                                Restore journey
                                            </IonButton>
                                        </IonCol>
                                        }

                                        {journeyDataToEdit.claimed === "Y" &&
                                        <IonCol style={{ textAlign: 'center' }}>
                                                <IonButton
                                                    className="ion-button-inverse"
                                                    onClick={() => {
                                                        let data = {...journeyDataToEdit, claimed:"N", miles_claimed:0}
                                                        setJourneyDataToEdit(data)
                                                        services.fetchService.editJourneys(data);
                                                    }}
                                                >
                                                    Unclaim journey
                                                </IonButton>
                               
                                
                                        </IonCol>}

                                        {!filtered ? 
                                        <IonCol style={{ textAlign: 'center' }}>
                                            <IonButton
                                                className="ion-button-money"
                                                onClick={() => {
                                                    let data = {...journeyDataToEdit, claimed:"Y"}
                                                    setJourneyDataToEdit(data)
                                                    services.fetchService.editJourneys(data);
                                                   
                                                }}
                                            >
                                                {journeyDataToEdit.claimed=="Y" ? "Save changes" : "Claim journey"}
                                            </IonButton>
                                        </IonCol>
                                        : null}



                                    </IonRow>

                                            
                                </IonGrid>

                                <IonLoading
                                    isOpen={show.loading}
                                    onDidDismiss={() => setShow((show) => ({ ...show, loading: false }))}
                                    message={'Please wait...'}
                                    duration={5000}
                                />
                            </div >
                        </IonPopover>}
                    </>)
                }
            },
            displayMapPopover: () => {
                if (recoilFetched && show.viewMap) {

                    const key = "AIzaSyDwVJuOGH7ihQlX5pZvHSwyV_ivrWvThac";
                    const from = mapRoute.from;
                    const to = mapRoute.to;
                    const waypoints = mapRoute.waypoints;
                    const mode = mapRoute.mode;

                    return (
                        <IonPopover
                            className="my-ion-map-popover"
                            isOpen={show.viewMap}
                            onDidDismiss={() => setShow((show) => ({ ...show, viewMap: false }))}
                        >
                            <div>
                                <iframe src={`https://www.google.com/maps/embed/v1/directions?key=${key}&mode=${mode}&origin=${from}&destination=${to}` + (waypoints !== "" ? `&waypoints=${waypoints}` : "") }
                                    frameborder="0" style={{ border: 0 }}
                                    width={window.innerWidth - 200} height={window.innerHeight - 200} allowfullscreen />
                            </div>
                        </IonPopover>
                    )
                }
            },
            displayChangeTagControls: () => {
                return (
                    <React.Fragment>
                        <Toolbar style={{ flexDirection: "row", paddingLeft: 0, paddingRight: 0}}>
                            <div style={styles.tagSelect}>
                            {!filtered ? <>
                                <div className="filter-container">
                                    <Button
                                        type="submit"
                                        style={styles.filterButtons.tag}
                                        onClick={() => {
                                            services.actionsService.claimSelected();
                                        }}
                                    >
                                        Claim
                                    </Button>
                                </div>

                                <div className="filter-container">
                                    <Button
                                        type="submit"
                                        style={styles.filterButtons.unTag}
                                        onClick={() => {
                                            services.actionsService.unClaimSelected();
                                        }}
                                    >
                                        Unclaim
                                    </Button>
                                </div>
                                
                                <div className="filter-container">
                                    <Button
                                        type="submit"
                                        style={styles.filterButtons.discard}
                                        onClick={() => setShow((show) => ({ ...show, discardAlert: true }))}
                                    >
                                        Discard
                                    </Button>
                                </div>
                                </>
                                :
                                <div className="filter-container">
                                    <Button
                                        type="submit"
                                        style={styles.filterButtons.tag}
                                        onClick={() => setShow((show) => ({ ...show, restoreAlert: true }))}
                                    >
                                        Restore
                                    </Button>
                                </div>
                                }
                                <div className="filter-container">
                                            <CustomFilter
                                                    select
                                                    style={{width: 180}}
                                                    value={bulkVehicleChange}
                                                    onChange={e => {
                                                        setBulkVehicleChange(e.target.value)
                                                        
                                                        // services.actionsService.bulkChangeVehicle(e.target.value)
                                                    }}
                                                >
                                                    <MenuItem value="change_vehicle">Change vehicle...</MenuItem>
                                                    <MenuItem value="in_vehicle">By car or van</MenuItem>
                                                    <MenuItem value="on_motorcycle">By motorbike or scooter</MenuItem>
                                                    <MenuItem value="on_bicycle">By bicycle</MenuItem>
                                            </CustomFilter>
                                </div>

                                <div className="filter-container">
                                    {`${counts.rowCount.toString().replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")}${counts.rowCount === 1 ? " item = " : " items = "}${services.actionsService.formatValue(counts.totalVisible)}${" mile(s)"}`}
                                </div>

                                <div className="filter-container">
                                    <CloseIcon
                                        style={styles.filterButtons.close}
                                        onClick={() => {
                                            gridApi.deselectAll();
                                        }}
                                    />
                                </div>
                            </div>
                        </Toolbar>
                    </React.Fragment>
                );
            },
            displayMainScreen: () => {
                if (recoilFetched && journeysFetched) {
                    return (<>
                        <div className={`ag-theme-alpine grid-container-small`}>
                            <AgGridReact
                                modules={AllCommunityModules}
                                defaultColDef={constants.defaultColDef}
                                rowData={journeysRowData}
                                columnDefs={constants.columnDefs}
                                pagination={false}
                                animateRows
                                rowHeight={30}
                                isExternalFilterPresent={() => true}
                                doesExternalFilterPass={services.gridService.doesExternalFilterPass}
                                rowSelection={"multiple"}
                                rowDeselection={true}
                                suppressRowClickSelection={true}
                                onFilterChanged={() => services.gridService.filterChanged()}
                                onSelectionChanged={() => services.gridService.onSelectionChanged()}
                                suppressDragLeaveHidesColumns={true}
                                onGridReady={services.gridService.onGridReady}
                                applyColumnDefOrder={true}
                            />
                        </div>

                        <IonAlert
                                    isOpen={bulkVehicleChange !== "change_vehicle"}
                                    // onDidDismiss={}
                                    backdropDismiss = {false}
                                    header={'Vehicle type change'}
                                    // subHeader={'Information required'}
                                    message={"Are you sure you want to change the vehicle type for the selected rows"}
                                    buttons={[  
                                {
                                    text: 'Cancel',
                                    handler: () => {
                                        setBulkVehicleChange("change_vehicle")
                                    }
                                },
                                {
                                    text: 'Continue',
                                    handler: () => {
                                        services.actionsService.bulkChangeVehicle(bulkVehicleChange)
                                    }
                                }
                            ]}
                                />


                        <IonAlert
                            isOpen={show.discardAlert}
                            onDidDismiss={() => setShow((show) => ({ ...show, discardAlert: false }))}
                            header={`You are about to discard ${counts.rowCount === 1 ? "a journey" : journeyDataToEdit ? "a journey" : "multiple journeys"}!`}
                            buttons={[  
                                {
                                    text: 'Cancel',
                                    handler: () => {
                                        gridApi.deselectAll();
                                    }
                                },
                                {
                                    text: 'Continue',
                                    handler: () => {
                                        services.fetchService.deleteJourney(journeyDataToEdit ? journeyDataToEdit.id : undefined);
                                        if(journeyDataToEdit) {
                                            setShow((show) => ({ ...show, editJourney: false }));
                                        } 
                                    }
                                }
                            ]}
                        />
                        <IonAlert
                            isOpen={show.restoreAlert}
                            onDidDismiss={() => setShow((show) => ({ ...show, restoreAlert: false }))}
                            header={`You are about to restore ${counts.rowCount === 1 ? "a journey" : journeyDataToEdit ? "a journey" : "multiple journeys"}!`}
                            buttons={[  
                                {
                                    text: 'Cancel',
                                    handler: () => {
                                        gridApi.deselectAll();
                                    }
                                },
                                {
                                    text: 'Continue',
                                    handler: () => {
                                        services.fetchService.restoreJourney(journeyDataToEdit ? journeyDataToEdit.id : undefined);
                                        if(journeyDataToEdit) {
                                            setShow((show) => ({ ...show, editJourney: false }));
                                        } 
                                    }
                                }
                            ]}
                        />
                    </>)
                }
            },
            displayFooter: () => {
                return (
                    <Footer />
                );
            },
            displayTransitionsModal: (props) => {
                return (
                    <div
            style={{
              /* width: 120, */
              borderColor: "black",
              float: "right",
              textAlign: "right",
              paddingRight: 2
            }}
          >

          <IconButton
            // aria-owns={show.txMenuOpen ? "material-appbar" : undefined}
            style={{padding:6}}
            aria-haspopup="true"
            onClick={(event) => {
              setShow((show) => ({ ...show, txMenuOpen: true, menuTarget:event.currentTarget }));
              Mixpanel.track("transaction menu clicked");
            }}
            color="inherit"
          >
            <svg xmlns="http://www.w3.org/2000/svg"  style={{fill: '#3f90b0'}}  height="32" viewBox="0 0 24 24" width="32"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
          </IconButton>


          <Menu
            anchorEl={show.menuTarget}
            open={Boolean(show.menuTarget)}
            onClose={()=>setShow({ ...show, menuTarget:undefined })}
            disableScrollLock={ true }
          >
            <MenuItem onClick={() => {
                services.actionsService.getInitialDate()
                // setAddJourney((addJourney) => ({ ...addJourney, date: services.actionsService.getInitialDate() }));
                setShow({ ...show, menuTarget:undefined})
                setShow((show) => ({ ...show, addJourney: true }))
              }}>              
              <svg xmlns="http://www.w3.org/2000/svg"  
                    style={{fill: '#3f90b0'}} 
                    height="24" 
                    viewBox="0 0 24 24"
                    width="24">                  
                    <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
              </svg>&nbsp;Add Journey
              </MenuItem>
            <MenuItem onClick={() => {
                if (!filtered){
                services.fetchService.getDiscardedJourneys()
                }
                else{
                    services.fetchService.getJourneys()
                }
                setFiltered(!filtered)
                setShow({ ...show, menuTarget:undefined })
              }}>
              {filtered ? <>
                <ListItemIcon style={{ maxWidth: 29, minWidth: 29}}>
                    <MotorcycleIcon style={{height: 24, width: 24, maxWidth: 24, minWidth: 24, color: '#3f90b0'}}/>
                </ListItemIcon>
                <span 
                // className="item-label"
                >Show journeys</span>
                </> : <>            
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    height="24px" 
                    viewBox="0 0 24 24" 
                    width="24px" 
                    fill="#000000">
                    <path d="M0 0h24v24H0V0z" fill="none"/><path fill= '#3f90b0' d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
                    </svg>&nbsp;Show discarded journeys
              </>
              }
              </MenuItem>
              <MenuItem

                  onClick={() => {
                    setShow({ ...show, menuTarget:undefined })
                    props.onDownload();
                  }}>

                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style={{fill: '#3f90b0'}}
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                  </svg>&nbsp;Download filtered journeys
              </MenuItem>
          </Menu>
                 
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className="transactions-page-modal-container"
                            open={show.open}
                            onClose={() => setShow((show) => ({ ...show, open: false }))}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                        >
                            <Fade in={show.open}>
                                <div className="transactions-page-modal-content">
                                    <h2 id="transition-modal-title" style={{ color: "#14A7CC" }}>
                                        About this Web App
                                  </h2>
                                    <p>
                                        This Web App is designed to accompany the untied mobile app which
                                        offers more insights, easier wizards and
                                        additional features such as journey logging.
                                  </p>

                                    <p>
                                        Please, visit either the "Google Play Store" or the "App Store"
                                        to download the Mobile App onto your device.
                                  </p>

                                    <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
                                        <img
                                            style={{
                                                width: 200,
                                                paddingLeft: -40,
                                                paddingTop: 20
                                            }}
                                            alt="untied logo"
                                            src={require("../images/play-300x116.png").default}
                                            href="https://help.untied.io/"
                                        />
                                    </a>
                                    <a href="https://apps.apple.com/gb/app/untied/id1458630284">
                                        <img
                                            style={{
                                                width: 200,
                                                paddingLeft: -40,
                                                paddingTop: 20
                                            }}
                                            alt="untied logo"
                                            src={require("../images/apple-app-store-badge-300x116.png").default}
                                        />
                                    </a>
                                    <h3 style={{ color: "#14A7CC" }}>How to use this Web App</h3>
                                    <p>
                                        This App works better on Chrome, Firefox or Safari. Edge works in
                                        a slightly different way and we cannot guarantee the user
                                        experience, unless you upgrade to the new version of Edge.
                                  </p>
                                    <p>
                                        You can change the width of the columns and re-arrange them (NB:
                                        they will return to default when the page is reloaded).
                                  </p>
                                    <p>
                                        You can search all your transactions within a set tax year in the
                                        main toolbar, or you can search in the top of the column headers.
                                  </p>
                                    <a
                                        style={{ color: "black" }}
                                        href="https://help.untied.io/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Get help
                                  </a>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                );
            }
        }
    };



    const styles = {
        tagSelect: {
            display: "flex",
            position: "relative",
            borderBottom: "2px solid #3f90b0",
            height: 40
        },
        toolBar: {
            maxWidth: "270px",
            height: "40px",
            flexDirection: "row",
            display: "flex",
            paddingRight: 15,
            alignItems: "stretch"
        },
        addJourneyInfoNote: {
            fontSize: 10
        },
        filterButtons: {
            tag: {
                height: "100%",
                borderRadius: 2,
                color: "white",
                backgroundColor: "#3f90b0",
                textTransform: "none"
            },
            unTag: {
                height: "100%",
                border: "1px solid #3f90b0",
                borderRadius: 2,
                color: "#3f90b0",
                backgroundColor: "white",
                textTransform: "none"
            },
            discard: {
                height: "100%",
                border: "1px solid red",
                borderRadius: 2,
                color: "red",
                backgroundColor: "white",
                textTransform: "none"
            },
            close: {
                height: 32,
                width: "auto",
                cursor: "pointer"
            },
            closeWhite: {
                height: 32,
                color: "white",
                width: "auto",
                cursor: "pointer"
              }
        },
        filtersContainer: {
            display: "flex",
            position: "relative",
            height: 51,
            width: "100%"
        },
    };
    function scale(val) {
        return val;
    }

    // useEffect(()=>{
    //  console.log(addJourney)
    // }, [addJourney])

    useEffect(() => {
        if (!show.first) {
            //if (!user_atom.token || (user_atom.agent !== undefined && user_atom.agent !== "")) {
            if (!user_atom.token) {
                let userState = getLocalStorage();
                // setToken(userState.token)
                // setAgentId(userState.agent)
                setUserAtom({
                    ...userState,
                    agent: user_atom.agent
                });
                services.fetchService.getRecoilTaxYears(userState.token, userState.agent)
            }
            else if (JSON.stringify(tax_years_atom) === "{}") {
                services.fetchService.getRecoilTaxYears()
            }
            else {
                setShow((show) => ({ ...show, first: true }));
                setRecoilFetched(true)
            }
        }
    }, []);

    useEffect (() => {
        if (gridApi){
        services.externalFiltersService.setDateFilter(tax_years_atom.dateFilter)
        }
      }, [tax_years_atom])
    


    useEffect(() => {
        if (show.first) {
            services.fetchService.getJourneys();
            if ( props.location.state && props.location.state.filters){
                setClearFilters(true)
            }
        }
    }, [show.first]);

    useEffect(() => {
        if (!firstTimeRender.current && gridApi && recoilFetched) {
            gridApi.onFilterChanged();
            if (props.location.state && props.location.state.descriptionFilter !== undefined) {
                const event = new KeyboardEvent("keypress", {
                    view: window,
                    keyCode: 13,
                    bubbles: true,
                    cancelable: true
                });
                document.getElementsByClassName('ag-floating-filter-input')[2].value = props.location.state.descriptionFilter;
                document.getElementsByClassName('ag-floating-filter-input')[2].dispatchEvent(event)
            }
        }
        firstTimeRender.current = false;
    }, [recoilFetched]);

    useEffect(() => {
        if (!show.distanceNotFound) {
            if (!firstTimeRender.current && show.addJourney) {
                milesRef.current.focus();
            }
        }
    }, [show.distanceNotFound]);


    useEffect(() => {
        if (gridApi) {
            gridApi.redrawRows(journeyId);
          
            Mixpanel.track("viewed map");
        }
    }, [mapUrl]);

    useEffect(() => {
        if(landedFromBreakdown) {
            if(gridApi && props.location.state){
                services.externalFiltersService.setDateFilter(props.location.state.yearData.id);
                gridApi.onFilterChanged();
                setLandedFromBreakdown(false);
                history.replace({ ...location, state: undefined });
            }
        }
        else {
            if(gridApi) {
                services.externalFiltersService.setDateFilter(tax_years_atom.dateFilter)
            }
        }
    }, [gridApi]);

    useEffect(() => {

        if(gridApi && props.location.state && props.location.state.addJourney){
          services.externalFiltersService.setDateFilter(props.location.state.yearData.id);
          setShow({...show, addJourney: true })
        }

    }, [gridApi]);
    


    useEffect(() => {
        let total = 0;
        if(gridApi){
            gridApi.getSelectedNodes().map(node => {
                total = total + Math.abs(node.data.miles);
            });
            setCounts((counts) => ({ ...counts, totalVisible: parseFloat(total.toString()).toFixed(2)}));
        }

    }, [counts.rowCount]);

    // useEffect(()=>{
    //     if(taxSummary){
    //     console.log(taxSummary)
    //     console.log(taxSummary.adjusted_mileage_claimed)
    //     }
    // },[taxSummary])

    // useEffect(()=>{
    //     if(addJourney){
    //     console.log(addJourney)
    //     }
    // },[addJourney])

    const journeysTaxSummary = (s) => {
        setTaxSummary(s)
    }

    return (
        <React.Fragment>
            <div className="journey-page-container">
            <MiniDrawer location={props.location}/>
                <div className="journey-page-content">
                {SelectionBar(recoilFetched, update, false, journeysTaxSummary)}
                    {Number(plan) !== 2 && <div style={{ height: '100%' }}>
                        <p><h1>Journey Logging</h1></p>
                        <p><a style={{textDecorationLine:'underline'}} onClick={() => props.history.push('/profileMain', {state: "Manage your plan"}) }>Upgrade to the pro plan to view your journeys</a></p>
                        <p>To log your journeys automatically install the mobile app:</p>

                        <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
                            <img
                                style={{
                                    width: 150,
                                    paddingLeft: -40,
                                    paddingTop: 0
                                }}
                                alt="untied logo"
                                src={require("../images/play-300x116.png").default}
                                href="https://help.untied.io/"
                            />
                        </a>
                        <a href="https://apps.apple.com/gb/app/untied/id1458630284">
                            <img
                                style={{
                                    width: 150,
                                    paddingLeft: -40,
                                    paddingTop: 0
                                }}
                                alt="untied logo"
                                src={require("../images/apple-app-store-badge-300x116.png").default}
                            />
                        </a>

                    </div>}
                    {Number(plan) === 2 && <>
                        {(counts.rowCount < 1 && recoilFetched) ? services.renderService.displayFilterControls(props) : services.renderService.displayChangeTagControls()}
                        {recoilFetched && services.renderService.displayMainScreen()}
                        {services.renderService.displayJourneyPopover()}
                    </>}
                    
                </div>
            </div>
        </React.Fragment >
    );
};

export default withRouter(Journeys)
