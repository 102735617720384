import {
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    IonIcon,
    IonItem,
    IonAlert, IonText,
    IonLoading, IonButton,
    IonCheckbox
} from '@ionic/react';
import React, { useEffect, useState } from "react";
import { CustomButton } from "./../../Common"
import MiniDrawer from "./../../MiniDrawer";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoIcon from '@material-ui/icons/Info';
import { Container } from "@material-ui/core";
import './HMRCPayment.scss';
import { SelectionBar } from '../../components/selectionBar.js';
import { getTimestamp } from '../../components/timeStamp.js'
import { getTaxCalc } from "../../Common"
import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../../atom.js"
import { getLocalStorage } from '../../components/localstorage.js'
//import logo from '../images/fractal-untied-1536x130.png';

const MoneyInput = (props) => {

    const [theValue, setValue] = useState(props.value);

    const doOnChange = (event) => {

        const max = props.max ? props.max : 999999999;

        let v = event.target.value
        v = v.replace(/[^0-9\.,]/g, '');
        console.log(v)

        if (props.onChange && v <= max) {
            props.onChange(v);
        }
    }

    return (
        <div style={{ position: 'relative', ...props.style }}>
            <input type="text" placeholder="Amount" className="input-box" value={props.value} onChange={doOnChange} min="0" step="any" max={props.max ? props.max : 999999999} />
            <div className="money-input-prefix-container"><div className="money-input-prefix">&pound;</div></div>
        </div>
    )
}

const HMRCPayment = (props) => {
    const [screen, setScreen] = useState("initial");
    const [banks, setBanks] = useState([]);
    const [showUTR, setShowUTR] = useState(false);
    const [formData, setFormData] = useState({ userProfile: {}, utr: "", amount: "", bankId: "" });
    const [picUrl, setPicUrl] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);
    const target_account = ['HMRC Self Assessment Cumbernauld', '083210', '12001039'];
    const [update, setUpdate] = useState(false)
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [haveFullReturn, setHaveFullReturn] = useState(false);
    const [haveLastYearReturn, setHaveLastYearReturn] = useState(false);
    const [selectedYear, setSelectedYear] = useState(tax_years_atom.dateFilter)
    const [selectedYearData, setSelectedYearData] = useState({})
    const [taxCalc, setTaxCalc] = useState({})
    const [lastYearTaxCalc, setLastYearTaxCalc] = useState({})
    const [lastYearString, setLastYearString] = useState("")
    const [finalData, setFinalData] = useState({})
    const [allFinalData, setAllFinalData] = useState({})
    const [paymentAmount, setPaymentAmount] = useState(0)
    const [paymentBankId, setPaymentBankId] = useState("")
    const [paymentBankPicUrl, setPaymentBankPicUrl] = useState("")
    const [doPayeCoding, setDoPayeCoding] = useState(false)
    const [updateFinal, setUpdateFinal] = useState(false)
    const [changedPayeFigures, setChangedPayeFigures] = useState(false)
    const [generalError, setGeneralError] = useState({show:false, message:"", header:""})

    //const target_account = ['HMRC Shipley', '083210', '12001020'];
    const getProfile = () => {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${global.api_url}/getUserProfile.php?ts=${timeStamp}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                setFormData({
                    userProfile: json,
                    utr: json.utr,
                    amount: formData.amount,
                    bankId: formData.bankId
                });
            })
    };
    // const getBanks = () => {
    //     fetch(`https://${global.api_url}/truelayer/getPaymentBanks.php`)
    //         .then(res => res.json())
    //         .then(json => {
    //             setBanks(json);
    //         })
    // };
    // const getPicUrl = (id) => {
    //     const obj = banks.filter((bank) => (bank.id === id))[0];
    //     setPicUrl(obj.icon);
    // };
    const saveUserUTR = () => {
        const timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        let payload = formData.userProfile;
        payload.utr = formData.utr;

        fetch(`https://${global.api_url}/saveUserProfile.php?ts=${timeStamp}`, {
            method: "POST",
            headers: { token: token },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {
                getProfile();
            })
    };
    const pay = () => {

        const token = localStorage.getItem("token");

        const payload = {
            //bank_id: formData.bankId,
            name: target_account[0],
            sort_code: target_account[1],
            account_number: target_account[2],
            reference: formData.utr + 'K',
            amount: formData.amount
        };
        setShowLoading(true);
        fetch(`https://${global.api_url}/truelayer/getPaymentAuthLinkV3.php`,
            { method: "POST", headers: { token: token }, body: JSON.stringify(payload) }
        )
            .then(res => res.json())
            .then(json => {
                if (json.status === 'success') {
                    const authUrl = json.authUrl;
                    window.location = authUrl
                }
            })
    };
    const checkScreen = (str) => {
        if (!str.includes("/form") && screen === "form") {
            setScreen("initial");
            setShowLoading(false);
        }
        if (str.includes("/form") && screen === "initial") {
            setScreen("form");
            setShowLoading(false);
            setError(false);
        }
    };
    const validForm = () => {
        return formData.utr !== "" && formData.amount !== "" && formData.amount !== 0
    };

    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {

        console.log('getRecoilTaxYears')
        console.log(user_atom);

        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        console.log('unauthorized')
                        //props.history.push("/logout/logout");
                    }
                } else {
                    let today = new Date();
                    let currentYearID = 0;
                    let dateFilterStart = "";
                    let dateFilterEnd = "";
                    json.years.forEach(element => {
                        // const start = new Date(element.start_date);
                        // const end = new Date(element.end_date);

                        let start = new Date(element.start_date);
                        start.setUTCHours(0); start.setMinutes(0); start.setSeconds(0)

                        let end = new Date(element.end_date);
                        end.setUTCHours(23); end.setMinutes(59); end.setSeconds(59)

                        if (today >= start && today <= end) {
                            currentYearID = element.id;
                            dateFilterStart = element.start_date;
                            dateFilterEnd = element.end_date;
                        }
                    });


                    if (props.match.params.taxyear) {
                        const taxYear = props.match.params.taxyear
                        const yearElement = json.years.find(year => year.tax_year === taxYear)
                        currentYearID = yearElement.id
                    }


                    setTaxYearsAtom(
                        {
                            taxYears: json.years,
                            dateFilterStart: dateFilterStart,
                            dateFilterEnd: dateFilterEnd,
                            dateFilter: currentYearID
                        }
                    );


                }
            });
    }

    useEffect(() => {
        // if tax_years_atom is not empty object then set recoilFetched to true
        if (Object.keys(tax_years_atom).length > 0) {
            setRecoilFetched(true)
        }
    }, [tax_years_atom])

    const getReturns = () => {
        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        const fromDate = selectedYearData.start_date;
        const toDate = selectedYearData.end_date + 'T23:59:59:59Z'

        fetch(`https://${global.api_url}/getTaxSubmissions.php?ts=${timeStamp}`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        localStorage.removeItem("token");
                        props.history.push("/logout");
                    }
                } else {

                    console.log({ returns: json.returns })

                    // do we have a successful return for this year?
                    const successfulReturn = json.returns.find(element => {
                        if (element.tax_year === selectedYearData.tax_year && element.success == 'Y' && (element.test == "N" || (element.test == "Y" && element.response != "THIS IS A TESTNOTE"))) {
                            return element
                        }
                    })

                    console.log({ successfulReturn })

                    if (successfulReturn) {
                        console.log('have a return')
                        setHaveFullReturn(true)
                    } else {
                        setHaveFullReturn(false)
                    }

                    const currentYearElement = tax_years_atom.taxYears.find(year => year.id === tax_years_atom.dateFilter)  
                    // end_date is ISO timestamp e.g. 2022-04-05T23:59:59. So let's sub 1 year from this to get last year's end date then use that to find prevousYearElement
                    const lastYearEndDate = new Date(currentYearElement.end_date)
                    lastYearEndDate.setFullYear(lastYearEndDate.getFullYear() - 1)
                    const lastYearElement = tax_years_atom.taxYears.find(year => year.end_date.substring(0, 10) === lastYearEndDate.toISOString().substring(0, 10))

                    // do we have a successful return for last year?
                    const successfulReturnLastYear = json.returns.find(element => {
                        if (element.tax_year === lastYearElement.tax_year && element.success == 'Y' && (element.test == "N" || (element.test == "Y" && element.response != "THIS IS A TESTNOTE"))) {
                            return element
                        }
                    }
                    )

                    console.log({ successfulReturnLastYear })
                    if (successfulReturnLastYear) {
                        console.log('have a return for previous year')
                        setHaveLastYearReturn(true)
                    }

                }
            });
    }

    const getFinal = () => {

        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");
        fetch(`https://${global.api_url}/getTaxData.php?ts=${timeStamp}&tax_year_id=${selectedYear}&type=final`, {
            method: "GET",
            headers: { token: token }
        })
            .then(res => res.json())
            .then(json => {

                var new_data = {
                    provisional: false,
                    otherinfo: ""
                };

                const data = JSON.parse(json.data);

                if (data != null) {

                    new_data = {
                        underpaid_tax_for_earlier_years_included_in_code: data.underpaid_tax_for_earlier_years_included_in_code || 0,
                        underpaid_tax_for_year_included_in_future_code: data.underpaid_tax_for_year_included_in_future_code || 0,
                        tax_already_paid_on_account_for_current_year: data.tax_already_paid_on_account_for_current_year || 0,
                        paye_coding: (data.underpaid_tax_for_earlier_years_included_in_code && data.underpaid_tax_for_earlier_years_included_in_code > 0) || (data.underpaid_tax_for_year_included_in_future_code || data.underpaid_tax_for_year_included_in_future_code > 0),
                        any_other_payments: data.any_other_payments,
                    }

                }

                console.log('nd', new_data)

                setAllFinalData(data)
                setFinalData(new_data)

            })
    }

    const saveFinal = () => {

        let timeStamp = Math.floor(Date.now() / 1000);
        const token = localStorage.getItem("token");

        const payload = {
            ...allFinalData,
            ...finalData
        }

        console.log(payload);

        fetch(`https://${global.api_url}/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${selectedYear}&type=final`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {

                getTaxCalc(selectedYear, (json) => {
                    setTaxCalc(json)
                    setUpdate(false)
                    setShowLoading(false)
                    setUpdateFinal(false)
                    setChangedPayeFigures(false)
                })
              
            })

    }

    useEffect(() => {
        if (updateFinal) {
            setShowLoading(true)
            saveFinal();
        }
    },[updateFinal])

    useEffect(() => {
        if (recoilFetched) {
            const yearElement = tax_years_atom.taxYears.find(year => year.id === tax_years_atom.dateFilter)
            console.log('setting selectedYear ...')
            setSelectedYear(tax_years_atom.dateFilter)
            setSelectedYearData({ ...yearElement })

        }
    }, [tax_years_atom, recoilFetched])


    useEffect(() => {

        if (selectedYear && recoilFetched && selectedYearData.tax_year) {
            console.log('getting returns ... ', selectedYear, recoilFetched, selectedYearData)
            getReturns()
            getFinal()


            getTaxCalc(tax_years_atom.dateFilter, (json) => {
                setTaxCalc({ ...json })

                // now want to get tax calc for PREVIOUS year. So first we need ID of the previous year from the tax_years_atom
                const currentYearElement = tax_years_atom.taxYears.find(year => year.id === tax_years_atom.dateFilter)  
                // end_date is ISO timestamp e.g. 2022-04-05T23:59:59. So let's sub 1 year from this to get last year's end date then use that to find prevousYearElement
                const lastYearEndDate = new Date(currentYearElement.end_date)
                lastYearEndDate.setFullYear(lastYearEndDate.getFullYear() - 1)
                const lastYearElement = tax_years_atom.taxYears.find(year => year.end_date.substring(0, 10) === lastYearEndDate.toISOString().substring(0, 10))
                setLastYearString(lastYearElement.tax_year)
                getTaxCalc(lastYearElement.id, (json) => {
                    console.log('last year tax calc', json)
                    setLastYearTaxCalc(json)
                })

            });
        }

    }, [selectedYear, selectedYearData, recoilFetched])

    useEffect(() => {

        console.log(props)
        if (props.match.params.taxyear) {
            const taxYear = props.match.params.taxyear
            console.log('taxyear', taxYear)
        }

        if (!user_atom.token) {
            let userState = getLocalStorage()
            setUserAtom(userState)
            getRecoilTaxYears(userState.token, userState.agentId)
        } else {
            setRecoilFetched(true)
        }

        getProfile()
        //getBanks();


    }, [])

    const showPayments = () => {

        const target_account = ['HMRC Self Assessment Cumbernauld', '083210', '12001039'];
        const first_poa = taxCalc.first_payment_on_account

        const payment_amount = parseFloat(first_poa + taxCalc.tax_to_pay + taxCalc.class2_nic + taxCalc.class4_nic + taxCalc.StudentLoanRepaymentDue - finalData.tax_already_paid_on_account_for_current_year).toFixed(2)

        const yearString = selectedYearData.hmrc_year_string
        const nextYear = parseInt(yearString.substring(0, 4)) + 2
        const nextYearString = (parseInt(yearString.substring(0, 4)) + 1) + "-" + (parseInt(yearString.substring(5, 7)) + 1)

        const prevNextYear = parseInt(lastYearString.substring(0, 4)) + 2

        const askPAYECoding = taxCalc.employment_salary > 0;


        // const getBanks = () => {
        //     fetch(`https://${global.api_url}/truelayer/getPaymentBanks.php`)
        //         .then(res => res.json())
        //         .then(json => {
        //             //this.setState({ banks: json, paymentBankId:"", payment_amount: payment_amount })
        //             setBanks(json)
        //             setPaymentBankId("")
        //             setPaymentAmount(payment_amount)
        //         })
        // };

        // const getPicUrl = (id) => {
        //     const obj = banks.filter((bank) => (bank.id === id))[0];
        //     setPaymentBankPicUrl(obj.icon)
        // };

        const pay = () => {

            if (changedPayeFigures) {
                //alert('Please click the update button in the PAYE coding section to review the figures')
                setGeneralError({show:true, message:"Please click the update button in the PAYE coding section to review the figures", header:"Save PAYE coding changes"})
                return;
            }


            if (!validForm()) {
                //alert("Please select a bank and enter a valid amount")
                setGeneralError({show:true, message:"Please enter a valid amount greater than 0", header:"Error"})
                return;
            }

            const payload = {
                //bank_id: paymentBankId,
                name: target_account[0],
                sort_code: target_account[1],
                account_number: target_account[2],
                reference: formData.utr + 'K',
                amount: paymentAmount
            };

            fetch(`https://${global.api_url}/truelayer/getPaymentAuthLinkV3.php`,
                { method: "POST", body: JSON.stringify(payload) }
            )
                .then(res => res.json())
                .then(json => {
                    if (json.status === 'success') {
                        const authUrl = json.authUrl;
                        //window.location = authUrl
                        //open new tab at authUrl
                        window.open(authUrl, '_blank');
                    }
                })
        };

        const validForm = () => {

            console.log({ formData })
            console.log({ paymentAmount })
            console.log({ paymentBankId })

            return formData.utr !== "" && paymentAmount !== "" && paymentAmount !== 0
        };


        //if (!banks) { getBanks() }

        const bankSelectorProps = {
            options: banks,
            getOptionLabel: (banks) => banks.displayable_name,
        };


        const renderAmount = (element) => {
            const prefix = element.type === 'CREDIT' ? "+ £" : "£"
            if (isNaN(element.amount)
                || element.amount === null
                || element.amount === undefined
                || element.amount === '') {
                return prefix + '0.00';
            }
            return (
                prefix +
                Number(element.amount)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
        }


        const taxesDue = taxCalc.tax_to_pay + taxCalc.class2_nic + taxCalc.class4_nic - taxCalc.StudentLoanRepaymentDue - taxCalc.PostgradLoanRepaymentDue

        return (
            <div style={{ margin: 'auto', maxWidth: 600, textAlign: 'left' }}>
                <h2>Pay your tax</h2>


                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        Total tax due for {yearString}
                    </div>
                    <div style={{ flex: 'unset' }}>
                        {renderAmount({ amount: taxesDue })}
                    </div>
                </div>

                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        Student loan repayment due
                    </div>
                    <div style={{ flex: 'unset' }}>
                        {renderAmount({ amount: taxCalc.StudentLoanRepaymentDue })}
                    </div>
                </div>

                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        Postgraduate loan repayment due
                    </div>
                    <div style={{ flex: 'unset' }}>
                        {renderAmount({ amount: taxCalc.PostgradLoanRepaymentDue })}
                    </div>
                </div>

                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        Payment already made on account for {yearString}
                    </div>
                    <div style={{ flex: 'unset' }}>
                        <MoneyInput value={finalData.tax_already_paid_on_account_for_current_year} onChange={(v) => { setFinalData({ ...finalData, tax_already_paid_on_account_for_current_year: v }) }}  style={{width:150}}/>
                        {/* {renderAmount({ amount: finalData.tax_already_paid_on_account_for_current_year })} */}
                    </div>
                </div>

                {haveLastYearReturn && (
                    <div className="columns pay-tax" style={{ flex: 1, marginTop: 10, marginLeft:10 }}>
                        According to your {lastYearString} tax return, payments on account for {yearString} were a total of {renderAmount({amount: (lastYearTaxCalc.first_payment_on_account||0)*2})}{". "}
                        {renderAmount({amount: lastYearTaxCalc.first_payment_on_account||0})} was due on 31 January {prevNextYear} and {renderAmount({amount: lastYearTaxCalc.first_payment_on_account||0})} was due on 31 July {prevNextYear}. 

                    </div>
                )}

                {askPAYECoding && (
                    <div style={{ padding: 10, borderWidth: 1, borderRadius: 4, borderStyle: 'solid', marginRight: 0 }} className="mt-4">
                        <div className="columns pay-tax mt-2" style={{ flex: 1, marginTop: 0 }}>
                            <div className="label left-label">
                                I had my tax code adjusted during the year and received a P2 'PAYE Coding Notice'
                            </div>
                            <div style={{ flex: 'unset' }}>
                                <IonCheckbox checked={doPayeCoding} onIonChange={e => setDoPayeCoding(e.detail.checked)} />
                            </div>
                        </div>

                        {doPayeCoding && (
                            <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                                <div className="label left-label">
                                    Underpaid tax for earlier years included in your tax code for {yearString}. Enter the amount shown as 'amount of underpaid tax for earlier years' from your P2
                                </div>
                                <div style={{ flex: 'unset' }}>
                                    <MoneyInput value={finalData.underpaid_tax_for_earlier_years_included_in_code} onChange={(v) => { setChangedPayeFigures(true); setFinalData({ ...finalData, underpaid_tax_for_earlier_years_included_in_code: v }) }} style={{width:150}}/>
                                </div>
                            </div>)}

                        {doPayeCoding && (
                            <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                                <div className="label left-label">
                                    Underpaid tax for {yearString} included in your tax code for {nextYearString}. Enter the amount shown as 'estimated underpayment for {yearString}' from your P2
                                </div>
                                <div style={{ flex: 'unset' }}>
                                    <MoneyInput value={finalData.underpaid_tax_for_year_included_in_future_code} onChange={(v) => { setChangedPayeFigures(true); setFinalData({ ...finalData, underpaid_tax_for_year_included_in_future_code: v }) }} style={{width:150}} />
                                </div>
                            </div>)}

                        {doPayeCoding && (

                            <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                                <div>
                                    &nbsp;
                                </div>
                                <div style={{ flex: 'unset' }}>
                                    <IonButton
                                        style={{ height: 30 }}
                                        className="ion-button-money"
                                        onClick={() => {
                                            setUpdateFinal(true)                                 
                                        }}>
                                        Update
                                    </IonButton>
                                </div>
                            </div>)} 
                        

                    </div>
                )}


                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label" style={{ fontWeight: 'bold' }}>
                        Total due for {yearString}

                    </div>
                    <div style={{ flex: 'unset', fontWeight: 'bold' }}>
                        {renderAmount({ amount: taxesDue + taxCalc.StudentLoanRepaymentDue - finalData.tax_already_paid_on_account_for_current_year })}
                    </div>
                </div>



                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        First payment on account for {nextYearString} due 31 January {nextYear}
                    </div>
                    <div style={{ flex: 'unset' }}>
                        {renderAmount({ amount: first_poa })}
                    </div>
                </div>

                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label" style={{ fontWeight: 'bold' }}>
                        Total due on 31 January {nextYear}
                    </div>
                    <div style={{ flex: 'unset', fontWeight: 'bold' }}>
                        {renderAmount({ amount: first_poa + taxesDue + taxCalc.StudentLoanRepaymentDue - finalData.tax_already_paid_on_account_for_current_year })}
                    </div>
                </div>

                <div className="columns pay-tax mt-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label" style={{ fontWeight: 'bold' }}>
                        Second payment on account for {nextYearString} due 31 July {nextYear}
                    </div>
                    <div style={{ flex: 'unset', fontWeight: 'bold' }}>
                        {renderAmount({ amount: first_poa })}
                    </div>
                </div>

                <p className="label">If you have already paid something towards this for {yearString} or {nextYearString}, you will be able to reduce the amount due accordingly</p>

                <div className="columns pay-tax" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        Reference number (your UTR followed by K)
                    </div>
                    <div style={{ flex: 'unset' }}>
                        {formData.utr}K
                    </div>
                </div>

                <div className="columns pay-tax mt-4 mb-4" style={{ flex: 1, marginTop: 0 }}>
                    <div className="label left-label">
                        Amount you would like to pay now
                    </div>
                    <div style={{ flex: 'unset' }}>
                        <MoneyInput value={paymentAmount} onChange={(v) => { setPaymentAmount(v) }} style={{width:150}}/>
                    </div>
                </div>

                {/* <div>
                    <div className="input-container bank-selector-container mt-4" style={{ border: "1px solid #3f90b0", borderRadius: "3px" }} lines="none" >
                        <Autocomplete
                            {...bankSelectorProps}
                            className="bank-selector"
                            autoComplete
                            style={{ marginLeft: "3px" }}
                            includeInputInList
                            onChange={(e, value) => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: formData.utr,
                                    amount: formData.amount,
                                    bankId: value ? value.id : ""
                                });
                                setPaymentBankId(value ? value.id : "")
                                if (value) {
                                    getPicUrl(value.id);
                                }
                            }}
                            renderInput={(params) => {
                                return (<div className={`display-inline-flex ${formData.bankId ? "selected" : ""}`}>
                                    <div className={formData.bankId ? "display-flex" : "display-none"}>
                                        <div style={{
                                            height: "20px",
                                            width: "20px",
                                            backgroundImage: `url(${picUrl})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            marginTop: "auto",
                                            marginBottom: "6px",
                                            marginRight: "10px"
                                        }} />
                                    </div>
                                    <TextField {...params} label="Choose your bank" placeholder="Search and select" margin="normal" />
                                </div>)
                            }}
                            renderOption={(option) => {
                                return (
                                    <div>
                                        <span style={{
                                            height: "20px",
                                            width: "20px",
                                            backgroundImage: `url(${option.icon})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            display: "inline-block",
                                            verticalAlign: "middle"
                                        }} />
                                        <span style={{ marginLeft: "20px" }}>{option.displayable_name}</span>
                                    </div>
                                )
                            }}
                        />
                    </div>

                </div> */}

                <div className="columns pay-tax mt-4 mb-4" style={{ flex: 1, marginTop: 0 }}>
                    <div>
                        &nbsp;
                    </div>
                    <div style={{ flex: 'unset' }}>

                        <IonButton
                            style={{ height: 30 }}
                            className="ion-button-money"
                            onClick={() => {
                                saveFinal();
                                pay();
                            }}>
                            Proceed to payment 
                        </IonButton>
                    </div>
                </div>

                <p>Payment will be made directly from your bank account using Open Banking. <a href="https://www.gov.uk/pay-self-assessment-tax-bill" target="_new">More ways to pay</a></p>

            </div>


        )
    }

    const buildInitialScreen = () => (
        <div style={{ width: '100%', margin: 'auto' }} >
            <IonRow>

                <IonCol className="text-center">
                    <p><span className="strong-text">Pay HMRC. Safe, Secure, Trusted.</span></p>
                    {/* <p><span className="strong-text">Safe. Secure. Trusted.</span></p> */}
                    <p>
                        <img style={{ width: 50, marginTop: 10 }} src={require("../../images/trusted-icon.png").default} />
                    </p>

                </IonCol>
            </IonRow>
            <IonRow>
                {/* <IonCol size-sm="3" /> */}
                <IonCol className="text-center">
                    <IonButton
                        style={{ height: 30, marginTop: 0 }}
                        className="ion-button-money"
                        onClick={() => {
                            setScreen("form");
                            window.history.pushState(null, "HMRC Payment", "/hmrcpay/form")
                        }}>
                        Get started
                    </IonButton>
                </IonCol>
            </IonRow>

            {/* <IonRow>
            <IonCol className="ion-align-self-center" style={{ textAlign: 'center', marginTop: 20 }}>

                <IonText style={{ fontSize: "0.8rem", paddingBottom: 20 }}>Powered by SMART from</IonText> <br />

                <a href="https://www.untied.io/smart/" target="_new">
                    <img style={{ width: '95%', marginTop: 18, maxWidth: 350 }} src={require("../../images/fractal-untied-1.png").default} />
                </a>

            </IonCol>
        </IonRow> */}

        </div>);
    const buildFormScreen = () => (<>
        <div style={{ width: '100%', margin: 'auto' }} >
            <IonRow>
                <IonCol>
                    <IonItem className="input-container" style={{ border: "1px solid #3f90b0", borderRadius: "3px" }} lines="none">
                        <IonLabel style={{ marginLeft: "3px" }} position="floating">Unique Taxpayer Reference:</IonLabel>
                        <IonInput
                            type="text"
                            placeholder=""
                            value={formData.utr}
                            style={{ marginLeft: "3px" }}
                            onIonChange={e => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: e.detail.value,
                                    amount: formData.amount,
                                    bankId: formData.bankId
                                })
                            }} />
                        <IonIcon color="primary" className="mr-0" slot="end" icon={InfoIcon}
                            onClick={() => {
                                setShowUTR(true)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem className="input-container" style={{ border: "1px solid #3f90b0", borderRadius: "3px" }} lines="none">
                        <IonLabel style={{ marginLeft: "3px" }} position="floating">Amount you are paying to HMRC</IonLabel>
                        <IonInput
                            type="number"
                            placeholder="0.00"
                            value={formData.amount}
                            style={{ marginLeft: "3px" }}
                            onIonChange={(e) => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: formData.utr,
                                    amount: e.detail.value,
                                    bankId: formData.bankId
                                })
                            }}><IonText style={{ marginTop: 8, marginRight: 3, marginLeft: 3 }}>&pound;</IonText></IonInput>
                    </IonItem>
                </IonCol>
            </IonRow>
            {/* <IonRow>
                <IonCol>
                    <IonItem className="input-container bank-selector-container" style={{ border: "1px solid #3f90b0", borderRadius: "3px" }} lines="none" >
                        <Autocomplete
                            {...bankSelectorProps}
                            className="bank-selector"
                            autoComplete
                            style={{ marginLeft: "3px" }}
                            includeInputInList
                            onChange={(e, value) => {
                                setFormData({
                                    userProfile: formData.userProfile,
                                    utr: formData.utr,
                                    amount: formData.amount,
                                    bankId: value ? value.id : ""
                                });
                                if (value) {
                                    getPicUrl(value.id);
                                }
                            }}
                            renderInput={(params) => {
                                return (<div className={`display-inline-flex ${formData.bankId ? "selected" : ""}`}>
                                    <div className={formData.bankId ? "display-flex" : "display-none"}>
                                        <div style={{
                                            height: "20px",
                                            width: "20px",
                                            backgroundImage: `url(${picUrl})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            marginTop: "auto",
                                            marginBottom: "6px",
                                            marginRight: "10px"
                                        }} />
                                    </div>
                                    <TextField {...params} label="Choose your bank" placeholder="Search and select" margin="normal" />
                                </div>)
                            }}
                            renderOption={(option) => {
                                return (
                                    <div>
                                        <span style={{
                                            height: "20px",
                                            width: "20px",
                                            backgroundImage: `url(${option.icon})`,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            display: "inline-block",
                                            verticalAlign: "middle"
                                        }} />
                                        <span style={{ marginLeft: "20px" }}>{option.displayable_name}</span>
                                    </div>
                                )
                            }}
                        />
                    </IonItem>

                </IonCol>
            </IonRow> */}
            <IonRow style={{paddingTop:10}}>
                {/* <IonCol size-sm="3" /> */}
                <IonCol className="text-center">
                    <IonButton
                        style={{ height: 30 }}
                        className="ion-button-money"
                        onClick={() => {
                            if (validForm()) {
                                saveUserUTR();
                                pay();
                            } else {
                                setError(true);
                            }
                        }}>
                        Proceed to payment
                    </IonButton>
                </IonCol>
                {/* <IonCol size-sm="3" /> */}
            </IonRow>
            <IonRow>
                <IonCol>
                    <p className="text-center">Pressing "Next" will safely take you to your bank, where you can authorise this payment.</p>
                    <p className="text-center">Payments are made to the default HMRC account for income tax.</p>
                </IonCol>
            </IonRow>
        </div>
    </>);

    const bankSelectorProps = {
        options: banks,
        getOptionLabel: (banks) => banks.displayable_name,
    };


    return (
        <React.Fragment>
            <div className="tax-return-page-container">
                <MiniDrawer />

                <Container className="tax-return-page-content">

                    {SelectionBar(recoilFetched && tax_years_atom, update, false)}

                    {haveFullReturn && showPayments()}

                    {!haveFullReturn && (
                        <div className="hmrc-payment">
                            {screen == "initial" && buildInitialScreen()}
                            {screen == "form" && buildFormScreen()}
                        </div>
                    )}

                </Container>


                <IonAlert
                    isOpen={showUTR}
                    onDidDismiss={() => {
                        setShowUTR(false)
                    }}
                    header={"Unique Tax Reference"}
                    subHeader={""}
                    message={'UTR stands for Unique Taxpayer Reference.  Your UTR is a unique number that identifies you to HMRC. ' +
                        'Your UTR number is assigned automatically by HMRC when you register for self assessment tax returns. ' +
                        'If you are not yet registered for self assessment you will need to do so if you need to submit a tax return. ' +
                        'You can do that <a href="https://www.gov.uk/log-in-file-self-assessment-tax-return/register-if-youre-not-self-employed">here on the HMRC website</a>.'}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <IonAlert
                    isOpen={error}
                    onDidDismiss={() => {
                        setError(false)
                    }}
                    header={"Payment form has errors"}
                    subHeader={""}
                    message={'Please ensure you have provided a valid reference and an amount greater than zero.'}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <IonAlert
                    isOpen={generalError.show}
                    onDidDismiss={() => {
                        setGeneralError(false)
                    }}
                    header={generalError.header}
                    subHeader={""}
                    message={generalError.message}
                    buttons={[{
                        text: 'OK',
                        role: 'OK',
                        cssClass: 'shadow text-center col-12'
                    }]}
                />
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'}
                    duration={10000}
                />
            </div >
        </React.Fragment>
    );
};

export default HMRCPayment;
