import React, { useState, useRef, useEffect, Component } from "react";

import "./App.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Redirect } from "react-router-dom";
import { Mixpanel } from "./mixpanel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from '@material-ui/core/Modal';
import Select from "@material-ui/core/Select";
import { getTimestamp } from './components/timeStamp.js'

import { userAtom, userErrorAtom, userCheckoutAtom, taxYearsAtom, profileAtom } from './atom.js'
import { useRecoilState, useRecoilValue } from 'recoil'



import {
    IonAlert,
} from '@ionic/react';


import { useToasts } from 'react-toast-notifications'

function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts()
        return <Component {...props} {...toastFuncs} />;
    }
}

function EyeIcon(props) {
    if (props.open === true) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
        )
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" /><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" /></svg>
        )
    }
}

function isEdgeOrIE() {
    const IE = (window.navigator.userAgent.indexOf('MSIE') > 0 || !!window.navigator.userAgent.match(/Trident.*rv:11\./));
    const Edge = (/Edge\/\d./i.test(window.navigator.userAgent))

    console.log('IE:', IE);
    console.log('Edge:', Edge);

    return IE || Edge;
}

const AgentLogin = (props) => {

    // recoil state
    const [user, setUser] = useRecoilState(userAtom)
    const [userError, setUserError] = useRecoilState(userErrorAtom)
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
    // end of recoil state

    const firstTimeRender = useRef(true)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [agent, setAgent] = useState({
        id: '',
        email: ''
    })
    const [leavingPage, setLeavingPage] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [agree, setAgree] = useState(false)
    const [doingMagiclink, setDoingMagicLink] = useState(false)
    const [devserver, setDevServer] = useState(false)
    const [secretcode, setSecretcode] = useState("")
    const [loggedin, setLoggedin] = useState(false)
    const [goBack, setGoBack] = useState(false)
    const [ip, setIp] = useState("")
    const [years, setYears] = useState([])
    const [headingText, setHeadingText] = useState("Personal taxes, untied")
    const [placeholder, setPlaceholder] = useState("Have a secret code? Paste it here.")
    const [showHelp, setShowHelp] = useState(false)
    const [helptext, setHelptext] = useState("")
    const [secureEntry, setSecureEntry] = useState(true)
    const [showUserList, setShowUserList] = useState(false)
    const [changingPassword, setChangingPassword] = useState(false)
    const [users, setUsers] = useState([])
    const [userId, setUserId] = useState('')
    // const [showError, setShowError] = useState(false)
    const [error, setError] = useState({
        show: false,
        errorTitle: "",
        errorSubTitle: "",
        errorText: ""
    })
    const [qrCode, setQRCode] = useState("");
    const [mfaCode, updateMFACode] = useState("");
    const [doMFA, setDoMFA] = useState(false)
    const [mfaSecret, setMFASecret] = useState("")

    const withToast = (Component) => {
        return function WrappedComponent(props) {
            const toastFuncs = useToasts()
            return <Component {...props} {...toastFuncs} />;
        }
    }

    const EyeIcon = (props) => {
        if (props.open === true) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
            )
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" /><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" /></svg>
            )
        }
    }

    const isEdgeOrIE = () => {
        const IE = (window.navigator.userAgent.indexOf('MSIE') > 0 || !!window.navigator.userAgent.match(/Trident.*rv:11\./));
        const Edge = (/Edge\/\d./i.test(window.navigator.userAgent))
        return IE || Edge;
    }

    // useEffect(() => {
    //     firstTimeRender.current = false
    //     // if (props.match != null) {
    //     //     console.log('here')
    //     //     if (props.match.params.token) {
    //     //         let token = props.match.params.token;
    //     //         let email = props.match.params.email;
    //     //         auth(email, token);
    //     //     }

    //     //     if (props.match.params.logout) {
    //     //         console.log('logout')
    //     //         localStorage.clear()
    //     //         // localStorage.removeItem("token");
    //     //         // localStorage.removeItem('IP_ADDRESS');
    //     //         // localStorage.removeItem('admin');
    //     //         // localStorage.removeItem('agentId');
    //     //         //localStorage.removeItem('userid');

    //     //     }
    //     // }

    //     //setPageLoaded(true)

    //     // //get user's IP
    //     // fetch("https://api.ipify.org?format=json")
    //     //     .then(res => res.json())
    //     //     .then(json => {
    //     //         localStorage.setItem("IP_ADDRESS", json.ip);
    //     //         setIp(json.ip)
    //     //         setPageLoaded(true)
    //     //     });

    // }, [])

    useEffect(() => {
        if (JSON.stringify(user) !== "{}") {
            setLeavingPage(true);
        }
    }, [user])

    useEffect(() => {
        if (pageLoaded && leavingPage) {
            props.removeAllToasts();
            getRecoilTaxYears(user.token, user.agent)
        }
    }, [leavingPage])

    useEffect(() => {
        if (pageLoaded) {
            props.history.push('/transactions')
        }
    }, [tax_years_atom, pageLoaded])

    const updateUsername = (event) => {
        setUsername(event.target.value)
    };

    const updateSecretcode = (event) => {
        setSecretcode(event.target.value)
    }

    const validateEmail = (email) => {
        // eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const doSecretCode = () => {
        // agentAuth(username, secretcode)
        auth(username, secretcode);
    }

    const auth = (email, token, usePassword = true, mfa_code="") => {

        var body = JSON.stringify({ email, token, mfa_code });
        if (usePassword) {
            body = JSON.stringify({ email, password: token, mfa_code });
        }
        // props.agentAuth(email, token, usePassword)

        fetch(`https://${global.api_url}/agent/agentLogin.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: body,
            headers: { partnerId: "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a" }
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === "success") {

                    console.log(json)
                    console.log(mfa_code)


                    if (json.mfa=="Y" && mfa_code==="") {

                        //did we get a qr code back ... 
                        setDoMFA(true)
                        setQRCode(json.mfa_qr)
                        setMFASecret(json.mfa_secret)

                    } else {

                        let userId = -1
                        if (json.users.length != 0) {
                            userId = json.users[0].id;
                        }

                        // sort users list by email attribute
                        json.users.sort((a, b) => {
                            if (a.email < b.email) {
                                return -1;
                            }
                            if (a.email > b.email) {
                                return 1;
                            }
                            return 0;
                        }
                        )

                        //now we need to list the users ....
                        setUsers(json.users)
                        setShowUserList(true)
                        setUserId(userId)
                        setAgent({ id: json.id, email: json.email })

                    }

                } else {

                    if (!usePassword) {
                        auth(email, token, true);
                        return;
                    }

                    // setShowError(true)
                    setError({
                        show: true,
                        errorTitle: "Unauthorised",
                        errorSubTitle: "",
                        errorText: "There was a problem logging you in, please check you have entered the correct email and secret code or password."
                    })
                    Mixpanel.track("Unsuccessful login");

                }
            });
    }

    const getRecoilTaxYears = (token, agentId) => {
        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token, agentId }
        })
            .then(response => response.json())
            .then(json => {

                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        console.log('logout')
                        props.history.push("/logout/logout");
                    }
                } else {
                    let today = new Date();
                    let currentYearID = 0;
                    let dateFilterStart = "";
                    let dateFilterEnd = "";
                    json.years.forEach(element => {
                        const start = new Date(element.start_date);
                        const end = new Date(element.end_date);

                        if (today >= start && today <= end) {
                            currentYearID = element.id;
                            dateFilterStart = element.start_date;
                            dateFilterEnd = element.end_date;
                        }
                    });
                    setTaxYearsAtom(
                        {
                            taxYears: json.years,
                            dateFilterStart: dateFilterStart,
                            dateFilterEnd: dateFilterEnd,
                            dateFilter: currentYearID
                        });
                }
            });
    }

    const connectUser = () => {
        const user_info = users.find((user) => {
            return user.id == userId
        })
        setUser({
            ...user_info,
            agent: agent.id
        })
        // props.connectUser(user)
        // adding agent boolean to localStorage
        localStorage.setItem('agent', agent.id)
        localStorage.setItem('agentEmail', agent.email)
        localStorage.setItem('guid', user_info.id);
        //localStorage.setItem('userid', json.id);
        localStorage.setItem("token", user_info.token);
        localStorage.setItem("username", user_info.email);
        localStorage.setItem("agentEmail", username)
        localStorage.setItem("mtd", user_info.mtd);
        localStorage.setItem('timeLoggedIn', Math.floor((new Date()).getTime() / 1000))

        setPageLoaded(true)

    }

    const changePassword = () => {

        const body = JSON.stringify({ email: username, password: secretcode, newPassword: newPassword, confirmPassword: confirmPassword });


        fetch(`https://${global.api_url}/agent/changePassword.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: body,
            headers: { partnerId: "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a" }
        })
            .then(res => res.json())
            .then(json => {
                console.log(json);
                if (json.status === "success") {
                    alert("password changed ... ");
                    setShowUserList(false)
                    setUsername("")
                    setSecretcode("")
                    setChangingPassword(false)
                } else {
                    alert(json.info)
                }
            });

    }

    const doBottomView = () => {

        if (doMFA && !showUserList) {
            return (
                
                <div>
                {qrCode!=="" && (
                    <>
                        <p style={{ color: 'white' }}>Please use the Google Authenticator app to scan this QR code and enable MFA:</p>

                        <img src={qrCode} />

                        <p style={{ color: 'white' }}>Alternatively enter this secret which you can also store securely as a backup:</p>

                        <textarea value={mfaSecret}/>
                    </>
                )}

            
                <p style={{ color: 'white' }}>Please enter authentication code:</p>

                <TextField
                    style={styles.input}
                    //onKeyPress={handleKeyDownSecret}
                    autoCapitalize="none"
                    value={mfaCode}
                    onChange={event => updateMFACode(event.target.value)}
                    type="password"
                    placeholder="Code"
                    margin="none"
                    InputProps={{ disableUnderline: true }}
                />


                <div
                    style={{
                        flexDirection: "row",
                        flex: 1,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                />

                <Button
                    style={styles.buttonContainer}
                    size="small"
                    onClick={() => auth(username, secretcode, true, mfaCode)}
                >
                    Login
                </Button>


                </div>

            )
        }


        if (showUserList) {

            return (
                <div>

                    <p style={{ color: 'white' }}>Please select client:</p>

                    <select value={userId} style={{ marginBottom: '50px' }}
                        onChange={(event) => setUserId(event.target.value)}>

                        {users.map((element) => {

                            return (<option key={element.id} value={element.id}>{element.email} - {element.name}</option>)

                        })}

                    </select>

                    <Button
                        style={styles.buttonContainer}
                        size="small"
                        onClick={() => {
                            connectUser();
                        }}
                    >
                        Continue
                    </Button>


                    <a style={{ color: "white", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => setChangingPassword(!changingPassword)}>Change password</a>

                    {changingPassword && (
                        <div>

                            <div style={{ paddingTop: 10 }}>
                                <TextField
                                    style={styles.passInput}
                                    label="New password"
                                    value={newPassword}
                                    onChange={(event) => setNewPassword(event.target.value)}
                                    inputProps={{ style: { color: 'white' } }}
                                    InputLabelProps={{ style: { color: 'white' } }}
                                    type="password"
                                />
                            </div>
                            <div>
                                <TextField
                                    style={styles.passInput}
                                    label="Confirm password"
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    inputProps={{ style: { color: 'white' } }}
                                    InputLabelProps={{ style: { color: 'white' } }}
                                    type="password"
                                />
                            </div>
                            <div>
                                <Button
                                    variant="contained" color="default"
                                    style={{ marginTop: 10 }}
                                    size="small"
                                    onClick={() => changePassword()}
                                >
                                    Set Password
                    </Button>
                            </div>
                        </div>

                    )}


                </div>


            )
        }

        return (
            <div>

                <p style={{ color: 'white' }}>Enter your agent login:</p>

                <TextField
                    style={styles.input}
                    autoCapitalize="none"
                    value={username}
                    onChange={event => updateUsername(event)}
                    placeholder="Email address"
                    margin="none"
                    InputProps={{ disableUnderline: true }}
                />

                <div style={{ paddingTop: 5 }} />

                <TextField

                    style={styles.input}

                    autoCapitalize="none"
                    value={secretcode}
                    onChange={event => updateSecretcode(event)}
                    type="password"
                    placeholder="Password"
                    margin="none"
                    InputProps={{ disableUnderline: true }}
                />

                <div
                    style={{
                        flexDirection: "row",
                        flex: 1,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                />

                <Button
                    style={styles.buttonContainer}
                    size="small"
                    onClick={() => auth(username, secretcode, true)}
                >
                    Login
          </Button>

            </div>
        );

    }

    const scale = (val) => {
        return val;
    }

    const styles = {
        root: {
            background: "black"
        },
        card: {
            minWidth: 675
        },
        bullet: {
            display: "inline-block",
            margin: "0 2px",
            transform: "scale(0.8)"
        },
        title: {
            fontSize: 14
        },
        pos: {
            marginBottom: 12
        },
        buttonContainer: {
            paddingVertical: 12,
            width: "100%",
            marginBottom: 15,
            height: 36,
            borderRadius: 4,
            backgroundColor: "#c7ff00",
            color: "#0C90B2",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 700
        },
        buttonContainer2: {
            paddingVertical: 12,
            width: "100%",
            marginBottom: 15,
            height: 36,
            borderRadius: 4,
            color: "#ffffff",
            textTransform: "none",
            fontSize: 14,
            fontWeight: 700,
            borderColor: "#FFFFFF",
            borderStyle: "solid",
            borderWidth: scale(1)
        },
        buttonText: {
            color: "#0C90B2",
            textAlign: "center",
            fontWeight: "700"
        },
        input: {
            width: "95%",
            //height: scale(48),
            borderRadius: scale(4),
            borderColor: "#ffffff",
            borderStyle: "solid",
            borderWidth: scale(1),
            backgroundColor: "#84C8D9",
            color: "#ffffff",
            paddingLeft: 16,
            placeholderTextColor: "#ffffff",
            textAlign: "center",
            fontSize: scale(14),
            marginLeft: 0
            //marginRight: 16
        },
        passInput: {
            color: "#ffffff",
            paddingLeft: 16,
            placeholderTextColor: "#ffffff",
            fontSize: scale(14),
        },
        itIs: {
            color: "#c7ff00",
            fontSize: 18,
            fontWeight: "500",
            letterSpacing: 0.54,
            textAlign: "center",
            alignSelf: "center"
        }
    }


    return (
        <div
            style={{
                backgroundColor: "#FAFAFC",
                width: "100%",
                height: "120vh",
                paddingTop: 30
            }}
        >

            <div
                style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
            >
                <Card
                    style={{ backgroundColor: "#0c90b2", width: 400, borderRadius: 5 }}
                >
                    <CardContent>
                        <div
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                flex: 0.6,
                                justifyContent: "center",
                                textAlign: "center"
                            }}
                        >
                            <img
                                style={{
                                    width: "100%",
                                    paddingLeft: -40,
                                    paddingTop: 20
                                }}
                                alt=""
                                src={require("./images/untied-word-white.svg").default}
                            />
                        </div>
                        <p
                            style={{
                                fontSize: 18,
                                color: "#ffffff",
                                paddingBottom: 30,
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                textTransform: "none",
                                fontWeight: 700
                            }}
                        >
                            {headingText}
                        </p>
                        {doBottomView()}
                    </CardContent>
                </Card>
            </div>


            <IonAlert
                isOpen={error.show}
                onDidDismiss={() => setError({
                    show: false,
                    errorTitle: "",
                    errorSubTitle: "",
                    errorText: ""
                })}
                header={error.errorTitle}
                subHeader={error.errorSubTitle}
                message={error.errorText}
                buttons={['OK']}
            />
        </div>

    );



    // end of functional component
}

// class AgentLogin extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             agent:{
//                 id:'',
//                 email:''      
//             },
//             username: "",
//             password: "",
//             agree: false,
//             doingMagiclink: false,
//             devserver: false,
//             secretcode: "",
//             loggedin: false,
//             goBack: false,
//             ip: "",
//             heading_text: "Personal taxes, untied",
//             placeholder: "Have a secret code? Paste it here.",
//             helptext: "",
//             secureEntry: true,
//             showUserList: false,
//             changePassword: false,
//             users: [],
//         };
//     }

//     componentDidMount() {
//         if (this.props.match != null) {
//             if (this.props.match.params.token) {
//                 let token = this.props.match.params.token;
//                 let email = this.props.match.params.email;
//                 this.auth(email, token);
//             }

//             if (this.props.match.params.logout) {
//                 localStorage.removeItem("token");
//                 localStorage.removeItem('IP_ADDRESS');
//                 localStorage.removeItem('admin');
//                 localStorage.removeItem('agentId');
//                 //localStorage.removeItem('userid');

//             }
//         }

//         //get user's IP
//         fetch("https://api.ipify.org?format=json")
//             .then(res => res.json())
//             .then(json => {
//                 localStorage.setItem("IP_ADDRESS", json.ip);
//                 this.setState({ ip: json.ip })
//             });
//     }

//     componentWillUnmount(){
//         this.props.removeAllToasts()
//         // this.props.getTaxYears(this.props.auth.auth.token, this.props.auth.auth.agentId)
//       }


//     _updateUsername = name => event => {
//         this.setState({
//             [name]: event.target.value
//         });
//     };

//     validateEmail(email) {
//         // eslint-disable-next-line
//         var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//         return re.test(String(email).toLowerCase());
//     }


//     _doSecretCode() {
//         this.auth(this.state.username, this.state.secretcode);
//     }

//     auth(email, token, usePassword = true) {
//         var timeStamp = Math.floor(Date.now() / 1000);
//         var body = JSON.stringify({ email: email, token: token });
//         if (usePassword) {
//             body = JSON.stringify({ email: email, password: token });
//         }

//         this.props.agentAuth(email, token, usePassword)


//         console.log(body)

//         fetch(`https://${global.api_url}/agent/agentLogin.php?ts=${timeStamp}`, {
//             method: "POST",
//             body: body,
//             headers: { partnerId: "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a" }
//         })
//             .then(res => res.json())
//             .then(json => {
//                 console.log(json);
//                 if (json.status === "success") {
//                     var userId = -1
//                     if (json.users.length != 0 ) {
//                         userId = json.users[0].id;
//                     }

//                     //now we need to list the users ....
//                     this.setState({ 
//                         users: json.users,
//                         showUserList: true,
//                         userId: userId,
//                         agent: {id: json.id, email:json.email}

//                      })


//                 } else {

//                     if (!usePassword) {
//                         this.auth(email, token, true);
//                         return;
//                     }

//                     //alert("Unauthorised Login");
//                     this.setState({ showError: true, errorTitle: "Unauthorised", errorSubTitle: "", errorText: "There was a problem logging you in, please check you have entered the correct email and secret code or password." })
//                     Mixpanel.track("Unsuccessful login");
//                 }
//             });
//     }

//     connectUser() {
//         let id = this.state.userId
//         const user = this.state.users.find((element) => {
//             return (element.id == id)
//         })
//         this.props.connectUser(user)

//         // adding agent boolean to localStorage
//         localStorage.setItem('agentId', this.state.agent.id)
//         localStorage.setItem('isAgentEmail', this.state.agent.email)

//         localStorage.setItem('guid', user.id);
//         //localStorage.setItem('userid', json.id);
//         localStorage.setItem("token", user.token);
//         localStorage.setItem("username", user.email);
//         localStorage.setItem("agentEmail", this.state.username)
//         this.setState({ loggedin: true });

//     }

//     changePassword() {

//         const email = this.state.username
//         const password = this.state.secretcode
//         const newPassword = this.state.newPassword
//         const confirmNewPassword = this.state.confirmNewPassword

//         let timeStamp = Math.floor(Date.now() / 1000);

//         const body = JSON.stringify({ email: email, password: password, newPassword: newPassword, confirmPassword: confirmNewPassword });


//         fetch(`https://${global.api_url}/agent/changePassword.php?ts=${timeStamp}`, {
//             method: "POST",
//             body: body,
//             headers: { partnerId: "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a" }
//         })
//             .then(res => res.json())
//             .then(json => {
//                 console.log(json);
//                 if (json.status === "success") {
//                     alert("password changed ... ");
//                     this.setState({ showUserList: false, username: "", secretcode: "", changePassword: false })

//                 } else {
//                     alert(json.info)
//                 }
//             });

//     }

//     doBottomView() {

//         if (this.state.showUserList) {

//             return (
//                 <div>

//                     <p style={{ color: 'white' }}>Please select client:</p>

//                     <select value={this.state.userId} style={{ marginBottom: '50px' }}
//                         onChange={(event) => this.setState({ userId: event.target.value })}>

//                         {this.state.users.map((element) => {

//                             return (<option key={element.id} value={element.id}>{element.email} - {element.name}</option>)

//                         })}

//                     </select>

//                     <Button
//                         style={styles.buttonContainer}
//                         size="small"
//                         onClick={() => this.connectUser()}
//                     >
//                         Continue
//                     </Button>


//                     <a style={{ color: "white", textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => this.setState({ changePassword: !this.state.changePassword })}>Change password</a>

//                     {this.state.changePassword && (
//                         <div>

//                             <div style={{ paddingTop: 10 }}>
//                                 <TextField
//                                     style={styles.passInput}
//                                     label="New password"
//                                     value={this.state.newPassword}
//                                     onChange={(event) => this.setState({ newPassword: event.target.value })}
//                                     inputProps={{ style: { color: 'white' } }}
//                                     InputLabelProps={{ style: { color: 'white' } }}
//                                     type="password"
//                                 />
//                             </div>
//                             <div>
//                                 <TextField
//                                     style={styles.passInput}
//                                     label="Confirm password"
//                                     value={this.state.confirmNewPassword}
//                                     onChange={(event) => this.setState({ confirmNewPassword: event.target.value })}
//                                     inputProps={{ style: { color: 'white' } }}
//                                     InputLabelProps={{ style: { color: 'white' } }}
//                                     type="password"
//                                 />
//                             </div>
//                             <div>
//                                 <Button
//                                     variant="contained" color="default"
//                                     style={{ marginTop: 10 }}
//                                     size="small"
//                                     onClick={() => this.changePassword()}
//                                 >
//                                     Set Password
//                     </Button>
//                             </div>
//                         </div>

//                     )}


//                 </div>


//             )
//         }

//         return (
//             <div>

//                 <p style={{ color: 'white' }}>Enter your agent login:</p>

//                 <TextField
//                     //defaultValue="color"
//                     style={styles.input}
//                     //inputStyle={{ paddingLeft: 16 }}
//                     autoCapitalize="none"
//                     value={this.state.username}
//                     onChange={this._updateUsername("username")}
//                     //autoCorrect={false}
//                     //keyboardType="email-address"
//                     //returnKeyType="next"
//                     placeholder="Email address"
//                     margin="none"
//                     InputProps={{ disableUnderline: true }}
//                 />

//                 <div style={{ paddingTop: 5 }} />

//                 <TextField
//                     //defaultValue="color"
//                     style={styles.input}
//                     //inputStyle={{ paddingLeft: 16 }}
//                     autoCapitalize="none"
//                     value={this.state.secretcode}
//                     onChange={this._updateUsername("secretcode")}
//                     //autoCorrect={false}
//                     //keyboardType="email-address"
//                     //returnKeyType="next"
//                     type="password"
//                     placeholder="Password"
//                     margin="none"
//                     InputProps={{ disableUnderline: true }}
//                 />

//                 <div
//                     style={{
//                         flexDirection: "row",
//                         flex: 1,
//                         paddingBottom: 10,
//                         paddingLeft: 10,
//                         textAlign: "center",
//                         justifyContent: "center",
//                         alignItems: "center"
//                     }}
//                 />

//                 <Button
//                     style={styles.buttonContainer}
//                     size="small"
//                     onClick={() => this.auth(this.state.username, this.state.secretcode, true)}
//                 >
//                     Login
//           </Button>

//             </div>
//         );

//     }

//     render() {
//         if (this.state.loggedin) {
//             if (this.state.target) {
//                 return <Redirect to={`/${this.state.target}`} />;
//             } else {
//                 return < to="/transactions" />;
//             }
//         }

//         return (

//             <div
//                 style={{
//                     backgroundColor: "#FAFAFC",
//                     width: "100%",
//                     height: "120vh",
//                     paddingTop: 30
//                 }}
//             >

//                 <div
//                     style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
//                 >
//                     <Card
//                         style={{ backgroundColor: "#0c90b2", width: 400, borderRadius: 5 }}
//                     >
//                         <CardContent>
//                             <div
//                                 style={{
//                                     marginTop: 20,
//                                     marginBottom: 20,
//                                     flex: 0.6,
//                                     justifyContent: "center",
//                                     textAlign: "center"
//                                 }}
//                             >
//                                 <img
//                                     style={{
//                                         width: "100%",
//                                         paddingLeft: -40,
//                                         paddingTop: 20
//                                     }}
//                                     alt=""
//                                     src={require("./images/untied-white-logo.png")}
//                                 />
//                             </div>
//                             <p
//                                 style={{
//                                     fontSize: 18,
//                                     color: "#ffffff",
//                                     paddingBottom: 30,
//                                     textAlign: "center",
//                                     justifyContent: "center",
//                                     alignItems: "center",
//                                     textTransform: "none",
//                                     fontWeight: 700
//                                 }}
//                             >
//                                 {this.state.heading_text}
//                             </p>
//                             {this.doBottomView()}
//                         </CardContent>
//                     </Card>
//                 </div>


//                 <IonAlert
//                     isOpen={this.state.showError}
//                     onDidDismiss={() => this.setState({ showError: false })}
//                     header={this.state.errorTitle}
//                     subHeader={this.state.errorSubTitle}
//                     message={this.state.errorText}
//                     buttons={['OK']}
//                 />
//             </div>

//         );
//     }
// }

// function scale(val) {
//     return val;
// }

// const styler = theme => ({
//     multilineColor: {
//         color: "red"
//     }
// });

// const styles = {
//     root: {
//         background: "black"
//     },
//     card: {
//         minWidth: 675
//     },
//     bullet: {
//         display: "inline-block",
//         margin: "0 2px",
//         transform: "scale(0.8)"
//     },
//     title: {
//         fontSize: 14
//     },
//     pos: {
//         marginBottom: 12
//     },
//     buttonContainer: {
//         paddingVertical: 12,
//         width: "100%",
//         marginBottom: 15,
//         height: 36,
//         borderRadius: 4,
//         backgroundColor: "#c7ff00",
//         color: "#0C90B2",
//         textTransform: "none",
//         fontSize: 14,
//         fontWeight: 700
//     },
//     buttonContainer2: {
//         paddingVertical: 12,
//         width: "100%",
//         marginBottom: 15,
//         height: 36,
//         borderRadius: 4,
//         color: "#ffffff",
//         textTransform: "none",
//         fontSize: 14,
//         fontWeight: 700,
//         borderColor: "#FFFFFF",
//         borderStyle: "solid",
//         borderWidth: scale(1)
//     },
//     buttonText: {
//         color: "#0C90B2",
//         textAlign: "center",
//         fontWeight: "700"
//     },
//     input: {
//         width: "95%",
//         //height: scale(48),
//         borderRadius: scale(4),
//         borderColor: "#ffffff",
//         borderStyle: "solid",
//         borderWidth: scale(1),
//         backgroundColor: "#84C8D9",
//         color: "#ffffff",
//         paddingLeft: 16,
//         placeholderTextColor: "#ffffff",
//         textAlign: "center",
//         fontSize: scale(14),
//         marginLeft: 0
//         //marginRight: 16
//     },
//     passInput: {
//         color: "#ffffff",
//         paddingLeft: 16,
//         placeholderTextColor: "#ffffff",
//         fontSize: scale(14),
//     },
//     itIs: {
//         color: "#c7ff00",
//         fontSize: 18,
//         fontWeight: "500",
//         letterSpacing: 0.54,
//         textAlign: "center",
//         alignSelf: "center"
//     }
// };

// export default withToast(AgentLogin);


// const mapStateToProps = (state) => {
//     return { ...state,
//     }
//   }

//   const mapDispatchToProps = (dispatch) =>{
//     return{
//         agentAuth: (email, token, password) => dispatch(agentAuth(email, token, password)),
//         connectUser: (user) => dispatch(connectUser(user)),
//         clearAuth: () => dispatch(clearAuth()),
//         // getTaxYears: (token, agentId) => dispatch(getTaxYears(token, agentId)),
//     }
//   }

//   export default connect(mapStateToProps, mapDispatchToProps)(withToast(AgentLogin))
export default withToast(AgentLogin)