import React, { useState, useRef, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Container } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MiniDrawer from "./../MiniDrawer";
import moment from "moment";
import {
  IonAlert,
  IonCheckbox,
  IonRow,
  IonCol,
  IonLoading,
  IonButton,
} from "@ionic/react";
import { getTimestamp } from "../components/timeStamp.js";
import { taxFieldVerify, verifyTaxForm } from "../components/taxFormVerify.js";

import { useRecoilState } from "recoil";
import { userAtom, taxYearsAtom, profileAtom } from "../atom.js";
import { getLocalStorage } from "../components/localstorage.js";
import { SelectionBar } from "../components/selectionBar.js";
import CustomFilter from "../components/CustomFilter";
import ReactHtmlParser from "react-html-parser";

import "./../App.css";

const TaxForms = (props) => {
  const [user_atom, setUserAtom] = useRecoilState(userAtom);
  const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom);

  const [profile_atom, setProfileAtom] = useRecoilState(profileAtom);
  const [recoilFetched, setRecoilFetched] = useState(false);
  const [isIrvUser, setIsIrvUser] = useState(false);
  const [irvEmployments, setIrvEmployments] = useState([]);
  const [showIrvData, setShowIrvData] = useState(false);

  const [propertyMin, setPropertyMin] = useState(false);

  const [covidState, setCovidState] = useState({});

  const [update, setUpdate] = useState(false);
  const firstTimeRender = useRef(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [checkboxRender, setCheckboxRender] = useState("");
  const [forms, setForms] = useState([]);
  const [data, setData] = useState([{}]);
  const [selectedForm, setSelectedForm] = useState("1");

  const [focusId, setFocusId] = useState("0");
  const [showLoading, setShowLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [saved, setSaved] = useState(false);

  const [includeData, setIncludeData] = useState(null);
  const [includeError, setIncludeError] = useState(false);
  const [userChanged, setUserChanged] = useState(false);

  const [wfhClaimAmount, setWfhClaimAmount] = useState(0);

  const [postToggleSave, setPostToggleSave] = useState({
    save: false,
    data: null,
  });

  const [connectAlert, setConnectAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [showNoPrePopData, setShowNoPrePopData] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    alertTitle: "",
    alertMessage: "",
  });
  const [landedFromBreakdown, setLandedFromBreakdown] = useState(
    props.location.state !== undefined
  );
  const location = useLocation();
  const history = useHistory();

  const multiSchedules = ["employment", "partnership"];

  useEffect(() => {
    if (recoilFetched) {
      getForms();
      getCovidData();
    }
  }, [recoilFetched]);

  useEffect(() => {
    firstTimeRender.current = false;
    if (!user_atom.token) {
      let userState = getLocalStorage();
      getProfile(userState.token, userState.agent);
      setUserAtom(userState);
      getRecoilTaxYears(userState.token, userState.agent);
    } else if (JSON.stringify(tax_years_atom) === "{}") {
      getRecoilTaxYears();
      console.log("get it here");
      getProfile(user_atom.token, user_atom.agent);
      getIsIrvConnected(user_atom.token, profile_atom.nin);
    } else {
      setRecoilFetched(true);
    }
  }, []);

  useEffect(() => {
    handleFormSelect({ target: { value: selectedForm } });
    getIsIrvConnected(user_atom.token, profile_atom.nin);
  }, [pageLoaded]);

  const getRecoilTaxYears = (
    passed_token = user_atom.token,
    passed_agentId = user_atom.agentId
  ) => {
    fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: passed_token, agentId: passed_agentId },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.error) {
          if (json.error === "unauthorized") {
            //alert('redirect to login page');
            props.history.push("/logout/logout");
          }
        } else {
          let today = new Date();
          let currentYearID = 0;
          let dateFilterStart = "";
          let dateFilterEnd = "";
          json.years.forEach((element) => {
            const start = new Date(element.start_date);
            const end = new Date(element.end_date);

            if (today >= start && today <= end) {
              currentYearID = element.id;
              dateFilterStart = element.start_date;
              dateFilterEnd = element.end_date;
            }
          });
          setTaxYearsAtom({
            taxYears: json.years,
            dateFilterStart: dateFilterStart,
            dateFilterEnd: dateFilterEnd,
            dateFilter: currentYearID,
          });
          if (!recoilFetched) {
            setRecoilFetched(true);
          }
        }
      });
  };

  useEffect(() => {
    if (forms.length > 0) {
      getTaxYears();
      setPageLoaded(true);
    }
  }, [forms]);

  const getForms = () => {
    fetch(`https://${global.api_url}/getTaxForms.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: user_atom.token, agentId: user_atom.agent },
    })
      .then((res) => res.json())
      .then((json) => {
        setForms([...json.forms]);
      });
  };

  const getTaxYears = () => {
    if (props.location.state === undefined) {
      setReady(true);
    } else {
      if (
        props.location.state.year ||
        (props.location.state.yearData &&
          props.location.state.yearData.tax_year)
      ) {
        const year_name =
          props.location.state.year || props.location.state.yearData.tax_year;

        console.log('year name', year_name)

        const tax_year_element = tax_years_atom.taxYears.find((element) => {
          return (year_name === element.explain || year_name === element.tax_year);
        });

        setTaxYearsAtom({
          taxYears: tax_years_atom.taxYears,
          dateFilterStart: tax_year_element.start_date,
          dateFilterEnd: tax_year_element.end_date,
          dateFilter: tax_year_element.id,
        });
      }
      const form_name =
        props.location.state.form || props.location.state.formName;
      const form_element = forms.find((element) => {
        return form_name === element.code;
      });

      setSelectedForm(form_element.id);
      handleFormSelect({ target: { value: form_element.id } });
      setReady(true);
    }
  };

  const getProfile = (
    passed_token = user_atom.token,
    passed_agent = user_atom.agent
  ) => {
    fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token: passed_token, agentId: passed_agent },
    })
      .then((res) => res.json())
      .then((json) => {
        // json.dob = moment(json.dob, 'DD/MM/YYYY').toISOString()
        setProfileAtom(json);
        getIsIrvConnected(passed_token, json.nin);
      });
  };

  const getIsIrvConnected = (token = user_atom.token, nino) => {
    fetch(
      `https://${global.api_url
      }/hmrc/irvGetUser.php?ts=${getTimestamp()}&nino=${nino}`,
      {
        method: "GET",
        headers: { token: token },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.status == "found") {
          setIsIrvUser(true);
          setIrvEmployments(json.employments);
        } else {
          setIsIrvUser(false);
        }
      });
  };

  const handleFormSelect = (event) => {
    if (forms.length === 0) {
      return false;
    }
    const form_id = event.target.value;
    const element = forms.find((form) => form.id === form_id);
    const tax_column = element.tax_column;
    setReady(false);
    setShowLoading(true);

    fetch(
      `https://${global.api_url
      }/getTaxData.php?ts=${getTimestamp()}&tax_year_id=${tax_years_atom.dateFilter
      }&type=${tax_column}`,
      {
        method: "GET",
        headers: { token: user_atom.token, agentId: user_atom.agent },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.data) {
          const json_data = JSON.parse(json.data);

          //if (tax_column === "employment") {
          if (Array.isArray(json_data)) {
            setData(json_data);
            setSelectedForm(form_id);
          } else {
            setData([json_data]);

            setSelectedForm(form_id);
          }
        } else {
          setData([{}]);
          setSelectedForm(form_id);
        }
        setReady(true);
        setShowLoading(false);
      });
  };

  const getValue = (name, ndx, def = "") => {
    if (data.length === 0) {
      return "";
    }

    if (data[ndx][name]) {
      return data[ndx][name];
    } else {
      return def;
    }
  };

  const setValue = (
    name,
    newValue,
    format,
    length,
    ndx,
    min = undefined,
    allowNegative = false
  ) => {
    if (newValue === " ") {
      newValue = newValue.trim();
    }

    let retValue = newValue;
    let validData;

    if (retValue != null) {
      if (format.toLowerCase() === "string") {
        retValue = retValue.substr(0, length);
      }

      if (format.toLowerCase() === "number") {
        if (newValue !== "") {
          const addNegative =
            allowNegative && newValue.length > 0 && newValue.charAt(0) === "-";
          newValue =
            (addNegative ? "-" : "") + newValue.replace(/[^0-9\.]/g, "");
          if (newValue.length > length)
            newValue = newValue.substring(0, length);
          if (min && newValue < min) newValue = min;
          retValue = newValue;
        }
      }

      if (format.toLowerCase() === "date") {
        retValue = "";
        let cChar = "";
        const dateMask = [
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
        for (let i = 0; i < newValue.length; i++) {
          cChar = newValue.charAt(i).match(dateMask[i]);
          if (cChar != null) {
            retValue = retValue + cChar;
          }
        }

        let curValue = data[ndx][name];

        if (curValue === undefined) {
          curValue = "";
        }

        if (
          (curValue.length === 1 && retValue.length === 2) ||
          (curValue.length === 4 && retValue.length === 5)
        ) {
          retValue = retValue + "/";
        }

        const element = forms.find((element) => {
          return element.id === selectedForm;
        });

        const tax_column = element.tax_column;

        if (retValue.length === 10) {
          let aDate = moment(retValue, "DD/MM/YYYY", true);
          let isValid = aDate.isValid();
          if (
            isValid &&
            (name === "box_5" || name === "box_6" || name === "box_7") &&
            tax_column == "self_employment"
          ) {
            let box_5;
            if (data[0].box_5) {
              box_5 = data[0].box_5.split("/");
              box_5 = `${box_5[2]}-${box_5[1]}-${box_5[0]}`;
            } else {
              box_5 = tax_years_atom.dateFilterEnd;
            }
            let valid = taxFieldVerify(
              "date",
              newValue,
              {
                param1: tax_years_atom.dateFilterStart,
                param2: tax_years_atom.dateFilterEnd,
                param3: box_5,
              },
              name
            );

            if (valid.test) {
              setAlert({
                open: true,
                alertTitle: "Invalid date",
                alertMessage: valid.message,
              });
              setFocusId(name);
            }
          } else {
            if (!isValid) {
              setTimeout(() => {
                validData = [...data];
                validData[ndx][name] = "";
                setAlert({
                  open: true,
                  alertTitle: "Invalid date",
                  alertMessage: "Please enter a valid date.",
                });
                setData(validData);

                setFocusId(name);
              }, 500);
            }
          }
        }
      }
    }
    validData = [...data];
    validData[ndx][name] = retValue;
    setData([...validData]);
  };

  const parseValue = (value, currency) => {
    if (currency && value) {
      value = value.toString();
      value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      if (value.indexOf(".") > -1) {
        const integers = value.split(".")[0] + ".";
        let digits = value.split(".")[1];
        if (digits.length > 2) digits = digits.substring(0, 2);

        return integers + digits;
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  const toggleValue = (name, ndx, extra, save = false) => {
    let dataClone = [...data];
    dataClone[ndx][name] = !data[ndx][name];
    if (
      ready &&
      extra.title &&
      !propertyMin &&
      extra.title == "Include property schedule (SA105)?" &&
      dataClone[ndx][name] == true
    ) {
      setPropertyMin(true);
    }

    setData(dataClone);
    setCheckboxRender(!checkboxRender);
    if (save) {
      setPostToggleSave({ save: true, data: dataClone });
    }
  };

  const saveData = (include) => {
    let dataPackage;
    let dataClone;
    const element = forms.find((element) => {
      return element.id === selectedForm;
    });

    const tax_column = element.tax_column;

    //if (tax_column === "employment" /*|| tax_column === "self_employment"*/) {
    if (multiSchedules.includes(tax_column)) {
      dataPackage = JSON.stringify(data);
    } else {
      dataClone = { ...data[0] };
      if (include && forms.find((f) => f.id == selectedForm).code !== "SA100") {
        dataClone.include = true;
      }

      dataPackage = JSON.stringify(dataClone);
    }
    const verified = verifyTaxForm(tax_column, data, tax_years_atom);
    if (!verified.pass) {
      setAlert({
        open: true,
        alertTitle: verified.alertTitle,
        alertMessage: verified.alertMessage,
      });
      return;
    }

    setPostToggleSave({ save: false, data: null });

    setShowLoading(true);
    setUserChanged(false);
    setIncludeData(null);

    fetch(
      `https://${global.api_url
      }/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${tax_years_atom.dateFilter
      }&type=${tax_column}`,
      {
        method: "POST",
        headers: { token: user_atom.token, agentId: user_atom.agent },
        body: dataPackage,
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.status === "success") {
          setAlert({ ...alert, open: false });
          setFocusId(null);
        } else {
          setAlert({
            open: true,
            alertTitle: "Error",
            alertMessage: "There was a problem saving the form.",
          });
          setFocusId(null);
        }

        if (tax_column === "home_working") {
          // get updated tax calculation
          fetch(
            `https://${global.api_url
            }/getTaxCalculation.php?ts=${getTimestamp()}&tax_year_id=${tax_years_atom.dateFilter
            }`,
            {
              method: "GET",
              headers: { token: user_atom.token, agentId: user_atom.agent },
            }
          )
            .then((res) => res.json())
            .then((json) => {
              setWfhClaimAmount(json.unadjusted_wfh_claim);
              setSaved(true);
              setShowLoading(false);
            });
        } else {
          setWfhClaimAmount(0);
          setSaved(true);
          setShowLoading(false);
        }

        setUpdate(!update);
      });
  };

  const formsSelection = () => {
    return (
      <span
        style={{
          marginLeft: "20px",
          fontSize: "16px",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          paddingTop: "10px",
        }}
      >
        Tax form:{" "}
        <Select
          value={selectedForm}
          style={{ marginBottom: "00px" }}
          onChange={handleFormSelect}
        >
          {forms.map((element) => {
            return (
              <MenuItem
                key={element.id}
                value={element.id}
                selected={element.code === "SA100"}
              >
                {element.code} - {element.title}
              </MenuItem>
            );
          })}
        </Select>
      </span>
    );
  };

  useEffect(() => {
    if (pageLoaded) {
      handleFormSelect({ target: { value: selectedForm } });
      if (landedFromBreakdown) {
        if (props.location.state) {
          setLandedFromBreakdown(false);
          history.replace({ ...location, state: undefined });
        }
      }
    }
  }, [tax_years_atom]);

  useEffect(() => {
    if (propertyMin) {
      setPropertyMin(false);
    }
  }, [propertyMin]);

  useEffect(() => {
    if (includeData) {
      checkInclude();
    }
  }, [includeData]);

  useEffect(() => {
    if (postToggleSave.save && postToggleSave.data == data) {
      saveData();
    }
  }, [postToggleSave, data]);

  const getCovidData = () => {
    fetch(
      `https://${global.api_url
      }/getObjectData.php?type=covid1&ts=${getTimestamp()}`,
      {
        method: "GET",
        headers: { token: user_atom.token, agentId: user_atom.agent },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (JSON.stringify(json.data) !== "[]") {
          setCovidState(json.data);
        }
      });
  };

  const saveCovidData = (val) => {
    let body;
    if (val) {
      body = JSON.stringify({
        type: "covid1",
        data: { ...covidState, self_employed: true, wfh: true },
      });
    } else {
      body = JSON.stringify({
        type: "covid1",
        data: { ...covidState, self_employed: false },
      });
    }

    fetch(`https://${global.api_url}/saveObjectData.php?ts=${getTimestamp()}`, {
      method: "POST",
      body: body,
      headers: { token: user_atom.token, agentId: user_atom.agent },
    })
      .then((res) => res.json())
      .then((json) => {
        // Mixpanel.track("covid_support_save_state", {state: covidState});
      });
  };

  const checkInclude = () => {
    let inc = includeData.fields.find((f) => {
      if (f.name) {
        return f.name.includes("include");
      }
    });

    if (forms.find((f) => f.id == selectedForm).code == "HW1") {
      let id = tax_years_atom.taxYears.find(
        (y) => y.tax_year == "2020-2021"
      ).id;
      if (tax_years_atom.dateFilter == id) {
        let inc_index = includeData.fields.indexOf(inc);
        let val = getValue(inc.name, inc_index);
        saveCovidData(val);
      }
    }

    if (inc && userChanged) {
      let inc_index = includeData.fields.indexOf(inc);
      let val = getValue(inc.name, inc_index);

      if (val) {
        saveData();
      } else {
        setIncludeError(true);
      }
    } else {
      saveData();
    }
  };

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ")
      .replace("_", " ");
  };

  const renderRow = (element, ndx, taxYearInfo, fieldIndex) => {
    if (!ready) {
      return;
    }

    //if this element has a taxYears attribute don't continue if it does not contain the selected year
    if (element.taxYears) {
      if (!element.taxYears.includes(taxYearInfo.tax_year)) {
        return;
      }
    }

    let format =
      element.format === undefined || element.format === "*"
        ? "string"
        : element.format;
    let allowNegative =
      element.allowNegative === undefined ? false : element.allowNegative;

    if (element.depends) {
      if (
        !getValue(element.depends, ndx) &&
        getValue(element.name, ndx) === ""
      ) {
        return;
      }
    }

    function helpi(element) {
      if (element.help_link) {
        return (
          <a href={element.help_link} target="_new">
            Info
          </a>
        );
      }
    }

    if (element.format === "checkbox") {
      var el_checked =
        getValue(element.name, ndx) !== "" ||
        getValue(element.name, ndx) === true;

      if (
        props.location.state !== undefined &&
        ndx === 0 &&
        !landedFromBreakdown
      ) {
        if (element.name === "include") {
          el_checked = true;
        }
      }
    }

    const yearStartDate = moment(tax_years_atom.dateFilterStart).format(
      "D MMM YYYY"
    );
    const yearEndDate = moment(tax_years_atom.dateFilterEnd).format(
      "D MMM YYYY"
    );
    var elTitle = element.title.replace("[yearStartDate]", yearStartDate);
    elTitle = elTitle.replace("[yearEndDate]", yearEndDate);

    return (
      <div key={fieldIndex}>
        <IonRow key={element.name}>
          <IonCol className="tax-forms-input" style={{ padding: 0 }}>
            {format === "checkbox" && (
              <div style={styles.inputContainer} id={element.name}>
                {element.heading && (
                  <h3 className="taxFormsHeading">{element.heading}</h3>
                )}
                {element.subheading && (
                  <h4 className="taxFormsSubHeading">
                    &nbsp;&nbsp;{element.subheading}
                  </h4>
                )}
                <div style={styles.displayFlex}>
                  <span style={styles.titleContainer95}>
                    {ReactHtmlParser(elTitle)} {helpi(element)}
                  </span>
                  <IonCheckbox
                    checked={el_checked}
                    style={styles.checkboxStyle}
                    onIonChange={(e) => {
                      if (
                        e.detail.checked !== el_checked &&
                        includeData == null
                      ) {
                        toggleValue(element.name, ndx, element);

                        // if (!e.detail.checked) {
                        //   const form_container = forms.find((element) => {
                        //     return element.id === selectedForm;
                        //   });
                      
                        //   const oform = JSON.parse(form_container.form_json);

                        //   // check if any other fields are dependent on this checkbox. To do this we loop through all other
                        //   // fields and see if any of them have a depends attribute that matches the name of this checkbox
                        //   oform.fields.forEach((el, i) => {
                        //     if (el.depends && el.depends === element.name) {
                        //       if (el.format === "checkbox") {
                        //         // if it is checked, uncheck it
                        //         if (getValue(el.name, ndx) === true) {
                        //           toggleValue(el.name, ndx, el);
                        //         }
                        //       } else {
                        //         setValue(el.name, "", el.format, el.length, ndx);
                        //       }
                        //       //toggleValue(el.name, ndx, el);
                        //       // if this element has a value in it then show a warning and stop looping
                        //       // if (getValue(el.name, ndx) !== "") {
                        //       //   setAlert({
                        //       //     open: true,
                        //       //     alertTitle: "Warning",
                        //       //     alertMessage:
                        //       //       el.format === "checkbox" 
                        //       //         ? "You have checked '" + el.title + "' which is dependent on this checkbox. You may need to remove that to avoid an error when submitting your return. If you do not need it please remove. If you do need it you should recheck this box."
                        //       //         : "You have a value in '" + el.title + "' which is dependent on this checkbox. You may need to remove that to avoid an error when submitting your return. If you do not need it please remove. If you do need it you should recheck this box."
                        //       //   });
                        //       //   return;
                        //       // }
                        //     }
                        //   });
                        // }

                      }
                      if (element.name === "more_options") {
                        setCheckboxRender(!checkboxRender);
                      }
                      if (!element.name.includes("include")) {
                        setUserChanged(true);
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {format === "select" && (
              <div style={styles.inputContainer} id={element.name}>
                {element.heading && (
                  <h3 className="taxFormsHeading">{element.heading}</h3>
                )}
                {element.subheading && (
                  <h4 className="taxFormsSubHeading">
                    &nbsp;&nbsp;{element.subheading}
                  </h4>
                )}
                <div style={styles.displayFlex}>
                  <span style={styles.titleContainer80}>
                    {ReactHtmlParser(elTitle)} {helpi(element)}
                  </span>
                  <div style={styles.inputContainer20}>
                    <CustomFilter
                      select
                      label=" "
                      className="custom-filter-input"
                      value={getValue(element.name, ndx, element.default)}
                      onChange={(e) => {
                        setValue(
                          element.name,
                          e.target.value,
                          format,
                          element.length,
                          ndx
                        );
                        setUserChanged(true);
                      }}
                    >
                      {element.choices.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.title}
                        </MenuItem>
                      ))}
                    </CustomFilter>
                  </div>
                </div>
              </div>
            )}

            {format === "number" && (
              <div style={styles.inputContainer} id={element.name}>
                {element.heading && (
                  <h3 className="taxFormsHeading">{element.heading}</h3>
                )}
                {element.subheading && (
                  <h4 className="taxFormsSubHeading">
                    &nbsp;&nbsp;{element.subheading}
                  </h4>
                )}
                <div style={styles.displayFlex}>
                  <span style={styles.titleContainer80}>
                    {ReactHtmlParser(element.indent || "")}
                    {ReactHtmlParser(elTitle)} {helpi(element)}
                  </span>
                  <div style={styles.inputContainer20}>
                    {element.currency && (
                      <span style={styles.currencyStyle}>£</span>
                    )}
                    <CustomFilter
                      label=" "
                      className="custom-filter-input"
                      value={parseValue(
                        getValue(element.name, ndx, element.default),
                        element.currency
                      )}
                      onChange={(e) => {
                        setValue(
                          element.name,
                          e.target.value,
                          format,
                          element.length || 11,
                          ndx,
                          element.min,
                          allowNegative
                        );
                        setUserChanged(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {format !== "select" &&
              format !== "checkbox" &&
              format !== "number" && (
                <div style={styles.inputContainer} id={element.name}>
                  {element.heading && (
                    <h3 className="taxFormsHeading">{element.heading}</h3>
                  )}
                  {element.subheading && (
                    <h4 className="taxFormsSubHeading">
                      &nbsp;&nbsp;{element.subheading}
                    </h4>
                  )}
                  <div
                    style={
                      element.multiline
                        ? styles.displayMultiLine
                        : styles.displaySingleLine
                    }
                  >
                    <span
                      style={
                        element.multiline
                          ? styles.titleContainer100
                          : styles.titleContainer80
                      }
                    >
                      {ReactHtmlParser(elTitle)}
                    </span>
                    <div
                      style={
                        element.multiline
                          ? styles.textareaContainer100
                          : styles.textareaContainer20
                      }
                    >
                      <div>
                        <CustomFilter
                          label=" "
                          style={{
                            width: "100%",
                            color: "#000000",
                            height: 70,
                          }}
                          multiline={element.multiline}
                          className={
                            !element.multiline
                              ? "tax-forms-input-textarea"
                              : "tax-forms-input-textarea-multiline"
                          }
                          value={getValue(element.name, ndx)}
                          placeholder={
                            element.multiline
                              ? "Add further info here, e.g. details of computations or attachments provided"
                              : ""
                          }
                          onChange={(e) => {
                            setValue(
                              element.name,
                              e.target.value,
                              element.format,
                              element.length,
                              ndx
                            );
                            setUserChanged(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </IonCol>
        </IonRow>
      </div>
    );
  };

  const queryPrePopData = () => {
    // now actually GET the data
    var timeStamp = Math.floor(Date.now() / 1000);
    fetch(
      `https://${global.api_url}/hmrc/getPrePopulation.php?ts=${timeStamp}&tax_year_id=${tax_years_atom.dateFilter}`,
      {
        method: "GET",
        headers: { token: user_atom.token, "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        // do something - fetch the data again ..
        console.log(json);

        if (json.result == "no_token") {
          setConnectAlert(true);
          return;
        }

        if (json.status == 200) {
          handleFormSelect({ target: { value: selectedForm } });
        } else {
          setShowNoPrePopData(true);
        }
      });
  };

  const irvConnect = () => {
    //create a pop up window as target for the form
    let w = 600;
    let h = 770;
    let left = window.screen.width / 2 - w / 2;
    let top = window.screen.height / 2 - h / 2;

    //const dob = moment(profile_atom.dob).format('DD/MM/YYYY')
    let dob = profile_atom.dob;

    // if no dob show an error
    if (dob == null || dob == "" || dob == undefined || dob == "null") {
      setAlert({
        open: true,
        alertTitle: "Please update your profile",
        alertMessage: "Please ensure your profile is up to date with your date of birth and valid national insurance number.",
      });
      return;
    }

    // if dob is in the format YYYY-MM-DD then convert to DD/MM/YYYY
    if (dob.includes("-")) {

      // if dob ends 'T00:00:00' etc then remove from the 'T'
      if (dob.includes("T")) {
        dob = dob.split("T")[0];
      }

      dob = dob.split("-");
      dob = `${dob[2]}/${dob[1]}/${dob[0]}`;
    }

    const nino = profile_atom.nin;

    // display error if nino is null 
    if (nino == null || nino == "" || nino == undefined || nino == "null" || nino.length < 9) {
      setAlert({
        open: true,
        alertTitle: "Please update your profile",
        alertMessage: "Please ensure your profile is up to date with your date of birth and valid national insurance number.",
      });
      return;
    }

    var child = window.open(
      `/irvinter.html?dob=${dob}&nino=${nino}`,
      "Popup_Window",
      "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=" +
      w +
      ", height=" +
      h +
      ", top = " +
      top +
      ", left = " +
      left
    );

    console.log("now start STAR irv connect");
    var timeStamp = Math.floor(Date.now() / 1000);
    fetch(`https://${global.api_url}/hmrc/irvConnection.php?ts=${timeStamp}`, {
      method: "GET",
      headers: { token: user_atom.token, "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        // do something - fetch the data again ..
        console.log(json);
        console.log('here')

        const info = JSON.parse(json.result);
        console.log(info)

        if (info.taxpayers && info.taxpayers[0].registered && info.taxpayers[0].registered === 'Y') {
          // close the pop up
          child.close()
          setIsIrvUser(true)
          getIsIrvConnected(user_atom.token, profile_atom.nin);

          setAlert({
            open: true,
            alertTitle: "HMRC Connected",
            alertMessage: "Your HMRC account is already connected to untied. Your data will be imported in due course. This can take up to an hour. Check back later.",
          });

          return;
        }

        if (info.message && info.message.includes('invalid nino')) {
          info.message = "Your National Insurance number is invalid. Please check your profile and try again.";
        }

        if (json.status == "error") {
          setAlert({
            open: true,
            alertTitle: "HMRC error",
            alertMessage: info.message,
          });
          setFocusId(null);
          child.close()
        }
      });


        
    var doneInterval = false;      

    // need to wait for message from child window
    window.addEventListener("message", function (event) {
      console.log("message received");
      console.log(event.data);
      if (event.data == "close") {
        child.close();
      }
      if (event.data == "continueIrv") {
        child.location.href = "/buffer.html";

        // console.log('now start STAR irv connect')
        // var timeStamp = Math.floor(Date.now() / 1000);
        // fetch(`https://${global.api_url}/hmrc/irvConnection.php?ts=${timeStamp}`, {
        //   method: "GET",
        //   headers: { token: user_atom.token, 'Content-Type': 'application/json' }
        // })
        //   .then(res => res.json())
        //   .then(json => {
        //     // do something - fetch the data again ..
        //     console.log(json)

        //     if (json.status == 'error') {

        //       setAlert({
        //         open: true,
        //         alertTitle: "HMRC error",
        //         alertMessage: json.message,
        //       });
        //       setFocusId(null);
        //       child.close()

        //     } else {

        // now keep polling irvCheck until status is not null
        if (doneInterval) {
          return false;
        }

        var interval = setInterval( () => {

          if (doneInterval) {
            clearInterval(interval);
            return false;
          }

          fetch(`https://${global.api_url}/hmrc/irvCheck.php?ts=${timeStamp}`, {
            method: "GET",
            headers: {
              token: user_atom.token,
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((json) => {

              if (doneInterval) {
                clearInterval(interval);
                return false;
              }

              // do something - fetch the data again ..
              console.log('in first interval ... ')
              console.log(json);

              if (json.irv_response && json.irv_response.status && json.irv_response.status != null) {

                console.log('clear first interval')
                clearInterval(interval);
                doneInterval = true;

                if (
                  (json.irv_response.status == "accepted" || json.irv_response.status == "data_updated") 
                ) {
                  setTimeout(() => {
                    setIsIrvUser(true)
                  }, 30000);

                  child.close();
                } else {

                  clearInterval(interval);
                  doneInterval = true;
                  var doError = false

                  console.log('response', json.irv_response.status, json)

                  var info_message = json.irv_response.status
                  if (json.irv_response.status == "NINO error") {
                    var info_message = "Your National Insurance number is invalid. Please check your profile and try again.";
                    doError = true
                  }
                  
                  if (json.irv_response.status == "NINO and DOB do not match") {
                    var info_message = "Please check your National Insurance number and date of birth. Please check your profile and try again.";
                    doError = true
                  }
                  
                  if (doError) {
                    setAlert({
                      open: true,
                      alertTitle: "HMRC error",
                      alertMessage: info_message,
                    });
                    setFocusId(null);
                    child.close()
                    return false;
                  }

                }

                if (json.irv_response.status == "Invited") {
                  let inviteUrl = json.irv_response.invite_url;
                  // set location of child window to inviteUrl
                  child.location = inviteUrl;

                  var invite_accepted = false

                  // now keep polling until status is 'accepted' or 'data_updated'
                  var interval2 = setInterval(() => {

                    console.log('in second interval')

                    fetch(
                      `https://${global.api_url}/hmrc/irvCheck.php?ts=${timeStamp}`,
                      {
                        method: "GET",
                        headers: {
                          token: user_atom.token,
                          "Content-Type": "application/json",
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((json) => {
                        // do something - fetch the data again ..
                        console.log(json);

                        if (
                          (json.irv_response.status == "accepted" || json.irv_response.status == "data_updated") && invite_accepted == false
                        ) {

                          console.log('invite accepted ... so here we should pop message to use and not show it again, but we also want to keep checking for a data_update')

                          // shouldn't this prevent the mssage showing again?
                          invite_accepted = true
                          
                          setTimeout(() => {
                            setIsIrvUser(true)
                          }, 30000);

                          setAlert({
                            open: true,
                            alertTitle: "Connected to HMRC",
                            alertMessage:
                              "Your HMRC account is now connected and your data will be imported in due course. This can take up to an hour. Check back later.",
                          });
                          child.close();

                        }


                        if (                          
                          json.irv_response.status == "data_updated"
                        ) {

                          console.log('data updated ... so we can clear the second interval now')

                          clearInterval(interval2);               
                          setIsIrvUser(true);
                          doneInterval = true;

                          // this will retrievet the employment data ... 
                          getIsIrvConnected(user_atom.token, profile_atom.nin);

                          setShowIrvData(true);
        
                        }
                      });
                  }, 1000);
                }
              }
            });
        }, 1000);

        //}

        //})
      }
    });
  };

  const irvDisconnect = () => {

    setShowLoading(true);
    const nino = profile_atom.nin;

    fetch(
      `https://${global.api_url
      }/hmrc/irvGetUser.php?ts=${getTimestamp()}&nino=${nino}&cancelAuth=Y`,
      {
      method: "GET",
      headers: { token: user_atom.token, "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => { 

        console.log(json)
        if (json.status == "found") {
          setIsIrvUser(false);
          setIrvEmployments([]);

          setAlert({
            open: true,
            alertTitle: "HMRC Disconnection",
            alertMessage: "We have removed the HMRC authentication to disconnect untied from HMRC. Please allow a few minutes for this to take effect",
          });

        }

        setShowLoading(false)

      });

  }

  const copyIrvData = () => {

    const token = user_atom.token;
    setShowLoading(true);

    const taxYearInfo = tax_years_atom.taxYears.find((element) => {
      return element.id === tax_years_atom.dateFilter;
    });

    const tax_year = taxYearInfo.tax_year
    fetch(
      `https://${global.api_url}/hmrc/irvCopyData.php?ts=${getTimestamp()}&tax_year=${tax_year}`,
      {
        method: "GET",
        headers: { token: token },
      }
    )
    .then((res) => res.json())
    .then((json) => {
      console.log(json);
      handleFormSelect({ target: { value: selectedForm } });
      setShowLoading(false);
    });
  }

  const irvUpdate = () => {
    const token = user_atom.token;
    const nino = profile_atom.nin;

    // display error if nino is null 
    if (nino == null || nino == "" || nino == undefined || nino == "null") {
      setAlert({
        open: true,
        alertTitle: "National Insurance Number Required",
        alertMessage: "Please enter your National Insurance Number in your profile.",
      });
    }

    setShowLoading(true);

    fetch(
      `https://${global.api_url
      }/hmrc/irvGetUser.php?ts=${getTimestamp()}&nino=${nino}&requestUpdate=Y`,
      {
        method: "GET",
        headers: { token: token },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setShowLoading(false);
        if (json.status == "found") {
          setUpdateAlert(true);
          setTimeout(() => {
            getIsIrvConnected(user_atom.token, profile_atom.nin);
            console.log('data updated')
          }, 120000);
        }
      });
  };

  const connectToHMRC = () => {
    setConnectAlert(false);
    let theForm = document.getElementById("connect_form");

    //create a pop up window as target for the form
    let w = 600;
    let h = 770;
    let left = window.screen.width / 2 - w / 2;
    let top = window.screen.height / 2 - h / 2;
    let child = window.open(
      "about:blank",
      "Popup_Window",
      "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=" +
      w +
      ", height=" +
      h +
      ", top = " +
      top +
      ", left = " +
      left
    );

    //set the window as the target for the form and submit
    theForm.target = "Popup_Window";
    theForm.submit();

    let leftDomain = false;
    let interval = setInterval(function () {
      try {
        if (child.document.domain === document.domain) {
          if (leftDomain && child.document.readyState === "complete") {
            console.log("hmrc flow completed");
            // we're here when the child window returned to our domain
            clearInterval(interval);
            //child.postMessage({ message: "requestResult" }, "*");
          }
        } else {
          // this code should never be reached,
          // as the x-site security check throws
          // but just in case
          leftDomain = true;
        }
      } catch (e) {
        // we're here when the child window has been navigated away or closed
        if (child && child.closed) {
          clearInterval(interval);
          console.log("window closed");
          //refresh this page
          // window.location.reload();

          // now actually GET the data
          queryPrePopData();

          return;
        }
        // navigated to another domain
        leftDomain = true;
      }
    }, 500);
  };

  const addItem = () => {
    setData([...data, {}]);
  };

  const delItem = (index) => {
    let filtered = data.filter((datum, i) => {
      if (i !== index) return datum;
    });
    setData([...filtered]);
  };

  const displayForm = () => {
    if (ready === false) {
      return <div />;
    }

    if (data == null) {
      return <div />;
    }

    //get the currently selected year string
    const taxYearInfo = tax_years_atom.taxYears.find((element) => {
      return element.id === tax_years_atom.dateFilter;
    });

    if (forms === []) {
      return <div />;
    }

    const form_container = forms.find((element) => {
      return element.id === selectedForm;
    });
    if (form_container === undefined) {
      return <div />;
    }

    const tax_column = form_container.tax_column;
    const oform = JSON.parse(form_container.form_json);

    // is box4 unique in data array
    var box4array = []
    var box4sUnique = true

    if (tax_column === "employment") {

      data.forEach((item, index) => {
        if (item.box_4) {
          let thisBox4 = item.box_4;
          thisBox4 = thisBox4.replace(/\s/g, "");
          thisBox4 = thisBox4.toUpperCase();
          box4array.push(thisBox4)
        }
      })

      // check if box4 is unique
      box4array.forEach((item, index) => {
        if (box4array.indexOf(item) != index) {
          box4sUnique = false
        }
      })
    }

    const empEmpty = data.length === 0 || (data.length === 1 && JSON.stringify(data[0]) === "{}");

    return (
      <div>
        {oform.help_link ? (
          <div style={{ width: "100%", textAlign: "left" }}>
            <a href={oform.help_link} target="_new">
              Get help on this form
            </a>
          </div>
        ) : (
          <div style={{ width: "100%", textAlign: "left" }}>
            <a
              href="https://help.untied.io/article/208-adding-income-expenses-in-untied-and-when-you-need-a-form"
              target="_new"
            >
              How to add your income and expenses in untied and when you need a
              form
            </a>
          </div>
        )}

        {tax_column === "employment" && (
          <div style={{ width: "100%", textAlign: "left", paddingTop: 10 }}>

            {isIrvUser && (
              <><span style={styles.buttons.addEmploymentButton}
                onClick={() => { 

                    // if no irvEmployments yet pop an alert to say no data yet, check back later or request an update
                    if (irvEmployments.length === 0) {
                      setAlert({
                        open: true,
                        alertTitle: "No HMRC Data",
                        alertMessage: "We have not yet received any data from HMRC for this tax year. Please check back later or request an update.",
                      });
                      return;
                    }

                    setShowIrvData(!showIrvData) 
                }}
              >View HMRC Data</span>{" "}</>
            )}

            <span
              style={styles.buttons.addEmploymentButton}
              onClick={() => {
                //   queryPrePopData()
                {
                  isIrvUser ? irvUpdate() : irvConnect();
                }

              }}
            >
              {isIrvUser
                ? "Request update"
                : "Connect to HMRC to import your employment data"}
            </span>{" "}

            {isIrvUser && (
                <span
                style={styles.buttons.addEmploymentButton}
                onClick={() => {
                  //   queryPrePopData()
                  {
                    irvDisconnect()
                  }
                }}
              >Remove connection to HMRC</span>
            )}

          </div>
        )}

        {tax_column === "employment" && irvEmployments.length > 0 && showIrvData && (
          <div style={{ width: "100%", textAlign: "left", paddingTop: 10 }}>
            <h3 className="taxFormsHeading">Employment Data From HMRC for {taxYearInfo.tax_year}</h3>

            <table className="table table-striped" style={{ fontSize: 12, paddingTop: 5 }}>
              <thead>
                <tr role="button" style={{ whiteSpace: "nowrap" }}>
                  {/* <th scope="col">ID</th> */}
                  {/* <th scope="col">Tax Year</th> */}
                  <th scope="col">Name</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Tax Code</th>
                  <th scope="col">PAYE REF</th>
                  {/* <th scope="col">Type</th> */}
                  <th scope="col">Status</th>
                  <th scope="col">Taxable income</th>
                  <th scope="col">Tax paid</th>
                  {/* <th scope="col">Student loan repaid</th>
                  <th scope="col">Allowances</th>
                  <th scope="col">Deductions</th>
                  <th scope="col">Benefits</th> */}
                  <th scope="col">Updated</th>
                </tr>
              </thead>
              <tbody>
                {irvEmployments.map((item, index) => {
                  if (item.tax_year == taxYearInfo.tax_year) {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          // nothing
                        }}
                        role="button"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {/* <th scope="row">{item.id}</th> */}
                        {/* <td>{item.tax_year}</td> */}
                        <td>{item.name}</td>
                        <td>{item.start_date}</td>
                        <td>{item.end_date}</td>
                        <td>{item.tax_code}</td>
                        <td>{item.paye_ref}</td>
                        {/* <td>{item.type}</td> */}
                        <td>{item.status}</td>
                        <td>{item.taxable_income}</td>
                        <td>{item.tax_paid}</td>
                        {/* <td>{item.student_loan_repaid}</td>
                        <td>
                          {item.allowances
                            .replaceAll("\\u00a3", "£")
                            .replaceAll("{", "")
                            .replaceAll("}", "")
                            .replaceAll('"', "")}
                        </td>
                        <td>
                          {item.deductions
                            .replaceAll("\\u00a3", "£")
                            .replaceAll("{", "")
                            .replaceAll("}", "")
                            .replaceAll('"', "")}
                        </td>
                        <td>
                          {item.benefits
                            .replaceAll("\\u00a3", "£")
                            .replaceAll("{", "")
                            .replaceAll("}", "")
                            .replaceAll('"', "")}
                        </td> */}
                        <td>{item.updated}</td>
                      </tr>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </tbody>
            </table>

            {!box4sUnique && (
              <p>Please review and copy data into the form below as appropriate</p>
            )}
            {box4sUnique && (
              <p>
                <span style={styles.buttons.addEmploymentButton}
                  onClick={() => copyIrvData()}
                >{empEmpty ? "Copy to untied" : "Update form below with this info"}</span>
                </p>
            )}

          </div>
        )}

        {data.map((form, index) => {
          return (
            <div key={index}>
              {multiSchedules.includes(
                tax_column
              ) /*|| tax_column === "self_employment"*/ && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      paddingTop: "10px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    <h3 className="taxFormsHeading">
                      {titleCase(tax_column)} {index + 1}
                    </h3>{" "}
                    {index > 0 && (
                      <span>
                        <span
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                            color: "#a00338",
                          }}
                          onClick={() => delItem(index)}
                        >
                          Delete
                        </span>{" "}
                      </span>
                    )}
                  </div>
                )}
              {oform.fields.map((element, fieldIndex) => {
                return renderRow(element, index, taxYearInfo, fieldIndex);
              })}
            </div>
          );
        })}

        <div className="tax-forms-buttons-container">
          {/* <Button
            type="submit"
            style={styles.buttons.backButton}
            onClick={() => props.history.push("/taxForms/menu")}
          >
            Back
          </Button> */}

          <IonButton
            className="ion-button-inverse"
            onClick={() => props.history.push("/taxForms/menu")}
            style={{ width: 150 }}
          >
            {" "}
            Cancel{" "}
          </IonButton>

          {multiSchedules.includes(
            tax_column
          ) /*|| tax_column == 'self_employment'*/ && (
              <>
                <Button
                  type="submit"
                  style={styles.buttons.addEmploymentButton}
                  onClick={() => addItem()}
                >
                  Add {titleCase(tax_column).toLowerCase()}
                </Button>

                {/* <form action={`https://${global.api_url}/hmrc/app_connect.php?`} method="POST" id='connect_form'>
                <input type='hidden' name='token' value = {user_atom.token} />
            </form> */}
                {/* <Button style={styles.buttons.basicButton}
                            onClick={() => setConnectAlert(true)}>
                        Retrieve from HMRC
                    </Button> */}
              </>
            )}

          {/* <Button
            type="submit"
            style={styles.buttons.basicButton}
            onClick={() => setIncludeData(oform)}
          >
            Save
          </Button>  */}

          <IonButton
            className="ion-button-money"
            style={{ width: 150 }}
            onClick={() => setIncludeData(oform)}
          >
            {" "}
            Save{" "}
          </IonButton>
        </div>

        <IonAlert
          isOpen={alert.open}
          onDidDismiss={() => {
            setAlert({ ...alert, open: false });
            if (focusId != null) {
              setTimeout(() => {
                const id = focusId;
                if (document.getElementById(id)) {
                  document.getElementById(id).focus();
                }
              }, 500);
            }
          }}
          header={alert.alertTitle}
          message={alert.alertMessage}
          buttons={["OK"]}
        />
      </div>
    );
  };

  const styles = {
    buttons: {
      basicButton: {
        width: 200,
        height: 48,
        borderRadius: 4,
        backgroundColor: "#c7ff00",
        fontSize: "16px",
        color: "#3f90b0",
        textTransform: "none",
      },
      backButton: {
        width: 200,
        height: 48,
        borderRadius: 4,
        backgroundColor: "#3f90b0",
        fontSize: "16px",
        color: "#ffffff",
        textTransform: "none",
      },
      addEmploymentButton: {
        //width: 200,
        //height: 48,
        //borderRadius: 4,
        //backgroundColor: "#c7ff00",
        fontSize: "16px",
        color: "#D10049",
        textTransform: "none",
        textDecorationLine: "underline",
        cursor: "pointer",
      },
    },
    inputContainer: {
      width: "100%",
      textAlign: "left",
      borderBottom: "2px solid #3f90b0",
    },
    displayFlex: {
      display: "flex",
      width: "100%",
      height: 70,
      fontSize: 14,
    },
    displayMultiLine: {
      marginTop: 10,
      display: "block",
    },
    displaySingleLine: {
      marginTop: 0,
      display: "flex",
    },
    titleContainer100: {
      width: "100%",
      margin: "auto",
      marginLeft: 0,
    },
    titleContainer80: {
      width: "80%",
      margin: "auto",
      marginLeft: 0,
    },
    titleContainer95: {
      width: "95%",
      margin: "auto",
      marginLeft: 0,
    },
    inputContainer20: {
      width: "20%",
      position: "relative",
    },
    textareaContainer100: {
      position: "relative",
      marginTop: 10,
      width: "100%",
    },
    textareaContainer20: {
      position: "relative",
      marginTop: 0,
      width: "20%",
    },
    checkboxStyle: {
      margin: "auto",
      marginRight: 0,
    },
    currencyStyle: {
      position: "absolute",
      bottom: 25,
    },
  };

  const displayMain = () => {
    if (pageLoaded) {
      return (
        <div style={{ width: "70%", minWidth: 700, margin: "auto" }}>
          <div style={{ margin: "10px 0" }}>{formsSelection()}</div>

          {displayForm()}

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
            duration={30000}
          />
        </div>
      );
    }
  };

  return (
    <>
      <IonAlert
        isOpen={saved}
        onDidDismiss={() => {
          setSaved(false);
        }}
        cssClass="form-save"
        message={
          wfhClaimAmount * 1 === 0
            ? "Your changes have been saved"
            : `Your changes have been saved. A claim of £${wfhClaimAmount} for working from home has been added to your tax return. Note that if your total expenses are lower than the £1,000 trading allowance you won't see this in your tax breakdown as you would be better off using the allowance.`
        }
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={connectAlert}
        onDidDismiss={() => setConnectAlert(false)}
        header={"Connect to your HMRC account"}
        message={
          "By connecting to your HMRC account we can query any historic employment data and pre-fill your employment form. Note that HMRC data is not always completely up to date and may not be available for the current year."
        }
        buttons={[
          {
            text: "Cancel",
            handler: () => setConnectAlert(false),
          },
          {
            text: "Continue",
            handler: () => connectToHMRC(),
          },
        ]}
      />
      <IonAlert
        isOpen={updateAlert}
        onDidDismiss={() => setUpdateAlert(false)}
        header={"Requesting your HMRC data"}
        message={
          "We have requested an update from HMRC. Please check back or refresh in a few minutes to see any changes."
        }
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showNoPrePopData}
        onDidDismiss={() => setShowNoPrePopData(false)}
        header={"No data found"}
        message={
          "HMRC did not return any data for the selected tax year. HMRC do not provide data until PAYE reconciliation is complete. This process starts on around 6th June and usually completes for all tax payers by the end of November. Please try again later."
        }
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={includeError}
        backdropDismiss={false}
        onDidDismiss={() => setIncludeError(false)}
        header={"Include this information on my tax return "}
        message={`You have made changes but have not checked the 'Include' box to include the form in your tax return`}
        buttons={[
          {
            text: "Save and include the data on my tax return",
            handler: () => {
              setIncludeError(false);
              setUserChanged(false);
              let inc = includeData.fields.find((f) => {
                if (f.name) {
                  return f.name.includes("include");
                }
              });
              let inc_index = includeData.fields.findIndex((f) => f == inc);
              toggleValue(inc.name, inc_index, inc, true);
              setIncludeData(null);
            },
          },
          {
            text: "Save without including this information on my return",
            handler: () => {
              setIncludeError(false);
              setUserChanged(false);
              setIncludeData(null);
              saveData();
            },
          },
          {
            text: "Cancel - go back to form",
            handler: () => {
              setIncludeData(null);
              setIncludeError(false);
            },
          },
        ]}
      />
      <React.Fragment>
        <div className="tax-forms-page-container">
          <MiniDrawer location={props.location} />
          <Container
            className={
              saved
                ? "tax-forms-page-content-no-scroll"
                : "tax-forms-page-content"
            }
          >
            {SelectionBar(pageLoaded, update)}
            {displayMain()}
            <form
              action={`https://${global.api_url}/hmrc/app_connect.php?`}
              method="POST"
              id="connect_form"
            >
              <input type="hidden" name="token" value={user_atom.token} />
            </form>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default withRouter(TaxForms);
