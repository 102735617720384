import React, { useState, useEffect } from "react";
import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import CustomFilterPadded from "./CustomFilterPadded";
import { getTimestamp } from './timeStamp.js';
import { withStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { MenuItem, Toolbar } from "@material-ui/core";
import "../App.css";
import { IonButton } from "@ionic/react";


const StyledMenuItem = withStyles((theme) => ({
    root: {paddingLeft: "5px" },
  }))(MenuItem);


export const SelectionBar = (conditional, update, centerAlign = false, journeysTaxSummary) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
    const [percentageInc, setPercentageInc] = useState()
    const [percentageExp, setPercentageExp] = useState()
    const [percentageTax, setPercentageTax] = useState()
    const [incomeSummary, setIncomeSummary] = useState(0)
    const [expenseSummary, setExpenseSummary] = useState(0)
    const [taxSummary, setTaxSummary] = useState(0)
    const [firstLoad, setFirstLoad] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const [redirect, setRedirect] = useState("")
  

    const makeYearLabel = (text, start_date, end_date) => {
        let today = new Date();
        //convert to BST (cos that's effectively what we are comparing with from back end!)
        today.setMinutes(today.getMinutes()+60)

        let start = new Date(start_date);
        let end = new Date(end_date);

        start.setUTCHours(0); start.setMinutes(0); start.setSeconds(0);
        end.setUTCHours(23); end.setMinutes(59); end.setSeconds(59)

        if (today >= start && today <= end) {
          return text + " (This tax year)";
        }
        let dateOffset = 24 * 60 * 60 * 1000 * 365;
        today.setTime(today.getTime() - dateOffset);
        if (today >= start && today <= end) {
          return text + " (Last tax year)";
        }
        return text;
    }

    const getTaxCalc = (element) => {
        fetch(`https://${global.api_url}/getTaxCalculation.php?ts=${getTimestamp()}&tax_year=${element.tax_year}`, {
          method: "GET",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        })
        // .then(res => res.text())
        // .then(text => {console.log(text)})
        .then(res => res.json())
        .then(json => {
            let income  = json.summary_income
            let expense = json.summary_expense + json.mileage_claimed
            setIncomeSummary(income)
            setExpenseSummary(expense)
            let total  = income + expense
            let tax = json.summary_tax

            setTaxSummary(tax)
            if (journeysTaxSummary){
                journeysTaxSummary(json)
            }
            let income_percentage
            let expense_percentage
            let tax_percentage
            if (total == 0){
                income_percentage = 50.00
                expense_percentage = 50.00
                tax_percentage = 0.00
            }
            else{
                income_percentage = Math.round(((income/total)*100).toFixed(2))
                // console.log('income percentage', income_percentage)
                //expense_percentage = Math.round(((expense/total)*100).toFixed(2))
                expense_percentage = 100 - income_percentage
                // console.log('expense percentage', expense_percentage)
                tax_percentage = Math.round(((tax/total)*100).toFixed(2))
            }
            setPercentageInc(income_percentage)
            setPercentageExp(expense_percentage)
            setPercentageTax(tax_percentage)
            
        })
    }

    const setDateFilter = (param) => {
        const element = tax_years_atom.taxYears.find(element => element.id === param);
        getTaxCalc(element)

        setTaxYearsAtom({
            ...tax_years_atom,
           dateFilter: element.id,
           dateFilterStart: element.start_date,
           dateFilterEnd: element.end_date });
      }


    const styles  = {
        toolBar: {
            maxWidth: "100%",
            width: "30%",
            flexDirection: "row",
            paddingRight: 15,
            alignItems: "stretch",
            minWidth: "300px",
            marginBottom: "15px"
          },
        barBox70:{
            alignItems: "center",
            alignContents: "center",
            width: "70%",
            display: 'flex',
            flexDirection: "column",
            margin: "auto",
            minWidth: "150px"
          },
        barBox30:{
            alignItems: "center",
            alignContents: "center",
            width: "30%",
            display: 'flex',
            flexDirection: "column",
            margin: "auto",
            minWidth: "150px"
          },
        barBoxRow:{
            alignItems: "center",
            alignContents: "center",
            width: "30%",
            display: 'flex',
            flexDirection: "row",
            marginRight: "auto",
            marginLeft: "30px",
            minWidth: "350px",
            overflow: "auto"
          },
        innerBarBox:{
            alignItems: "center",
            alignContents: "center",
            display: 'inline-block',
            width: "100%",
            minWidth: "150px",
            margin: "auto",
          },
        innerBarBoxRight:{
            alignItems: "center",
            alignContents: "center",
            display: 'inline-block',
            width: "100%",
            minWidth: "80px",
            marginRight: "30px",
          },
        incStyle: {
            backgroundColor: "#c7ff00",
            width: `${percentageInc}%`,
            height: "100%",
            borderRadius: "5px",
            display: 'inline-block',
            cursor: "pointer"
        },
        expStyle :{
            display: 'inline-block',
            backgroundColor: "#A800FF",
            width: `${percentageExp}%`,
            height: "100%",
            borderRadius: "5px",
            float: "right",
            cursor: "pointer"
        },
        taxStyle:{
            display: 'inline-block',
            backgroundColor: "grey",
            minWidth: "1%",
            width: `${percentageTax}%`,
            maxWidth:'100%',
            height: "100%",
            borderRadius: "5px",
            opacity: "0.8",
            float: "right",
            cursor: "pointer"
        },
        zeroStyle: {
            display: 'inline-block',
            backgroundColor: "grey",
            width: `50.00%`,
            height: "100%",
            borderRadius: "5px",
            opacity: "0.2",
            cursor: "pointer"
        },
        mainBackgroundCenter: {
            backgroundColor: "white",
            border: "3px solid #3f90b0",
            // boxShadow: "2px 2px 3px grey",
            borderRadius: "3px",
            // maxWidth: "1300px"  ,
            margin: "auto",
            minWidth: 700
            },
        mainBackgroundLeft: {
            backgroundColor: "white",
            border: "3px solid #3f90b0",
            // boxShadow: "2px 2px 3px grey",
            borderRadius: "3px",
            minWidth: 450
            // maxWidth: "1300px"  ,
            },
        dateFilter: {
                padding: "0px",
                "--font-Size": "18px",
                minWidth: "270px",
                textAlign: "left",
              }
    }

    const redirectToBreakdown = () => {
        setRedirect("/taxBreakdown")
    }

    const redirectToTransactions = () => {
        setRedirect("/transactions")
    }

    const displayMain = () => {

        // handle redirect without losing recoil state
        if (redirect!="") {
            return  <Redirect
                to={{
                    pathname: redirect
                }}
        />
        }

        if (conditional){
            if (!firstLoad){
                setFirstLoad(true)
            }
    
            return(
                <React.Fragment>
                <div style = {centerAlign ? styles.mainBackgroundCenter : styles.mainBackgroundLeft }>
                     <Toolbar className="filters-container">
                        
                     <div style = {styles.barBoxRow}>
                       
                        <div style = {{width: "100px", paddingTop: "10px", fontSize:"18px"}}>Tax year:</div>
                        <div style = {styles.innerBarBox}>
                            <CustomFilterPadded
                                style = {styles.dateFilter}
                                
                                select
                                label=" "
                                value={tax_years_atom.dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                                lines = "full"
                            >
                            {tax_years_atom.taxYears.map(item =>
                                <StyledMenuItem key={item.id} value={item.id}>
                                    {makeYearLabel(
                                        item.explain,
                                        item.start_date,
                                        item.end_date
                                    )}
                                </StyledMenuItem>)}
                            </CustomFilterPadded>
                            </div>
                        </div>
                        <div style = {{display: "flex", flexDirection: "row", width: "50%", minWidth: 450}}>
                        <div style = {styles.barBox70}>
                            <div style = {styles.innerBarBox}>
                                <div style = {{ float: "left"}}>+£{incomeSummary ? incomeSummary.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0.00"}</div>
                                <div style = {{ float: "right"}} >-£{expenseSummary ? expenseSummary.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0.00"}</div>
                            </div>
                            {incomeSummary === 0 && expenseSummary === 0 ?
                            <div style = {styles.innerBarBox}>
                                <div style = {styles.zeroStyle} onClick = {() =>redirectToBreakdown()}>&nbsp;</div>
                                <div style = {styles.zeroStyle} onClick = {() =>redirectToBreakdown()}>&nbsp;</div>
                            </div>:
                            <div style = {styles.innerBarBox}>
                                <div style = {styles.incStyle} onClick = {() =>redirectToBreakdown()}>&nbsp;</div>
                                <div style = {styles.expStyle} onClick = {() =>redirectToBreakdown()}>&nbsp;</div>
                            </div> }
                            <div style = {styles.innerBarBox}>
                                <div style = {{ float: "left"}} >Income</div>
                                <div style = {{ float: "right"}}>Expenses</div>
                            </div>
                        </div>

                        <div style = {styles.barBox30}>
                            <div style = {styles.innerBarBoxRight}>
                                <div style = {{ float: "right"}}>Tax estimate</div>
                            </div>
                        <div style = {styles.innerBarBoxRight}>
                                <div style = {{ fontSize:"22px",float: "right"}} >£{taxSummary.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>
                            </div>
                            {/* <div style = {styles.innerBarBoxRight}>
                                <div style = {styles.taxStyle} onClick = {() =>redirectToBreakdown()}>&nbsp;</div>
                            </div> */}
                        </div>
                        <div style ={{height: "100%", marginRight: "10px", marginTop: "4px"}}>
                        {location.pathname !=="/taxBreakdown" ? <IonButton
                        className = "ion-button-inverse"
                        style ={{height: "65px", width: "110px", whiteSpace: "normal", fontSize: "12px"}}
                        onClick={() =>redirectToBreakdown()}>
                        View tax breakdown
                        </IonButton> :
                        <IonButton
                        className = "ion-button-inverse"
                        style ={{height: "65px", width: "110px", whiteSpace: "normal", fontSize: "12px"}}
                        onClick={() =>redirectToTransactions()}>
                        View transactions
                        </IonButton>
                        }
                        </div>
                        </div>
                    </Toolbar>

                    </div>
              </React.Fragment>

            )
        }
        else {
            return (<></>
            )
        }
    }

    useEffect (() => {
        if(firstLoad){
            const element = tax_years_atom.taxYears.find(element => element.id === tax_years_atom.dateFilter);
            getTaxCalc(element)
        }
    },
    [firstLoad])

    useEffect (() => {
        if (tax_years_atom.taxYears) {
            const element = tax_years_atom.taxYears.find(element => element.id === tax_years_atom.dateFilter);
            getTaxCalc(element)
        }}, [update])



    return (
        displayMain()
        )

}

export default SelectionBar
