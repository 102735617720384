import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles, IconButton, FormControl, Input, InputLabel, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& label.Mui-focused': {
            color: '#3f90b0'
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#3f90b0',
            borderBottomWidth: 2
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3f90b0'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#000000'
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#3f90b0'
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#3f90b0'
            }
        },
        '& .MuiIconButton-root': {
            color: '#3f90b0',
            padding: 0
        },
        '& .MuiInputBase-root': {
            width: 250
        }
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch'
    }
}));

export const PasswordInput = (props) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={classes.root}>
            <div>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor={props.id}>
                    {props.label}
                </InputLabel>
                <Input
                    id={props.id}
                    type={showPassword ? 'text' : 'password'}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </FormControl>
            </div>
        </div>
    );
};


export const AuthCodeInput = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                <InputLabel htmlFor={props.id}>
                    {props.label}
                </InputLabel>
                <Input
                    id={props.id}
                    type={'text'}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                />
                </FormControl>
            </div>
        </div>
    );
};

export default PasswordInput;
