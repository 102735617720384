import React, { useState, useEffect } from "react";
import { IonGrid, IonRow, IonCol, IonAlert } from "@ionic/react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { getTimestamp } from "./timeStamp.js";
import { styles } from "../LoginStyles";
import { useRecoilState } from "recoil";
import { userAtom } from "../atom.js";
import "./LoginPopup.css";

const LoginPopup = (props) => {
  const routerProps = props.routerProps;
  const setShowTimeout = props.setShowTimeout;
  const startTimer = props.startTimer
  const [user, setUser] = useRecoilState(userAtom);


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [passwordCount, setPasswordCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [showAgentError, setShowAgentError] = useState(false);



  useEffect(()=>{
    if (passwordCount === 3) {
      setShowTimeout(false);
      localStorage.clear()
      routerProps.history.push("/logout");
    } else {
      if (passwordCount > 0){
      setShowError(true);
      setPassword("");
      }
    }
  },
  [passwordCount])


  const refreshTokenAgent = () => {

     let body = JSON.stringify({
        email: email,
        password: password,
      })

      fetch(`https://${global.api_url}/agent/agentLogin.php?ts=${getTimestamp()}`, {
        method: "POST",
        body: body,
        headers: { partnerId: "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a" }
    })
        .then(res => res.json())
        .then(json => {
            console.log(json)
            console.log(props)
            let agent = localStorage.getItem("agent")
            if (json.status === "success") {
            
              localStorage.setItem("token", props.userToken);
              setPassword("");
              setShowTimeout(false);
              setPasswordCount(0)
              startTimer()
              }
              else{
                setPasswordCount(passwordCount + 1);
                setShowAgentError(true)
              }
            // } else {
            // }
         
        });
  }

  const refreshToken = () => {

    let body = JSON.stringify({
      email: localStorage.getItem('username'),
      password: password,
    });

    fetch(`https://${global.api_url}/getToken.php?ts=${getTimestamp()}`, {
      method: "POST",
      body: body,
      headers: { partnerId: global.partner_id },
    })
      .then((res) => res.json())
      .then((json) => {

        if (json.status === "success") {
          localStorage.setItem("token", json.token);
          setPassword("");
          setShowTimeout(false);
          startTimer()
        } else {
          setPasswordCount(passwordCount + 1);
          // if (passwordCount === 3) {
          //   setShowTimeout(false);
          //   routerProps.history.push("/logout/logout");
          // } else {
          //   setShowError(true);
          //   setPassword("");
          // }
        }
      });
  };

  const handleKeyDownPassword = (e) => {
    if (e.key === "Enter") {
      if(props.userToken){
        refreshTokenAgent()
      }
      else{
      refreshToken();
      }
    }
  };

  return (
    <div class="container">
      <div class="modal">
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            flex: 0.6,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <img
            style={{
              width: "100%",
              paddingLeft: -40,
              paddingTop: 20,
            }}
            alt=""
            src={require("../images/untied-word-white.svg").default}
          />
        </div>

        {props.userToken ? 
        
          <>
          
          <p style={{ color: "white" }}>We logged you out because we need you to reauthenticate. Please enter your adviser email and password and we'll take you back to where you were</p>
          <TextField
            id="inputFocus"
            autoFocus
            style={styles.inputBottomMargin}
            autoCapitalize="none"
            type="text"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              }}
            returnkeytype="next"
            placeholder="Adviser email"
            margin="none"
            InputProps={{ disableUnderline: true }}
          />

            <TextField
         
            style={styles.input}
            autoCapitalize="none"
            type="password"
            value={password}
            onKeyPress={handleKeyDownPassword}
            onChange={(event) => {
              setPassword(event.target.value);
              }}
            returnkeytype="next"
            placeholder="Password"
            margin="none"
            InputProps={{ disableUnderline: true }}
          /></>
         : <><p style={{ color: "white" }}>We logged you out because we need you to reauthenticate. Please enter your password and we'll take you back to where you were</p>

        <TextField
          id="inputFocus"
          autoFocus
          style={styles.input}
          autoCapitalize="none"
          type="password"
          value={password}
          onKeyPress={handleKeyDownPassword}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          returnkeytype="next"
          placeholder="Password"
          margin="none"
          InputProps={{ disableUnderline: true }}
        /></>}

        <IonGrid style={{ paddingTop: 10, marginLeft: 0, marginRight: 0 }}>
          <IonRow>
            <IonCol style={{ paddingLeft: 0, paddingRight: 5 }}>
              <Button
                style={styles.buttonContainer2}
                size="small"
                onClick={() => {
                  setShowTimeout(false);
                  routerProps.history.push("/logout/logout");
                }}
              >
                Log out
              </Button>
            </IonCol>

            <IonCol style={{ paddingLeft: 5, paddingRight: 0 }}>
              <Button
                style={styles.buttonContainer}
                size="small"
                onClick={() => {
                  if(props.userToken){
                    refreshTokenAgent()
                  }
                  else{
                  refreshToken();
                  }
                }}
              >
                Log in
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert
          isOpen={showError}
          onDidDismiss={() => setShowError(false)}
          header={"Wrong password"}
          subHeader={""}
          message={"You have entered an incorrect password. Please try again."}
          buttons={["OK"]}
        />

        <IonAlert
          isOpen={showAgentError}
          onDidDismiss={() => setShowAgentError(false)}
          header={"Wrong adviser"}
          subHeader={""}
          message={"You have entered incorrect adviser information for this client"}
          buttons={["OK"]}
        />
      </div>
    </div>
  );
};

export default LoginPopup;