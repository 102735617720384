import {TextField, withStyles} from "@material-ui/core";

const CustomFilterPadded = withStyles({
    selected: {paddingLeft: "15px"},

    root: { paddingLeft: "15px",
        '& label.Mui-focused': {
            color: '#3f90b0',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#3f90b0',
            borderBottomWidth: 2,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3f90b0',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#000000',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#3f90b0',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#3f90b0',

            }
        }
    }
})(TextField);

export default CustomFilterPadded;
