import React, {useState, useEffect} from "react";
import { AgGridReact } from "ag-grid-react";
import {AllCommunityModules} from "@ag-grid-community/all-modules";
import { withRouter } from "react-router-dom";
import MiniDrawer from "./../MiniDrawer";
import { Mixpanel } from "../mixpanel";
import {Button, Card, CardActions, Modal, Backdrop, Fade} from "@material-ui/core";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPopover,
  IonAlert,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonLoading, IonLabel, IonDatetime
} from '@ionic/react';
import Papa from "papaparse";
import moment from "moment";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Link, Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import needs NPM https://www.npmjs.com/package/react-confirm-alert npm install react-confirm-alert --save
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

import { useRecoilState } from 'recoil'
import { userAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { getTimestamp } from '../components/timeStamp.js'


const ManualTransactionsImporter = (props) => {

  const [user_atom, setUserAtom] = useRecoilState(userAtom)          
  const [recoilFetched, setRecoilFetched] = useState(false)
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [uploadedRowData, setUploadedRowData] = useState([]);
  const [flags, setFlags] = useState({
    renderReady: false,
    open: false,
    addThenTag: false,
    showDeleteRow: false,
    caughtBadCsv: false
  });
  const errors = []
  const [errorsLog, setErrorsLog] = useState("")
  const [uploadAlert, setUploadAlert]= useState(false)
  const [csvFile, setCsvFile] = useState(null);
  const [transIds, setTransIds] = useState([]);
  const constants = {
    defaultColDef: {
      resizable: true,
      editable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      flex: 1,
      minWidth: 100
    },
    columnDefs: [
      {
        suppressFilter: true,
        suppressSorting: true,
        suppressResize: false,
        filter: false,
        maxWidth: 40,
        sortable: false,
        resizable: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {
        headerName: "Account",
        field: "Account",
        width: 100,
        cellClass: "cell-style"
      },
      {
        headerName: "Date",
        field: "Date",
        width: 100,
        valueFormatter: params => services.actionsService.prettyDateTime(params.value),
        sort: "desc",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) return 0;

            let cellDate = new Date(dateAsString);
            cellDate.setHours(0, 0, 0, 0);
            filterLocalDateAtMidnight.setHours(0, 0, 0, 0);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        },
        cellStyle: params => {
          if (moment(params.value, 'DD/MM/YYYY')._isValid !== true) {
            return {
              color: "red",
              fontWeight: "bold",
              textAlign: "right"
            };
          }
        },
        cellClass: "cell-style"
      },
      {
        headerName: "Description",
        field: "Description",
        width: 350,
        valueGetter: params => {
          if(params.data.Description){
          return params.data.Description.substring(0,100)}
          else{
            errors.push(params.node.childIndex+1)
          }
        },
        cellClass: "cell-style"
      },
      {
        headerName: "Amount",
        field: "Amount",
        width: 120,
        valueGetter: params => Math.abs(params.data.Amount),
        valueFormatter: params => {
          if (params.data.Amount > 0) {
            return (
                "+ £" +
                Math.abs(Number(params.value))
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          } else {
            return (
                "£" +
                Math.abs(Number(params.value))
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            );
          }
        },
        cellStyle: params => {
          let amount = params.data.Amount;
          amount = parseFloat(amount);
          if (isNaN(amount)) {
            return {
              color: "red",
              fontWeight: "bold",
              textAlign: "right"
            };
          }
          if (params.data.Amount > 0) {
            return {
              color: "#0c90b2",
              fontWeight: "bold",
              textAlign: "right"
            };
          } else {
            return {
              fontWeight: "bold",
              textAlign: "right"
            };
          }
        },
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "Category",
        field: "Category",
        valueGetter: params => {if(params.data.Category){return params.data.Category.substring(0,50)}},
        width:80,
        cellStyle: params => {
          if (params.data.Category){
          if (
              params.data.Category.toLowerCase() === "in" ||
              params.data.Category.toLowerCase() === "income"
          ) {
            return {
              color: "#0c90b2",
              fontWeight: "bold",
              textAlign: "right"
            };
          } else {
            return {
              category: "In or Out",
              fontWeight: "bold",
              textAlign: "right"
            };
          }
        }
      }
      },
      {
        headerName: "Classification",
        field: "Classification",
        resizable: false,
        valueGetter: params => {if(params.data.Classification){return params.data.Classification.substring(0,255)}},
        cellClass: "cell-style"
      }
    ]
  };
  useEffect(()=>{
    if(uploadAlert){

        confirmAlert({
              title: "Whoops!",
              message:
                  `There seems to be a problem with the formatting of some of your data, there are problems on line${errorsLog.length>1 ? "s" : ""} ${errorsLog}`,
              buttons: [
                {
                  label: "Try again!",
                  onClick: () => {
                    props.history.push("/ManualTransactionsImporter");
                    // setUploadedRowData(null)
                    setUploadAlert(false)
                    setCsvFile(undefined);
                  }
                }]
              })
         setFlags({...flags, renderReady: false})
    }
  }, [uploadAlert])

  useEffect(()=> {
    if(errors.length > 1){
      setErrorsLog(errors.join(", "))
    }
  }, [errors])

  useEffect(()=> {
    if(errorsLog){
    if (!uploadAlert){
      setUploadAlert(true)
      }
    }
  }, [errorsLog])

  useEffect(() => {
    if (!recoilFetched) {
      if (!user_atom.token || user_atom.agent !== ""){
      let userState = getLocalStorage()
      setUserAtom({
        ...userState,
        agent: user_atom.agent
        })
        setRecoilFetched(true)
    }
    else{
      setRecoilFetched(true)
    }
  }
  }, [])

  const services = {
    fetchService: {
      postTransaction: (node) => {
        let payloads = [];
        let payload = {};
        gridApi.forEachNode((node) => {
          node = node.data;
          payload = {
            id: Math.floor(Math.random() * 999999) + 999999,
            ac_name: node.Account,
            importedAmount: node.Amount,
            amount: Math.abs(node.Amount),
            description: node.Description.substring(0,100),
            trans_type: node.Category,
            importCategory: node.Category,
            classification: node.Classification ? node.Classification.substring(0,255) : '',
            trans_date: node.Date,
            datetime: node.Date
          };
          payloads.push(payload);
        });

        fetch(
            `https://${global.api_url}/createTransactions.php?&ts=${getTimestamp()}&noclaim=1`,
            {
              method: "POST",
              headers: { token: user_atom.token, agentId: user_atom.agent },
              body: JSON.stringify(payloads)
            }
        )
            .then(res => res.json())
            .then(json => {
              setTransIds(json.trans_ids);
              return;
            });

        gridApi.updateRowData({ node });
      },
      saveFromCSV: () => {
        gridApi.forEachNode(node => {
          node = node.data;
          const amount = node.Amount;
          if (isNaN(amount) || moment(node.Date, "DD/MM/YYYY")._isValid !== true) {
            setFlags((flags) => ({...flags, caughtBadCsv: true}));
            Mixpanel.track("csv_bad_format");

            confirmAlert({
              title: "Whoops!",
              message:
                  "There seems to be a problem with the formatting of some of your data! Please, see the data coloured 'red' for clues. NaN means 'Not a Number'.",
              buttons: [
                {
                  label: "Try again!",
                  onClick: () => {
                    props.history.push("/ManualTransactionsImporter");
                    setFlags((flags) => ({...flags, addThenTag: false, renderReady: true, caughtBadCsv: false}));
                    setCsvFile(undefined);
                  }
                },
                {
                  label: "Help!",
                  onClick: () => {
                    alert(
                        "The 'red' items are in the wrong format! 31/12/2020 or 3333.99 only as we add the formatting for you. Press 'i' for more info."
                    );
                    props.history.push("/ManualTransactionsImporter");
                    Mixpanel.track("csv_help_pressed");
                    setFlags((flags) => ({...flags, addThenTag: true, renderReady: !flags.renderReady, caughtBadCsv: false}));
                    setCsvFile(undefined);
                  }
                }
              ]
            });
          }
        });

        setTimeout(
            function() {
              if (!flags.caughtBadCsv) {
                //this.forceUpdate();
                services.fetchService.postTransaction();
                setFlags((flags) => ({...flags, addThenTag: true, renderReady: true}));
                Mixpanel.track("csv_added_trans");
              }
            }.bind(this),
            350
        );
      }
    },
    actionsService: {
      prettyDateTime: (dateToProcess) => {
        return dateToProcess;
        const date1 = new Date(dateToProcess);
        const longDate = date1.toDateString();
        const timePart = date1.toLocaleTimeString();
        return longDate + " " + timePart;
      },
      updateData: result => {
        const data = result.data;
        if( data.length > 0 ){
          setUploadedRowData(data);
          setFlags((flags) => ({...flags, renderReady: true}));
        }
        //this.forceUpdate();  commented because I don't know who forceUpdate is
      },
      handleChange: e => setCsvFile(e.target.files[0]),

      importCSV: () => {
        const ext = csvFile.name.substr(csvFile.name.length - 3)
        console.log("csv test:",csvFile)
        if((csvFile.type == "text/csv") || ext == "txt" || ext == "csv" ){
        Papa.parse(csvFile, {
          complete: services.actionsService.updateData,
          header: true,
          skipEmptyLines: true
        });
        setFlags((flags) => ({...flags, addThenTag: false}));
        Mixpanel.track("csv_imported");
        }
        else{
          confirmAlert({
            title: "Whoops!",
            message:
                "It appears the file you are trying to upload is not in the correct format, please make sure any file you are uploading is a csv file",
            buttons: [ {
              label: "Ok",
            },]
          });
        }
      },
      selectDeleteRow: () => {
        confirmAlert({
          title: "You are about to delete this row!",
          message: "All data in the selected row/s will be removed.",
          buttons: [
            {
              label: "Continue",
              onClick: () => services.actionsService.clearData()
            },
            {
              label: "Oops, I made a mistake!",
              onClick: () => {
                alert("Don't worry, nothing has changed!");
                gridApi.deselectAll();
              }
            }
          ]
        });
      },
      clearData: () => {
        Mixpanel.track("manual_row_deleted");
        const data = uploadedRowData;

        gridApi.getSelectedRows().forEach((node) => {
          for (let i = data.length - 1; i >= 0; --i) {
            if (JSON.stringify(data[i]) === JSON.stringify(node)) {
              data.splice(i,1);
            }
          }
        });
        setUploadedRowData(data);
        gridApi.setRowData(uploadedRowData);
        gridApi.redrawRows();
        gridApi.deselectAll();
      }
    },
    gridService: {
      onGridReady: (params) => {
        services.actionsService.importCSV();
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
      },
      onSelectionChanged: () => {
        if (gridApi.getSelectedRows().length > 0) {
          setFlags((flags) => ({...flags, showDeleteRow: true}));
        } else {
          setFlags((flags) => ({...flags, showDeleteRow: false}));
        }

      }
    },
    externalFiltersService: {},
    renderService: {
      displayTransitionsModal: () => {
        const handleOpen = () => {
          setFlags((flags) => ({...flags, open: true}));
          Mixpanel.track("csv_info_pressed");
        };

        const handleClose = () => {
          setFlags((flags) => ({...flags, open: false}));
        };

        return (
            <div
                style={{
                  // width: 64,
                  borderColor: "blue",
                  float: "right",
                  textAlign: "right",
                  flex: 1,
                  paddingLeft: 20,
                  paddingTop: 5,
                  paddingRight: 10
                }}
            >
              <span onClick={handleOpen} id="info" style={{cursor:'pointer'}}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                </svg>
              </span>

              <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={styles.modal}
                  open={flags.open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropColor = {"white"}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500
                  }}
              >
                <Fade in={flags.open}>
                <div style = {{backgroundColor: "white", width: "70%", margin: "auto", marginTop: "15vh", padding: "10px"}}>
                  <div className={styles.paper}>
                    <h2
                        id="transition-modal-title"
                        style={{ color: "#14A7CC", overflow: "auto" }}
                    >
                      About this Manual Transactions Importer
                    </h2>

                    <h3 style={{ color: "#14A7CC" }}>Importing your own data</h3>
                    <ul>
                      <li>
                        <em>
                          <b>Download CSV template</b>.
                        </em>{" "}
                        We strongly suggest you download the {" "}
                        <a href="/CSV/CSV_Template.csv" download>                          
                          CSV template
                        </a>{" "}
                        and use the existing data as a guide. You can then overwrite our
                        data with your own - but keeping the existing format!
                      </li>
                      <li>
                        <em>
                          <b>Importing a CSV</b>.
                        </em>{" "}
                        Once you have created a spreadsheet with your own data, you can
                        import it into the <b>untied</b> worksheet. You should - if you
                        have used the correct format from the template - see your data
                        on the screen.
                      </li>
                      <li>
                        <em>
                          <b>Using your data</b>.
                        </em>{" "}
                        After you have uploaded your csv file and it is displayed on
                        screen, you can edit, delete, add and transfer your data so it
                        is displayed in your main transaction screen. After adding your
                        data to the main transactions screen, you will only see your new
                        data so you can correctly tag them before clicking 'See all
                        transactions'.
                      </li>
                    </ul>

                    <h3 style={{ color: "#14A7CC" }}>Please note!</h3>
                    <p>
                      <strong>File format</strong>. The file must be saved as a csv. If you create your own
                      spreadsheet in something like Excel, you will need to 'save as' or
                      export the sheet as a{" "}
                      <a href="https://support.office.com/en-us/article/Save-a-workbook-to-text-format-txt-or-csv-3E9A9D6C-70DA-4255-AA28-FCACF1F081E6" target="_blank">
                        {" "}
                        .csv
                      </a>
                    </p>
                    <p>
                      The <strong>date format</strong> must be dd/mm/yyyy (e.g. 29/02/2020) and <strong>the
                      amount</strong> should be a positive or negative number e.g. 9999.99 or -9999.99 (you can use a decimal for
                      pence, but no comma for thousands). Negative values will create an outgoing transaction (debit). Positive
                      values an incoming transaction (credit). Category and Classification can be set to anything you like.
                    </p>
                    <p>
                      This App works better on Chrome, Firefox or Safari. Edge works in
                      a slightly different way and we cannot guarantee the user
                      experience.
                    </p>
         
        
                  <div style ={{ display: "flex", width: "100%"}}>

                    <a
                        style={{ marginTop: "10px", color: "black" }}
                        href="https://help.untied.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Get help
                    </a>
                    <Button
                    // className="ion-button-money"
                    style ={{backgroundColor: "grey", color: "white", marginLeft: "87%"}}
                    onClick ={()=>handleClose()}>Close</Button>

                  </div>
                  </div>
              
                  </div>
                </Fade>
              </Modal>
            </div>
        );
      },
      displayMainScreen: () => {
        if (recoilFetched){
        return(<>
          <h1 className="header-no-margin">
            Review transactions to import
          </h1>
          {!flags.addThenTag && <>
            <p>
              If everything looks right in the table below click 'Import
              transactions'.
            </p>
            <p>
              Double click an entry to edit. Select checkbox to delete.
              Click 'Cancel' to start over.
            </p>
            <div className={`ag-theme-alpine grid-container import-page ${1===2 ? "hide-grid" : "show-grid"}`}>
              <AgGridReact
                  modules={AllCommunityModules}
                  defaultColDef={constants.defaultColDef}
                  rowData={uploadedRowData}
                  columnDefs={constants.columnDefs}
                  animateRows
                  rowHeight={30}
                  rowSelection={"multiple"}
                  rowDeselection={true}
                  suppressRowClickSelection={true}
                  suppressDragLeaveHidesColumns={true}
                  onSelectionChanged={() => services.gridService.onSelectionChanged()}
                  onGridReady={services.gridService.onGridReady}
              />
            </div>
          </>}

          {!flags.addThenTag && <>
            <Button
                type="submit"
                style={Object.assign({}, styles.buttonContainer4, {
                  marginRight: "10px",
                  width: "150px"
                })}
                onClick={() => {
                  setFlags((flags) => ({...flags, addThenTag: false, renderReady: false}));
                }}
            >
              Cancel
            </Button>

            {flags.showDeleteRow && <Button
                type="submit"
                style={Object.assign({}, styles.buttonContainer5, {
                  marginLeft: "10px",
                  width: "150px"
                })}
                onClick={() => services.actionsService.selectDeleteRow()}
            >
              Delete row
            </Button>}
            {!flags.showDeleteRow && <Button
                type="submit"
                style={Object.assign({}, styles.buttonContainer4, {
                  marginLeft: "20px"
                })}
                onClick={() => {
                  services.fetchService.saveFromCSV();
                }}
            >
              Import transactions
            </Button>}
          </>}
          {flags.addThenTag && <Dots />}
        </>)}
      },
      displayCsvTemplate() {
        if(recoilFetched){
        return (
            <div className="csv-import-container">
              <Card>
                <CardActions>
                  <div className="csv-import-row">
                    <div>
                      <span className="csv-import-step">Step 1: </span>
                      <a
                          style={{ lineHeight: "24px", verticalAlign: "middle" }}
                          id="download_link"
                          href="/CSV/CSV_Template.csv"
                      >Download CSV template</a>
                    </div>
                    <div className="csv-import-icon"
                         onClick={() =>
                            document.getElementById("download_link").click()
                         }
                         style={{cursor:'pointer'}}
                    >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                    </svg>
                  </div>
                  </div>
                </CardActions>
                <CardActions>
                  <div className="csv-import-row">
                    <div>
                      <span className="csv-import-step">Step 2: </span>
                      <span>Edit in Excel, Google Sheets or another spreadsheet editor. <a style={{textDecorationLine:'underline', cursor:'pointer', color:'-webkit-link'}} onClick={() => document.getElementById("info").click()}>Read this help</a></span>
                    </div>
                    <div style={{ display: "none" }}>
                      {services.renderService.displayTransitionsModal()}
                    </div>
                    <div className="csv-import-icon"
                         onClick={() => document.getElementById("info").click()}
                         style={{cursor:'pointer'}}>
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                      </svg>
                    </div>
                  </div>
                </CardActions>
                <CardActions>
                  <div className="csv-import-row">
                    <div>
                      <span className="csv-import-step">Step 3: </span>
                      <span
                          style={{cursor:'pointer'}}
                          alt="upload"
                          onClick={() => document.getElementById("uploader").click()}
                      >
                          Choose your CSV file
                        </span>
                    </div>
                    <div>
                      <input
                          id="uploader"
                          className="csv-input"
                          style={{
                            display: "none",
                            marginLeft: 90
                          }}
                          type="file"
                          ref={input => {
                            this.filesInput = input;
                          }}
                          name="file"
                          placeholder={null}
                          onChange={(e) =>{
                            services.actionsService.handleChange(e)
                          }}
                          // onInput = {services.actionsService.handleChange} 
                      
                      />
                      <div className="csv-import-icon"
                           onClick={() => document.getElementById("uploader").click()}
                           style={{cursor:'pointer'}}
                      >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </CardActions>
                {csvFile && <CardActions>
                  <div className="csv-import-row">
                    <div>
                      <span className="csv-import-step">Step 4: </span>
                      <span
                          style={{cursor:'pointer', textDecorationLine:'underline'}}
                          onClick={() => document.getElementById("upload").click()}
                      >
                            Upload {csvFile.name}
                          </span>
                    </div>
                    <div>
                      <div className="csv-import-icon-left"
                            id="upload"
                            style = {{cursor: 'pointer'}}
                            onClick={() => services.actionsService.importCSV()}
                      >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
                        </svg>
                      </div>
                      <div className="csv-import-icon-right"
                            id="delete"
                            style = {{cursor: 'pointer'}}
                            onClick={() =>{
                              document.getElementById("uploader").value = ""
                              setCsvFile(null)
                              }}
                      >
                        <RemoveCircleIcon style = {{fontsize: "medium"}}/>
                      </div>
                    </div>
                  </div>
                </CardActions>}
              </Card>
              <Button
                  type="submit"
                  style={Object.assign({}, styles.buttonContainer4, {
                    marginRight: "10px",
                    width: "150px"
                  })}
                  onClick={() => props.history.push("/transactions")}
              >
                Cancel
              </Button>

              {csvFile && (
                  <Button
                      type="submit"
                      style={Object.assign({}, styles.buttonContainer4, {
                        marginRight: "10px",
                        width: "150px"
                      })}
                      onClick={() => services.actionsService.importCSV()}
                  >
                    Upload
                  </Button>
              )}
            </div>
        );}
      }
    }
  };

  const styles = {
    card: {
      minWidth: 875
    },

    title: {
      fontSize: 16,
      //color: "black"
      color: "black",
      alignContent: "left",
      paddingBottom: 20,
      paddingTop: 5
    },
    pos: {
      marginBottom: 12
    },
    itIs: {
      color: "#c7ff00",
      fontSize: 18,
      fontWeight: "500",
      letterSpacing: 0.54,
      textAlign: "center",
      alignSelf: "center"
    },
    buttonContainer: {
      paddingVertical: 5,
      width: 100,
      height: 40,
      borderRadius: 2,
      color: "#14A7CC",
      backgroundColor: "#ffffff",
      textTransform: "none",
      fontSize: 16,
      fontWeight: "600",
      textDecoration: "none"
    },
    buttonContainer1: {
      width: 100,
      height: 50,
      borderRadius: 2,
      color: "#14A7CC",
      backgroundColor: "green",

      fontSize: 16,
      fontWeight: "600",
      textDecoration: "none",
      padding: 20
    },
    buttonContainer3: {
      paddingVertical: 12,
      width: 300,
      height: 50,
      borderRadius: 2,
      color: "white",
      backgroundColor: "#14A7CC",
      textTransform: "none",
      fontSize: 16,
      fontWeight: "600",
      textDecoration: "none"
    },
    buttonContainer4: {
      paddingVertical: 12,
      width: 400,
      height: 40,
      borderRadius: 2,
      color: "white",
      backgroundColor: "#14A7CC",
      textTransform: "none",
      fontSize: 16,
      fontWeight: "600",
      textDecoration: "none",
      marginTop: 20
    },
    buttonContainer5: {
      paddingVertical: 12,
      width: 400,
      height: 40,
      borderRadius: 2,
      color: "red",
      backgroundColor: "#fd8e8e",
      textTransform: "none",
      fontSize: 16,
      fontWeight: "600",
      textDecoration: "none",
      marginTop: 20
    },
    buttonContainer10: {
      paddingVertical: 20,
      width: 500,
      height: 50,
      borderRadius: 2,
      color: "white",
      backgroundColor: "#14A7CC",
      textTransform: "none",
      fontSize: 22,
      fontWeight: "700",
      justifyContent: "center",
      float: "center",
      alignItems: "center",
      alignSelf: "center",
      margin: 15,
      padding: 15,
      textDecoration: "none"
    },
    btn: {
      color: "green"
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px"
    },
    paper: {
      border: "2px solid #14A7CC",
      width: "70%",
      overflow: "auto",
      backgroundColor: "white"
    }
  };

  return (<>
    {transIds.length > 0 && <Redirect
        to={{
          pathname: "/transactions",
          state: { fromCsvImporter: transIds }
        }}
    />}
    <React.Fragment>
      <div className="transaction-page-container">
        <MiniDrawer />
        <div className="transaction-page-content">
          {!flags.renderReady && services.renderService.displayCsvTemplate()}
          {flags.renderReady && services.renderService.displayMainScreen()}
        </div>
      </div>
    </React.Fragment>
  </>);
};



// const mapStateToProps = (state) => {
//   return { ...state }
// };


// export default connect(mapStateToProps)(withRouter(ManualTransactionsImporter))
export default withRouter(ManualTransactionsImporter)
