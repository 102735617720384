import React, { useState, useRef, useEffect } from "react";
import { withRouter} from "react-router-dom";
import { IonAlert, IonGrid, IonRow, IonCol } from '@ionic/react';
import { Dots } from "react-activity";
import { Container, Box, Button, MenuItem, Modal, Select, withStyles, Tab, Tabs, Typography, } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import moment from "moment";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import MiniDrawer from "./../MiniDrawer";
import { v4 as uuidv4 } from 'uuid';
import { getTimestamp } from '../components/timeStamp.js'
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import "./../App.css";
import Refresh from '@material-ui/icons/Refresh';

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { JsonToTable } from "react-json-to-table";


const MTD = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)          
    const [recoilFetched, setRecoilFetched] = useState(false)

    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
    const [gotTaxYear, setGotTaxYear] = useState(false)

    const firstTimeRender = useRef(true);
    const [page, setPage] = useState(0);
    const [pageView, setPageView] = useState(0);
    const [status, setStatus] = useState("error");
    const [flags, setFlags] = useState({
        ready: false,
        confirmEOP: false,
        showFileButton: true,
        crystaliseConfirmed: false,
        finalised: false,
        viewRaw: false,
        submitPeriodPreview: false,
        confirmSummary: false,
        intentToCrystalise: false,
        retrievingCrystalCalc: false,
        readyToCrystalise: false,
        crystallizeProcessing: false,
        alertOpen: false,
        EOPConfirmed: false,
        connectorOpen: false
    });
    const [componentConfigData, setComponentConfigData] = useState({
        headers: "",
        selectedBusiness: null,
        businesses: [],
        currentObligations: {
            obligations:[]
        },
        periodicUpdates: [],
        eopObligations: [],
        eopStart: null,
        eopEnd: null,
        taxCalc: {
            incomeTaxAmount: 0,
            totalNicAmount: 0
        },
        years: tax_years_atom.taxYears,
        selectedYear: null,
        lastYearString: null,
        lastYearHMRCString: null,
        lastYearStart: null,
        lastYearEnd: null,
        amounts: null,
        startDate: null,
        endDate: null,
        companyId: null,
        errorMessage: {
            alertHeader: '',
            alertMessage: ''
        },
        fullReturn: {},
        messages: {},
        amendment: null,
        periodId: null,
        calcId: null
    });

    useEffect(() => {
        if (!recoilFetched) {
          if (!user_atom.token || user_atom.agent !== ""){
          let userState = getLocalStorage()
          setUserAtom({
            ...userState,
            agent: user_atom.agent
            })
          getRecoilTaxYears(userState.token, userState.agent)
        }
        else if (JSON.stringify(tax_years_atom) === "{}"){
          getRecoilTaxYears()
        }
        else{
          setRecoilFetched(true)
        }
      }
      }, [])


    useEffect( () => {
        if(recoilFetched){
            firstTimeRender.current = false;
            setComponentConfigData((config) => ({...config, headers: JSON.stringify(getHMRCAFHeaders())}));
            getTaxYears();
        }
    }, [recoilFetched]);

    useEffect(() => {
        console.log('in selected year effect', gotTaxYear, )
        if (recoilFetched && gotTaxYear) {
            console.log(gotTaxYear, recoilFetched, 'getting mtd businesses')
            getMTDBusinesses('Y')   
            if (componentConfigData.years) {
               console.log('getting local tax calc')
               getTaxCalc(componentConfigData.selectedYear, false, true)
               // --> leave for user to request!
            }
        }
    }, [gotTaxYear, componentConfigData.selectedYear, componentConfigData.years]);

    useEffect(() => {
        getPeriodicUpdates()
    }, [componentConfigData.selectedBusiness]);

    // create a useEffect on flags.alertOpen so that when it is closed we reset error message
    useEffect(() => {
        if (!flags.alertOpen) {
            setComponentConfigData({ ...componentConfigData, errorMessage: { alertHeader: '', alertMessage: '', buttons: ['OK'] } } );
        }
    }, [flags.alertOpen])

    const localIpUrl = require('local-ip-url');

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                <Box p={3}>{children}</Box>
            </Typography>
        );
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    const a11yProps = (index) => ({
        "id": `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`
    });

    const LinkTab = (props) => (<Tab
        component="a"
        onClick={e => e.preventDefault()}
        {...props}
    />);

    const StyledTabs = withStyles({
        indicator: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#0c90b2",
            "& > div": {
                maxWidth: 40,
                width: "100%",
                backgroundColor: "#0c90b2"
            }
        }
    })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {
        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
          method: "GET",
          headers: { token: passed_token, agentId: passed_agentId }
        })
          .then(response => response.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                //alert('redirect to login page');
                props.history.push("/logout/logout");
              }
            } else {
                setComponentConfigData({...componentConfigData, years: json.years})
              let today = new Date();
              let currentYearID = 0;
              let dateFilterStart = "";
              let dateFilterEnd = "";
              json.years.forEach(element => {
                const start = new Date(element.start_date);
                const end = new Date(element.end_date);
    
                if (today >= start && today <= end) {
                  currentYearID = element.id;
                  dateFilterStart = element.start_date;
                  dateFilterEnd = element.end_date;
                }
              });
              setTaxYearsAtom(
                { taxYears: json.years,
                  dateFilterStart: dateFilterStart,
                  dateFilterEnd: dateFilterEnd,
                  dateFilter: currentYearID
                });
              if (!recoilFetched){
                setRecoilFetched(true)
              }
            }
          });
      }

    const getCookie = (cName) => {
        let name = cName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    const setCookie = (cName, cValue, exDays) => {
        let d = new Date();
        d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    };

    const pad = (number, length) => {
        let str = "" + number;
        while (str.length < length) {
            str = '0' + str
        }
        return str
    };

    const getOffset = () => {
        let offset = new Date().getTimezoneOffset();
        offset = ((offset < 0 ? '+' : '-') + // Note the reversed sign!
            pad(parseInt(Math.abs(offset / 60)), 2) + ':' +
            pad(Math.abs(offset % 60), 2));
        return offset
    };


    const getHMRCAFHeaders = () => {
        let array = [];

        // does user have an MTD token from HMRC?
        let hmrc_uid = getCookie('uid');
        if (hmrc_uid === "") {
            hmrc_uid = uuidv4();
        }
        setCookie('uid', hmrc_uid, 365);

        array.push('Gov-Client-Device-ID: ' + hmrc_uid);
        array.push('Gov-Client-User-IDs: untied=' + localStorage.getItem('guid'));
        array.push('Gov-Client-Screens: width=' + window.screen.width + '&height=' + window.screen.height + '&scaling-factor=' + window.visualViewport.scale + '&colour-depth=' + window.screen.colorDepth);
        array.push('Gov-Client-Window-Size: width=' + window.visualViewport.width.toString() + '&height=' + window.visualViewport.height.toString());
        //array.push('Gov-Client-Browser-Do-Not-Track: ' + ((Number(navigator.doNotTrack) === 1) ? 'true' : 'false'));
        array.push('Gov-Client-Timezone: UTC' + getOffset());
        //array.push('Gov-Client-Local-IPs: ' + localIpUrl('private'));
        let plugins_str = "";
        try {
            navigator.plugins.forEach((item, index) => {
                plugins_str += encodeURI(item['name']) + ((index < navigator.plugins.length - 1) ? ',' : '');
            });
        } catch (e) {
            //sod it!
        }
        array.push('Gov-Client-Browser-Plugins: ' + plugins_str);
        array.push('Gov-Client-Browser-JS-User-Agent: ' + navigator.userAgent);
        array.push('Gov-Vendor-License-IDs: untied=' + localStorage.getItem('guid'));
        array.push('Gov-Client-Multi-Factor: type=TOTP&timestamp=' + localStorage.getItem('timeLoggedIn'));

        return array
    };

    const getMTDBusinesses = (update='N') => {

        const year_id = componentConfigData.selectedYear
        var start = ""
        var end = ""
        if (year_id) {
            const year = componentConfigData.years.find(e => e.id === year_id)
            start = year.start_date.substring(0,10)
            end = year.end_date.substring(0,10)
        }

        console.log(start,end)
        
        fetch(`https://${global.api_url}/hmrc/getBusinesses.php?from=${start}&to=${end}&update=${update}`, {
            method: "GET",
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent}
        })
            .then(res => res.json())
            .then(json => {

                //setFlags((flags) => ({...flags, ready: true}));
                console.log(json)

                if (json.mtd_supported==false) {
                    console.log('mtd not supported')

                    const msgObj = {
                        alertHeader: 'Income types not supported',
                        alertMessage: 'You have income types that are currently not supported by MTD. You will need to submit a legacy return using the "Tax Filings" section of untied. Please contact us if you need assistance.',
                        // buttons needs to make OK button go to tax filings
                        buttons: [
                            {
                                text: 'OK',
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: blah => {
                                    props.history.push("/taxReturns");
                                }
                            }]
                    };
                    setComponentConfigData((config) => ({
                        ...config,
                        errorMessage: msgObj
                    }));
                    setFlags((flags) => ({...flags, alertOpen: true, ready:true}));

                }

                if (json.businesses && json.businesses.length >0) {

                    console.log('biz', json.businesses)
                    if (json.businesses[0].obligations) {
                        console.log('obligs', JSON.parse(json.businesses[0].obligations))
                    }

                    console.log('final dec obs', json.final_declaration_obligations)

                    const finalDecObs = json.final_declaration_obligations.obligations || []
                    let finalDeclarationStatus = 'Open'

                    finalDecObs.forEach(element => {
                        if (element.periodStartDate === start && element.periodEndDate === end) {
                            console.log('found final dec obs', element)
                            finalDeclarationStatus = element.status
                        }
                    })

                    setFlags((flags) => ({...flags, finalDeclarationStatus: finalDeclarationStatus, finalised: finalDeclarationStatus === 'Fulfilled'}));

                    let defaultBusiness = '0';
                    if (json.businesses.length !== 0) {
                        defaultBusiness = json.businesses[0].se_id;
                    }
                    setComponentConfigData((config) => ({
                        ...config,
                        businesses: json.businesses,
                        selectedBusiness: defaultBusiness,
                        currentObligations: json.obligations,
                        finalDeclarationObligations: json.final_declaration_obligations
                    }));
                    setStatus(json.status);
                    console.log('get businesses get periodic updates',componentConfigData.selectedYear)
                    
                    //getPeriodicUpdates()
                    //if (page && page>0) {
                    if (page) {
                        if (page === 1) {
                            getPeriodicUpdates()
                        }            
                        if (page === 1) {
                            getEOPStatements()
                        }
                    }
                    //getTaxYears();
                    setFlags((flags) => ({...flags, ready: true}));
                }
            });
    };

    const getTaxYears = () => {
        const this_year_date = moment(new Date());
        const this_year_element = componentConfigData.years.find(element => this_year_date >= moment(element.start_date) && this_year_date <= moment(element.end_date));
        const last_year_date = moment(new Date()).subtract(1, "years");
        const element = componentConfigData.years.find(element => last_year_date >= moment(element.start_date) && last_year_date <= moment(element.end_date));
        setComponentConfigData((config) => ({
            ...config,
            selectedYear: this_year_element.id,
            lastYearString: element.explain,
            lastYearHMRCString: element.hmrc_year_string,
            lastYearStart: element.start_date,
            lastYearEnd: element.end_date
        }));
        //getTaxCalc(this_year_element.id, false);
        setGotTaxYear(true)
    };

    const getPeriodicUpdates = () => {
        console.log('get periodic updates')
        fetch(`https://${global.api_url}/hmrc/getPeriods.php?ts=${getTimestamp()}&business_id=${componentConfigData.selectedBusiness}`, {
            method: "GET",
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent}
        })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.updates) {
                    setComponentConfigData((config) => ({
                        ...config,
                        periodicUpdates: JSON.parse(json.updates),
                    }));
                }
          //      setFlags((flags) => ({...flags, ready: true}));
            });
    };

    const submitPeriodicUpdate = () => {

        let start_date = componentConfigData.startDate;
        let end_date = componentConfigData.endDate;
        let business_id = componentConfigData.companyId;
        let period_id = componentConfigData.periodId;

        //get company type ... 
        const company = componentConfigData.businesses.find(e => e.se_id === business_id);

        const endpoint = company.type === "PROPERTY" ? "hmrc/submitPropertyPeriodicUpdate.php" : "hmrc/submitSEPeriodicUpdate.php";

        fetch(`https://${global.api_url}/${endpoint}?ts=${getTimestamp()}`, {
            method: "POST",
            body: JSON.stringify({ business_id, start_date, end_date, period_id }),
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === 200 || json.status === 201 || json.status === 204) {
                    const msgObj = {
                        alertHeader: 'Submitted',
                        alertMessage: 'Data for the period has been submitted. There could be a delay of a few minutes before updates are reflected here.'
                    };
                    setComponentConfigData((config) => ({
                        ...config,
                        errorMessage: msgObj
                        }));
                    setFlags((flags) => ({...flags, submitPeriodPreview: false, alertOpen: true}));
                    getTaxCalc(componentConfigData.selectedYear);
                } else {
                    setComponentConfigData((config) => ({...config, errorMessage: JSON.parse(json['result'])}));
                }
            });
    };

    const getEOPStatements = (test_header = "", p_start_date = null, p_end_date = null) => {

        let from = p_start_date || componentConfigData.lastYearStart;
        let to = p_end_date || componentConfigData.lastYearEnd;

        from = from.substring(0,10)
        to = to.substring(0,10)

        fetch(`https://${global.api_url}/hmrc/getEOPObligations.php?ts=${getTimestamp()}&business_id=${componentConfigData.selectedBusiness}&test_header=${test_header}&from=${from}&to=${to}`, {
            method: "GET",
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {

                console.log('get obs for business', componentConfigData.selectedBusiness)
                console.log('obligations', JSON.parse(json.result))

                const res = JSON.parse(json.result);

                //const allObs = res.obligations;
                // find the one with businessId = componentConfigData.selectedBusiness
                //const obs = allObs.find(e => e.businessId === componentConfigData.selectedBusiness);
                // const obs = res.obligations
                // console.log(obs.obligationDetails)

                const obs = res.obligations[0].obligationDetails;
                setComponentConfigData((config) => ({...config, eopObligations: res.obligations}));
                setFlags((flags) => ({...flags, ready: true}));
            });
    };

    const handleTabChange = (event, value) => {

        console.log('changing page', value)

        setPage(value);
        setPageView(value);

        if (value === 0) {
            //setFlags((flags) => ({...flags, ready: false}));
            console.log('tab changed get periodic updates')
            // do we need this?
            // getPeriodicUpdates()
        }

        if (value === 1) {
            setFlags((flags) => ({...flags, ready: false}));
            getEOPStatements()
        }
    };

    const preparePeriodicUpdate = (data, amendment = false) => {
        let start_date = data.periodStartDate || data.from;
        let end_date = data.periodEndDate || data.to;
        let company_id = data.id;

        let period_id = "";
        if (amendment) {
            company_id = componentConfigData.selectedBusiness;
            
            //we need to find the period id ... 
            //const period = componentConfigData.periodicUpdates.find(e => e.from === start_date && e.to === end_date)
            //period_id = period.id
            period_id = data.periodStartDate + '_' + data.periodEndDate;    
        }
        
        fetch(`https://${global.api_url}/hmrc/getAmounts.php?ts=${getTimestamp()}&start_date=${start_date}&end_date=${end_date}`, {
            method: "GET",
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                setComponentConfigData((config) => ({
                    ...config,
                    amounts: json.amounts,
                    startDate: start_date,
                    endDate: end_date,
                    companyId: company_id,
                    errorMessage: {},
                    amendment: amendment,
                    periodId: period_id
                }));
                setFlags((flags) => ({...flags, submitPeriodPreview: true}));
            });
    };

    const getTaxCalc = (year_id, sleep=true, refresh=true) => {

        const element = componentConfigData.years.find((element) => element.id === year_id);

        if (!element) { return }

        const hmrc_year_string = element.hmrc_year_string;
        const doSleep = sleep ? "Y" : "N"
        const doRefresh = refresh ? "Y" : "N"

        fetch(`https://${global.api_url}/hmrc/getTaxCalc.php?ts=${getTimestamp()}&tax_year=${hmrc_year_string}&sleep=${doSleep}&refresh=${doRefresh}`, {
            method: "GET",
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        }) 
            .then(res => res.json())
            .then(json => {

                console.log('tax calc', json)

                if (json && json.calculation && json.calculation.taxCalculation) {

                    setComponentConfigData((config) => ({
                        ...config,
                        taxCalc: json.calculation.taxCalculation
                    }));
                }
                setFlags((flags) => ({...flags, ready: true}));
            });
    };

    const prettyDate = (dateToProcess) => {
        let date1 = new Date(dateToProcess);
        return date1.toDateString();
    };

    const prettyAmount = (amount) => {
        return "£" +
            Number(amount)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    };

    const crystallize = () => {
        if (!flags.crystaliseConfirmed) {
            setFlags((flags) => ({...flags, crystallizeProcessing: false}));
            alert("Please check the box to confirm that you are happy to finalise your tax with HMRC.");
            return;
        }
        const tax_year = componentConfigData.lastYearHMRCString;
        

        fetch(`https://${global.api_url}/hmrc/crystalise.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: JSON.stringify({ intentOnly: false, tax_year, calcID: componentConfigData.calcId }),
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status === 200 || json.status === 201 || json.status === 204 || json.status === 202) {
                    const msgObj = {
                        alertHeader: 'Submitted',
                        alertMessage: 'Your tax calculation has been finalised and submitted to HMRC.'
                    };
                    setComponentConfigData((config) => ({
                        ...config,
                        errorMessage: msgObj
                    }));
                    setFlags((flags) => ({
                        ...flags,
                        intentToCrystalise: false,
                        readyToCrystalise: false,
                        crystallizeProcessing: false,
                        finalised: true,
                        alertOpen: true
                    }));
                } else {
                    alert('Error during crystalisation - please try again.');
                    setFlags((flags) => ({...flags, crystallizeProcessing: false}));
                }
            });
    };

    const intentToCrystallize = () => {
        const tax_year = componentConfigData.lastYearHMRCString;

        const year_id = componentConfigData.selectedYear
        const element = componentConfigData.years.find((element) => element.id === year_id);

        if (!element) { return }

        const hmrc_year_string = element.hmrc_year_string;
        const doSleep = "N"
        const doRefresh = "Y"

        fetch(`https://${global.api_url}/hmrc/getTaxCalc.php?ts=${getTimestamp()}&tax_year=${hmrc_year_string}&sleep=${doSleep}&refresh=${doRefresh}`, {
            method: "GET",
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => { 


                console.log(json);

                setComponentConfigData((config) => ({
                    ...config,
                    fullReturn: json,
                    //taxCalc: json.eoy_summary.summary,
                    //taxCalc: json.income_tax_nics_calculated.summary,
                    taxCalc: json.calculation.taxCalculation,
                    calcId: json.metadata.calculationId
                }));
                setFlags((flags) => ({
                    ...flags,
                    readyToCrystalise: true,
                    crystallizeProcessing: false,
                    retrievingCrystalCalc: false
                }));

            });

        return;

        fetch(`https://${global.api_url}/hmrc/crystalise.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: JSON.stringify({ intentOnly: true, tax_year }),
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent}
        })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.calcID) {
                    const calcID = json.calcID;
                    setComponentConfigData((config) => ({...config, calcId: calcID}));
                    setFlags((flags) => ({...flags, retrievingCrystalCalc: true}));
                    setTimeout(() => {
                        fetch(`https://${global.api_url}/hmrc/getTaxCalc.php?ts=${getTimestamp()}&location=${calcID}&intent=1&tax_year=${tax_year}`, {
                            method: "GET",
                            headers: {govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
                        })
                            .then(res => res.json())
                            .then(json => {

                                console.log(json);

                                setComponentConfigData((config) => ({
                                    ...config,
                                    fullReturn: json,
                                    //taxCalc: json.eoy_summary.summary,
                                    taxCalc: json.income_tax_nics_calculated.summary,
                                    calcId: calcID
                                }));
                                setFlags((flags) => ({
                                    ...flags,
                                    readyToCrystalise: true,
                                    crystallizeProcessing: false,
                                    retrievingCrystalCalc: false
                                }));
                            });
                    }, 500);
                } else {
                    alert('Error during intent to crystalise - no calculation ID received.');
                    setFlags((flags) => ({
                        ...flags,
                        crystallizeProcessing: false
                    }));
                }

            });
    };

    const modalSubmitPeriod = () => {
        if (!flags.submitPeriodPreview) {
            return (<div />)
        }

        const start_date = prettyDate(componentConfigData.startDate);
        const end_date = prettyDate(componentConfigData.endDate);
        const company_id = componentConfigData.selectedBusiness;

        let message = "";
        if (Object.keys(componentConfigData.errorMessage).length > 0) {
            message = componentConfigData.errorMessage.errors ? "Error: ":"";
            const resp = componentConfigData.errorMessage;
            const errors = resp.errors || [];
            for( let i=0; i<errors.length; i++ ){
                message = message + errors[i].message + '\r\n';
            }
        }

        //get company type ... 
        const company = componentConfigData.businesses.find(e => e.se_id === company_id);


        return (
            <Modal
                open={flags.submitPeriodPreview}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description-submit-period"
            >
                <div style={{
                    position: 'absolute', top: '30%', left: '50%', width: '60%',
                    "MsTransform": "translateX(-50 %) translateY(- 50 %)",
                    "WebkitTransform": "translate(-50%,-50%)",
                    transform: "translate(-50%,-50%)",
                    backgroundColor: 'white', padding: 20
                }}>
                    <h2 id="simple-modal-title">Submit {componentConfigData.amendment ? "amendment" : "periodic update"} for {start_date} - {end_date}</h2>
                    <p id="simple-modal-description-submit-period">
                        Please review the information to submit for this period below and check that it is correct:
                    </p>

                    {company.type === "PROPERTY" && (
                        <IonGrid>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>Rental income:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.property_income)}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>Rental expenses:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.property_expenses)}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>FHL income:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.fhl_income)}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>FHL expenses:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.fhl_expenses)}</IonCol>
                            </IonRow>
                        </IonGrid>
                    )}

                    {company.type === "SE" && (
                        <IonGrid>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>Trading income:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.trading_income)}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>Other income:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.other_income)}</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol style={{ paddingRight: 30 }}>Trading expenses:</IonCol>
                                <IonCol>{prettyAmount(componentConfigData.amounts.trading_expenses)}</IonCol>
                            </IonRow>
                        </IonGrid>
                    )}

                    <div style={{ marginTop: 20, fontWeight: 'bold' }}>{message}</div>

                    <div style={{ marginTop: 20, textAlign: 'right' }}>
                        <Button onClick={() => setFlags((flags) => ({...flags, submitPeriodPreview: false, ready: true}))}
                                style={{...styles.actionButton, ...styles.leftButtonMargin}}
                        >Cancel</Button>
                        <Button onClick={() => {
                            setFlags((flags) => ({...flags, ready: false}));
                            submitPeriodicUpdate();
                        }}
                                style={{...styles.actionButton, ...styles.rightButtonMargin}}
                        >Submit</Button></div>
                </div>
            </Modal >
        )
    };

    const modalCrystalise = () => {
        if (!flags.intentToCrystalise) {
            return (<div />)
        }

        let message = "";
        if (Object.keys(componentConfigData.errorMessage).length > 0) {
            message = componentConfigData.errorMessage.errors ? "Error: ":"";
            const resp = componentConfigData.errorMessage;
            const errors = resp.errors || [];
            for( let i=0; i<errors.length; i++ ){
                message = message + errors[i].message + '\r\n';
            }
        }

        localStorage.setItem('302Json', JSON.stringify(componentConfigData.fullReturn));
        localStorage.setItem('302Year', componentConfigData.lastYearString);

        console.log('full return', componentConfigData.fullReturn)

        let report_token = "";
        if (componentConfigData.fullReturn) {
            report_token = componentConfigData.fullReturn.report_token !== undefined ? componentConfigData.fullReturn.report_token : "";
        }

        const report_url = 'https://' + window.location.host + '/mtd_report/' + report_token;

        return (
            <Modal
                open={flags.intentToCrystalise}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description-crystalise"
            >
                <div style={{
                    position: 'absolute', top: '30%', left: '50%', width: '60%',
                    "MsTransform": "translateX(-50 %) translateY(- 50 %)",
                    "WebkitTransform": "translate(-50%,-50%)",
                    transform: "translate(-50%,-50%)",
                    backgroundColor: 'white', padding: 20
                }}>
                    <h2 id="simple-modal-title">Finalise tax for {componentConfigData.lastYearString}</h2>
                    <div id="simple-modal-description-crystalise">
                        This will finalise your tax position for {componentConfigData.lastYearString} with HMRC. Before continuing please ensure that:
                        <ul>
                            <li>You have submitted end of year statements for all your businesses (we will check this)</li>
                            <li>You have tagged all relevant income and expenditure</li>
                            <li>You have completed all wizards and provided any relevant additional income.</li>
                        </ul>
                    </div>

                    {flags.retrievingCrystalCalc && (
                        <div>
                            <p>Retrieving calculation, please wait ... </p>
                        </div>
                    )}

                    {flags.readyToCrystalise && (
                        <div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol style={{ paddingRight: 30 }}>Calculation Id:</IonCol>
                                    <IonCol>{componentConfigData.calcId}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol style={{ paddingRight: 30 }}>Income tax Charged:</IonCol>
                                    <IonCol>{prettyAmount(componentConfigData.taxCalc.incomeTax.incomeTaxCharged)}</IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol style={{ paddingRight: 30 }}>Total Tax and NICs due:</IonCol>
                                    <IonCol>{prettyAmount(componentConfigData.taxCalc.totalIncomeTaxAndNicsDue)}</IonCol>
                                </IonRow>

                            </IonGrid>

                            <p>Please download your tax return and check that everything is correct before proceeding:</p>


                            <a
                                href={
                                    "https://pdfe1.untied.io/render_mtd_pdf.php?u=" +
                                    report_url
                                }
                            >
                                Download Tax Return Report (PDF)
                            </a>

                            {" "}<a href="#" onClick={() => setFlags((flags) => ({...flags, viewRaw: !flags.viewRaw}))}>Toggle Raw Tax Return Data</a>

                            {flags.viewRaw && (
                                <textarea style={{ width: '100%', height: 100 }}>{JSON.stringify(componentConfigData.fullReturn)}</textarea>
                            )}

                            <p>Before you can submit the information displayed here in response to your notice to file from HM Revenue &amp; Customs,
                                you must read and agree to the following statement by checking the box below:</p>

                            <p style={{ fontWeight: 'bold' }}>
                                I declare that the information and self-assessment I have filed are (taken together) correct and complete to the best of my knowledge. I understand that I may have to pay financial penalties and face prosecution if I give false information.”
                            </p>

                            <div id="simple-modal-description-crystalise" style={{ fontWeight: 'bold' }}>
                                <input type="checkbox" onChange={() => setFlags((flags) => ({...flags, crystaliseConfirmed: !flags.crystaliseConfirmed}))}/> I'm ready, submit my tax calculation!
                            </div>
                        </div>
                    )}
                    <div style={{ marginTop: 20, fontWeight: 'bold' }}>{message}</div>
                    <div style={{ marginTop: 20, textAlign: 'right' }}>
                        <Button onClick={() => setFlags((flags) => ({...flags, intentToCrystalise: false, readyToCrystalise: false }))}
                                style={{...styles.actionButton, ...styles.leftButtonMargin}}
                        >Cancel</Button>
                        {!flags.crystallizeProcessing ? <span>
                                {flags.readyToCrystalise && <Button onClick={() => {
                                    if (flags.crystaliseConfirmed) {
                                        setFlags((flags) => ({...flags, crystallizeProcessing: true }));
                                        crystallize({ intentOnly: false })
                                    } else {
                                        const msgObj = {
                                            alertHeader: 'Please confirm',
                                            alertMessage: 'You must agree to the declaration before you can submit your tax calculation'
                                        };
                                        setComponentConfigData((config) => ({
                                            ...config,
                                            errorMessage: msgObj
                                        }));
                                        setFlags((flags) => ({...flags, alertOpen: true }));
                                    }
                                }} style={{...styles.actionButton, ...styles.rightButtonMargin}}>Submit</Button>}
                                {!flags.readyToCrystalise && <Button onClick={() => {
                                    setFlags((flags) => ({...flags, crystallizeProcessing: true}));
                                    intentToCrystallize();
                                }}
                                style={{...styles.actionButton, ...styles.rightButtonMargin}}>Review</Button>}
                            </span>
                            : <Dots />
                        }
                    </div>
                </div>
            </Modal >
        )
    };

    // const renderConnectPopup = () => {

    //     return (
    //         <Popup
    //             open={flags.connector_open}
    //             modal
    //             onClose={() => setFlags((flags) => ({...flags, connectorOpen: false}))}
    //         >
    //             {close => (
    //                 // eslint-disable-next-line react/jsx-no-comment-textnodes
    //                 <div className="modal">
    //                     <span className="close" onClick={close} style={{ cursor: 'pointer' }}>
    //                         &times;
    //                     </span>
    //                     <div className="header">
    //                         Connect to HMRC {mtd_env}
    //                     </div>
    //                     <div className="content">
    //                         <iframe
    //                             id="connector_frame"
    //                             name="connector_frame"
    //                             title="2"
    //                             src={`https://${global.api_url}/hmrc/app_connect.php?token=` + localStorage.getItem("token")}
    //                             width="100%"
    //                             height="100%"
    //                             style={{ border: 0 }}
    //                         />
    //                     </div>
    //                     <div className="actions">
    //                         <button
    //                             className="button"
    //                             onClick={() => {
    //                                 close();
    //                             }}
    //                         >
    //                             cancel
    //                         </button>
    //                     </div>
    //                 </div>
    //             )}
    //         </Popup>
    //     )
    // };

    const submitEOPStatement = (p_start_date = null, p_end_date = null, p_business_id = null) => {

        const start_date = p_start_date || componentConfigData.lastYearStart;
        const end_date = p_end_date || componentConfigData.lastYearEnd;
        const business_id = p_business_id || componentConfigData.selectedBusiness;

        fetch(`https://${global.api_url}/hmrc/submitEOPStatement.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: JSON.stringify({ business_id, start_date, end_date }),
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === 201 || json.status === 204) {
                    getEOPStatements('EOPS_FULFILLED', start_date, end_date);
                    setFlags((flags) => ({...flags, showFileButton: false}));
                } else {
                    setComponentConfigData((config) => ({
                        ...config,
                        errorMessage: JSON.parse(json['result'])
                    }));
                }
            });
    };

    const submitAnnualSummary = (sleep=true) => {
        //const tax_year = componentConfigData.lastYearHMRCString;

        const element = componentConfigData.years.find((element) => element.id === componentConfigData.selectedYear);

        if (!element) { return }

        const tax_year = element.hmrc_year_string;

        const business_id = componentConfigData.selectedBusiness;

        console.log(tax_year)
        //return;

        fetch(`https://${global.api_url}/hmrc/updateAnnualSummary.php?ts=${getTimestamp()}`, {
            method: "POST",
            body: JSON.stringify({ business_id, tax_year }),
            headers: { govHeaders: componentConfigData.headers, token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {

                if (json.status === 201 || json.status === 204 || json.status === 200) {
                    //getTaxCalc(componentConfigData.selectedYear);
                } else {
                    const msgObj = {
                        alertHeader: 'Error',
                        alertMessage: 'An error occured. Status code was: ' + json.status.toString()
                    };
                    setComponentConfigData((config) => ({
                        ...config,
                        errorMessage: msgObj
                    }));
                    setFlags((flags) => ({...flags, alertOpen: true, ready:true}));

     
                }

                //do this anyway .... 
                getTaxCalc(componentConfigData.selectedYear, sleep);

            });
    };

    const modalEOPs = () => {
        if (!flags.confirmEOP) {
            return (<div />)
        }

        return (
            <Modal
                open={flags.confirmEOP}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description-eop"
            >
                <div style={{
                    position: 'absolute', top: '30%', left: '50%', width: '60%',
                    "MsTransform": "translateX(-50 %) translateY(- 50 %)",
                    "WebkitTransform": "translate(-50%,-50%)",
                    transform: "translate(-50%,-50%)",
                    backgroundColor: 'white', padding: 20
                }}>
                    <h2 id="simple-modal-title">Submit End of Year Statement for {componentConfigData.lastYearString}</h2>
                    <div id="simple-modal-description-eop">

                        <p>This will finalise your self employment income for the selected business and period. Please make sure you are happy with the tax calculation before you proceed.</p>

                        <p>Before you can continue you must agree to the statement below:</p>

                        <p style={{ fontWeight: 'bold' }}>I confirm that I have reviewed the information provided to establish the taxable profits for the relevant period ending in {componentConfigData.lastYearString} together with the designatory data provided for that period and that it is correct and complete to the best of my knowledge. I understand that I may have to pay financial penalties or face prosecution if I give false information.</p>

                        <div id="simple-modal-description-eop" style={{ fontWeight: 'bold' }}>
                            <input type="checkbox" onChange={() => setFlags((flags) => ({...flags, EOPConfirmed: !flags.EOPConfirmed}))} /> I agree, submit my End Of Year Statement
                        </div>

                        <div style={{ marginTop: 20, float: 'right' }}>
                            <Button onClick={() => setFlags((flags) => ({...flags, confirmEOP: false}))}
                                    style={{...styles.actionButton, ...styles.leftButtonMargin}}
                            >Cancel</Button>
                            <span>
                                <Button onClick={() => {
                                    if (flags.EOPConfirmed) {
                                        setFlags((flags) => ({...flags, ready: false, confirmEOP: false, EOPConfirmed: false}));
                                        submitEOPStatement(componentConfigData.eopStart, componentConfigData.eopEnd, componentConfigData.selectedBusiness)
                                    } else {
                                        const msgObj = {
                                            alertHeader: 'Please confirm',
                                            alertMessage: 'You must agree to the declaration before you can submit your end of year statement'
                                        };
                                        setComponentConfigData((config) => ({
                                            ...config,
                                            errorMessage: msgObj
                                        }));
                                        setFlags((flags) => ({...flags, alertOpen: true}));
                                    }
                                }} style={{...styles.actionButton, ...styles.rightButtonMargin}}
                                >Confirm</Button>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal >
        )
    };

    const renderPage = (page) => {
        const renderService = (page) => {
            const service = [
                {
                    description: "renderDashBoard",
                    method: () => {
                        const columnDefs = [

                            // {
                            //     headerName: "ID",
                            //     field: "id",
                            // },

                            {
                                headerName: "Period Start",
                                field: "periodStartDate",
                                cellRenderer: (params) => prettyDate(params.data.periodStartDate),
                                cellClass: "cell-style"
                            },
                            {
                                headerName: "Period End",
                                field: "periodEndDate",
                                cellRenderer: (params) => prettyDate(params.data.periodEndDate),
                                cellClass: "cell-style"
                            },
                            {
                                headerName: "Due",
                                field: "due",
                                cellClass: "cell-style"
                            },
                            {
                                headerName: "Met",
                                field: "status",
                                width: 100,
                                cellRenderer: (params) => params.data.status==="Open" ? "No" : "Yes",
                                cellClass: "cell-style"
                            },
                            {
                                headerName: 'Action',
                                width: 300,
                                resizable: false,
                                cellRendererFramework: (params) => {
                                    if (params.data.status==="Open") {
                                        return(<div style={styles.actionButtons}>
                                            <Button onClick={() => preparePeriodicUpdate(params.data)} style={styles.actionButton}>Submit Update</Button>
                                        </div>)
                                    } else {
                                        return (<div style={styles.actionButtons}>
                                            {/* <div style={styles.leftButtonPadding}>
                                                <Button onClick={() => {
                                                    setPage(1);
                                                    getPeriodicUpdates()
                                                }}
                                                        style={styles.actionButton}>View Updates</Button>
                                            </div> */}

                                            <div style={styles.leftButtonPadding}>
                                                <Button onClick={() => {                     
                                                    preparePeriodicUpdate(params.data, true)
                                                }} style={styles.actionButton}>Amend</Button>
                                            </div>

                                            {user_atom.mtd==='S' &&
                                                <div style={styles.rightButtonPadding}>
                                                    <Button onClick={() => preparePeriodicUpdate(params.data)}
                                                            style={styles.actionButton}
                                                    >Sandbox Update</Button>
                                                </div>
                                            }
                                        </div>)
                                    }
                                },
                                cellClass: "cell-style"
                            }
                        ];

                        let businessObligations = [];

                        // componentConfigData.businesses.forEach(busElement => {
                        //     const info = JSON.parse(busElement.info);
                        //     const obligations = JSON.parse(busElement.obligations);

                        //     if (busElement.se_id === componentConfigData.selectedBusiness) {
                        //         obligations.forEach(obsElement => {
                        //             //const id = info.id === undefined ? 'Property' : info.id;
                        //             const id = busElement.se_id
                        //             businessObligations.push({ id: id, se_id: busElement.se_id, periodStartDate: obsElement.periodStartDate, periodEndDate: obsElement.periodEndDate, dueDate: obsElement.dueDate, status: obsElement.status })
                        //         })
                        //     }
                        // });



                        console.log(componentConfigData.currentObligations)

                        if (componentConfigData.currentObligations && componentConfigData.currentObligations.obligations) {

                            componentConfigData.currentObligations.obligations.forEach(busElement => {

                                    const id = busElement.businessId
                                    
                                    const obligations = busElement.obligationDetails;

                                    console.log('obligations', obligations) 
                                    console.log('id', id)   
                                    console.log('selected', componentConfigData.selectedBusiness)

                                    if (id === componentConfigData.selectedBusiness) {
                                        obligations.forEach(obsElement => {

                                            console.log('found element', obsElement)

                                            businessObligations.push({ id: id, se_id: busElement.se_id, periodStartDate: obsElement.periodStartDate, periodEndDate: obsElement.periodEndDate, dueDate: obsElement.dueDate, status: obsElement.status })
                                        })
                                    }


                            });

                            console.log('obs', businessObligations)
                        }

                        let taxCalc = {};

                        if (componentConfigData && componentConfigData.taxCalc) {

                            var taxCalcObj = componentConfigData.taxCalc;
                            // keys are all lowerCamelCase. Conver to Title Case but subequent words are not capitalised. 
                            // We need to do this in a recursive function.
                            const convertKeysToTitleCase = (obj) => {
                                if (obj === null || obj === undefined) {
                                    return obj;
                                }
                                if (typeof obj !== 'object') {
                                    return obj;
                                }
                                if (Array.isArray(obj)) {
                                    return obj.map((item) => convertKeysToTitleCase(item));
                                }
                                return Object.keys(obj).reduce((acc, key) => {
                                    const newKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
                                    // but only first character should be capitalised
                                    const newKey2 = newKey.charAt(0).toUpperCase() + newKey.slice(1).toLowerCase();
                                    
                                    acc[newKey2] = convertKeysToTitleCase(obj[key]);
                                    return acc;
                                }, {});
                            };
                            
                            taxCalc = convertKeysToTitleCase(taxCalcObj);
                        }

                        return (
                            <div>
                                <div style={styles.topGridControls}>
                                    <div>
                                        Filing obligations
                                    </div>
                                    <div onClick={() => {console.log('clicked'); setFlags((flags) => ({...flags, ready:false})); getMTDBusinesses('Y') }}>
                                        <Refresh style={{height:20, cursor:'pointer'}} />
                                    </div>
                                    
                                </div>

                                <div className={'ag-theme-alpine'}>
                                    
                                    <AgGridReact
                                        modules={AllCommunityModules}
                                        rowData={businessObligations}
                                        columnDefs={columnDefs}
                                        defaultColDef={{resizable: true}}
                                        domLayout='autoHeight'
                                        rowHeight={30}
                                        suppressDragLeaveHidesColumns={true}
                                        onGridReady={(params) => {
                                            params.api.sizeColumnsToFit()
                                        }}
                                    />
                                </div>

                                {/* display tax calculation here */}

                                <p style={{textAlign:'left', fontWeight: 'bold', marginTop:20}}>Your tax calculation from HMRC</p>
                                <p style={{textAlign:'left'}}>This is an estimate based on the information that you have provided. untied takes no responsibility for the calculation which is provided by HMRC.</p>

                                <JsonToTable json={taxCalc} />
                           

                            </div>)
                    }
                },
                // {
                //     description: "renderQuarterlyUpdates",
                //     method: () => {
                //         const columnDefs = [
                //             {
                //                 headerName: "Period Start",
                //                 field: "start",
                //                 cellRenderer: (params) => prettyDate(params.data.from),
                //                 cellClass: "cell-style"
                //             },
                //             {
                //                 headerName: "Period End",
                //                 field: "end",
                //                 cellRenderer: (params) => prettyDate(params.data.to),
                //                 cellClass: "cell-style"
                //             },
                //             {
                //                 headerName: 'Action',
                //                 cellRendererFramework: (params) => (<div style={styles.actionButtons}>
                //                         <Button style={styles.actionButton}
                //                                 onClick={() => {
                //                                     preparePeriodicUpdate(params.data, true)
                //                                 }}
                //                         >Amend</Button>
                //                     </div>
                //                 ),
                //                 resizable: false,
                //                 cellClass: "cell-style"
                //             }
                //         ];

                //         return (
                //             <div className={'ag-theme-alpine grid-container'}>
                //                 <p>Submitted updates:</p>
                //                 <AgGridReact
                //                     modules={AllCommunityModules}
                //                     rowData={componentConfigData.periodicUpdates}
                //                     columnDefs={columnDefs}
                //                     defaultColDef={{resizable: true}}
                //                     domLayout='autoHeight'
                //                     rowHeight={30}
                //                     suppressDragLeaveHidesColumns={true}
                //                     onGridReady={(params) => {
                //                         params.api.sizeColumnsToFit()
                //                     }}
                //                 />
                //             </div>)
                //     }
                // },
                {
                    description: "renderEOP",
                    method: () => {
                        const columnDefs = [
                            {
                                headerName: "Period Start",
                                field: "start",
                                cellRenderer: (params) => {
                                    return prettyDate(params.data.periodStartDate);
                                },
                                cellClass: "cell-style"
                            },
                            {
                                headerName: "Period End",
                                field: "end",
                                cellRenderer: (params) => {
                                    return prettyDate(params.data.periodEndDate);
                                },
                                cellClass: "cell-style"
                            },
                            {
                                headerName: "Due",
                                field: "due",
                                cellRenderer: (params) => {
                                    return prettyDate(params.data.dueDate);
                                },
                                cellClass: "cell-style"
                            },
                            {
                                headerName: "Processed",
                                field: "processed",
                                cellRenderer: (params) => {
                                    if (params.data.processed) {
                                        return prettyDate(params.data.processed);
                                    } else {
                                        return "";
                                    }
                                },
                                cellClass: "cell-style"
                            },
                            {
                                headerName: 'Status',
                                field: 'status',
                                cellClass: "cell-style"
                            },
                            {
                                headerName: 'Action',
                                cellRendererFramework: (params) => {
                                    if (params.data.status === "Open") {
                                        return (<div style={styles.actionButtons}>
                                            <Button onClick={() => {
                                                setFlags((flags) => ({...flags, confirmEOP: true}));
                                                setComponentConfigData((config) => ({
                                                    ...config,
                                                    eopStart: params.data.start,
                                                    eopEnd: params.data.end
                                                }));
                                            }}
                                                    style={styles.actionButton}
                                            >Submit</Button>
                                        </div>)
                                    } else {
                                        return <div />
                                    }
                                },
                                resizable: false,
                                cellClass: "cell-style"

                            }
                        ];

                        console.log("eopobs 2", componentConfigData.eopObligations)
                        let eopObs = componentConfigData.eopObligations.filter(e => e.businessId === componentConfigData.selectedBusiness);
                        

                        return (
                            <div className={'ag-theme-alpine grid-container'}>
                                <div style={{...styles.topGridControls, display:'block'}}>
                                    <Button onClick={() => setFlags((flags) => ({...flags, confirmSummary: true}))}
                                            style={Object.assign({}, styles.actionButton, { width: 200 })}
                                    >Update Annual Summary</Button>

                                    <p>EoY Statement Obligations:</p>
                                </div>
                                <AgGridReact
                                    modules={AllCommunityModules}
                                    rowData={eopObs[0].obligationDetails || []}
                                    columnDefs={columnDefs}
                                    defaultColDef={{resizable: true}}
                                    domLayout='autoHeight'
                                    rowHeight={30}
                                    suppressDragLeaveHidesColumns={true}
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit()
                                    }}
                                />

                                {!flags.finalised && (
                                    <div style={{ width: '100%', textAlign: 'center' }}
                                         onClick={() => setFlags((flags) => ({...flags, intentToCrystalise: true}))}>
                                        <Button style={Object.assign({}, styles.buttonContainer, { height: 50 })}>
                                            Finalise Tax for {componentConfigData.lastYearString}
                                        </Button>
                                    </div>
                                )}
                            </div>)
                    }
                }
            ];
            return(<>
                {service[page].method()}
            </>);
        };
        if (!flags.ready) {
            return (<div style={{ marginTop: "20px" }}>
                <Dots />
            </div>)
        }

        if (status === 'error') {
            return (
                <div>
                    <form action={`https://${global.api_url}/hmrc/app_connect.php?env=` + localStorage.getItem("mtd")} method="POST" id='connect_form'>
                        <input type='hidden' name='token' value = {user_atom.token} />
                    </form>

                    <p>Before you can continue we need you to connect your HMRC account</p>

                    <Button style={styles.buttonContainer}
                            onClick={() => {
                                let theForm = document.getElementById('connect_form');

                                //create a pop up window as target for the form
                                let w = 450;
                                let h = 800;
                                let left = (window.screen.width / 2) - (w / 2);
                                let top = (window.screen.height / 2) - (h / 2);
                                let child = window.open('about:blank', 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);

                                //set the window as the target for the form and submit
                                theForm.target = 'Popup_Window';
                                theForm.submit();

                                let leftDomain = false;
                                let interval = setInterval(function () {
                                    try {
                                        if (child.document.domain === document.domain) {
                                            if (leftDomain && child.document.readyState === "complete") {
                                                // we're here when the child window returned to our domain
                                                clearInterval(interval);

                                                //child.postMessage({ message: "requestResult" }, "*");
                                            }
                                        }
                                        else {
                                            // this code should never be reached,
                                            // as the x-site security check throws
                                            // but just in case
                                            leftDomain = true;
                                        }
                                    }
                                    catch (e) {
                                        // we're here when the child window has been navigated away or closed
                                        if (child && child.closed) {
                                            clearInterval(interval);

                                            //refresh this page
                                            window.location.reload();
                                            return;
                                        }
                                        // navigated to another domain
                                        leftDomain = true;
                                    }
                                }, 500);

                            }}>
                        Connect to HMRC
                    </Button>
                </div>
            )
        } else {
            if (componentConfigData.businesses.length === 0) {
                return (
                    <div>
                        <p>You have no businesses set up for Making Tax Digital.<br /> Please check your HMRC account and make sure you have set your business up for Self Assessment MTD.</p>

                        <p><a href="https://www.tax.service.gov.uk/account" target="_new">Log into HMRC Online</a></p>
                    </div>
                )
            } else {
                //list the businesses ...
                let businessObligations = [];

                componentConfigData.businesses.forEach(busElement => {
                    //const info = JSON.parse(busElement.info);
                    const obligations = JSON.parse(busElement.obligations);

                    if (busElement.se_id === componentConfigData.selectedBusiness) {
                        obligations.forEach(obsElement => {
                            businessObligations.push({ id: busElement.se_id, start: obsElement.periodStartDate, end: obsElement.periodEndDate, due: obsElement.dueDate, met: obsElement.status })
                        })
                    }
                });

                let incomeTaxAmount = 0;
                let totalNicAmount = 0;
                if (componentConfigData.taxCalc) {
                    if (componentConfigData.taxCalc.incomeTax && componentConfigData.taxCalc.incomeTax.incomeTaxCharged) { incomeTaxAmount = componentConfigData.taxCalc.incomeTax.incomeTaxCharged }
                    if (componentConfigData.taxCalc.nics && componentConfigData.taxCalc.nics.totalNic) { totalNicAmount = componentConfigData.taxCalc.nics.totalNic }

                }

                return (<>
                        <div style={{ display: "flex", justifyContent: "center" }}>Income tax for
                            <select style={{marginLeft:5, marginRight:5}} value={componentConfigData.selectedYear || undefined}
                                    onChange={(e) => {
                                        setComponentConfigData((config) => ({
                                            ...config,
                                            selectedYear: e.target.value
                                        }));
                                        setFlags((flags) => ({...flags, ready: false}));
                                        //getTaxCalc(componentConfigData.selectedYear)
                                       
                                    }}>
                                {componentConfigData.years.map((element, index) => {
                                    return (<option key={index} value={element.id}>{element.explain}</option>)
                                })}
                            </select>
                            {"  "}
                            : {prettyAmount(incomeTaxAmount)}, NICs: {prettyAmount(totalNicAmount)}
                            {"  "}
                            <span style={{ textDecorationLine: 'underline', cursor: 'pointer', fontSize:"unset" }}
                               onClick={() => {
                                   setFlags((flags) => ({...flags, ready: false}));
                                submitAnnualSummary(false)
                                //getTaxCalc(componentConfigData.selectedYear)
                               }}><Refresh style={{height:20}}/></span>
                        </div>

                        <div style={{ paddingTop:'10px',display: "flex", alignItems: "center", justifyContent: "center" }}>
                                Business:{" "}
                            <Select value={componentConfigData.selectedBusiness} style={{ marginBottom: '5px', fontSize:"unset" }}
                                    onChange={(e) => setComponentConfigData((config) => ({
                                        ...config,
                                        selectedBusiness: e.target.value
                                    }))}>
                                    {componentConfigData.businesses.map((element) => {
                                        const info = JSON.parse(element.info);
                                        return (<MenuItem key={element.se_id} value={element.se_id}>
                                            {info.tradingName != null ? info.tradingName + ' - ' : ""}{element.se_id} ({element.type})
                                        </MenuItem>)
                                    })}
                            </Select>
                            <span style={{ textDecorationLine: 'underline', cursor: 'pointer', fontSize: "0.875rem!important" }}
                               onClick={() => {
                                    setFlags((flags) => ({...flags, ready: false}));
                                    getMTDBusinesses('Y')                                   
                               }}><Refresh style={{height:20}}/></span>
                        </div>
                        {renderService(page)}
                    </>)
            }
        }
    };

    return(<React.Fragment>
        <div
            style={{
                display: "flex",
                width: "100%",
                backgroundColor: "#FFFFFF"
            }}
        >
            <MiniDrawer />
            <Container
                style={{
                    width: "75%",
                    marginTop: 20,
                    overflow: "auto"
                }}
            >
            <h2 style={{textAlign: "center"}}>Digital Tax (MTD)</h2>

                <StyledTabs
                    variant="fullWidth"
                    value={page}
                    onChange={handleTabChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab
                        label="Quarterly Updates"
                        {...a11yProps(0)}
                    />
                    {/* <LinkTab
                        label="Quarterly Updates"
                        {...a11yProps(1)}
                    /> */}
                    <LinkTab label="End of Year" {...a11yProps(1)} />

                </StyledTabs>


                <div className="mtd-grid-container">
                    {renderPage(page)}
                </div>

                {modalSubmitPeriod()}
                {modalCrystalise()}
                {modalEOPs()}



                <IonAlert
                    isOpen={flags.alertOpen}
                    onDidDismiss={() => setFlags((flags) => ({...flags, alertOpen: false}))}
                    header={componentConfigData.errorMessage.alertHeader}
                    message={componentConfigData.errorMessage.alertMessage}
                    buttons={componentConfigData.errorMessage.buttons ? componentConfigData.errorMessage.buttons : ['OK']}
                />

                <IonAlert
                    isOpen={flags.confirmSummary}
                    onDidDismiss={() => setFlags((flags) => ({...flags, confirmSummary: false}))}
                    header={"Update Annual Summary"}
                    message={`This will update your annual summary with details you have provided during the course of the year. Where possible this will already have been done automatically, but we recommend you run this before finalising your end of year.`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {

                            }
                        },
                        {
                            text: 'OK',
                            handler: () => {
                                setFlags((flags) => ({...flags, ready: false}));
                                submitAnnualSummary()
                            }
                        }
                    ]}
                />
            </Container>
        </div>
    </React.Fragment>);

};

const styles = {
    topGridControls: {
        display:"flex",
        textAlign: "left"
    },
    buttonContainer: {
        paddingVertical: 12,
        width: 400,
        height: 40,
        borderRadius: 2,
        color: "white",
        backgroundColor: "#14A7CC",
        textTransform: "none",
        fontSize: 16,
        fontWeight: "600",
        textDecoration: "none",
        marginTop: 20
    },
    actionButton: {
        width: 100,
        height: 27,
        borderRadius: 2,
        color: "white",
        backgroundColor: "#14A7CC",
        textTransform: "none",
        fontSize: 12,
        fontWeight: "400",
        textDecoration: "none",
    },
    actionButtons: {
        height: 30,
        display: "flex"
    },
    leftButtonPadding: {
        paddingRight: 5,
        display: "flex"
    },
    rightButtonPadding: {
        paddingLeft: 5,
        display: "flex"
    },
    leftButtonMargin: {
        marginRight: 5
    },
    rightButtonMargin: {
        marginLeft: 5
    },
};

export default withRouter(MTD)
