import {
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IonAlert,
    IonLoading,
    IonCheckbox,
    IonLabel,
    IonModal,
    IonTextarea,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonPopover,
} from '@ionic/react';
import React, { useState, useRef, useEffect } from "react";

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom, profileAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import DropFile from "../components/DropFile";


import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import { getTaxCalc } from "../Common"
import { Mixpanel } from "../mixpanel";
import MiniDrawer from "./../MiniDrawer";
import { Container, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { getTimestamp } from '../components/timeStamp.js'
import { SelectionBar } from '../components/selectionBar.js';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import './Page.css';

const Returns = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
    const [recoilFetched, setRecoilFetched] = useState(false)

    const [profile_atom, setProfileAtom] = useRecoilState(profileAtom)

    const firstTimeRender = useRef(true)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [taxCalcDataLoaded, setTaxCalcDataLoaded] = useState(false)
    const [declare, setDeclare] = useState(false)
    const [amendment, setAmendment] = useState(false)

    const [selectedYear, setSelectedYear] = useState(tax_years_atom.dateFilter)
    const [selectedYearData, setSelectedYearData] = useState({})
    const [returns, setReturns] = useState([])
    const [taxCalc, setTaxCalc] = useState({})
    const [useAgent, setUseAgent] = useState()
    const [amlDetails, setAmlDetails] = useState(false)
    const [data, setData] = useState(null)

    const [hmrcPassword, setHmrcPassword] = useState("")
    const [hmrcUsername, setHmrcUsername] = useState(localStorage.getItem("HMRCUsername"))
    const [fileAsAgent, setFileAsAgent] = useState(localStorage.getItem("agent") !== "")
    const [addReturn, setAddReturn] = useState(true)
    const [authError, setAuthError] = useState(false)
    const [activeError, setActiveError] = useState(false)
    const [declareError, setDeclareError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [utrError, setUtrError] = useState(false)
    const [dotil, setDotil] = useState(true)
    const [extraTax, setExtraTax] = useState(false)
    const [nic2, setNic2] = useState(false)
    const [updateNic2, setUpdateNic2] = useState(false)
    const [taxOwedNotToBeCodedOut, setTaxOwedNotToBeCodedOut] = useState(false)
    const [bankData, setBankData] = useState({
        account_name: "",
        account_number: "",
        sort_code: "",
        bank_name: "",
    })
    const [childBenefit, setChildBenefit] = useState(false)
    const [cbError, setCbError] = useState(false)
    const [covidSupport, setCovidSupport] = useState(false)
    const [childBenefitData, setChildBenefitData] = useState({
        cb_amount: null,
        cb_number: null,
        cb_date_end: ''
    })
    const [marriageTransfer, setMarriageTransfer] = useState(false)
    const [marriageError, setMarriageError] = useState(false)
    const [spouseNiNError, setSpouseNiNError] = useState(false)
    const [marriageTransferData, setMarriageTransferData] = useState({
        spouse_first_name: '',
        spouse_last_name: '',
        spouse_nin: '',
        spouse_dob: '',
        married_date: ''
    })
    const [declareSa103Error, setDeclareSa103Error] = useState(false)
    const [update, setUpdate] = useState(false)
    const [attachment, setAttachment] = useState("")
    const [attachmentSize, setAttachmentSize] = useState(0)
    const [attachmentName, setAttachmentName] = useState("")
    const [fileFormatError, setFileFormatError] = useState(false)
    const [provisional, setProvisional] = useState(false)
    const [otherInfo, setOtherInfo] = useState("")
    const [se_box_5_on, setSeBox5On] = useState(null)
    const [se_box_5, setSeBox5] = useState(null)
    const [se_box_3, setSeBox3] = useState(null)
    const [se_details_1, setSeDetails1] = useState(null)
    const [se_details_2, setSeDetails2] = useState(null)
    const [se_details_3, setSeDetails3] = useState(null)
    const [reportToken, setReportToken] = useState("")
    const [success, setSuccess] = useState(false)
    const [sentEmail, setSentEmail] = useState(false)
    const [upgradeError, setUpgradeError] = useState(false)
    const [confirmSubmit, setConfirmSubmit] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [allowedToExpressSubmit, setAllowedToExpressSubmit] = useState(false);
    const [chooseSubmissionTypePopover, setChooseSubmissionTypePopover] = useState(false);
    const [expressSubmitError, setExpressSubmitError] = useState(false);
    const [useAgentError, setUseAgentError] = useState(false);
    const [til, setTil] = useState('')
    const [filesInput, setFilesInput] = useState(null)
    const [testService, setTestService] = useState('N')
    const [currentYearError, setCurrentYearError] = useState(false)
    const [thisYearData, setThisYearData] = useState({})
    const [taxData, setTaxData] = useState({})
    const [incomeError, setIncomeError] = useState(false)
    const [bankError, setBankError] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [attachmentNames, setAttachmentNames] = useState([])
    const [clearFiles, setClearFiles] = useState(false)
    const [attachmentError, setAttachmentError] = useState(false);
    const [attachmentsPresent, setAttachmentPresent] = useState(false)
    const [showAttachmentUpload, setShowAttachmentUpload] = useState(false)
    const [errorText, setErrorText] = useState("");

    const checkBoxStyles = theme => ({
        root: {
            '&$checked': {
                color: '#3D70B2',
            },
        },
        checked: {}
    });

    const PopoverCheckbox = withStyles(checkBoxStyles)(Radio);



    useEffect(() => {

        firstTimeRender.current = false;
        // debugger
        if (!user_atom.token) {
            let userState = getLocalStorage()
            getProfile(userState.token, userState.agent)
            setUserAtom(userState)
            getRecoilTaxYears(userState.token, userState.agent)

        }
        else {
            if (JSON.stringify(tax_years_atom) === "{}") {
                getRecoilTaxYears()
            }

            if (JSON.stringify(profile_atom) === "{}") {
                getProfile()
            }
        }
        if (user_atom.token && JSON.stringify(tax_years_atom) !== "{}" && JSON.stringify(profile_atom) !== "{}") {
            setRecoilFetched(true)
        }

    }, []);

    useEffect(() => {
        console.log("checking tax_years_atom, profile_atom")
        if (!recoilFetched && JSON.stringify(tax_years_atom) !== "{}" && JSON.stringify(profile_atom) !== "{}") {
            setRecoilFetched(true)
        }
    }, [tax_years_atom, profile_atom]);



    useEffect(() => {
        if (recoilFetched && !pageLoaded) {
            const yearElement = tax_years_atom.taxYears.find(element => {
                return element.id === tax_years_atom.dateFilter
            })
            Mixpanel.track("loaded_returns");
            setSelectedYear(tax_years_atom.dateFilter)
            setSelectedYearData({ ...yearElement })
            getAllData()
            getTaxCalc(tax_years_atom.dateFilter, (json) => {
                setTaxCalc({ ...json })
                if (json.express_submit_enabled == 'Y') {
                    setAllowedToExpressSubmit(true);
                    setUseAgent(true)

                }
                else {
                    setAllowedToExpressSubmit(false);
                }
            })
            getCurrentYear()

            setTestService((props.match.params.name === 'testService') || (profile_atom.utr === '1000000239') ? 'Y' : 'N')

            window.onpopstate = (e) => {
                if (e.state) {
                    if (data != null) {
                        setData(null)
                    }
                    if (addReturn) {
                        setAddReturn(false)
                    }
                }
            };
            setPageLoaded(true)
        }
    }, [recoilFetched])



    useEffect(() => {
        if (pageLoaded) {
            getTaxCalc(selectedYear, (json) => {
                setTaxCalc(json);
                setAllowedToExpressSubmit(json.express_submit_enabled == 'Y');
                setUseAgent(json.express_submit_enabled == 'Y')
            })
            setOtherInfo('')
            setTaxCalcDataLoaded(true)
        }

    }, [pageLoaded, selectedYear])

    useEffect(() => {
        if (updateNic2) {
            submitReturn(true, false)
            setUpdateNic2(false)
        }
    }, [nic2, updateNic2])

    const showExtraTax = () => {
        setExtraTax(!extraTax)
    }

    const getCurrentYear = () => {
        const today = new Date();
        const yearElement = tax_years_atom.taxYears.find(element => {
            const sd = new Date(element.start_date);
            const ed = new Date(element.end_date);
            return (today >= sd && today <= ed)
        })
        setThisYearData(yearElement)
    }

    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agent) => {

        console.log("getRecoilTaxYears")
        console.log({ passed_token, passed_agentId })

        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    let today = new Date();
                    let currentYearID = 0;
                    let dateFilterStart = "";
                    let dateFilterEnd = "";
                    json.years.forEach(element => {
                        const start = new Date(element.start_date);
                        const end = new Date(element.end_date);

                        if (today >= start && today <= end) {
                            currentYearID = element.id;
                            dateFilterStart = element.start_date;
                            dateFilterEnd = element.end_date;
                        }
                    });
                    if (!taxYearsAtom.taxYears) {
                        setTaxYearsAtom(
                            {
                                taxYears: json.years,
                                dateFilterStart: dateFilterStart,
                                dateFilterEnd: dateFilterEnd,
                                dateFilter: currentYearID
                            });
                    }

                    //   if (!recoilFetched){
                    //     setRecoilFetched(true)
                    //   }

                }
            });
    }

    const paymentBreakdown = () => {
        let rowStyle = { marginLeft: '10px', lineHeight: '20px', borderBottom: "1px solid rgb(212, 212, 212)" }
        return (
            <IonGrid style={{ backgroundColor: '#f5f7fa', fontSize: '14px', borderRadius: '5px' }}>
                <IonRow>
                    <IonCol style={{ textAlign: 'left' }}><strong>Tax Summary</strong></IonCol>
                </IonRow>
                <IonRow style={rowStyle}>
                    <IonCol style={{ textAlign: 'left' }}>Tax for {selectedYearData.explain}:</IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        {formatCurrency(taxCalc.tax_to_pay - taxCalc.StudentLoanRepaymentDue - taxCalc.PostgradLoanRepaymentDue)}
                    </IonCol>
                </IonRow>
                <IonRow style={rowStyle}>
                    <IonCol style={{ textAlign: 'left' }}>Student loan repayment:</IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        {formatCurrency(taxCalc.StudentLoanRepaymentDue)}
                    </IonCol>
                </IonRow>
                <IonRow style={rowStyle}>
                    <IonCol style={{ textAlign: 'left' }}>Postgrad loan repayment:</IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        {formatCurrency(taxCalc.PostgradLoanRepaymentDue)}
                    </IonCol>
                </IonRow>

                <IonRow style={rowStyle}>
                    <IonCol style={{ textAlign: 'left' }}>First payment on account:</IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        {formatCurrency(taxCalc.first_payment_on_account)}
                    </IonCol>
                </IonRow>

                <IonRow style={rowStyle}>
                    <IonCol style={{ textAlign: 'left' }}>Class 2 NICs due:</IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        {formatCurrency(taxCalc.class2_nic)}
                    </IonCol>
                </IonRow>

                <IonRow style={{ marginLeft: '10px', lineHeight: '20px' }}>
                    <IonCol style={{ textAlign: 'left' }}>Class 4 NICs due:</IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        {formatCurrency(taxCalc.class4_nic)}
                    </IonCol>
                </IonRow>
            </IonGrid>)
    }

    const backToForms = () => {
        return (
            <IonCol className="ion-align-self-end" style={{ textAlign: 'center' }}>
                <IonButton
                    className="ion-button-money"
                    onClick={() => {
                        setData(null)
                        setAddReturn(true)
                    }}
                >
                    Back to Forms</IonButton>
            </IonCol>)
    }

    const getHelp = () => {
        return (
            <IonCol className="ion-align-self-end" style={{ textAlign: 'center' }}>
                <IonButton
                    className="ion-button-money"
                    href='https://help.untied.io/article/34-my-submission-failed'
                    target='_blank'
                >
                    Help</IonButton>
            </IonCol>)
    }

    const getAllData = () => {
        getReturns();
        //getFinal();
        //getSEProfile();
        getTaxData()
    }

    useEffect(() => {
        getTaxData()
    }, [selectedYearData])

    const getProfile = (passed_token = user_atom.token, passed_agent = user_atom.agent) => {

        fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agent }
        })
            .then(res => res.json())
            .then(json => {
                json.dob = moment(json.dob, 'DD/MM/YYYY').toISOString()
                setProfileAtom(json)
                if (json.utr === '1000000239') {
                    setTestService('Y')
                }

            })
    }

    const getReturns = () => {

        const fromDate = selectedYearData.start_date;
        const toDate = selectedYearData.end_date + 'T23:59:59:59Z'

        fetch(`https://${global.api_url}/getTaxSubmissions.php?ts=${getTimestamp()}&timefrom=${fromDate}&timeto=${toDate}`, {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout");
                    }
                } else {
                    setReturns(json.returns)
                }
            });
    }

    const checkTradingIncome = () => {
        if (taxCalc !== {} && taxCalc.sa103_valid == false) {

            if (taxCalc.sa103_valid == false && !declareSa103Error) {
                setDeclareSa103Error(true)
            }
        }
    }

    const askNic2Volunteer = () => {
        if (taxCalc != {}) {
            if (taxCalc.nic2_compulsory == false) {
                return (
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >Your trading profits are less than £{parseFloat(taxCalc.class2_nic_limit).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} so you don't need to pay Class 2 NICs. If you want to pay them voluntarily, tick the box.</IonLabel>
                                <IonCheckbox checked={nic2}
                                    onIonChange={e => {
                                        setNic2(e.detail.checked)
                                        setUpdateNic2(true)
                                        //submitReturn(true, false)
                                    }}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>)
            }
        }
    }

    const AskTaxOwedNotToBeCodedOut = () => {
        if (taxCalc != {}) {
            if (taxCalc.tax_to_pay - taxCalc.StudentLoanRepaymentDue - taxCalc.class2_nic < 3000) {

                // get next year name
                //currYear is format YYYY-YYYY
                const currYear = selectedYearData.tax_year
                //get nextYear from currYear
                const nextYear = currYear.substring(5, 9) + '-' + (parseInt(currYear.substring(5, 9)) + 1)

                return (
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >
                                    As you owe less than £3,000 (excluding Class 2 NICs) HMRC will try to collect the tax through your wages or pension by adjusting your tax code for {nextYear}. If you DO NOT want this check the box.
                                </IonLabel>
                                <IonCheckbox checked={taxOwedNotToBeCodedOut}
                                    onIonChange={e => {
                                        setTaxOwedNotToBeCodedOut(e.detail.checked)
                                    }}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>)
            }
        }
    }


    const higherChildBenefit = () => {
        if (taxCalc != null) {
            if (taxCalc.higher_childbenefit) {
                return (
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >Your income is greater than £{taxCalc.higher_childbenefit_threshold}. If you or your partner got Child Benefit, please tick the box.</IonLabel>
                                <IonCheckbox checked={childBenefit}
                                    onIonChange={e => {
                                        setChildBenefit(e.detail.checked)

                                    }}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>)
            }
        }
    }

    const childBenefitInfo = () => {
        if (childBenefit) {
            return (
                <IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol >
                            <IonItem >
                                <IonLabel position="floating" >Enter the total amount of Child Benefit you or your partner received.</IonLabel>
                                <IonInput placeholder="" type='number' value={childBenefitData.cb_amount} onIonChange={e => {

                                        /*update: true,*/ setChildBenefitData({ ...childBenefitData, cb_amount: e.detail.value })
                                }}

                                    onIonBlur={() => submitReturn(true, false)} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol >
                            <IonItem >
                                <IonLabel position="floating" >Enter the number of children you or your partner received Child Benefit for.</IonLabel>
                                <IonInput placeholder="" type='number' value={childBenefitData.cb_number} onIonChange={e => setChildBenefitData({ ...childBenefitData, cb_number: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol >
                            <IonItem >
                                <IonLabel position="floating" style={{}}>Enter the date you stopped receiving Child Benefit if before 6 April</IonLabel>
                                <IonInput placeholder="" type='date' displayFormat="DD/MM/YYYY" style={{ fontSize: '13px' }}
                                    value={childBenefitData.cb_date_end} onIonChange={e => setChildBenefitData({ ...childBenefitData, cb_date_end: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                </IonRow>
            )
        }
    }

    const marriageTransferValid = () => {
        if (taxCalc != null) {
            if (taxCalc.marriage_transfer_valid) {
                return (
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >As your income is below the threshold you are able to transfer some of your personal allowance to your spouse or civil partner. Tick the box if you would like to do this. <a href="https://help.untied.io/article/183-how-do-i-claim-the-marriage-allowance" target="_blank">Help</a></IonLabel>
                                <IonCheckbox checked={marriageTransfer}
                                    onIonChange={e => {
                                        setMarriageTransfer(e.detail.checked)
                                    }
                                    }
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>)
            }
        }
    };

    const marriageTransferInfo = () => {
        if (marriageTransfer) {
            return (
                <IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol>
                            <IonItem >
                                <IonLabel position="floating" >Your spouse/civil partner's first name.</IonLabel>
                                <IonInput placeholder="" value={marriageTransferData.spouse_first_name} onIonChange={e => setMarriageTransferData({ ...marriageTransferData, spouse_first_name: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol>
                            <IonItem >
                                <IonLabel position="floating" >Your spouse/civil partner's last name.</IonLabel>
                                <IonInput placeholder="" value={marriageTransferData.spouse_last_name} onIonChange={e => setMarriageTransferData({ ...marriageTransferData, spouse_last_name: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol>
                            <IonItem >
                                <IonLabel position="floating" >Your spouse/civil partner's National Insurance Number</IonLabel>
                                <IonInput placeholder="" value={marriageTransferData.spouse_nin} onIonChange={e => setMarriageTransferData({ ...marriageTransferData, spouse_nin: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol>
                            <IonItem >
                                <IonLabel position="floating" style={{}}>Your spouse/civil partner's date of birth </IonLabel>
                                <IonInput type="date" placeholder="" style={{ fontSize: '13px' }} value={marriageTransferData.spouse_dob} onIonChange={e => setMarriageTransferData({ ...marriageTransferData, spouse_dob: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ width: '100%' }}>
                        <IonCol>
                            <IonItem >
                                <IonLabel position="floating" style={{}}>Date of marriage/civil partnership</IonLabel>
                                <IonInput type="date" placeholder="" style={{ fontSize: '13px' }} value={marriageTransferData.married_date} onIonChange={e => setMarriageTransferData({ ...marriageTransferData, married_date: e.detail.value })} />
                            </IonItem >
                        </IonCol>
                    </IonRow>
                </IonRow>
            )
        }
    };


    const askSEISSGrant = () => {
        if (taxCalc !== {}) {
            if (taxCalc.has_sa103 === true && selectedYearData.tax_year === '2020-2021') {
                return (
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >If any of your businesses received any coronavirus support payments that have been included as taxable income, tick the box.</IonLabel>
                                <IonCheckbox checked={covidSupport}
                                    onIonChange={e => {

                                        setCovidSupport(e.detail.checked) /*, update: true*/

                                    }}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>)
            }
        }
    };

    const askBankDetails = () => {

        if (taxCalc != {}) {

            //if (parseInt(taxCalc.tax_to_pay) <  0) {
            return (
                <>
                    {/* <IonRow style={{ width: '100%' }}>
                   <IonCol style={{

                        paddingLeft: 15,
                        textAlign: "left"
                   }}>
         
                            If you believe a tax repayment may be due and would like to enter your bank details tick the box
                      
            
                        </IonCol>
                    </IonRow> */}


                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{
                                    paddingLeft: 15, whiteSpace: 'normal',
                                    fontSize: '14px', paddingRight: 10,
                                    fontWeight: parseInt(taxCalc.tax_to_pay) < 0 ? 600 : undefined
                                }} >If you think a repayment may be due and would like to enter your bank details tick the box.</IonLabel>
                                <IonCheckbox checked={bankData.show ?? false}
                                    onIonChange={e => {
                                        setBankData({ ...bankData, show: e.detail.checked })
                                    }}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    {bankData.show && (
                        <div style={{ backgroundColor: 'white', marginLeft: 5, marginRight: 5 }}>

                            <IonRow style={{ width: '100%' }}>


                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating" >Bank name:</IonLabel>
                                        <IonInput placeholder="" value={bankData.bank_name} onIonChange={e => setBankData({ ...bankData, bank_name: e.detail.value })} />
                                    </IonItem >
                                </IonCol>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating" >Account name:</IonLabel>
                                        <IonInput placeholder="" value={bankData.account_name} onIonChange={e => setBankData({ ...bankData, account_name: e.detail.value })} />
                                    </IonItem >
                                </IonCol>
                            </IonRow>
                            <IonRow style={{ width: '100%' }}>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating" >Account number:</IonLabel>
                                        <IonInput placeholder="" type='tel' maxlength={8} value={bankData.account_number}
                                            onIonChange={e => setBankData({ ...bankData, account_number: e.detail.value.replace(/\D/g, '') })} />
                                    </IonItem >
                                </IonCol>
                                <IonCol>
                                    <IonItem >
                                        <IonLabel position="floating" >Sort code:</IonLabel>
                                        <IonInput placeholder="" type='tel' maxlength={6} value={bankData.sort_code}
                                            onIonChange={e => setBankData({ ...bankData, sort_code: e.detail.value.replace(/\D/g, '') })} />
                                    </IonItem >
                                </IonCol>
                            </IonRow>

                        </div>)}
                </>

            )
            //}
        }
    }

    // const getFinal = () => {
    //     fetch(`https://${global.api_url}/getTaxData.php?ts=${getTimestamp()}&tax_year_id=${selectedYear}&type=final`, {
    //         method: "GET",
    //         headers: { token: user_atom.token, agentId:user_atom.agent }
    //     })
    //         .then(res => res.json())
    //         .then(json => {
    //             setOtherInfo("")
    //             setProvisional(false)
    //             const json_data = JSON.parse(json.data)

    //             if (json_data != null) {
    //                 let marriedDate = inverseDates(json_data.married_date)
    //                 let spouseDOB = inverseDates(json_data.spouse_dob)
    //                 let cbDateEnd = inverseDates(json_data.cb_date_end)

    //                 setProvisional(json_data.estimated)
    //                 setOtherInfo(json_data.other_information)
    //                 setNic2(json_data.nic2_pay)
    //                 setMarriageTransfer(json_data.marriage_transfer)
    //                 setMarriageTransferData({
    //                     married_date: marriedDate,
    //                     spouse_first_name: json_data.spouse_first_name,
    //                     spouse_last_name: json_data.spouse_last_name,
    //                     spouse_nin: json_data.spouse_nin,
    //                     spouse_dob: spouseDOB
    //                 })
    //                 setChildBenefit(json_data.child_benefit)
    //                 setChildBenefitData({
    //                     cb_amount: json_data.cb_amount,
    //                     cb_date_end: cbDateEnd,
    //                     cb_number: json_data.cb_number
    //                 })
    //             }


    //         })
    // }

    // const getSEProfile = () => {
    //     fetch(`https://${global.api_url}/getTaxData.php?ts=${getTimestamp()}&tax_year_id=${selectedYear}&type=self_employment`, {
    //         method: "GET",
    //         headers: { token: user_atom.token, agentId:user_atom.agent }
    //     })
    //         .then(res => res.json())
    //         .then(json => {
    //             setOtherInfo("")
    //             setProvisional(false)
    //             const json_data = JSON.parse(json.data)

    //             if (json_data != null) {
    //                 setSeBox5On(json_data.box_5 != "" && json_data.box_5 != null)
    //                 setSeBox5(moment(json_data.box_5, 'DD/MM/YYYY').toISOString())
    //                 setSeBox3(json_data.box_3)
    //                 setSeDetails1(json_data.details_1)
    //                 setSeDetails2(json_data.details_2)
    //                 setSeDetails3(json_data.details_3)
    //             }
    //         })
    // }

    const getTaxData = () => {
        fetch(`https://${global.api_url}/getTaxData.php?ts=${getTimestamp()}&tax_year_id=${selectedYear}&type=*`, {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {

                setOtherInfo("")
                setProvisional(false)

                if (json.data && json.data != "null") {

                    const json_data = JSON.parse(json.data)
                    setTaxData(json_data)

                    if (json_data.self_employment != null) {

                        const self_employment_data = JSON.parse(json_data.self_employment)

                        //self employment
                        setSeBox5On(self_employment_data.box_5 != "" && self_employment_data.box_5 != null)
                        setSeBox5(moment(self_employment_data.box_5, 'DD/MM/YYYY').toISOString())
                        setSeBox3(self_employment_data.box_3)
                        setSeDetails1(self_employment_data.details_1)
                        setSeDetails2(self_employment_data.details_2)
                        setSeDetails3(self_employment_data.details_3)

                    }

                    if (json_data.final != null) {

                        const final_data = JSON.parse(json_data.final)

                        //final
                        let marriedDate = inverseDates(final_data.married_date)
                        let spouseDOB = inverseDates(final_data.spouse_dob)
                        let cbDateEnd = inverseDates(final_data.cb_date_end)

                        setProvisional(final_data.estimated)
                        setOtherInfo(final_data.other_information)
                        setNic2(final_data.nic2_pay)
                        setMarriageTransfer(final_data.marriage_transfer)
                        setMarriageTransferData({
                            married_date: marriedDate,
                            spouse_first_name: final_data.spouse_first_name,
                            spouse_last_name: final_data.spouse_last_name,
                            spouse_nin: final_data.spouse_nin,
                            spouse_dob: spouseDOB
                        })
                        setChildBenefit(final_data.child_benefit)
                        setChildBenefitData({
                            cb_amount: final_data.cb_amount,
                            cb_date_end: cbDateEnd,
                            cb_number: final_data.cb_number
                        })
                        setTaxOwedNotToBeCodedOut(final_data.tax_owed_not_to_be_coded_out)

                        setBankData({
                            bank_name: final_data.bank_name ?? "",
                            account_name: final_data.bank_acname ?? "",
                            account_number: final_data.bank_acnumber ?? "",
                            sort_code: final_data.bank_sort ?? ""
                        })

                    }

                }
            })
    }


    const properDates = (date) => {
        if (date == '' || date == undefined || date == null) {
            return date
        }
        else {
            let properDate = date.split("-")
            properDate = `${properDate[2]}/${properDate[1]}/${properDate[0]}`
            return properDate
        }

    }

    const inverseDates = (date) => {
        if (date == '' || date == undefined || date == null) {
            return date
        }
        else {
            let properDate = date.split("/")
            properDate = `${properDate[2]}-${properDate[1]}-${properDate[0]}`
            return properDate
        }

    }

    const uploadAttachments = () => {
        let data = new FormData()

        attachments.forEach(a => {
            data.append('attachments[]', a);
        })

        console.log(attachments)
        setShowLoading(true)

        fetch(
            `https://${global.api_url}/tax/uploadAttachments.php?tax_year_id=${selectedYear}&ts=${getTimestamp()}`,
            {
                method: "POST",
                headers: { Accept: "application/json", token: user_atom.token, agentId: user_atom.agent },
                body: data
            }
        )
            .then(res => {
                res.json()
            })
            .then(json => {
                //Mixpanel.track('upload_receipt');
                setClearFiles(true)
                setShowAttachmentUpload(false)
                getTaxCalc(selectedYear, (json) => {

                    setAttachments([])

                    console.log('uploaded attachments', json.attachments)

                    var newTaxCalc = taxCalc
                    newTaxCalc.attachments = json.attachments
                    setTaxCalc(newTaxCalc)

                    console.log('new taxCalc')
                    console.log(newTaxCalc)

                    setShowLoading(false)
                });

            })
            .catch(err => {
                console.log(err)
            })
    }

    const deleteAttachment = (id, index) => {

        setShowLoading(true)

        //call deleteAttachment endpoint
        fetch(`https://${global.api_url}/tax/deleteAttachment.php?ts=${getTimestamp()}&id=${id}`, {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
        })
            .then(res => res.json())
            .then(json => {

                getTaxCalc(selectedYear, (json) => {

                    console.log('after delete', json)

                    console.log('stored attachments', json.attachments)

                    var newTaxCalc = taxCalc
                    newTaxCalc.attachments = json.attachments
                    setTaxCalc(newTaxCalc)

                    console.log('new taxCalc')
                    console.log(newTaxCalc)

                    setShowLoading(false)
                })
            })
    }

    const submitReturn = (til = true, submit = true) => {
        let married_date = properDates(marriageTransferData.married_date)
        let spouse_dob = properDates(marriageTransferData.spouse_dob)
        let cb_date_end = properDates(childBenefitData.cb_date_end)

        const finalData = JSON.parse(taxData.final)

        let payload = {
            ...finalData,
            include: true,
            estimated: provisional,
            other_information: otherInfo,
            nic2_pay: nic2,
            child_benefit: childBenefit,
            cb_amount: childBenefitData.cb_amount,
            cb_number: childBenefitData.cb_number,
            cb_date_end: cb_date_end,
            marriage_transfer: marriageTransfer,
            spouse_first_name: marriageTransferData.spouse_first_name,
            spouse_last_name: marriageTransferData.spouse_last_name,
            spouse_nin: marriageTransferData.spouse_nin,
            spouse_dob: spouse_dob,
            married_date: married_date,
            covid_support: covidSupport,
            tax_owed_not_to_be_coded_out: taxOwedNotToBeCodedOut,
            bank_name: bankData.bank_name ? bankData.bank_name.substring(0, 28) : '',
            bank_acname: bankData.account_name ? bankData.account_name.substring(0, 28) : "",
            bank_acnumber: bankData.account_number ? bankData.account_number.substring(0, 8) : "",
            bank_sort: bankData.sort_code ? bankData.sort_code.substring(0, 6) : "",

        };

        fetch(`https://${global.api_url}/saveTaxData.php?ts=${getTimestamp()}&tax_year_id=${selectedYear}&type=final`, {
            method: "POST",
            headers: { token: user_atom.token, agentId: user_atom.agent },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(json => {

                getTaxCalc(selectedYear, (json) => {
                    setTaxCalc(json)
                    setUpdate(false)
                })
                if (submit) {
                    setExtraTax(false)
                    finalFileReturn(til);
                }
                else {
                    setShowLoading(false)
                }
            })
    }

    const finalFileReturn = (til) => {
        const test_service = testService;
        const test_file = til ? 'Y' : 'N'
        const amendment_flag = amendment ? 'Y' : 'N'
        const file_data = `test_file=${test_file}&amendment=${amendment_flag}&test_service=${test_service}&utr=${profile_atom.utr}`;

        let hmrc_username = hmrcUsername;
        let hmrc_password = hmrcPassword;

        localStorage.setItem("HMRCUsername", hmrc_username);
        setTestService(test_service)
        const payload = {
            hmrc_username: hmrc_username,
            hmrc_password: hmrc_password,
            attachment: attachment,
            attachment_size: attachmentSize,
            attachment_name: attachmentName,
            express_submit: useAgent ? 'Y' : 'N',
            file_as_agent: fileAsAgent ? 'Y' : 'N'
        }

        const agent_id = localStorage.getItem('agent')

        fetch(
            `https://${global.api_url}/tax/submitXML.php?tax_year_id=${selectedYear}&ts=${getTimestamp()}&${file_data}`,
            {
                method: "POST",
                headers: { token: user_atom.token, agentId: agent_id },
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(json => {
                Mixpanel.track('submitted_return', { test: til });
                setTil(til)
                setShowLoading(false)
                setAddReturn(false)
                setData(json.data)
                setReportToken(json.report_token)
                setSuccess(json.success)

                //now trigger send email
                if (json.success == "Y" && !til) {

                    Mixpanel.track("submitted_tax_return");

                    const agent_id = localStorage.getItem('agent')

                    fetch(`https://${global.api_url}/tax/sendPDFEmail.php`, {
                        method: "POST",
                        headers: { token: user_atom.token, agentId: agent_id },
                        body: JSON.stringify({
                            report_token: json.report_token,
                            tax_year_id: selectedYear
                        })
                    })
                        .then(res => res.json())
                        .then(json => {
                            if (json.status === 'success') {
                                setSentEmail(true)
                            }
                        });
                }

                if (json.success === "Y" && til) {
                    setData(<>YOUR TEST WAS SUCCESSFUL. YOU NOW NEED TO <span style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                        let check = doValidation()
                        // debugger
                        if (!check) {
                            return;
                        }
                        else {
                            doSubmit(false);
                        }
                    }}>SUBMIT A LIVE TAX RETURN</span>.</>)
                }
        

            })
    }

    useEffect(() => {
        if (recoilFetched) {
            console.log('year change')
            handleYearChange(tax_years_atom.dateFilter)
        }
    }, [tax_years_atom])

    const handleYearChange = (id) => {
        const yearElement = tax_years_atom.taxYears.find(year => year.id === id)
        setSelectedYear(tax_years_atom.dateFilter)
        setSelectedYearData({ ...yearElement })
        setMarriageTransfer(false)
        setMarriageTransferData({ spouse_first_name: '', spouse_last_name: '', spouse_nin: '', spouse_dob: '', married_date: '' })
        setChildBenefit(false)
        setChildBenefitData({ cb_amount: null, cb_number: null, cb_date_end: '' })
        setAmendment(false)
        setDeclare(false)
    }

    const renderAmount = (element) => {
        const prefix = element.type === 'CREDIT' ? "+ £" : "£"
        return (
            prefix +
            Number(element.amount)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        );
    }

    const prettyDateTime = (dtstr) => {
        return moment(dtstr, 'YYYY-MM-DD HH:mm:ss').format('ddd DD MMM YYYY');
    }

    const showButtons = () => {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol className="ion-align-self-start" style={{ textAlign: 'center' }}>
                        <IonButton className="ion-button-money"
                            onClick={() => {
                                Mixpanel.track("submit_return_start");
                                window.history.pushState('form', 'Tax Return', '/taxReturn/form');
                                setAddReturn(true)
                            }}>Submit Return</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    const showReturns = () => {
        return (
            <IonList>
                {returns.map(element => {
                    if (element.tax_year === selectedYearData.tax_year) {
                        return (
                            <IonItem style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }} key={element.id}>
                                <IonGrid>
                                    <IonRow style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}>
                                        <IonCol className="ion-align-self-center">{prettyDateTime(element.submitted)}</IonCol>
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>{element.test == "Y" ? "Test" : "Final"}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                            <a href={`https://pdfe1.untied.io/render_pdf.php?u=https://${global.api_url}/tax/viewReturnReport.php?t=${element.token}`}>{element.response}</a>

                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonItem>
                        )
                    } else {
                        return <div />
                    }
                })}

            </IonList>
        )
    }

    const formatCurrency = (value) => {
        if (value != null) {

            if (value == "") return "£0";

            let prefix = "£";
            let suffix = "";
            if (value < 0) {
                prefix = "-£";

            }
            if (isNaN(value)) {
                value = 0;
            }
            return (
                prefix +
                Math.abs(value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
                suffix
            );
        } else {
            return "£0";
        }
    }

    const handleUpload = event => {

        const file = event.target.files[0]
        const type = file.type

        if (type != 'application/pdf') {
            document.getElementById("uploader").value = "";
            setFileFormatError(true)
            setAttachment("")
            setAttachmentName("")
            setAttachmentSize(0)
            setShowLoading(false)
            return
        }

        const size = file.size
        const name = file.name

        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }

        setShowLoading(true)
        getBase64(file).then(
            data => {
                const filedata = data.substring(28);
                setAttachment(filedata)
                setAttachmentSize(size)
                setAttachmentName(name)
                setShowLoading(false)
            }
        );

    };

    const handleUploadMulti = data => {
        if (data.length === 0) {
            data[0] = {
                size: 0,
                name: "",
                type: ""
            }
        }

        var error = false;

        data.forEach(file => {
            //const file = data[0];
            const size = file.size;
            const name = file.name;

            // setShow((show) => ({ ...show, loading: false }))
            if (size >= 10485760) {
                setAttachment("")
                // setAttachmentName("")
                setAttachmentError(true)
                return

            } else {

                if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === "image/png" || file.type === 'application/pdf' || file.type === 'image/gif') {

                    let atts = attachments                    
                    let attNames = attachmentNames
            
                    // add file to atts if it doesn't already exist
                    if (!atts.find(att => att.name === name)) {
                      atts.push(file)
                      attNames.push(name)
                    }

                    setAttachments(atts)
                    setAttachmentNames(attNames)
                    if (atts.length > 0) {
                        setAttachmentPresent(true)
                    }

                }
                else {
                    if (file.type !== "") setFileFormatError(true)
                    error = true;
                }
            }
        })

    }

    const handleDropFileError = (error) => {
        console.log(error)
        setErrorText(error)
    }

    const showSubmitReturnForm = () => {
        checkTradingIncome()
        const askSE = ((taxCalc.trading_income > 0) || (taxCalc.trading_expenses > 0));
        const advisor = localStorage.getItem("agent")

        return (
            <div>
                <IonAlert
                    isOpen={declareSa103Error}
                    onDidDismiss={() => setDeclareSa103Error(false)}
                    header={'Please confirm'}
                    message={'Sorry, but as your self employed turnover is higher than the SA103S threshold we are unable to help you right now.'}
                    buttons={['OK']}
                />

                <IonAlert
                    isOpen={declareError}
                    onDidDismiss={() => setDeclareError(false)}
                    header={'Info'}
                    subHeader={'Declaration required'}
                    message={'In order to submit please check everything is right to the best of your knowledge and check the declaration box .'}
                    buttons={['OK']}
                />
                <IonAlert
                    isOpen={fileFormatError}
                    onDidDismiss={() => setFileFormatError(false)}
                    header={'File type incorrect'}
                    message={'You can only upload pdf files'}
                    buttons={['OK']}
                />
                <IonAlert
                    isOpen={errorText!=""}
                    onDidDismiss={() => setErrorText("")}
                    header={'Info'}
                    message={errorText}
                    buttons={['OK']}
                />
                <p style={{ textAlign: 'center', marginBottom: 0 }} ts={testService}>Submit Return</p>
                <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px' }}>
                    {!allowedToExpressSubmit ?
                        <IonRow>
                            <IonCol className="ion-align-self-center" style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10, fontSize: '14px' }}>
                                You must have your UTR to submit. You can submit using your HMRC login (also known as Government Gateway login), or using our "express submit" service. <a href="https://help.untied.io/article/59-submitting-your-tax-return" target="_blank">Find out more</a>
                            </IonCol>
                        </IonRow>
                        :
                        <IonRow>
                            <IonCol className="ion-align-self-center" style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10, fontSize: '14px' }}>
                                You must have your UTR to submit. Your account is enabled for express submit.
                            </IonCol>
                        </IonRow>
                    }
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            {/* <IonButton className="ion-button-money"
                                       onClick={() => {
                                           setChooseSubmissionTypePopover(true)
                                       }}>{useAgent === true ? (allowedToExpressSubmit? "Express submit enabled (change)": "Express submit selected (change)") : (useAgent === false? "Filing with HMRC account (change) " : "Choose how to file your return")}</IonButton> */}
                            <IonButton className="ion-button-money"
                                onClick={() => {
                                    setChooseSubmissionTypePopover(true)
                                }}>{useAgent === true ? (allowedToExpressSubmit ? "Express submit enabled (change)" : "Express submit selected (change)") : "Choose how to file your return"}</IonButton>
                        </IonCol>
                    </IonRow>

                    {!useAgent && useAgent !== undefined && (
                        <IonRow>
                            <IonCol>
                                <IonItem >
                                    <IonLabel position="stacked">HMRC Username:</IonLabel>
                                    <IonInput placeholder="" value={hmrcUsername} onIonChange={e => setHmrcUsername(e.detail.value)} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}
                    {!useAgent && useAgent !== undefined && (
                        <IonRow>
                            <IonCol>
                                <IonItem >
                                    <IonLabel position="stacked">HMRC Password:</IonLabel>
                                    <IonInput placeholder="" type="password" value={hmrcPassword} onIonChange={e => setHmrcPassword(e.detail.value)} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}
                    {!useAgent && useAgent !== undefined && advisor !== "" && (
                        <IonRow>
                            <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                <IonItem style={{ "--padding-start": 0, }}>
                                    <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >These are agent credentials and I'm filing as an agent</IonLabel>
                                    <IonCheckbox checked={fileAsAgent}
                                        onIonChange={e => {
                                            setFileAsAgent(!fileAsAgent)
                                        }}
                                        style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >File test submission?
                                    Use this to "test-in-live" before submitting for real. Recommended.</IonLabel>
                                <IonCheckbox checked={dotil}
                                    onIonChange={e => {
                                        if (profile_atom.plan == 2) {
                                            setDotil(e.detail.checked)
                                        } else {
                                            setDotil(true)
                                            setUpgradeError(true)
                                        }
                                    }}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >Is this an amendment?
                                    Use this if you have already filed a return for this year and want to submit an update. </IonLabel>
                                <IonCheckbox checked={amendment} onIonChange={e => setAmendment(e.detail.checked)}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    {askSE && (
                        <IonRow>
                            <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                <IonItem style={{ "--padding-start": 0, }}>
                                    <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >
                                        If your name, address or postcode has changed in the last 12 months, check the box and provide
                                        details in 'Any other information' below.
                                    </IonLabel>
                                    <IonCheckbox checked={se_box_3} onIonChange={e => setSeBox3(e.detail.checked)}
                                        style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                                </IonItem >
                            </IonCol>
                        </IonRow>
                    )}


                    {/* Extra questions to be input here */}
                    {askNic2Volunteer()}
                    {higherChildBenefit()}
                    {childBenefitInfo()}
                    {marriageTransferValid()}
                    {marriageTransferInfo()}
                    {AskTaxOwedNotToBeCodedOut()}
                    {askSEISSGrant()}
                    {askBankDetails()}

                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '14px', paddingRight: 10 }} >Check the box if any amounts given are provisional. <a href="https://help.untied.io/article/36-what-goes-in-the-any-other-information-box" target="_blank">Help</a></IonLabel>
                                <IonCheckbox checked={provisional} onIonChange={e => setProvisional(e.detail.checked)}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem >
                                <IonLabel position="floating">Any other information:</IonLabel>
                                <IonTextarea placeholder="" value={otherInfo} onIonChange={e => setOtherInfo(e.detail.value)} rows={4}></IonTextarea>
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    {paymentBreakdown()}

                    {taxCalc.attachments && taxCalc.attachments.length > 0 && (
                        <IonRow>
                            <IonCol style={{ textAlign: 'left', marginLeft: 10 }}><strong>Attachments</strong></IonCol>
                        </IonRow>)}

                    <IonRow>
                        {taxCalc.attachments && taxCalc.attachments.map((receipt, index) => {

                            const friendly_name = receipt.friendly_name == null ? "attachment " + index : receipt.friendly_name
                            const url = `https://${global.api_url}/tax/downloadAttachment.php?ts=${getTimestamp()}&name=${receipt.name}&year_id=${selectedYear}&token=${taxCalc.report_token}`;
                            return (

                                <IonRow>
                                    <IonCol>
                                        <div style={{ display: 'flex', paddingLeft: 10 }}>
                                            <a href={url} target="_new">{friendly_name}</a> <DeleteIcon style={{ cursor: "pointer" }} onClick={() => deleteAttachment(receipt.id, index)} />
                                        </div>
                                    </IonCol>
                                </IonRow>

                            )
                        })}
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonItem style={{ "--padding-start": 0, }}>
                                <IonLabel style={{ paddingLeft: 15, whiteSpace: 'normal', fontSize: '15px', fontWeight: 600, paddingRight: 10 }}>Check the box to declare that the information provided for this tax return is correct and complete to the best of your knowledge and belief.</IonLabel>
                                <IonCheckbox checked={declare} onIonChange={e => setDeclare(e.detail.checked)}
                                    style={{ "--border-width": '2px', "--border-color": '#3f90b0', "--background-checked": "#3f90b0" }} />
                            </IonItem >
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                            <IonButton color="black" style={{ color: 'black' }}
                                onClick={() => {
                                    setAddReturn(false);
                                }}>Cancel</IonButton>
                        </IonCol>

                        <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                            <IonButton color="black" style={{ color: 'black' }}
                                onClick={() => {
                                    //ss
                                    setShowAttachmentUpload(true)
                                }}>Add attachments</IonButton>
                        </IonCol>

{/*                         

                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>

                            {attachmentName == "" && (
                                <IonButton color="black" style={{ color: 'black' }}
                                    onClick={() => {
                                        document.getElementById("uploader").click();
                                    }}>Add Attachment</IonButton>
                            )}

                            {attachmentName != "" && (
                                <p>{attachmentName} [<a href="#" onClick={() => {
                                    document.getElementById("uploader").value = "";
                                    setAttachment("")
                                    setAttachmentName("")
                                    setAttachmentSize(0)
                                }}>X</a>]</p>
                            )}

                            <input
                                id="uploader"
                                className="csv-input"
                                style={{
                                    display: "none",
                                    marginLeft: 90
                                }}
                                type="file"
                                ref={input => {
                                    setFilesInput(input);
                                }}
                                name="file"
                                placeholder={null}
                                onChange={handleUpload}
                            /> 

                            </IonCol> */}

                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <IonButton color="black" style={{ color: 'black' }}
                                onClick={() => {

                                    setShowLoading(true);
                                    uploadAttachments();
                                    submitReturn(false, false)

                                }}>Save</IonButton>
                        </IonCol>


                        <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                            <IonButton className="ion-button-money"
                                onClick={() => {

                                    if (!doValidation()) {
                                        return
                                    }

                                    if (declare) {
                                        doSubmit(dotil);

                                    } else {
                                        setDeclareError(true);
                                    }
                                }}>{update ? "Update, check Show Tax Breakdown for changes" : "Submit"}</IonButton>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                            <p></p>
                        </IonCol>
                    </IonRow>

                </IonGrid>


                <IonAlert
                    isOpen={amlDetails}
                    onDidDismiss={() => setAmlDetails(false)}
                    header={'Use untied\'s account'}
                    subHeader={'Get a secret code'}
                    message={'If you cannot use your HMRC online account you can send via "untied ". To do this ' +
                        'we will need two forms of ID - one with a photo (e.g. passport, or driver\'s license) and a different one with your address. ' +
                        'Please send these as scans or photos to <a href="mailto:aml@untied.io">aml@untied.io</a>. It can take up to 24 hours to process. ' +
                        'We will then give you a secret code you can use to submit your return'}
                    buttons={[
                        {
                            text: 'Translate',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                var text = document.body.innerText;
                                var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                window.open(url, '_untiedTranslater');

                            }
                        },
                        'OK'
                    ]}
                />

                <IonModal isOpen={showAttachmentUpload} className='attachment-uploader'>

                    <div style={{ height: '100%' }}>

                        <div style={{ backgroundColor: "rgb(12, 144, 178)", width: "100%", height: "42px", display: "flex", flexDirection: "row" }}>
                            <div style={{ paddingTop: "10px", color: "white", fontFamily: "Roboto, Helvetica, Arial, sans-serif", marginLeft: "10px", fontSize: "16px" }}>
                                Upload attachments
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignContents: "center", paddingRight: "10px", marginLeft: "auto" }}>

                                <div style={{ alignSelf: "flex-end" }}>
                                    <CloseIcon
                                        style={{
                                            height: 32,
                                            color: "white",
                                            width: "auto",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            setShowAttachmentUpload(false)
                                        }}
                                    /> </div>
                            </div>
                        </div>


                        <div style={{ height: '100%' }}>

                            <div style={{ paddingBottom: 5, width: '100%', height: '100%', alignItems: 'center' }} >

                                <DropFile
                                    accept=".pdf"
                                    label=""
                                    multiple={true}
                                    fileFormatError={fileFormatError}
                                    handleUpload={handleUploadMulti}
                                    clearFiles={clearFiles}
                                    setAttachments={setAttachments}
                                    maxFileSizeInBytes={10485760}
                                    style={{ height: '100%' }}
                                    maxFiles={5}
                                    currentAttachments={taxCalc.attachments}
                                    handleError={handleDropFileError}
                                />
                            </div>
                       
                            <IonGrid style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%'
                            }}>

                                <IonRow>
                                    <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                        <IonButton color="black" style={{ color: 'black' }}
                                            onClick={() => {

                                                setShowAttachmentUpload(false)

                                            }}>Cancel</IonButton>
                                    </IonCol>

                                    <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                        <IonButton
                                            className="ion-button-money"
                                            onClick={() => {

                                                uploadAttachments();

                                            }}>Upload</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>

                    </div>

                </IonModal>



            </div >
        )

    }

    const doResponse = () => {

        if (data != null) {

            const repType = til ? '_draft' : '_final';
            const fileName = (profile_atom.name != '' ? profile_atom.name : user_atom.email) + repType + '_tax_return_report_' + selectedYearData.hmrc_year_string + '.pdf';  

            console.log('filename: ', fileName)

            return (
                <div>

                    <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px' }}>
                        <IonRow>
                            <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                <IonCard color="black">
                                    <IonCardHeader>
                                        <IonCardTitle color="black" style={{ color: 'black' }}><img src={require(success === "Y" ? "../images/character_tick.png" : "../images/character_error.png").default} style={{ height: '20px' }} alt="success/fail from HMRC" />
                                            {success == "Y" && til ? " TEST SUCCESSFUL" : " HMRC Response"}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {data}
                                    </IonCardContent>
                                </IonCard>
                                {success !== "Y" ? <div>{getHelp()} {backToForms()} </div> : null}
                            </IonCol>
                        </IonRow>

                        {success === "N" && (
                            <IonRow>
                                <IonCol className="ion-align-self-center" >
                                    {data.includes("Authentication Failure") && (
                                        <IonCard color="black">
                                            <IonCardHeader>
                                                <IonCardTitle>Please Read</IonCardTitle>
                                            </IonCardHeader>
                                            <IonCardContent>

                                                <p>This error is being returned to us by HMRC.</p>

                                                <p>The most likely cause of this error is one of the following:</p>

                                                <ul>
                                                    <li>Your UTR is incorrect. Check your <a href="/profileMain">profile</a></li>
                                                    <li>Your HMRC username/password is wrong</li>
                                                    <li>You have not registered for self assessment in your HMRC online account</li>
                                                    <li>You registered for self assessment very recently - it can take a day or two before you can file.</li>
                                                </ul>

                                                <p>Please check all the above by logging into your <a href="https://www.gov.uk/log-in-register-hmrc-online-services">HMRC online account</a>. Check your UTR there and
                                                    check that self assessment is available.
                                                </p>

                                                <p><strong>If you continue to have problems it is still possible to submit via our "express submit" service.
                                                    Please <a href="https://help.untied.io/contact" target="_blank">contact us</a> for details.</strong>
                                                </p>

                                                <p>Also see <a href="https://help.untied.io/article/34-my-submission-failed" target="_blank">this guide</a>.</p>
                                            </IonCardContent>
                                        </IonCard>
                                    )}
                                </IonCol>
                            </IonRow>
                        )}

                        {success === "Y" && (
                            <IonRow>
                                <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                    {til
                                        ? "Your Test-in-Live submission was successful."
                                        : "Please keep a copy of this response. You should get a " +
                                        "confirmation by email but you might also want to take a " +
                                        "screenshot. HMRC will send you a bill within a few days and " +
                                        "you can view your statement in your HMRC account. "}
                                </IonCol>

                            </IonRow>
                        )}

                        {success === "Y" && (
                            <IonRow>
                                <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                    <a href={`https://pdfe1.untied.io/render_pdf.php?f=${fileName}&u=https://${global.api_url}/tax/viewReturnReport.php?t=${reportToken}`}>Download PDF Report</a>
                                </IonCol>
                            </IonRow>
                        )}
                        {success === "Y" && til && (
                            <IonRow>
                                <IonCol className="ion-align-self-start" style={{ textAlign: 'center' }}>
                                    <IonButton className="ion-button-money"
                                        onClick={() => {
                                            let check = doValidation()
                                            // debugger
                                            if (!check) {
                                                return;
                                            }
                                            else {
                                                doSubmit(false);
                                            }
                                        }}>Submit Live Return</IonButton>
                                </IonCol>
                                {backToForms()}
                            </IonRow>

                        )}
                        {success == "Y" && !til && (
                            <IonRow>
                                <IonCol className="ion-align-self-center" style={{ textAlign: 'center' }}>
                                    <IonButton className="ion-button-money"
                                        onClick={() => {
                                            window.location = '/TaxReturns';
                                        }}>Done</IonButton>
                                </IonCol>
                                {backToForms()}
                            </IonRow>
                        )}

                    </IonGrid>
                </div>
            )
        }
    }

    const doValidation = () => {

        if ((profile_atom.plan == '1' || profile_atom.sub_active == 'N') && (!dotil || til)) {
            setActiveError(true)
            return false;
        }

        //give a warning if trying to submit for the current year?
        if (selectedYearData.start_date === thisYearData.start_date) {
            if (!window.location.href.includes('testService')) {
                setCurrentYearError(true)
                return false;
            }
        }


        if (childBenefit) {
            if (!childBenefitData.cb_amount || !childBenefitData.cb_number) {
                setShowLoading(false);
                setCbError(true);
                return false;
            }
        }

        if (marriageTransfer) {
            const nat_ins_regex = /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i

            if (!marriageTransferData.spouse_first_name || !marriageTransferData.spouse_last_name || !marriageTransferData.spouse_nin || !marriageTransferData.spouse_dob || !marriageTransferData.married_date) {
                setShowLoading(false);
                setMarriageError(true);
                return false;
            }
            if (!nat_ins_regex.test(marriageTransferData.spouse_nin)) {
                setShowLoading(false);
                setSpouseNiNError(true);
                return false;
            }
        }

        if (profile_atom.utr === "" || profile_atom.utr === null) {
            setShowLoading(false);
            setUtrError(true);
            return false;
        }

        // check sub valid and not in trial



        if (profile_atom.name === "" || profile_atom.postcode === "" || profile_atom.address === "" ||
            profile_atom.name === null || profile_atom.postcode === null || profile_atom.address === null) {
            setShowLoading(false);
            setAddressError(true);
            return false;
        }

        if (!allowedToExpressSubmit && useAgent) {
            setShowLoading(false);
            setExpressSubmitError(true);
            return false;
        }

        if (useAgent === undefined) {
            setShowLoading(false);
            setUseAgentError(true);
            return false;
        }

        if (!useAgent && (hmrcPassword === "" || hmrcUsername === "")) {
            setShowLoading(false);
            setAuthError(true);
            return false;
        }

        if (
            taxCalc.trading_income > 0 ||
            taxCalc.trading_expenses > 0
        ) {
            if (
                taxData == null ||
                taxData.self_employment == null
            ) {
                setIncomeError('You have trading income but have not completed your self employment profile.')
                return false
            } else {
                var json = JSON.parse(taxData.self_employment);
                if (json.include == false) {
                    setIncomeError('You have trading income but have not completed your self employment profile.')
                    return false
                }
            }
        }

        if (
            taxCalc.property_income > 0 ||
            taxCalc.property_expenses > 0
        ) {
            if (
                taxData == null ||
                taxData.property == null
            ) {
                setIncomeError('You have property income but have not completed your property profile.')
                return false
            } else {
                var json = JSON.parse(taxData.property);
                if (json.include == false) {
                    setIncomeError('You have property income but have not completed your property profile.')
                    return false
                }
            }
        }

        // -PAYE-
        if (
            taxCalc.paye_income > 0 ||
            taxCalc.employment_salary > 0
        ) {
            if (
                taxData == null ||
                taxData.employment == null
            ) {
                setIncomeError('You have PAYE income but have not completed your employment profile.')
                return false
            } else {
                var json = JSON.parse(taxData.employment);
                let validated = true;

                var hasEmployerProfile = false;
                json.forEach((element, index) => {
                    if (element.include) {
                        hasEmployerProfile = true;
                    }
                });

                validated = hasEmployerProfile;

                json.forEach((element, index) => {
                    if (element.include) {
                        //do we have employer references ....
                        if (element.box_4 == "") {
                            validated = false;
                        }
                    }
                });
                if (!validated) {
                    setIncomeError('You have PAYE income but employment profile is incomplete.')
                    return;
                }
            }
        }

        if (bankData.show && (bankData.bank_name === "" || bankData.account_number === "" || bankData.sort_code === "") || bankData.bank_acname === null || bankData.account_number === null || bankData.sort_code === null) {
            setShowLoading(false);
            setBankError(true);
            return false;
        }

        if (bankData.account_number != "" && bankData.account_number.length != 8) {
            setShowLoading(false);
            setBankError(true);
            return false
        }
        if (bankData.sort_code != "" && bankData.sort_code.length != 6) {
            setShowLoading(false);
            setBankError(true);
            return false
        }
        if (bankData.account_number != "" && bankData.sort_code.length != 6) {
            setShowLoading(false);
            setBankError(true);
            return false
        }
        if (bankData.sort_code != "" && bankData.account_number.length != 8) {
            setShowLoading(false);
            setBankError(true);
            return false
        }

        return true
    };

    const doSubmit = (til) => {
        if (taxCalc)
            if (til) {
                setShowLoading(true)
                submitReturn(dotil);
            } else {
                if (doValidation()) {
                    setConfirmSubmit(true);
                }
            }
    }

    const renderSubmissionTypePopover = () => {
        return (
            <IonPopover
                className="my-ion-submit-type-popover"
                isOpen={chooseSubmissionTypePopover}
                onDidDismiss={() => setChooseSubmissionTypePopover(false)}
            >
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <div style={{ textAlign: 'center' }}><h3>Choose how to file your return</h3></div>
                        </IonCol>
                    </IonRow>
                    <RadioGroup value={useAgent !== undefined ? (useAgent ? "2" : "1") : ""} onChange={(e) => setUseAgent(e.target.value === "2")}>
                        <IonRow>
                            <IonCol style={{ borderBottom: "1px solid" }}>
                                <FormControlLabel
                                    value="1"
                                    control={<PopoverCheckbox />}
                                    label="File with HMRC account"
                                    labelPlacement="end"
                                />
                                <p>
                                    Use your HMRC online account / Government Gateway login. Check them here: <a href="https://www.tax.service.gov.uk/account" target="_blank">https://www.tax.service.gov.uk/account</a>
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <FormControlLabel
                                    value="2"
                                    control={<PopoverCheckbox />}
                                    label="File with Express Submit"
                                    labelPlacement="end"
                                />
                                <p>
                                    Express Submit allows you to file without needing an HMRC account. To be approved for Express Submit we first need proof of ID. <a href="https://help.untied.io/article/59-submitting-your-tax-return" target="_blank">Go here to find out more/send us your ID</a>. If you are already approved you can select this option and then go ahead and submit.
                                </p>
                            </IonCol>
                        </IonRow>
                    </RadioGroup>
                    <IonRow>
                        <IonCol style={{ textAlign: 'left' }}>
                            <IonButton color="black"
                                style={{ color: 'black' }}
                                onClick={() => setChooseSubmissionTypePopover(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol style={{ textAlign: 'right' }}>
                            <IonButton
                                className="ion-button-money"
                                onClick={() => setChooseSubmissionTypePopover(false)}
                            >
                                OK
                            </IonButton>
                        </IonCol>
                    </IonRow>

                </IonGrid >
            </IonPopover>
        )
    };

    const renderAlerts = () => {
        return (<>
            <IonAlert
                isOpen={confirmSubmit}
                onDidDismiss={() => setConfirmSubmit(false)}
                header={'Confirm Submission!'}
                message={`You are about to submit a live tax return for ${selectedYearData.explain}. Are you ready?`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            setConfirmSubmit(false)
                        }
                    },
                    {
                        text: 'Yes, submit',
                        handler: () => {
                            setShowLoading(true)
                            setConfirmSubmit(false)
                            submitReturn(false);
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={sentEmail}
                onDidDismiss={() => setSentEmail(false)}
                header={'Success!'}
                subHeader={'Check your email'}
                message={'We have sent a PDF copy of your tax return by email. You can also download a copy from this page.'}
                buttons={['OK']}
            />

            <IonAlert
                isOpen={!allowedToExpressSubmit && useAgent && expressSubmitError}
                onDidDismiss={() => setExpressSubmitError(false)}
                header={'Express Submit not enabled'}
                subHeader={''}
                message={'Express Submit is not enabled for your account, please contact us or try using your HMRC login details.'}
                buttons={['OK']}
            />

            <IonAlert
                isOpen={useAgentError}
                onDidDismiss={() => setUseAgentError(false)}
                header={'Oops'}
                subHeader={''}
                message={'Please choose how you would like to file your return.'}
                buttons={['OK']}
            />

            <IonAlert
                isOpen={bankError}
                onDidDismiss={() => setBankError(false)}
                header={'Oops'}
                subHeader={''}
                message={'Please enter valid bank account details. Bank account number must be 8 digits and sort code must be 6 digits.'}
                buttons={['OK']}
            />

            <IonAlert
                isOpen={incomeError != ""}
                onDidDismiss={() => setIncomeError("")}
                header={'Oops'}
                subHeader={''}
                message={incomeError}
                buttons={[
                    { text: "Cancel", role: 'cancel', cssClass: 'secondary' },
                    {
                        text: 'Go to forms',

                        handler: () => {
                            props.history.push("/taxForms/menu");
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={spouseNiNError}
                onDidDismiss={() => setSpouseNiNError(false)}
                header={'Spouse National Insurance Error'}
                subHeader={'Incorrect format'}
                message={'Please check that you have entered your spouses national insurance information correctly in the Marriage Transfer section'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            var text = document.body.innerText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />

            <IonAlert
                isOpen={cbError}
                onDidDismiss={() => setCbError(false)}
                header={'Child Benefit Error'}
                subHeader={'Missing details'}
                message={'Please check that you have entered your information correctly in the Child Benefit section'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            var text = document.body.innerText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />

            <IonAlert
                isOpen={marriageError}
                onDidDismiss={() => setMarriageError(false)}
                header={'Marriage Transfer Error'}
                subHeader={'Missing details'}
                message={'Please check that you have entered your information correctly in the Marriage Transfer section'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            var text = document.body.innerText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />

            <IonAlert
                isOpen={utrError}
                onDidDismiss={() => setUtrError(false)}
                header={'Unique Tax Reference'}
                subHeader={'Your UTR is missing'}
                message={'We cannot submit without a valid UTR (Unique Tax Reference). Please enter your UTR in the ' +
                    '<a href="/profileMain">Profile screen</a>.'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            var text = document.body.innerText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />

            <IonAlert
                isOpen={currentYearError}
                onDidDismiss={() => setCurrentYearError(false)}
                header={`Cannot submit`}
                subHeader={"The year hasn't ended yet"}
                message={`You cannot submit a tax return for ${selectedYearData.explain} as it hasn't finished yet. Please select a different year.`}
                buttons={[{
                    text: 'OK',
                    role: 'OK',
                    cssClass: 'shadow text-center col-12'
                }]}
            />

            <IonAlert
                isOpen={activeError}
                onDidDismiss={() => setActiveError(false)}
                header={`Plan not valid`}
                subHeader={""}
                message={`Please purchase a plan to submit a return`}
                buttons={[{
                    text: 'OK',
                    role: 'OK',
                    cssClass: 'shadow text-center col-12'
                },
                {
                    text: 'Purchase a plan',
                    handler: () => props.history.push("/plan")
                }]}
            />

            <IonAlert
                isOpen={authError}
                onDidDismiss={() => setAuthError(false)}
                header={useAgent ? 'Password Error' : 'Username and Password'}
                subHeader={'Missing credentials'}
                message={useAgent ? 'Please enter the authorisation code received from untied.' : 'Please check your HMRC username and password'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            var text = document.body.innerText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />

            <IonAlert
                isOpen={addressError}
                onDidDismiss={() => setAddressError(false)}
                header={'Name & Address Error'}
                subHeader={'Missing details'}
                message={'Please check that you have entered your name, address and postcode in the <a href="/profileMain">Profile screen</a>.'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            var text = document.body.innerText;
                            var url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />

            <IonAlert
                isOpen={upgradeError}
                onDidDismiss={() => setUpgradeError(false)}
                header={'Your plan'}
                subHeader={'A subscription is required'}
                message={'A pro subscription is required to submit a live tax return. Do you want to view upgrade options now?'}
                buttons={[
                    { text: "No", role: 'cancel', cssClass: 'secondary' },
                    {
                        text: 'Yes',

                        handler: () => {
                            props.history.push('/profileMain', { state: "Manage your plan" })
                        }
                    }
                ]}
            />

            <IonAlert
                isOpen={fileFormatError}
                onDidDismiss={() => setFileFormatError(false)}
                header={'File upload error'}
                subHeader={'Invalid format'}
                message={'File attachments must be PDF format. Please ensure your attachment is saved as a PDF and try again.'}
                buttons={[
                    {
                        text: 'Translate',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            let text = document.body.innerText;
                            let url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                            window.open(url, '_untiedTranslater');

                        }
                    },
                    'OK'
                ]}
            />
        </>)
    }

    const displayMain = () => {
        if (taxCalcDataLoaded && recoilFetched) {
            return (
                <div style={{ width: "75%", margin: "auto" }}>
                    <div style={{ marginTop: 20 }}>
                        {!addReturn && data == null && showButtons()}
                        {!addReturn && data == null && showReturns()}
                        {addReturn && data == null && showSubmitReturnForm()}
                        {doResponse()}

                        <IonLoading
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Please wait...'}
                            duration={30000}
                        />
                        {renderSubmissionTypePopover()}
                        {renderAlerts()}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div />
            )
        }
    }

    return (<>

        <React.Fragment>
            <div className="tax-return-page-container">
                <MiniDrawer />

                <Container className="tax-return-page-content">
                    {SelectionBar(recoilFetched, update, true)}
                    {displayMain()}
                </Container>
            </div>
        </React.Fragment>
    </>
    )
};

export default withRouter(Returns)
