import React, {useEffect, useState, useRef} from "react";
import { IonButton, IonPopover, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol, IonItem, IonSelect, IonSelectOption, IonInput, IonLoading, IonLabel, IonDatetime, IonModal, IonText, IonTextarea } from '@ionic/react';
import { Redirect, useLocation, useHistory  } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import { Button } from "@material-ui/core";

import MiniDrawer from "../MiniDrawer";
import { Mixpanel } from "../mixpanel";

import { useRecoilState } from 'recoil'
import { userAtom,  profileAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { getTimestamp } from '../components/timeStamp.js';
import CustomFilter from "../components/CustomFilter";
import CloseIcon from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import Link from '@material-ui/icons/Link';

import "./../App.css";
import './Page.css';
import SelectInput from "@material-ui/core/Select/SelectInput";

const InvoiceSettings = (props) => {
 

  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const [user_atom, setUserAtom] = useRecoilState(userAtom);
//   const [userProfile, setUserProfile] = useState("")
  const paymentData = props.location.state
  const [profile_atom, setProfileAtom] = useRecoilState(profileAtom);
  const [title, setTitle] = useState("");
  const [tagline, setTagline] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [postcode, setPostcode] = useState("");
  const [imageData, setImageData] = useState("");
  const [redirect, setRedirect] = useState(false)
  const [linebreaks, setLinebreaks] = useState(3)
  const [recoilFetched, setRecoilFetched] = useState(false)
  const [savedData, setSavedData] = useState(false)

  const inputFile = useRef(null)
  const [file, setFile] = useState()
  const [upload, setUpload] = useState({
    show:false,
    error:false,
    message: ""
  })
  //handles navigation options - title and custom back handler

  const labelLeft = {
    display: "block", 
    color: "rgba(0, 0, 0, 0.38)", 
    fontSize: 12, 
    width: "100%", 
    paddingLeft: "16px", 
    textAlign: "left"
  }

  const borderBox = {
      "border": "1px solid #3f90b0", 
      borderRadius: "3px"
    }


  const handleKeyDownAddress = (e) => {        
    let lbreaks = address ? address.split("\n").length : 3
    setLinebreaks(lbreaks)
  }

  useEffect(() => {
    if(user_atom.token){
    fetch(`https://${global.api_url}/invoiceSettings.php?ts=${getTimestamp()}`,
      {method: "GET",
      headers:{ 
          token: user_atom.token, 
          // "Content-Type": "application/json" 
        },
    })
    .then(res => res.json())
    .then(json => {
        console.log(json);
        if (json.status == "success" || json.status == "queued") {
          // setTitle(profile_atom.name);
          // setAddress(profile_atom.address);
          // setPostcode(profile_atom.postcode);
          // setPhone(profile_atom.phone);

          if (json.settings) {
            setTitle(json.settings.title);
            setTagline(json.settings.tagline);
            setImageData(json.settings.logo);

            if (json.settings.address) setAddress(json.settings.address);
            if (json.settings.postcode) setPostcode(json.settings.postcode);
            if (json.settings.phone) setPhone(json.settings.phone);
          }
        } else {
        //   Alert.alert("Error retrieving invoice settings. ");
        }
      }
    );
    }
  }, [user_atom]);

  useEffect(() => {
        
    if (!recoilFetched){
        if(!user_atom.token){
            let userState = getLocalStorage();
            setUserAtom(userState);
            getProfile(userState.token, userState.agent);
        }else if (JSON.stringify(profile_atom) === "{}"){
            getProfile();
        }else{
            getProfile()
        }
    }
}, []);



  //save the profile when saving == true
  useEffect(() => {
    if (saving) {
      console.log("saving");
      fetch(`https://${global.api_url}/invoiceSettings.php?ts=${getTimestamp()}`,
        {method : "POST",
        headers : { 
            token: user_atom.token, 
            // "Content-Type": "application/json" 
        },
        body: JSON.stringify({ title, tagline, imageData, phone, address, postcode }),
        })
        // .then(res => res.text())
        // .then(t => console.log(t))
        .then (res=> res.json())
        .then (json => {
          console.log(json);
          if (json.status == "success" || json.status == "queued") {
            setSaving(false);
            Mixpanel.track("saved_invoice_settings");
            setSavedData(true)
            // props.navigation.goBack(null);
          } else {
            // Alert.alert("Error saving invoice settings", json.message);
            setSaving(false);
          }
        }
      );
    }
  }, [saving]);

  const getProfile = (passed_token = user_atom.token, passed_agent = user_atom.agentId) => {
    fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
        method: "GET",
        headers: { token: passed_token, agentId: passed_agent }
    })
        .then(res => res.json())
        .then(json => {
            console.log(json)

            if (json.error) {
                if (json.error === "unauthorized") {
                    //alert('redirect to login page');
                    props.history.push("/logout/logout");
                }
            } else {
                localStorage.setItem('support_token_start', json.support_token_start);
                setProfileAtom(json);
                let lbreaks = json.address ? json.address.split("\n").length+1 : 3
                setLinebreaks(lbreaks)
                setAddress(json.address);
                setPostcode(json.postcode);
                setPhone(json.phone)
            }
            setRecoilFetched(true)
        });

}

useEffect(()=>{
  if(file){
    if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === "image/png"){
      if(file.size< 512000){
        getBase64()
      }
      else{
        setUpload({
          show:true,
          error:true,
          message:"File too large, upload limit 500kb"
        })

      }
    }
    else{
      setUpload({
        show:true,
        error:true,
        message:"File wrong format, file types: jpg, jpeg, or png"
      })
    }
}
},[file])

const getBase64 =() => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {

    let data  = reader.result.split(",")[1]
    setImageData(data)
    // setUpload({
    //   show:true,
    //   error:false,
    //   message: ""
    // })
    setSaving(true)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
    setUpload({
      show:true,
      error:true,
      message: "Error with uploading"
    })
  };
}


const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    
    setFile(file)
    // this.setState({file}); /// if you want to upload latter
}


  const displayMain = () =>{
      return(
        <>
        <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', backgroundColor: "#FFFFFF", "--ion-grid-padding": "0px", borderRadius: "5px 5px 0px 0px",}}>
        <IonRow style = {{backgroundColor : "#3f90b0", borderRadius: "5px 5px 0px 0px", }}>
        <div style ={{width: "100%", display: "flex", flexDirection: "row", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}> 
          <h2 style = {{marginLeft: "10px", color: "white"}}>Invoice settings</h2> 
          <div style={{alignSelf:"center", alignItem: "flex-end", paddingRight: "10px", marginLeft: "auto"}}>  
          <CloseIcon
                style={{
                    color: "white",
                    height: "40px",
                    width: "auto",
                    cursor: "pointer"
                    }}
                onClick={() => {
                  setRedirect(true)
                }}
            />
          </div>
        </div>
        
        </IonRow>

        <IonRow>
                    <IonCol>
                      <IonLabel style = {labelLeft}>Business name</IonLabel>
                      <IonItem lines="none" style = {borderBox}>
                            <CustomFilter
                                // label="Send payment request to"
                                placeholder="Title"
                                value={title}
                                className="custom-filter-payments"
                                onChange={e => setTitle(e.target.value)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
        <IonRow>
                    <IonCol>
                      <IonLabel style = {labelLeft}>Tag line</IonLabel>
                      <IonItem lines="none" style = {borderBox}>
                            <CustomFilter
                                // label="Send payment request to"
                                placeholder="Displayed under business name on your invoices"
                                value={tagline}
                                className="custom-filter-payments"
                                onChange={e => setTagline(e.target.value)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                            <IonCol>
                                <IonLabel style = {labelLeft}>Address</IonLabel>
                                <IonItem style = {borderBox} lines = "none">
                        
                                    <IonTextarea 
                                        
                                        onKeyPress={handleKeyDownAddress}
                                        placeholder="" 
                                        value={address} 
                                        onIonChange={e => setAddress(e.detail.value)}
                                        rows={linebreaks}
                                        ></IonTextarea>
                                </IonItem>
                            </IonCol>
                        </IonRow>

            <IonRow>
                    <IonCol>
                      <IonLabel style = {labelLeft}>Postcode</IonLabel>
                      <IonItem lines="none" style = {borderBox}>
                            <CustomFilter
                                // label="Send payment request to"
                                placeholder="Postcode"
                                value={postcode}
                                className="custom-filter-payments"
                                onChange={e => setPostcode(e.target.value)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

            <IonRow>
                    <IonCol>
                      <IonLabel style = {labelLeft}>Phone number</IonLabel>
                      <IonItem lines="none" style = {borderBox}>
                            <CustomFilter
                                // label="Send payment request to"
                                placeholder="Phone number"
                                value={phone}
                                className="custom-filter-payments"
                                onChange={e => setPhone(e.target.value)}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
            <IonRow>
                         {imageData!== "" && <>
            <IonCol style = {{textAlign: "right"}}>
                    <img
                        style={{
                            height: 80,
                            // width: 80,
                            alignSelf: "center",
                            // border: "1px solid #3f90b0",
                            margin:"auto",
                            }}
                            src={`data:image/gif;base64,${imageData}`}
                            
                            />
                    </IonCol>

                    <IonCol style = {{textAlign: "left"}}>
                    <Button
                            style={{
                              height: "100%", 
                              verticalAlign: "middle", 
                              color: "#3f90b0"
                              }}
                            onClick={() => {
                              inputFile.current.click()
                              
                              }}
                        >
                        <input 
                    type='file' 
                    id='file'
                     ref={inputFile} 
                     style={{display: 'none'}} 
                    onChange={(e) => onChangeFile(e)}/>
                        <CachedIcon /> Replace
                      </Button>
                   
                    </IonCol>
                    <IonCol style = {{textAlign: "left"}}>
                    <Button
                            style={{
                              height: "100%", 
                              verticalAlign: "middle", 
                              color: "#a00338"
                            }}
                            onClick={() => {
                              setImageData("")
                              setFile(null)
                              inputFile.current = null
                              }}
                        >
                            <DeleteOutlineOutlinedIcon /> Remove
                      </Button>
                    </IonCol>
                    
                         </>}
                       
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonButton
                            style = {{"--box-shadow": "none"}}
                            className= "ion-button-inverse"
                            onClick = {()=> {
                                setRedirect(true)
                            }
                            }>
                            Back
                        </IonButton>
                    </IonCol>
                    {!imageData && 
                    <IonCol>
                    <IonButton
                      className = "ion-button-money"
                      onClick = {()=> {
                      inputFile.current.click()
                      }}>
                    <input 
                    type='file' 
                    id='file'
                     ref={inputFile} 
                     style={{display: 'none'}} 
                    onChange={(e) => onChangeFile(e)}/>
                    Add logo
                    </IonButton>
                    </IonCol>
                         }
                    <IonCol>
                        <IonButton
                            style = {{"--box-shadow": "none"}}
                            className="ion-button-money"
                            onClick = {() => {
                                setSaving(true)
                            }}
                        >
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>



        </IonGrid>
    </>
  )} 

  return(<>
    {redirect && <Redirect
            to={{
                pathname: "/payment/form",
                state: { paymentData }
        }}
        />}
          <IonAlert
      isOpen={savedData}
      header = "Data saved"
      onDidDismiss={() => {
        setSavedData(false)
        // setRedirect(true)
        }}
      buttons = {["OK"]}
    />
    <IonAlert
      isOpen={upload.show}
      header = {upload.error? "Error uploading":"Logo uploaded - still need to save to confirm"}
      message = {upload.message}
      onDidDismiss={() => {
        setUpload({
          show: false,
          error:false
        })
        }}
      buttons = {["OK"]}

    />
 
    <React.Fragment>
    <div className="payments-page-container" style={{backgroundColor: "lightGrey", top: 0, height: "100vh"}}>
        <MiniDrawer />
        <div className="payments-page-content" style = {{marginTop: "13vh"}}>
             {displayMain()}
            {/* {displayPopover()}
            {loading ? null : displayBottomInfo()} */}
          
        </div>
    </div>
</React.Fragment>
  </>)
  
}

export default InvoiceSettings;
