//CustomFilter
import {TextField, withStyles} from "@material-ui/core";

const   CustomFilter = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#3f90b0'
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#3f90b0',
            borderBottomWidth: 2
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3f90b0'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#000000'
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#3f90b0'
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#3f90b0'
            }
        },
        '& .MuiSelect-select': {
            width: '100%'
        }
    }
})(TextField);

export default CustomFilter;
