import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonText,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
} from '@ionic/react';
import { Checkbox, FormControlLabel, Card, Button, CardContent, Container } from "@material-ui/core";

import { getTimestamp } from '../components/timeStamp.js';
import CloseIcon from '@material-ui/icons/Close';
import OpenInFull from './../images/open_in_full_black_24dp.svg'
import AddingReceipts from './../images/adding_receipts.png'
import AddingTransactions from './../images/adding_manual_transactions.png'
import TaggingTransactions from './../images/tagging_transactions.png'
import UntaggingTransactions from './../images/untagging_transactions.png'
import EditingTransactions from './../images/editing_transactions.png'
import FilteringTransactions from './../images/filtering_transactions.png'
import SendingInvoice from './../images/sending_invoice.png'
import MakingPayRequest from './../images/making_pay_request.png'
import PaymentAndInvoices from './../images/payments_and_invoices_page.png'
import AddManualJourney from './../images/add_manual_journey.png'
import EditJourney from './../images/editing_journey.png'

import MiniDrawer from "./../MiniDrawer";
import "./../App.css";
import { Mixpanel } from "../mixpanel";

import { useRecoilState } from 'recoil'
import { userAtom, profileAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'



const Tutorials = (props) => {
    const [user_atom, setUserAtom] = useRecoilState(userAtom);
    const [recoilFetched, setRecoilFetched] = useState(false);
    const [profile_atom, setProfileAtom] = useRecoilState(profileAtom);
    const [bigVid, setBigVid] = useState("")
 
    const [selected, setSelected] = useState("Get started")

    const transactionVids =[
        {
            title: "Tagging transactions",
            content: <iframe src="https://muse.ai/embed/64mFPcG" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: TaggingTransactions
        },
        {
            title: "Filtering transactions",
            content: <iframe src="https://muse.ai/embed/Zto58sq" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: FilteringTransactions
        },
        {
            title: "Adding a manual transaction",
            content: <iframe src="https://muse.ai/embed/A7BGY1C" width= "100%" height= "100%"  frameborder="0" allowfullscreen></iframe>,
            image: AddingTransactions
          
        },
        {
            title: "Adding receipts",
            content: <iframe src="https://muse.ai/embed/m8PVgfm" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: AddingReceipts
        },
        {
            title: "Editing a transaction",
            content: <iframe src="https://muse.ai/embed/WA1sR49" width= "100%" height= "100%" frameborder="0" allowfullscreen subtitles></iframe>,
            image: EditingTransactions
        },
            {
            title: "Untagging transactions",
            content: <iframe src="https://muse.ai/embed/FrcCTA3" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: UntaggingTransactions
        },
    ]

    const paymentVids =[
        {
            title: "Payments and invoices page",
            content: <iframe src="https://muse.ai/embed/HE7K9Wy" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: PaymentAndInvoices
        },
        {
            title: "Making a payment request",
            content: <iframe src="https://muse.ai/embed/QaJKjeP" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: MakingPayRequest
        },
        {
            title: "Sending an invoice",
            content: <iframe src="https://muse.ai/embed/iPVtvqF" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: SendingInvoice
        },
    ]

    const journeyVids = [
        {
            title: "Adding a manual journey",
            content: <iframe src="https://muse.ai/embed/gL7HbVd" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: AddManualJourney
        },
        {
            title: "Editing a journey",
            content: <iframe src="https://muse.ai/embed/XyPWE7q" width= "100%" height= "100%" frameborder="0" allowfullscreen></iframe>,
            image: EditJourney
        },

    ]
    
    

const getProfile = (passed_token = user_atom.token, passed_agent = user_atom.agentId) => {
            fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
                method: "GET",
                headers: { token: passed_token, agentId: passed_agent }
            })
                .then(res => res.json())
                .then(json => {
                    if (json.error) {
                        if (json.error === "unauthorized") {
                            //alert('redirect to login page');
                            props.history.push("/logout/logout");
                        }
                    } else {
                        localStorage.setItem('support_token_start', json.support_token_start);
                        setProfileAtom(json);
                    }
                    setRecoilFetched(true)
                });

        }

    const styles = {
            cardStyle: {
                marginTop: 10,
                //minWidth: 800,
                padding: "none",
                position: "relative"
            },
            divCardStyle: {
                marginTop: 10,
                //minWidth: 800,
                padding: "none",
                position: "relative",
                display: "flex"
            },
        };

    const topStyles = {
        selectedBarButton:{
            marginLeft: "5px",
            marginRight: "5px",
            height: "25px",
            border: " 1px solid #3f90b0",
            borderBottom: "none",
            color: "white",
            fontWeight: "bold",
            backgroundColor: "#3f90b0",
            backgroundHover: "#a00338",
            boxShadow: "none",
            cursor: "pointer",
            borderRadius: "5px 5px 0 0",
            paddingTop: "10px"
        },
        unselectedBarButton:{
            marginLeft: "5px",
            marginRight: "5px",
            height: "25px",
            border: " 1px solid #3f90b0",
            borderBottom: "none",
            fontWeight: "bold",
            color: "#3f90b0",
            backgroundColor: "white",
            backgroundHover: "grey",
            boxShadow: "none",
            cursor: "pointer",
            borderRadius: "5px 5px 0 0",
            paddingTop: "10px"
        }
    }

    

    const optionBar = ()=> {
        
        return(
            <div style ={{
                borderBottom: "2px solid #3f90b0"
            }}>
            <IonGrid style ={{"padding-bottom": 0}} >
            <IonRow>
                <IonCol 
                    style = {selected == "Get started" ? topStyles.selectedBarButton : topStyles.unselectedBarButton } 
                   
                    onClick = {()=>{
                        setSelected("Get started")
                    }}>
                    <IonText>Get started</IonText>
                </IonCol>
                <IonCol 
                    style = {selected == "Transactions" ? topStyles.selectedBarButton : topStyles.unselectedBarButton } 
                   
                    onClick = {()=>{
                        setSelected("Transactions")
                    }}>
                    <IonText>Transactions</IonText>
                </IonCol>
            </IonRow>
        </IonGrid>
        </div>
        )
    }

    const renderService = {
        GetStartedCard: () => {
            return(
            <Card style={styles.cardStyle}>
                <CardContent>
                    <IonGrid>
                    <IonRow style ={{height:"95vh", }}>
                    <IonCol>
                    <div style={{
                      height: "100%"
                      }}>
                    <div 
                    style={{
                      height: "100%"
                      }}>
                      <iframe 
                      src="https://player.vimeo.com/video/641733898?h=5f38a05eeb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;texttrack=en"
                      frameborder="0" 
                      allow="autoplay; fullscreen; picture-in-picture" 
                      allowfullscreen 
                      style={{
                        width:"100%",
                        height:"100%",}}
                      title="Welcome to untied Pro in the browser">

                       </iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

                       </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </CardContent>
                </Card>
            )
        },
        OverviewCard: () => {
            return(
            <Card style={styles.cardStyle}>
                <CardContent>
                    <IonGrid>
                    <IonRow style ={{height:"95vh", }}>
                    <IonCol>
                    <div style={{
                      height: "100%"
                      }}>
                    <div 
                    style={{
                        marginRight: 130,
                        height: "100%"
                      }}>
                      <iframe 
                      src="https://player.vimeo.com/video/665298865?h=989ad0c0c0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;texttrack=en"
                      frameborder="0" 
                      allow="autoplay; fullscreen; picture-in-picture" 
                      allowfullscreen 
                      style={{
                        width:"100%",
                        height:"100%",
                        }}
                      title="Welcome to untied Pro in the browser">

                       </iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

                       </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </CardContent>
                </Card>
            )
        },
        MuseCards : (vids) =>{
            if(bigVid){
                return(
                    <IonCard className="tax-forms-menu-card" 
                    style={{height: "98%", width: "98%", overflow: "hidden"}} 
    
                             onClick={() => {
                                // setChosenVideo(item.title);
                                }}>
                        <IonCardHeader style={{height: 40, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <IonCardTitle className="tax-forms-card-title" style = {{width: "fit-content", overflow: "hidden"}}>{bigVid.title}</IonCardTitle>
                                <CloseIcon
                                style = {{height: 30, width: 30}}
                                onClick={()=>{
                                    setBigVid("")
                                }}
                                
                                />
                        </IonCardHeader>
                        <IonCardContent className="tax-forms-card-content" style={{height: "90%", overflow: "hidden"}}>
                            {bigVid.content}
                        </IonCardContent>
                    </IonCard>
                )
            }
            return(
            <div className="tax-forms-menu-container" style ={{justifyContent: "space-evenly"}}>
            {vids.map((item, index) => (<div key={index} style = {{minWIdth: "40%"}}>
                <IonCard className="tax-forms-menu-card" 
                style={{height: "fit-content", minWidth: "fit-content"}} 
                            >

                    <IonCardContent 
                    style={{ width: "390px", height: "200px", overflow: "hidden", position: "relative"}}>
                        <img src = {item.image} style={{height: "100%",}} onClick = {() => setBigVid(item)}/>
                    </IonCardContent>
                </IonCard>
            </div>))}
            </div>
            )

        },
        MuseCard2: () => {
            // let collection = MuseCollection({collection: 'FExUepG',containerResults: '#muse-videos-grid',containerInput: '#muse-search',style: 'large'})
            return (

                                <div 
                                 style={{
                                    height: "100%",
                                    width: "85%",
                                    margin:"auto"
                                    }}>
                                <iframe src="https://muse.ai/c/htLA1FD"  
                                style ={{
                                width:"100%", 
                                height:"100%",
                                }}
                                data-width= "60vw"
                                data-height= "85vh"
                                frameborder="0" 
                                allowfullscreen></iframe>
                                </div>
            )
        },
        GenericCard: () => {
            return (
                <div style={{paddingRight:10, }}>
                <Card style={styles.cardStyle}>
                    <CardContent>
                        <IonGrid>
                        <IonRow style ={{height:"90vh", }}>
                                <IonCol>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </CardContent>
                </Card>
                </div>
            )
        },
        Alerts: () => {
            return (<>
                {/* <IonAlert
                    isOpen={show.showHelp}
                    onDidDismiss={() => setShow({...show, showHelp: false})}
                    header={help.helpMainTitle || 'Help'}
                    subHeader={help.helpTitle}
                    message={help.helpText}

                buttons={[
                        {
                            text: 'Translate',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                const text = document.body.innerText;
                                const url = "https://translate.google.com/?source=gtx_c#auto/en/" + encodeURIComponent(text);
                                window.open(url, '_untiedTranslater');
                            }
                        },
                        'OK'
                    ]}
                /> */}

            </>)
        }
    };

    useEffect(() => {
        
        if (!recoilFetched){
            if(!user_atom.token){
                let userState = getLocalStorage();
                setUserAtom(userState);
                getProfile(userState.token, userState.agent);
               
            }else if (JSON.stringify(profile_atom) === "{}"){
                getProfile();
            }else{
                getProfile()
            }
        }
    }, []);

    useEffect(()=> {
            if(props.location && props.location.state){
                setSelected(props.location.state.tutorial)

            }

    }, [props])

    const museCard = (vids) => {
        return(
            <Card style={styles.cardStyle}>
                    <CardContent>
                        <IonGrid style = {{height: "95vh"}}>
                        <IonRow style = {{height: "100%"}}>
                            <IonCol style = {{height: "98%", overflowY: "auto"}}>
                             {renderService.MuseCards(vids)}
                        </IonCol>
                        </IonRow>
                        </IonGrid>
                    </CardContent>
                </Card>
        )

    }




    return (<React.Fragment>
             {console.log(props.location.state)}
    <div className="profile-page-container">
    
      <MiniDrawer />

      {recoilFetched && 
      <div className="profile-page-content">
        {/* {optionBar()} */}
        <div style={{paddingRight:10}}>
        {selected == "Transactions" &&  museCard(transactionVids)}
        {selected == "Payments and invoicing" && museCard(paymentVids)}
        {selected == "Journeys" && museCard(journeyVids)}
        {selected == "Start here"  && renderService.GetStartedCard()}
        {selected == "Doing your tax return"  && renderService.OverviewCard()}
                </div>
        {renderService.Alerts()}
      </div>}
    </div>
  </React.Fragment>);

};


export default withRouter(Tutorials)
