import React, { useState, useRef, useEffect, Component } from "react";
import { withRouter } from "react-router-dom";
import "./../App.css";
import { JsonToTable } from "react-json-to-table";
import { IonLoading } from '@ionic/react';
import "./MTDReport.css";

// Create styles

const MTDReport = (props) => {
   const firstTimeRender = useRef(true)

   const [eoySummary, setEoySummary] = useState({})
   const [name, setName] = useState("")
   const [utr, setUtr] = useState("")
   const [yearString, setYearString] = useState("")
   const [taxableIncome, setTaxableIncome] = useState(null)
   const [taxNics, setTaxNics] = useState(null)
   const [allowancesDeductionsReliefs, setAllowancesDeductionsReliefs] = useState(null)
   const [showLoading, setShowLoading] = useState(false)
   const [taxCalc, setTaxCalc] = useState(null)

   const styles = {
      page: {
         backgroundColor: 'white',
         marginLeft: 20,
         marginRight: 20,
      }
   };

   let rename = require('deep-rename-keys');
   const ref = React.createRef();

   useEffect(() => {

      if (props.match != null) {
         if (props.match.params.token) {
            const token = props.match.params.token
            console.log(token)
            getReport(token)

         }
      }

      window.Beacon('destroy')

      // const handler = () => {
      //    window.Beacon('destroy')
      //    console.log(1);
      // };

      // if (document.readyState === "complete") {
      //    handler();
      // } else {
      //    window.addEventListener('load', handler);
      //    return () => document.removeEventListener('load', handler);
      // }

   }, [])


   const getReport = (token) => {

      fetch(`https://${global.api_url}/hmrc/getReportData.php?t=${token}`, {
         method: "GET"
      })
         .then(res => res.json())
         .then(json => {

            console.log(json);

            if (json.status == "OK") {
               setName(json.name)
               setUtr(json.utr)
               setYearString(json.tax_year)
               parseJson(json.json)
            }

         })
   }

   const parseJson = (json_text) => {
      //var json_text = localStorage.getItem('302Json');
      let json = JSON.parse(json_text);

      console.log(json);


      var taxCalcObj = json.calculation;
      // keys are all lowerCamelCase. Conver to Title Case but subequent words are not capitalised. 
      // We need to do this in a recursive function.
      const convertKeysToTitleCase = (obj) => {
          if (obj === null || obj === undefined) {
              return obj;
          }
          if (typeof obj !== 'object') {
              return obj;
          }
          if (Array.isArray(obj)) {
              return obj.map((item) => convertKeysToTitleCase(item));
          }
          return Object.keys(obj).reduce((acc, key) => {
              const newKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
              // but only first character should be capitalised
              const newKey2 = newKey.charAt(0).toUpperCase() + newKey.slice(1).toLowerCase();
              
              acc[newKey2] = convertKeysToTitleCase(obj[key]);
              return acc;
          }, {});
      };
      
      const taxCalc = convertKeysToTitleCase(taxCalcObj);
      setTaxCalc(taxCalc);

      // delete json.eoy_summary.links
      // delete json.taxable_income.links
      // delete json.income_tax_nics_calculated.links
      // delete json.allowances_deductions_reliefs.links

      // console.log(json)

      // let bus_pl = json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss;
      // console.log('pl', bus_pl)

      // if (bus_pl.selfEmployments) {

      //    bus_pl.selfEmployments.forEach(element => {

      //       const seId = element.selfEmploymentId

      //       json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId] = element;
      //       delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['selfEmploymentId'];


      //       let lossClaimsSummary = element.lossClaimsSummary;
      //       json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Loss Claims Summary for ' + seId] = lossClaimsSummary

      //       let lossClaimsDetail = element.lossClaimsDetail;
      //       json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Loss Claims Detail for ' + seId] = lossClaimsDetail

      //       let Bsas = element.bsas;
      //       json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['bsas for ' + element.selfEmploymentId] = Bsas

      //       delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['lossClaimsSummary']
      //       delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['lossClaimsDetail']
      //       delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['bsas'];


      //    })
      // }

      // json = rename(json, function (key) {
      //    if (key == 'studentLoanTotalIncomeAmount') { return 'Total Income' }
      //    if (key == 'studentLoanChargeableIncomeAmount') { return 'Chargeable' }
      //    if (key == 'studentLoanRepaymentAmount') { return 'Repayment' }
      //    if (key == 'studentLoanDeductionsFromEmployment') { return 'Employer Deductions' }
      //    if (key == 'studentLoanRepaymentAmountNetOfDeductions') { return 'Repayment Net Deductions' }
      //    if (key == 'studentLoanApportionedIncomeThreshold') { return 'Income Threshold' }
      //    if (key == 'studentLoanRate') { return 'Rate' }

      //    let theKey = key.replace(/([A-Z])/g, ' $1').trim()
      //    theKey = theKey.charAt(0).toUpperCase() + theKey.slice(1);
      //    return theKey
      // });

      // console.log(json)
      // setEoySummary(json.Eoy_summary)
      // setTaxableIncome(json.Taxable_income)
      // setTaxNics(json.Income_tax_nics_calculated)
      // setAllowancesDeductionsReliefs(json.Allowances_deductions_reliefs)
      setShowLoading(false)
   }

   return (

      <div style={styles.page} ref={ref} id="tax-return-report">
         <h1 style={{ textAlign: 'left', paddingLeft: 10 }}>Tax return for {yearString}</h1>

         <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>{name} {utr}</h2>

         {/* <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>End of Year Summary</h2>

         <JsonToTable json={eoySummary} /> 

         <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>Taxable Income</h2>

         <JsonToTable json={taxableIncome} />

         <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>Tax and NICs Due</h2>

         <JsonToTable json={taxNics} />

         <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>Allowances, Deductions & Reliefs</h2>

         <JsonToTable json={allowancesDeductionsReliefs} /> */}

         <JsonToTable json={taxCalc} />


         <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
            duration={30000}
         />

      </div>


   )

   // bottom of functional component
}


// class MTDReport extends Component {
//    constructor(props) {
//       super(props);
//       this.state = {
//          eoy_summary: {}
//       };

//    }

//    getReport(token) {
//       var timeStamp = Math.floor(Date.now() / 1000);

//       console.log('get report', token)
//       fetch(`https://${global.api_url}/hmrc/getReportData.php?t=${token}`, {
//          method: "GET"
//       })
//          .then(res => res.json())
//          .then(json => {
//             console.log('here');
//             console.log(json);
//             if (json.status == "OK") {

//                this.setState({
//                   name: json.name, utr: json.utr, year_string: json.tax_year
//                }, () => {
//                   this.parseJson(json.json)
//                })

//             }

//          })
//    }

//    //  getProfile() {
//    //    var timeStamp = Math.floor(Date.now() / 1000);
//    //    var token = localStorage.getItem("token");

//    //    fetch(`https://${global.api_url}/getUserProfile.php?ts=${timeStamp}`, {
//    //      method: "GET",
//    //      headers: { token: token }
//    //    })
//    //      .then(res => res.json())
//    //      .then(json => {
//    //        if (json.error) {
//    //          if (json.error === "unauthorized") {
//    //            //alert('redirect to login page');
//    //            this.props.history.push("/logout/logout");
//    //          }
//    //        } else {
//    //          this.setState({ 
//    //            name: json.name,
//    //            email: json.email,
//    //            nin: json.nin,
//    //            utr: json.utr,
//    //          }, () => this.parseJson());

//    //        }
//    //      });
//    //  }

//    parseJson(json_text) {
//       //var json_text = localStorage.getItem('302Json');
//       var json = JSON.parse(json_text);

//       delete json.eoy_summary.links
//       delete json.taxable_income.links
//       delete json['income-tax-nics-calculated'].links
//       delete json['allowances-deductions-reliefs'].links

//       console.log(json)

//       var bus_pl = json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss;
//       console.log('pl', bus_pl)

//       bus_pl.selfEmployments.forEach(element => {

//          const seId = element.selfEmploymentId

//          json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId] = element;
//          delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['selfEmploymentId'];


//          var lossClaimsSummary = element.lossClaimsSummary;
//          json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Loss Claims Summary for ' + seId] = lossClaimsSummary

//          var lossClaimsDetail = element.lossClaimsDetail;
//          json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Loss Claims Detail for ' + seId] = lossClaimsDetail

//          var Bsas = element.bsas;
//          json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['bsas for ' + element.selfEmploymentId] = Bsas

//          delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['lossClaimsSummary']
//          delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['lossClaimsDetail']
//          delete json.taxable_income.detail.payPensionsProfit.businessProfitAndLoss['Self Employment ' + seId]['bsas'];


//       })

//       json = rename(json, function (key) {
//          if (key == 'studentLoanTotalIncomeAmount') { return 'Total Income' }
//          if (key == 'studentLoanChargeableIncomeAmount') { return 'Chargeable' }
//          if (key == 'studentLoanRepaymentAmount') { return 'Repayment' }
//          if (key == 'studentLoanDeductionsFromEmployment') { return 'Employer Deductions' }
//          if (key == 'studentLoanRepaymentAmountNetOfDeductions') { return 'Repayment Net Deductions' }
//          if (key == 'studentLoanApportionedIncomeThreshold') { return 'Income Threshold' }
//          if (key == 'studentLoanRate') { return 'Rate' }

//          var theKey = key.replace(/([A-Z])/g, ' $1').trim()
//          theKey = theKey.charAt(0).toUpperCase() + theKey.slice(1);
//          return theKey
//       });

//       console.log(json)

//       this.setState({
//          eoy_summary: json.Eoy_summary,
//          taxable_income: json.Taxable_income,
//          tax_nics: json['Income-tax-nics-calculated'],
//          allowances_deductions_reliefs: json['Allowances-deductions-reliefs'],
//          showLoading: false
//       })
//    }

//    componentDidMount() {


//       if (this.props.match != null) {
//          if (this.props.match.params.token) {
//             const token = this.props.match.params.token
//             console.log(token)

//             this.getReport(token)

//          }
//       }
//       // const year_string = localStorage.getItem('302Year') != undefined ? localStorage.getItem('302Year') : '2019-2021';
//       // this.setState({year_string, showLoading:true}, () => this.getProfile())       

//    }



//    render() {



//       return (

//          <div style={styles.page} ref={ref} id="tax-return-report">
//             <h1 style={{ textAlign: 'left', paddingLeft: 10 }}>Tax return (SA302) for {this.state.year_string}</h1>

//             <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>{this.state.name} {this.state.utr}</h2>

//             <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>End of Year Summary</h2>

//             <JsonToTable json={this.state.eoy_summary} />

//             <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>Taxable Income</h2>

//             <JsonToTable json={this.state.taxable_income} />

//             <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>Tax and NICs Due</h2>

//             <JsonToTable json={this.state.tax_nics} />

//             <h2 style={{ textAlign: 'left', paddingLeft: 10 }}>Allowances, Deductions & Reliefs</h2>

//             <JsonToTable json={this.state.allowances_deductions_reliefs} />


//             <IonLoading
//                isOpen={this.state.showLoading}
//                onDidDismiss={() => this.setState({ showLoading: false })}
//                message={'Please wait...'}
//                duration={30000}
//             />

//          </div>


//       )
//    }

// }

export default withRouter(MTDReport)
