import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import { withRouter } from "react-router-dom";
import MiniDrawer from "./../MiniDrawer";
import { Mixpanel } from "../mixpanel";
import Footer from "../components/Footer";
import { IonButton, IonPopover, IonGrid, IonRow, IonCol, IonItem, IonInput, IonAlert } from '@ionic/react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Container } from "@material-ui/core";
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import './Page.css';
import "./../App.css";
import './bank_selector.scss'

import { getTimestamp } from '../components/timeStamp.js'
import { ReconsentModal } from '../components/ReconsentModal.js'

import { useRecoilState } from 'recoil'
import { userAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import moment from "moment";


const Accounts = (props) => {

  const [user_atom, setUserAtom] = useRecoilState(userAtom)
  const [recoilFetched, setRecoilFetched] = useState(false)
  const [linkAccountFromPayment, setLinkAccountFromPayment] = useState({ open: false, data: {} })

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [accountsRowData, setAccountsRowData] = useState([]);
  const [numAccounts, setNumAccounts] = useState(0);
  const numAccountsRef = useRef(null);
  const accountsRef = useRef([])
  const [plan, setPlan] = useState(2);


  const [banks, updateBanks] = useState([])
  const [bank_id, setBank] = useState("")
  const [picUrl, setPicUrl] = useState("");
  const [showAddAccount, setShowAddAccount] = useState(false)
  const [showEditAccount, setShowEditAccount] = useState(false)
  const [editingAccount, setEditingAccount] = useState({})
  const [reconsentInfo, setReconsentInfo] = useState({ show: false, ac_id: 0, name: 'Mock', complete: false })

  const [alert, setAlert] = useState({
    show: false,
    title: "",
    text: "",
    buttonHandler: () => { }
  })

  const addAccountForm = useRef();
  const constants = {
    defaultColDef: {
      resizable: true,
      editable: false,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      flex: 1,
      minWidth: 100,
      suppressMovable: true
    },
    columnDefs: [
      {
        headerName: "",
        field: "logo_url",
        cellClass: "cell-style",
        resizable: false,
        sortable: false,
        filter: false,
        maxWidth: 40,
        cellRenderer: (params) => {
          const logo = params.data.logo_url != null ? params.data.logo_url : "https://api.untied.io/bank_icons/taxberry-bank-icon.svg"
          // let iDiv = document.createElement("div");
          // iDiv.innerHTML = `<img src="${logo}" width=24 height=24 />`
          return <div><img src={logo} width={24} height={24} /></div>
        }
      },
      {
        headerName: "Account",
        field: "simple_name",
        cellClass: "cell-style",
      },
      // {
      //   headerName: "Type",
      //   field: "type",
      //   suppressMovable: false,
      //   cellClass: "cell-style"
      // },
      {
        headerName: "Sort Code",
        field: "sort_code",
        width: 110,
        cellClass: "cell-style",
        valueFormatter: (params) => {
          let sort = params.data.sort_code
          if (sort != null && sort != "00-00-00") {
            return (
              sort.replace(/-/g, "").replace(/(\d\d)(\d\d)(\d\d)/, "$1-$2-$3") + " "
            );
          } else {
            return "";
          }
        }
      },
      {
        headerName: "Account Number",
        field: "ac_number",
        width: 110,
        cellClass: "cell-style"
      },
      {
        headerName: "Currency",
        field: "currency",
        width: 90,
        cellClass: "cell-style"
      },
      {
        headerName: 'Consent',
        field: 'connected',
        width: 90,
        cellClass: "cell-style",
        valueFormatter: (params) => {
          const connected = params.data.connected
          const source = params.data.source
          const enabled = params.data.enabled

          if (enabled == 'N' || source == 'untied') { return "" }


          const item = params.data
          const isCash = item.source === "untied" && item.enabled === "Y" && item.manual === "Y"
          let status = ""
          if (!isCash) {
            status = "Enabled"
            if (item.enabled == "N") {
              //must be 'deleted'
              status = "Removed";
            } else {
              //if source = untied and manual = N
              if (
                (item.source == "untied" && item.manual == "N") ||
                item.token == "ERROR"
              ) {
                //must be unlinked
                status = "Disconnected";
              }
            }
          }

          if (status !== "Disconnected") {

            const connectedDate = moment(connected).add(91, 'days')
            const todayDate = moment(new Date())
            const daysUntil = connectedDate.diff(todayDate, 'days');

            if (daysUntil < 0) {
              return "Expired - please reconsent"
            } else {
              if (daysUntil == 0) {
                return "Expires today"
              } else {
                return "Expires in " + daysUntil + " day" + (daysUntil == 1 ? "" : "s")
              }
            }
          }
        }
      },
      // {
      //   headerName: "Tagged Transactions",
      //   field: "num_trans",
      //   width: 110,
      //   cellStyle: { textAlign: "right" }
      // },
      {
        headerName: "Status",
        field: "enabled",
        width: 110,
        cellClass: "cell-style",
        valueFormatter: (params) => {

          const item = params.data
          const isCash = item.source === "untied" && item.enabled === "Y" && item.manual === "Y"
          let status = ""
          if (!isCash) {
            status = "Enabled"
            if (item.enabled == "N") {
              //must be 'deleted'
              status = "Removed";
            } else {

              const connected = params.data.connected
              const connectedDate = moment(connected).add(91, 'days')
              const todayDate = moment(new Date())
              const daysUntil = connectedDate.diff(todayDate, 'days');

              //if source = untied and manual = N
              if (
                (item.source == "untied" && item.manual == "N") ||
                item.token == "ERROR" || item.token == null || item.token == "" || (daysUntil < 0)
              ) {
                //must be unlinked
                status = "Disconnected";
              }
            }
          }

          return status
        },
        cellStyle: (params) => {
          const item = params.data
          const isCash = item.source === "untied" && item.enabled === "Y" && item.manual === "Y"
          let style = {}

          if (!isCash) {

            style = {
              color: "#0C90B2",
              backgroundColor: "#C7FF00",
              fontWeight: "bold",
              textAlign: "center"
            }


            if (item.enabled == "N") {
              //must be 'deleted'
              style = {
                color: "black",
                backgroundColor: "#F08080",
                textAlign: "center"
              }
            } else {

              const connected = params.data.connected
              const connectedDate = moment(connected).add(91, 'days')
              const todayDate = moment(new Date())
              const daysUntil = connectedDate.diff(todayDate, 'days');

              //if source = untied and manual = N
              if (
                (item.source == "untied" && item.manual == "N") ||
                item.token == "ERROR" || item.token == null || item.token == "" || (daysUntil < 0)
              ) {
                //must be unlinked
                style = {
                  color: "black",
                  backgroundColor: "#F0E68C",
                  textAlign: "center"
                }
              }
            }
          }
          return style
        }
      },
      {
        headerName: "Actions",
        //force width to 1000px

        minWidth: 300,
        field: "id",
        resizable: true,
        filter: false,
        sortable: false,
        cellClass: "cell-style",
        cellRenderer: (params) => {
          if (
            params.data.source === "untied" &&
            params.data.enabled === "Y" &&
            params.data.manual === "Y"
          ) {
            return <></>
          } else {
            // let eDiv = document.createElement("div");                   
            let eDiv = <div></div>

            const editButton = <button class="gridButton btn-rename"
              onClick={() => {
                setEditingAccount(item)
                setShowEditAccount(true)
              }}
            >Rename</button>


            const item = params.data
            const isCash = item.source === "untied" && item.enabled === "Y" && item.manual === "Y"
            let status = ""
            if (!isCash) {
              if (item.enabled == 'Y') {


                var reconsentButton = <button class="gridButton btn-reconsent"
                  onClick={() => {
                    if (item.source === 'truelayer' || item.orig_source === 'truelayer') {
                      services.fetchService.relinkAccount(params);
                    } else {
                      let w = 600;
                      let h = 750;
                      const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
                      const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
                      let child = window.open("", 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);
                      services.fetchService.relinkAccount(params, child);
                    }
                  }}
                >Reconsent</button>

                var disconnectButton = <button class="gridButton btn-unlink"
                  onClick={() => {
                    services.actionsService.unLinkAccount(params);
                  }}
                >Disconnect</button>

                eDiv = <div> {reconsentButton} {disconnectButton} {editButton} </div>

              }

              if (item.enabled == "N") {

                var recoverButton = <button class="gridButton btn-recover"
                  onClick={() => {
                    services.fetchService.deleteAccount(params.value, false, "N", "Y");
                  }}
                >Recover</button>

                var deleteButton = <button class="gridButton btn-delete"
                  onClick={() => {
                    services.fetchService.deleteAccount(params.value, true);
                  }}
                >Delete</button>

                eDiv = <div> {recoverButton} {deleteButton} {editButton} </div>

              } else {
                if (
                  (item.source == "untied" && item.manual == "N") ||
                  (item.token == "ERROR" || item.token == "" || item.token == null)
                ) {

                  var reconnectButton = <button class="gridButton btn-relink"
                    onClick={() => {
                      let w = 600;
                      let h = 750;
                      const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
                      const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
                      let child = window.open("", 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);
                      services.fetchService.relinkAccount(params, child);
                    }}
                  >Reconnect</button>

                  var removeButton = <button class="gridButton btn-remove"
                    onClick={() => {
                      services.fetchService.removeAccount(params.value, true);
                    }}
                  >Remove</button>

                  eDiv = <div> {reconnectButton} {removeButton} {editButton} </div>

                }
              }

            }

            return eDiv;

          }
        }
      },

    ]
  };

  useEffect(() => {
    if (!user_atom.token) {
      let userState = getLocalStorage()
      setUserAtom(userState)
    }
    else {
      setRecoilFetched(true)
    }

  }, [])

  useEffect(() => {
    if (user_atom.token) {
      setRecoilFetched(true)

      if (props.location.state && props.location.state.addAccount) {
        window.history.replaceState({}, document.title)
        //services.fetchService.addAccount();
        setShowAddAccount(true);
      }
      if (props.location.state && props.location.state.reconnectAccount) {
        let ac_info = props.location.state.reconnectAccount
        setLinkAccountFromPayment({ open: true, data: { value: ac_info.value, data: ac_info.data } })
      }

      fetch(`https://${global.api_url}/getProviders.php`,
        {
          method: "GET",
          headers: { token: user_atom.token, agentId: user_atom.agent }
        })
        .then(res => res.json())
        .then(json => {

          var banks = json.providers

          banks.forEach((bank, index) => {
            banks[index].value = bank.id
          })

          updateBanks(banks)
        })


    }
  }, [user_atom])

  useEffect(() => {
    if (linkAccountFromPayment.open) {
      let w = 600;
      let h = 750;
      const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
      const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
      let child = window.open("", 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);
      services.fetchService.relinkAccount(linkAccountFromPayment.data, child)
    }
  }, [linkAccountFromPayment])


  const services = {
    fetchService: {
      getAccounts: () => {
        fetch(
          `https://${global.api_url}/getAccounts.php?ts=${getTimestamp()}&app=web&all=`,
          {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
          }
        )
          .then(res => res.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {
              setAccountsRowData(json.accounts);
              setNumAccounts(json.accounts.length)
              numAccountsRef.current = json.accounts.length
              accountsRef.current = json.accounts
              setPlan(json.plan);
              setRecoilFetched(true)
            }
          });
      },
      getNumberOfAccounts: () => {
        fetch(
          `https://${global.api_url}/getNumberOfAccounts.php?ts=${getTimestamp()}`,
          {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
          }
        )
          .then(res => res.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {

              console.log('number of accounts ', json.numberOfAccounts, numAccountsRef.current)
             
              setNumAccounts(json.numberOfAccounts)
              numAccountsRef.current = json.numberOfAccounts
            
            }
          });
      },

      getAccountStatus: (ac_id) => {
        fetch(
          `https://${global.api_url}/getAccountStatus.php?ts=${getTimestamp()}&account_id=${ac_id}`,
          {
            method: "GET",
            headers: { token: user_atom.token, agentId: user_atom.agent }
          }
        )
          .then(res => res.json())
          .then(json => {
            if (json.error) {
              if (json.error === "unauthorized") {
                props.history.push("/logout/logout");
              }
            } else {



            }
          });
      },
      // addAccount: () => {
      //   Mixpanel.track("add_account_truelayer");
      //   fetch(
      //     `https://${global.api_url}/truelayer/app_connect.php?ts=${getTimestamp()}`,
      //     {
      //       body: `token=${user_atom.token}&redirect=N`,
      //       method: "POST",
      //       headers: {
      //         'Content-Type': 'application/x-www-form-urlencoded'
      //       }
      //     }
      //   )
      //     .then(res => res.json())
      //     .then(json => {
      //       if (json.url !== null) {

      //         Mixpanel.track("add_account_truelayer");

      //         let w = 600;
      //         let h = 750;
      //         const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
      //         const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
      //         let child = window.open(json.url, 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);

      //         let leftDomain = false;
      //         let interval = setInterval(function () {
      //           try {
      //             if (child.document.domain === document.domain) {
      //               if (leftDomain && child.document.readyState === "complete") {
      //                 // we're here when the child window returned to our domain
      //                 clearInterval(interval);
      //                 //child.postMessage({ message: "requestResult" }, "*");
      //               }
      //             }
      //             else {
      //               // this code should never be reached,
      //               // as the x-site security check throws
      //               // but just in case
      //               leftDomain = true;
      //             }
      //           }
      //           catch (e) {
      //             // we're here when the child window has been navigated away or closed
      //             if (child && child.closed) {
      //               clearInterval(interval);

      //               //refresh this page
      //               //window.location.reload();
      //               services.fetchService.getAccounts()
      //               return;
      //             }
      //             // navigated to another domain
      //             leftDomain = true;
      //           }
      //         }, 500);


      //       }
      //     });
      // },
      removeAccount: (ac_id, doDelete) => {
        let url = `https://${global.api_url}/deleteAccount.php?ts=${getTimestamp()}&ac_id=${ac_id}`;

        if (doDelete) {
          url = url + "&delete_transactions=Y";
        }
        let r = false;
        if (doDelete) {
          setAlert({
            show: true,
            title: "Deleting account",
            text: "This account may have claimed transactions that will also be removed. Are you sure you want to disable this account and all related transactions?",
            buttonHandler: () => {
              Mixpanel.track("remove_account_truelayer");
              fetch(url, {
                method: "GET",
                headers: { token: user_atom.token, agentId: user_atom.agent }
              })
                .then(res => res.json())
                .then(json => {
                  if (json.status === "success") {
                    setAccountsRowData([]);
                    setTimeout(
                      function () {
                        services.fetchService.getAccounts();
                      }.bind(this),
                      100
                    );
                  } else {
                    alert("Error deleting account!");
                  }
                });
            }
          })


          //   r = window.confirm(
          //     "This account may have claimed transactions that will also be removed. Are you sure you want to disable this account and all related transactions?"
          //   );
          // } else {
          //   r = true;
          // }

          // if (r === true) {
          //   Mixpanel.track("remove_account_truelayer");
          //   fetch(url, {
          //     method: "GET",
          //     headers: { token: user_atom.token, agentId: user_atom.agent }
          //   })
          //     .then(res => res.json())
          //     .then(json => {
          //       if (json.status === "success") {
          //         setAccountsRowData([]);
          //         setTimeout(
          //           function () {
          //             services.fetchService.getAccounts();
          //           }.bind(this),
          //           100
          //         );
          //       } else {
          //         alert("Error deleting account!");
          //       }
          //     });
        }
      },
      deleteAccount: (ac_id, doDelete = true, full = "Y", recover = "") => {
        let url = `https://${global.api_url}/deleteAccount.php?ac_id=${ac_id}&ts=${getTimestamp}&full_delete=${full}&recover=${recover}`;

        let r = false;

        if (doDelete) {
          url = url + "&delete_transactions=Y";
          setAlert({
            show: true,
            title: "Deleting account",
            text: "This account has claimed transactions. Are you sure you want to permanently remove the account? Any transactions will also be deleted. This operation is irreversible. This may take a few moments to delete depending on the amount of transactions connected with the account.",
            buttonHandler: () => {
              Mixpanel.track("delete_account_truelayer");
              fetch(url, {
                headers: { token: user_atom.token, agentId: user_atom.agent }
              })
                .then(res => res.json())
                .then(json => {
                  if (json.status === "success") {
                    setAccountsRowData([]);
                    services.fetchService.getAccounts();
                    global.account_change = true;
                  } else {
                    setAlert({
                      show: true,
                      title: "Error",
                      text: "Error updating account!"
                    });
                  }
                });
            }
          })
        }
        else {
          fetch(url, {
            headers: { token: user_atom.token, agentId: user_atom.agent }
          })
            .then(res => res.json())
            .then(json => {
              if (json.status === "success") {
                setAccountsRowData([]);
                services.fetchService.getAccounts();
                global.account_change = true;
              } else {
                alert("Error", "Error updating account!");
              }
            });
        }
      },
      discardAccount: (ac_id, doDelete = false, full = "N", recover = "") => {
        let url = `https://${global.api_url}/deleteAccount.php?ac_id=${ac_id}&ts=${getTimestamp()}&full_delete=${full}&recover=${recover}`;

        let r = false;
        if (!doDelete) {


          setAlert({
            show: true,
            title: "Disconnecting account",
            text: "Are you sure you want to disconnect the account from untied, stopping future transactions from syncing, but keeping all current ones?",
            buttonHandler: () => {
              Mixpanel.track("disconnect_account_truelayer");
              fetch(url, {
                headers: { token: user_atom.token, agentId: user_atom.agent }
              })
                .then(res => res.json())
                .then(json => {
                  if (json.status === "success") {
                    setAccountsRowData([]);
                    services.fetchService.getAccounts();
                    global.account_change = true;
                  } else {
                    alert("Error", "Error updating account!");
                  }
                });

            }
          })


          //   r = window.confirm(
          //     "Are you sure you want to disconnect the account from untied, stopping future transactions from syncing, but keeping all current ones?"
          //   );
          // } else {
          //   r = true;
          // }

          // if (r === true) {
          //   Mixpanel.track("disconnect_account_truelayer");
          //   fetch(url, {
          //     headers: { token: user_atom.token, agentId: user_atom.agent }
          //   })
          //     .then(res => res.json())
          //     .then(json => {
          //       if (json.status === "success") {
          //         setAccountsRowData([]);
          //         services.fetchService.getAccounts();
          //         global.account_change = true;
          //       } else {
          //         alert("Error", "Error updating account!");
          //       }
          //     });
        }
      },
      relinkAccount: (item, child = undefined) => {

        let source = item.data.source;
        let orig_source = item.data.orig_source
        let tl_prov_id = item.data.tl_prov_id;
        let enabled = item.data.enabled;
        let ac_id = item.value;
        let tink_prov_name = item.data.tink_prov_name;
        let institution = item.data.institution;
        let url = ""

        if (source === "truelayer" || source === "triodos" || tl_prov_id != null || orig_source === "triodos" || orig_source === "truelayer" || source === "tink" || orig_source === "tink" || source === 'yapily') {

          if (source === "triodos" || orig_source === "triodos") {
            setReconsentInfo({ show: false, ac_id: 0, name: 'Mock', complete: false })
            url = `https://${global.api_url}/triodos/connect_account.php?ac_id=${ac_id}&ts=${getTimestamp()}`
            Mixpanel.track("relink_account_triodos");
          } else {
            if (source === "starling" || orig_source === "starling") {
              setReconsentInfo({ show: false, ac_id: 0, name: 'Mock', complete: false })
              url = `https://${global.api_url}/starling/connect.php?ac_id=${ac_id}&ts=${getTimestamp()}`
              Mixpanel.track("relink_account_starling");
            } else {
              if (source === "tink" || orig_source === "tink") {
                setReconsentInfo({ show: false, ac_id: 0, name: 'Mock', complete: false })
                url = `https://${global.api_url}/tink/connect.php?provider_id=${tink_prov_name}&name=${institution}&credentials_id=${item.data.tink_credentials_id}`
                Mixpanel.track("relink_account_tink");
              } else {
                if (source === "yapily" || orig_source === "yapily") {
                  setReconsentInfo({ show: false, ac_id: 0, name: 'Mock', complete: false })
                  url = `https://${global.api_url}/yapily/connect.php?provider_id=${tl_prov_id}&name=${institution}&consent=${item.data.token}`
                  Mixpanel.track("relink_account_yapily");
                } else {

                  // *** TRUELAYER *** 
                  if (((enabled == "N" || item.data.token == "ERROR" || item.data.token == null) /*&& source != "untied"*/) || reconsentInfo.show) {

                    setReconsentInfo({ show: false, ac_id: 0, name: 'Mock', complete: false })
                    url = `https://${global.api_url}/truelayer/app_relink.php?ac_id=${ac_id}&ts=${getTimestamp()}`
                    if (source === "truelayer" || tl_prov_id != null) {
                      Mixpanel.track("relink_account_truelayer");
                    }

                  } else {

                    setReconsentInfo({ show: true, ac_id: ac_id, bankName: institution, item: item, complete: false })
                    return;
                  }

                }
              }
            }
          }

          fetch(
            url,
            {
              body: `token=${user_atom.token}&redirect=N`,
              method: "POST",
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': user_atom.token
              }
            }
          )
            .then(res => res.json())
            .then(json => {

              setLinkAccountFromPayment({ open: false, data: {} })

              if (child === undefined) {
                let w = 600;
                let h = 750;
                const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
                const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
                child = window.open(json.redirectURL || json.url, 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);
              } else {
                child.location = json.redirectURL || json.url;
              }

              var ts = getTimestamp();

              let checkAccountsAdded = setInterval(() => {
                // services.fetchService.getAccounts();

                fetch(`https://${global.api_url}/getAccountStatus.php?ts=${getTimestamp()}&account_id=${ac_id}`,  {
                  method: "GET",
                  headers: { token: user_atom.token, agentId: user_atom.agent }
                }
              )
                .then(res => res.json())
                .then(json => {

                  console.log('account status info ', json)

                  const newStatus = json.account.source + json.accountenabled + json.accounttoken
                  const numAccounts = json.numberOfAccounts
                  
                  if (numAccounts != origNumAccounts || newStatus != origStatus) {

                    console.log({numAccounts, origNumAccounts, newStatus, origStatus})

                    console.log('number of accounts has changed or status changed - closing window')
                    child.close();
                    clearInterval(checkAccountsAdded)
                    services.fetchService.getAccounts()
                  }

                });


                // // read through accounts and find status of this account
                // var newStatus = "";
                // accountsRef.current.forEach((account) => {
                //   if (account.id === ac_id) {
                //     newStatus = account.source + account.enabled + account.token
                //   }
                // });

                // if ((numAccountsRef.current !== origNumAccounts) || (newStatus !== origStatus)) {
                //   console.log('number of accounts has changed or status changed - closing window')
                //   child.close();
                //   clearInterval(checkAccountsAdded)
                // }

                // if 10 minutes has passed, stop checking
                if (getTimestamp() - ts > 600) {
                  clearInterval(checkAccountsAdded)
                }

              }, 10000); // changed to 10 seconds

              let leftDomain = false;
              let interval = setInterval(function () {
                try {
                  if (child.document.domain === document.domain) {
                    if (leftDomain && child.document.readyState === "complete") {
                      // we're here when the child window returned to our domain
                      clearInterval(interval);
                      clearInterval(checkAccountsAdded);
                      //child.postMessage({ message: "requestResult" }, "*");
                    }
                  }
                  else {
                    // this code should never be reached,
                    // as the x-site security check throws
                    // but just in case
                    leftDomain = true;
                  }
                }
                catch (e) {
                  // we're here when the child window has been navigated away or closed
                  if (child && child.closed) {
                    clearInterval(interval);
                    clearInterval(checkAccountsAdded);
                    console.log('closing window')

                    //refresh this page
                    //window.location.reload();
                    services.fetchService.getAccounts()
                    return;
                  }
                  // navigated to another domain
                  leftDomain = true;
                }
              }, 500);

              // set up interval to check if an account has been added
              var origNumAccounts = numAccountsRef.current;
              var origAccounts = accountsRef.current;

              //get value of Status column for this account in grid
              var origStatus = "";
              origAccounts.forEach((account) => {
                if (account.id === ac_id) {
                  origStatus = account.source + account.enabled + account.token
                }
              });

            

            })
        }

      },
      renameAccount: () => {

        let ac_id = editingAccount.id;
        let nickName = editingAccount.simple_name

        let url = `https://${global.api_url}/setAccountName.php`
        fetch(url, {
          body: JSON.stringify({ id: ac_id, name: nickName }),
          method: "POST",
          headers: {
            token: user_atom.token, 'Content-Type': 'application/json'
          }
        })
          .then((res) => res.json())
          .then((json) => {
            services.fetchService.getAccounts()
          })
        setShowEditAccount(false)

      },
      reconsentAccount: (popupWindow) => {

        const ac_id = reconsentInfo.ac_id

        var timeStamp = Math.floor(Date.now() / 1000);

        fetch(`https://${global.api_url}/truelayer/extend.php`, {
          body: JSON.stringify({ ac_id: ac_id }),
          method: "POST",
          headers: {
            token: user_atom.token, 'Content-Type': 'application/json'
          }
        })
          .then((res) => res.json())
          .then((json) => {

            if (json.action && json.action == 'no_action_needed') {

              //close the popup window - we don't need it
              //you may ask why we created it then - well, we need to create the popup window early on the fitst click to get around the popup blocker
              popupWindow.close()

              // just display a thank you screen!
              setReconsentInfo({ ...reconsentInfo, complete: true })

            } else {

              // resize the popup window ... 
              let w = 600;
              let h = 770;
              const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
              const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
              popupWindow.resizeTo(w, h);
              popupWindow.moveTo(left, top);

              services.fetchService.relinkAccount(reconsentInfo.item, popupWindow)

            }

          });
      },
      revokeConsent: () => {

        const ac_id = reconsentInfo.ac_id
        fetch(`https://${global.api_url}/truelayer/deleteCredentials.php`, {
          body: JSON.stringify({ ac_id: ac_id }),
          method: "POST",
          headers: {
            token: user_atom.token, 'Content-Type': 'application/json'
          }
        })
          .then((res) => res.json())
          .then((json) => {
            services.fetchService.getAccounts()
            setReconsentInfo({ ac_id: 0, item: {}, complete: false, show: false })
          });
      }
      //   addTide: () => {
      //     Mixpanel.track("add_account_truelayer");
      //     fetch(
      //       `https://ob-tide.untied.io/get-auth-url`,
      //       {
      //         method: "GET",
      //         headers: {
      //           "Content-Type": "application/json",
      //           token: user_atom.token, agentId: user_atom.agent
      //         }
      //       }
      //     )
      //       .then(res => res.json())
      //       .then(json => {
      //         if (json.url) {
      //           window.open(json.url, '_new');
      //         }
      //       });
      //   }
    },
    actionsService: {
      prettyDateTime: (dateToProcess) => {
        let date1 = new Date(dateToProcess);
        let longDate = date1.toDateString();
        let timePart = date1.toLocaleTimeString();
        return longDate + " " + timePart;
      },
      openInNewTab: (url) => {
        let win = window.open(url, "_blank");
        win.focus();
      },
      unLinkAccount: (item) => {
        let enabled = item.data.enabled;
        let ac_id = item.value;
        Mixpanel.track("unlink_account_truelayer");
        if (enabled === "Y") {
          // we are recovering ...
          services.fetchService.discardAccount(ac_id);
          return;
        } else {
          alert("Account already disconnected!");
        }
      }
    },
    gridService: {
      onGridReady: (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        //   params.api.sizeColumnsToFit();
        services.fetchService.getAccounts();
      },
      onSelectionChanged: () => {
        let rowCount = gridApi.getSelectedRows().length;
        let showMultiList = false;
        let typeSelected = "";
        if (rowCount > 0) {
          const selectedGridRows = gridApi.getSelectedRows();
          typeSelected = selectedGridRows[0].type;
          selectedGridRows.forEach(element => {
            if (!showMultiList) {
              showMultiList = element.type !== typeSelected;
            }
          });
        }
      }
    },
    renderService: {
      displayMainScreen: () => {
        if (recoilFetched) {
          return (<>
            <IonAlert
              isOpen={alert.show}
              onDidDismiss={() => setAlert({
                show: false,
                title: "",
                text: "",
                buttonHandler: () => { }
              })}
              backdropDismiss={false}
              header={alert.title}
              // subHeader={'Information required'}
              message={alert.text}
              buttons={alert.title !== "Error" ? [
                {
                  text: 'Cancel',
                  handler: () => {
                    setAlert({
                      show: false,
                      title: "",
                      text: "",
                      buttonHandler: () => { }
                    })
                  }
                },
                {
                  text: 'Confirm',
                  handler: () => {
                    alert.buttonHandler()
                  }
                }
              ] : ["OK"]}
            />
            <div
              style={{
                height: 48,
                flexDirection: "row",
                display: "flex",
                paddingLeft: 0,
                paddingBottom: 7
              }}
            >

              <IonButton className="ion-button-money"
                onClick={() => {
                  if (Number(plan) === 2) {
                    //addAccountForm.current.submit();
                    //services.fetchService.addAccount()
                    setShowAddAccount(true)
                  } else {
                    //window.location = '/profile'
                    props.history.push('/plan', { state: "Manage your plan" })
                  }
                }}
              >
                {Number(plan) === 2 ? "Add Account" : "To add accounts upgrade to Pro"}
              </IonButton>
            </div>
            <div className={`ag-theme-alpine grid-container ${1 === 2 ? "hide-grid" : "show-grid"}`}>
              <AgGridReact
                modules={AllCommunityModules}
                defaultColDef={constants.defaultColDef}
                rowData={accountsRowData}
                columnDefs={constants.columnDefs}
                animateRows
                rowHeight={30}
                rowSelection={"multiple"}
                pagination={false}
                rowDeselection={true}
                suppressRowClickSelection={true}
                suppressDragLeaveHidesColumns={true}
                onSelectionChanged={() => services.gridService.onSelectionChanged()}
                onGridReady={services.gridService.onGridReady}
              />
            </div>
          </>)
        }
      },
      displayAccountList: () => {

        return (
          <IonPopover
            className="my-ion-submit-type-popover"
            isOpen={showAddAccount}
            onDidDismiss={() => setShowAddAccount(false)}
          >
            <div className="hmrc-payment">
              <Container>

                <IonGrid>
                  <IonRow>
                    <IonCol>

                      <IonItem className="input-container bank-selector-container">
                        <Autocomplete
                          {...bankSelectorProps}
                          className="bank-selector"
                          autoComplete
                          includeInputInList
                          onChange={(e, value) => {
                            if (value) {
                              setBank(value.provider_id)
                              getPicUrl(value.provider_id);
                            } else {
                              setBank("")
                              setPicUrl("")
                            }
                          }}
                          renderInput={(params) => {
                            return (<div className={`display-inline-flex ${bank_id != "" ? "selected" : ""}`}>
                              <div className={bank_id != "" ? "display-flex" : "display-none"}>
                                <div style={{
                                  height: "20px",
                                  width: "20px",
                                  backgroundImage: `url(${picUrl})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  marginTop: "auto",
                                  marginBottom: "6px",
                                  marginRight: "10px"
                                }} />
                              </div>
                              <TextField {...params} label="Choose your bank" placeholder="Search and select" margin="normal" />
                            </div>)
                          }}
                          renderOption={(option) => {
                            return (
                              <div>
                                <span style={{
                                  height: "20px",
                                  width: "20px",
                                  backgroundImage: `url(${option.icon_url})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  display: "inline-block",
                                  verticalAlign: "middle"
                                }} />
                                <span style={{ marginLeft: "20px" }}>{option.name}</span>
                              </div>
                            )
                          }}
                        />
                      </IonItem>

                    </IonCol>
                  </IonRow>

                  <IonRow>


                    <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                      <IonButton color="black" style={{ color: 'black' }}
                        onClick={() => {
                          setShowAddAccount(false)
                        }}
                      >
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                      <IonButton className="ion-button-money" disabled={bank_id === ""}
                        onClick={() => {

                          let w = 600;
                          let h = 770;
                          const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
                          const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
                          let child = window.open('', 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);

                          connectSelectedAccount(child)
                        }}>
                        Add account
                      </IonButton>
                    </IonCol>

                  </IonRow>


                </IonGrid>
              </Container>
            </div>
          </IonPopover>
        )
      },
      displayAccountEdit: () => {
        return (
          <IonPopover
            className="my-ion-submit-type-popover"
            isOpen={showEditAccount}
            onDidDismiss={() => setShowEditAccount(false)}
          >
            <div className="hmrc-payment">
              <Container>

                <IonGrid>
                  <IonRow>
                    <IonCol>

                      <IonItem className="input-container bank-selector-container">
                        <IonInput value={editingAccount.simple_name}
                          onIonChange={e => setEditingAccount({ ...editingAccount, simple_name: e.target.value })}
                          placeholder="Description" inputmode="text" type="text" />
                      </IonItem>

                    </IonCol>
                  </IonRow>

                  <IonRow>


                    <IonCol className="ion-align-self-center" style={{ textAlign: 'left' }}>
                      <IonButton color="black" style={{ color: 'black' }}
                        onClick={() => {
                          setShowEditAccount(false)
                        }}
                      >
                        Cancel
                      </IonButton>
                    </IonCol>
                    <IonCol className="ion-align-self-center" style={{ textAlign: 'right' }}>
                      <IonButton className="ion-button-money"
                        onClick={() => {
                          services.fetchService.renameAccount()
                        }}>
                        Save
                      </IonButton>
                    </IonCol>

                  </IonRow>


                </IonGrid>
              </Container>
            </div>
          </IonPopover>
        )
      },
      displayFooter: () => {
        return (
          <Footer />
        );
      }
    }
  };

  const getPicUrl = (id) => {
    const obj = banks.filter((bank) => (bank.provider_id === id))[0];
    setPicUrl(obj.icon_url);
  };

  const bankSelectorProps = {
    options: banks,
    getOptionLabel: (banks) => banks.name,
  };

  const connectSelectedAccount = (child) => {

    if (bank_id === "") return;

    setShowAddAccount(false)
    const obj = banks.filter((bank) => (bank.provider_id === bank_id))[0];
    const url_getter = obj.auth_url_getter

    fetch(url_getter, {
      method: "GET",
      headers: { token: user_atom.token },
    })
      .then((res) => res.json())
      .then((json) => {
        child.location = json.url;

        console.log('here before pop up')

        // set up interval to check if an account has been added
        var origNumAccounts = numAccountsRef.current;
        let checkAccountsAdded = setInterval(() => {
          services.fetchService.getNumberOfAccounts();
          if (numAccountsRef.current != origNumAccounts) {
            console.log('number of accounts has changed - closing window')
            services.fetchService.getAccounts()
            child.close();
            clearInterval(checkAccountsAdded)
          }
        }, 5000);

        let leftDomain = false;
        let interval = setInterval(function () {
          try {
            if (child.document.domain === document.domain) {
              if (leftDomain && child.document.readyState === "complete") {
                // we're here when the child window returned to our domain
                clearInterval(interval);
                clearInterval(checkAccountsAdded)
                //child.postMessage({ message: "requestResult" }, "*");
              }
            }
            else {
              // this code should never be reached,
              // as the x-site security check throws
              // but just in case
              leftDomain = true;
            }
          }
          catch (e) {
            // we're here when the child window has been navigated away or closed
            if (child && child.closed) {
              clearInterval(interval);
              clearInterval(checkAccountsAdded)
              console.log('I closed')

              //refresh this page
              //window.location.reload();
              services.fetchService.getAccounts()
              return;
            }
            // navigated to another domain
            leftDomain = true;
          }
        }, 500);




      })

  }



  return (
    <React.Fragment>
      <div className="accounts-page-container">
        <MiniDrawer />
        <div className="accounts-page-content">
          <div>
            <form
              action={`https://${global.api_url}/truelayer/app_connect.php`}
              method="post"
              target="_new"
              ref={addAccountForm}
            >
              <input type="hidden" name="token" value={user_atom.token} />
            </form>
          </div>
          <ReconsentModal show={reconsentInfo.show} complete={reconsentInfo.complete} ac_id={reconsentInfo.ac_id}
            onDidDismiss={() => {
              setReconsentInfo({ show: false, ac_id: 0, name: 'Mock', complete: false })
            }}
            onRevoke={() => {
              services.fetchService.revokeConsent()
            }}
            bankName={reconsentInfo.bankName} onConfirm={(child) => services.fetchService.reconsentAccount(child)} />
          {services.renderService.displayAccountList()}
          {services.renderService.displayAccountEdit()}
          {services.renderService.displayMainScreen()}

        </div>
      </div>
    </React.Fragment>
  );
};


export default withRouter(Accounts)
