import React, { useState, useRef, useEffect } from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { IonGrid, IonRow, IonCol, IonModal, IonAlert } from '@ionic/react';
import { Alert } from "@material-ui/lab";
import { RestaurantMenu } from "@material-ui/icons";

const buttonStyles = ({
    consentButton: {
        alignItems: "center",
        justifyContent: "center",
        borderRadius: (120),
        backgroundColor: "#0c90b2",
        height: (40),
        elevation: 3,
        marginRight: 15,
        display: 'flex'
    },
    consentButtonText: {
        //color: "#000000",
        //color: "#ffffff",
        color: "#ffffff",
        fontFamily: "Helvetica Neue",
        fontSize: (16),
        fontWeight: "500",
        letterSpacing: (0.48),
        textAlign: "center"
    },
})


export const ReconsentModal = (props) => {

    const [ac_id, setAcId] = useState(props.ac_id)
    const [isOpen, setIsOpen] = useState(props.show)
    const [saving, setSaving] = useState(false)
    const [bankName, setBankName] = useState(props.bankName ?? "Mock Bank")
    const [showSection, setShowSection] = useState(0)
    const [complete, setComplete] = useState(false)
    const [revokeConfirm, setRevokeConfirm] = useState(false)

    useEffect(() => {
        setIsOpen(props.show) 
        setBankName(props.bankName)
        setAcId(props.ac_id)
        setComplete(props.complete)
    }, [props.bankName, props.ac_id, props.show, props.complete])

    useEffect(() => {
        // call props.onDidDismiss when modal is closed
        if (isOpen === false) {
            if (props.onDidDismiss) {
                setComplete(false)
                props.onDidDismiss()
            }
        }
    }, [isOpen])


    const revokeAccount = () => {
        if (props.onRevoke) {
            props.onRevoke()
        }
        setRevokeConfirm(false)
    }

    const doConfirm = () => {

        //create a child window
        let w = 200;
        let h = 200;
        const top = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
        const left = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
        var child = window.open("/buffer.html", 'Popup_Window', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=' + w + ', height=' + h + ', top = ' + top + ', left = ' + left);

        var isDone = false;
        if (props.onConfirm) {
            isDone = props.onConfirm(child)
        }
        if (isDone) {
            setComplete(true)
        }
        
    }

    return (
        <IonModal
            isOpen={isOpen}
            backdropDismiss={true}
            onDidDismiss={() => setIsOpen(false)}
            className="reconsent-modal">
            <div style={{ flex: 1, backgroundColor: "white", paddingTop: 12 }}>
                <div
                    style={{ backgroundColor: "#ffffff", alignItems: "center", flex: 1 }}
                >
                    <div>

                        <div style={{ paddingLeft: 15, paddingRight: 15, width: '100%' }}>

                            <p style={{
                                color: '#A00338', fontWeight: '500',
                                alignSelf: 'center', textAlign: 'center',
                                fontSize: (24)
                            }}>{complete ? "Thank you for reconsenting!" : "untied needs your consent"}</p>

                            <div style={{ alignSelf: 'center' }}>

                                <p style={{ marginRight: 5, marginLeft: 5, textAlign: 'center' }}>

                                    {bankName}

                                </p>

                                <p style={{

                                    fontSize: (16),
                                    fontWeight: '600',
                                    paddingLeft: 20, paddingRight: 20,
                                    textAlign: 'center',
                                }}>{complete ? ("We will continue to import data from " + bankName) : "Every 90 days, we need your permission to securely import and update your data."}
                                </p>

                            </div>

                            {!complete &&
                                <div style={{ overflowX: 'hidden', overflowY: 'scroll', height: 230, paddingBottom: 100 }}>

                                    <div style={{ height: 1, width: '100%', borderTopWidth: 1, backgroundColor: "#E0E6ED" }} />

                                    <IonGrid onClick={() => setShowSection(showSection == 1 ? 0 : 1)} style={{ cursor: 'pointer' }}>
                                        <IonRow>
                                            <IonCol size="11" style={{ fontWeight: '600', fontSize: 16 }}>
                                                How does it work?
                                            </IonCol>
                                            <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                {showSection === 1 ? <CloseIcon /> : <ArrowDropDownIcon />}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>

                                    {showSection === 1 && (
                                        <IonGrid style={{ paddingTop: 0 }}>
                                            <IonRow>
                                                <IonCol size="12" style={{ paddingTop: 0 }}>
                                                    <p>
                                                        When you click Confirm we will continue to securely import your account transactions. If necessary you may be transferred to your bank to re-authenticate. If you choose to revoke consent we will remove the connection but will keep your existing transactions.
                                                    </p>
                                                    <p>untied is a registered account information services provider regulated by the Financial Conduct Authority. FRN 910169</p>
                                                    <p>Connections are powered by TrueLayer who you may see mentioned in subsequent pages.</p>
                                                </IonCol>

                                            </IonRow>
                                        </IonGrid>)}

                                    <div style={{ height: 1, width: '100%', borderTopWidth: 1, backgroundColor: "#E0E6ED" }} />

                                    <IonGrid onClick={() => setShowSection(showSection === 2 ? 0 : 2)} style={{ cursor: 'pointer' }}>
                                        <IonRow>
                                            <IonCol size="11" style={{ fontWeight: '600', fontSize: 16 }}>
                                                What data am I sharing?
                                            </IonCol>
                                            <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                {showSection === 2 ? <CloseIcon /> : <ArrowDropDownIcon />}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>

                                    {showSection === 2 && (
                                        <IonGrid style={{ paddingTop: 0 }}>
                                            <IonRow>
                                                <IonCol size="12" style={{ paddingTop: 0 }}>
                                                    <p>
                                                        untied will securely import and update the following information to help manage your taxes:
                                                    </p>

                                                    <p>
                                                        <ul>
                                                            <li>The list of your accounts</li>
                                                            <li>Transaction anounts and descriptions</li>
                                                            <li>Balance information which helps untied to check the completeness of your transaction data</li>
                                                        </ul>
                                                    </p>

                                                </IonCol>

                                            </IonRow>
                                        </IonGrid>)}

                                    <div style={{ height: 1, width: '100%', borderTopWidth: 1, backgroundColor: "#E0E6ED" }} />

                                    <IonGrid onClick={() => setShowSection(showSection === 3 ? 0 : 3)} style={{ cursor: 'pointer' }}>
                                        <IonRow>
                                            <IonCol size="11" style={{ fontWeight: '600', fontSize: 16 }}>
                                                How is my data used?
                                            </IonCol>
                                            <IonCol style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                {showSection === 3 ? <CloseIcon /> : <ArrowDropDownIcon />}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>

                                    {showSection === 3 && (
                                        <IonGrid style={{ paddingTop: 0 }}>
                                            <IonRow>
                                                <IonCol size="12" style={{ paddingTop: 0 }}>
                                                    <p>untied will use your data to help you identify income and expenses, and find tax savings for you</p>
                                                    <p>Balance information is used to check the completeness of your transaction data.</p>
                                                </IonCol>

                                            </IonRow>
                                        </IonGrid>)}

                                </div>
                            }

                            <div style={{ flex: 1, position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }}>

                                {complete &&
                                    <div style={{ width: "80%", margin: 'auto', cursor: 'pointer' }} onClick={() => setIsOpen(false)} >
                                        <div style={{ ...buttonStyles.consentButton, width: '100%' }}>
                                            <p style={buttonStyles.consentButtonText}>Close</p>
                                        </div>
                                    </div>}

                                {!complete &&
                                    <div style={{ width: "80%", margin: 'auto', cursor: 'pointer' }} onClick={() => doConfirm()} >
                                        <div style={{ ...buttonStyles.consentButton, width: '100%' }}>
                                            <p style={buttonStyles.consentButtonText}>Confirm consent</p>
                                        </div>
                                    </div>}

                                {!complete &&
                                    <div style={{ width: "80%", margin: 'auto', marginTop: 10, cursor: 'pointer' }} onClick={() => setRevokeConfirm(true)}>
                                        <div style={{ ...buttonStyles.consentButton, backgroundColor: '#848484', width: '100%', opacity: 0.5 }}>
                                            <p style={buttonStyles.consentButtonText}>Revoke consent</p>
                                        </div>
                                    </div>}

                                <div style={{
                                    marginTop: 20,
                                    alignSelf: 'center', textAlign: 'center',
                                    fontSize: (12)
                                }}>
                                    Regulated by the FCA (FRN 910169)</div>

                                <div style={{
                                    alignSelf: 'center', textAlign: 'center',
                                    paddingBottom: (20),
                                    fontSize: (12)
                                }}><a href='https://www.untied.io/untied-privacy-policy/' target='_new'>Privacy Policy</a>{" "}
                                    <a href='https://www.untied.io/untied-app-terms-conditions/' target="_new">Terms of Service</a></div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <IonAlert
                isOpen={revokeConfirm}
                onDidDismiss={() => setRevokeConfirm(false)}
                header={"Revoke consent"}
                message={"Are you sure you want to revoke consent?"}
                buttons={
                    [
                        {
                            cssClass: 'pointer',
                            text: "No",
                            handler: () => {
                                // alert("Don't worry, nothing has changed!");
                                setRevokeConfirm(false)
                            }
                        },
                        {
                            cssClass: 'pointer',
                            text: "Yes, please",
                            handler: () => revokeAccount()
                        }

                    ]

                }

            />

        </IonModal>)
}
