import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import { withRouter } from "react-router-dom";
import { IonButton, IonPopover, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol, IonItem, IonSelect, IonSelectOption, IonInput, IonLoading, IonLabel, IonDatetime, IonModal, IonText } from '@ionic/react';

import { TextField, Select, MenuItem, Button, Modal, Backdrop, Fade } from "@material-ui/core";
import MiniDrawer from "./../MiniDrawer";
import Footer from "../components/Footer";
import { Dots } from "react-activity";

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import "react-activity/dist/react-activity.css";
import './Page.css';
import { getTimestamp } from '../components/timeStamp.js'
import CloseIcon from '@material-ui/icons/Close';


import { useRecoilState } from 'recoil'
import { userAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { TagSelector } from "../components/TagSelector.js";



// let formDropdownItems = {
//     ruleOps: [],
//     taxCats: []
// };
let delRuleId = null;


const Rules = (props) => {

    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [recoilFetched, setRecoilFetched] = useState(false)

    const [formDropdownItems, setFormDropdownItems] = useState({
        ruleOps: [],
        taxCats: []
    })

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rulesRowData, setRulesRowData] = useState([]);
    const [accountsRowData, setAccountsRowData] = useState([]);
    const [showTagSelector, setShowTagSelector] = useState(false)
    const [rulesParams, setRulesParams] = useState({
        ruleOps: [],
        taxCats: [],
        id: "",
        desc_op_id: "1",
        account_id: "0",
        description: "",
        type_id: 'E',
        classification: "",
        class_op_id: "1",
        tax_cat_id: 11,
        tax_cat_title: "",
        percentage: '100%',
        desc_op_title: "",
        class_op_title: "",
        account_name: "",
        type_title: "",
        apply_to_existing: false
    });
    const [counts, setCounts] = useState({
        taxCat: 0,
        rowCount: 0,
        accountsConnected: 0,
        totalVisible: 0,
        rowTotal: 0
    });
    const [flags, setFlags] = useState({
        readyForGrid: false,
        saving: false,
        allTransWarning: false,
        alertOpen: false,
        deleteSingleRuleWarning: false,
        addRuleOpen: false,
        deleteRuleWarning: false
    });
    const constants = {
        defaultColDef: {
            resizable: true,
            editable: false,
            sortable: true,
            filter: true,
            floatingFilter: true,
            suppressMenu: true,
            flex: 1,
            suppressMovable: true
        },
        columnDefs: [
            {
                suppressResize: false,
                maxWidth: 40,
                filter: false,
                cellStyle: { textAlign: "center" },
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                resizable: false
            },
            {
                field: "id",
                hide: true
            },
            {
                headerName: "Account",
                field: "account_name",
                suppressMovable: true,
                cellStyle: { textAlign: "left" },
                width: 80
            },
            {
                headerName: "Transaction",
                field: "trans_rule",
                suppressMovable: true,
                cellStyle: { textAlign: "left" },
                width: 100,
                valueGetter: params => params.data.trans_rule.replace('Transaction ', ''),
            },
            {
                headerName: "Description",
                field: "description_rule",
                suppressMovable: true,
                cellStyle: { textAlign: "left" },
                width: 100,
                valueGetter: params => params.data.description_rule.replace('Description ', ''),
            },
            // {
            //     headerName: "Classification",
            //     field: "classification_rule",
            //     suppressMovable: true,
            //     cellStyle: { textAlign: "left" },
            //     width: 100,
            //     valueGetter: params => params.data.classification_rule.replace('Classification ', ''),
            // },
            {
                headerName: "Apply",
                field: "percentage",
                suppressMovable: true,
                cellStyle: { textAlign: "right" },
                width: 50,
                valueFormatter: params => params.value + '%'
            },
            {
                headerName: "Tag as",
                width: 150,
                field: "taxcat",
                suppressMovable: true,
                cellStyle: { textAlign: "left" },

            },
            {
                headerName: "Actions",
                checkboxSelection: false,
                suppressMovable: true,
                filter: false,
                sortable: false,
                cellRenderer: (params) => {

                    return <div>
                        <button className="gridButton" id="edit_rule" onClick={() => services.gridService.onRowDoubleClicked(params.node)}>Edit</button>
                        &nbsp;&nbsp;    
                        <button className="gridButton" id="del_rule" onClick={() => {
                            setFlags((flags) => ({ ...flags, deleteSingleRuleWarning: true }));
                            delRuleId = params.node.data.id;
                        }}
                          >Delete</button>
                    </div>

                },
                suppressResize: true,
                resizable: false,
                width: 110
            }
        ]
    };

    const currentTaxYearPeriod = () => {
        let todaysDate = new Date
        let currentYear = todaysDate.getFullYear()
        let fifthApr = new Date(`04/05/${currentYear}`)
        let year_period
        if (todaysDate < fifthApr){
            year_period = `${currentYear-1} - ${currentYear}`
        }
        else{
            year_period = `${currentYear} - ${currentYear+1}`
        }
        return year_period
    }

    const customActionSheetOptions = {
        cssClass: "rules-action-sheet",
      };

    const close = {
        color: "white",
        height: "40px",
        width: "auto",
        cursor: "pointer"
      }

    const labelLeft = {
        display: "block", 
        color: "rgba(0, 0, 0, 0.38)", 
        fontSize: 12, 
        width: "100%", 
        paddingLeft: "16px", 
        textAlign: "left"
      }

    const borderBox = {
        "border": "1px solid #3f90b0", 
        borderRadius: "3px"
    }

    useEffect(() => {
        if (!user_atom.token) {
            let userState = getLocalStorage()
            setUserAtom(userState)
        }
        else {
            setRecoilFetched(true)
        }
    }, [])
    useEffect(() => {
      //rulesParams)
    }, [rulesParams])

    useEffect(() => {
        if (user_atom.token) {
            setRecoilFetched(true)
        }
    }, [user_atom])

    useEffect(()=> {
        if(props.location.state && formDropdownItems.ruleOps.length > 0  && formDropdownItems.taxCats.length > 0){
            let id = formDropdownItems.ruleOps.find(d => d.description == props.location.state.addRule.desc_op).id
            let expenses = rulesParams.taxCats.filter(t => t.type =='E')
            let tag = expenses.find(e => e.title.toLowerCase().includes(props.location.state.addRule.tag))
            setRulesParams({ ...rulesParams, id: -1, desc_op_id: id, description: props.location.state.addRule.description, apply_to_existing: true, tax_cat_id: tag.id })
            setFlags({...flags, addRuleOpen: true})
        }
    }, [props, formDropdownItems])

    useEffect(()=> {
     
        if(formDropdownItems.ruleOps.length > 0  && formDropdownItems.taxCats.length == 0){
            services.fetchService.getTaxCats();
        }
    }, [formDropdownItems])



    const rulesTable = () => {
        let options = formDropdownItems.ruleOps.map((item) => ({ key: item.id, label: item.description }));
        let accounts = [{ key: "0", label: "Any" }];

        accountsRowData.forEach(function (element) {
     
            accounts.push({ key: element.id, label: element.simple_name,  ac: element.ac_number, sort_code: element.sort_code});
        });

        let tax_cats = [];
        

        formDropdownItems.taxCats.forEach(element => {
            if (element.type == rulesParams.type_id) {
                tax_cats.push({ key: element.id, label: element.title, title: element.title, type: element.type, description:element.description, id: element.id, tax_cat_id: element.tax_cat_id, custom_tag_id: element.custom_tag_id });
            }
        });

        const types = [
            { key: "E", label: "expense" },
            { key: "I", label: "income" }
        ];
        return(

            <>
                    <TagSelector 
                        taxCats={tax_cats}
                        type={rulesParams.type_id}
                        show={showTagSelector} 
                        onDidDismiss={() => setShowTagSelector(false)} 
                        onClick={(item) => {
                            
                            setRulesParams((rulesParams) => ({ ...rulesParams, tax_cat_id: item.tax_cat_id, custom_tag_id: item.custom_tag_id }))
                            setShowTagSelector(false)
                        }} 
                    />

            <IonModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            isOpen={flags.addRuleOpen}
            onDidDismiss={() => setFlags((flags) => ({ ...flags, addRuleOpen: false }))}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            className = "no-outline"
            style={{
                outline: "none !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "50vw",
                margin: "auto",
                minWidth: 400
            }}
        >
            <Fade in={flags.addRuleOpen}>
            <IonGrid 
            className = "no-outline" 
            style={{ marginBottom: 5, outline: "none !important", "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px', backgroundColor: "#FFFFFF", "--ion-grid-padding": "0px", borderRadius: "5px 5px 0px 0px",}}>
              <IonRow style = {{backgroundColor : "#3f90b0", borderRadius: "5px 5px 0px 0px", }}>
              <div style ={{width: "100%", display: "flex", flexDirection: "row", fontFamily:"Roboto, Helvetica, Arial, sans-serif"}}> 
                <h2 style = {{marginLeft: "10px", color: "white"}}> {rulesParams.id === -1 ? "Add rule" : "Edit rule"}</h2> 
                <div style={{alignSelf:"center", alignItem: "flex-end", paddingRight: "10px", marginLeft: "auto"}}>  
                <CloseIcon
                      style={close}
                      onClick={() => {
                        setFlags((flags) => ({ ...flags, addRuleOpen: false }))
                      }}
                  />
                </div>
              </div>
              
              </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel style = {labelLeft}>when account is</IonLabel>
                        <IonItem lines="none" style = {borderBox}>
                        <IonSelect 
                        placeholder = "Any"
                        value={rulesParams.account_id}
                        style={{ fontWeight: 600 }}
                        interfaceOptions ={customActionSheetOptions}
                        interface="action-sheet"
                        onIonChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, account_id: e.detail.value }))}>
                        {accounts.map(item => (
                            <IonSelectOption key={item.key} value={item.key}>{item.label} {item.ac} {item.sort_code}</IonSelectOption>
                        ))}
                        </IonSelect>
                        {/* <Select value={rulesParams.account_id}
                        style={{ fontWeight: 600 }}
                        onChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, account_id: e.target.value }))}>
                        {accounts.map(item => (
                            <MenuItem key={item.key} value={item.key}>{item.label} {item.ac} {item.sort_code}</MenuItem>
                        ))}
                    </Select> */}
                        </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonLabel style = {labelLeft}>and transaction type is</IonLabel>
                      <IonItem lines="none" style = {borderBox}>

                        <IonSelect value={rulesParams.type_id}
                            style={{ fontWeight: 600 }}
                            interfaceOptions ={customActionSheetOptions}
                            interface="action-sheet"
                            onIonChange={(e) => {
                                services.actionsService.getDefaultCatId(e.target.value);
                                setRulesParams((rulesParams) => ({ ...rulesParams, type_id: e.detail.value }))
                            }}
                        >
                            {types.map(item => (
                            <IonSelectOption key={item.key} value={item.key}>{item.label}</IonSelectOption>
                            ))}
                            </IonSelect>
                        {/* <Select value={rulesParams.type_id}
                            style={{ fontWeight: 600 }}
                            onChange={(e) => {
                                services.actionsService.getDefaultCatId(e.target.value);
                                setRulesParams((rulesParams) => ({ ...rulesParams, type_id: e.target.value }))
                            }}
                        >
                            {types.map(item => (
                            <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
                            ))}
                            </Select> */}
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonLabel style = {labelLeft}> and description</IonLabel>
                        <IonItem lines="none" style = {borderBox}>
                        <IonSelect 
                            value={rulesParams.desc_op_id}
                            style={{ fontWeight: 600 }}
                            placeholder= {"is whatever"}
                            interfaceOptions ={customActionSheetOptions}
                            interface="action-sheet"
                            onIonChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, desc_op_id: e.detail.value }))}>
                            {options.map(item => (
                            <IonSelectOption key={item.key} value={item.key}>{item.label}</IonSelectOption>
                            ))}
                        </IonSelect>
                   
                        </IonItem>
               
                    </IonCol>
                    
                    {Number(rulesParams.desc_op_id) !== 1 && (<IonCol>
                        <IonLabel style = {labelLeft}> description contents</IonLabel>
                        <IonItem lines="none" style = {borderBox}>
                                <IonInput
                                    inputProps={{ style: { textAlign: 'left', fontWeight: 400} }}
                                    style ={{width: "100%"}}
                                    value={rulesParams.description}
                                    onIonChange={e => {
                                        // e.persist();
                                        setRulesParams((rulesParams) => ({ ...rulesParams, description: e.detail.value }));
                                    }}
                                />
                    </IonItem>        
                    </IonCol>)}
                </IonRow>

                <IonRow>

                </IonRow>

                <IonRow>
                <IonCol>
                      <IonLabel style = {labelLeft}>then claim and tag transaction as</IonLabel>
                      <IonItem lines="none" style = {borderBox}>
                         {/* <IonSelect value={rulesParams.tax_cat_id}
                            style={{ fontWeight: 600 }}
                            interfaceOptions ={customActionSheetOptions}
                            interface="action-sheet"
                            placeholder={"tag transaction"}
                            onIonChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, tax_cat_id: e.detail.value }))}>
                             {tax_cats.map(item => (
                                 <IonSelectOption key={item.key} value={item.key}>{item.label}</IonSelectOption>
                            ))}
                         </IonSelect> */}

                        <IonInput value={tax_cats.find(el => el.id === rulesParams.tax_cat_id) ? tax_cats.find(el => el.id === rulesParams.tax_cat_id).title : "Select"} readonly onClick={() => {
                                        setShowTagSelector(true)
                                        //setTagClickFunction("filterbar")
                                    }}
                                    className="my-tax-selector-input left-indent">
                                    <div className="my-caret-dropdown-icon" />
                        </IonInput>
                        
                        </IonItem>
                    </IonCol>
                    <IonCol>
                      <IonLabel style = {labelLeft}> and allocate </IonLabel>
        
                      <IonItem lines="none" style = {borderBox}>
                         <IonInput
                             value={rulesParams.percentage}
                            //  inputProps={{ style: { textAlign: 'center', fontWeight: 600 } }}
                             style={{width: "100%"}}
                             onIonChange={e => {
                                 const value = e.detail.value;
                                 let newVal = value;
                                 let lastChar = value[value.length - 1];
                                 if (value !== "") {
                                     let v = value.replace("%", "");
                                     if (!isNaN(parseFloat(v)) && isFinite(Number(v))) {
                                         if (Number(v) < 101) {
                                             newVal = v;
                                             if (lastChar === "%") {
                                                 newVal = v + "%";
                                             }
                                         } else {
                                             newVal = "100%";
                                         }
                                     } else {
                                         newVal = "100%";
                                     }
                                 }
                                 setRulesParams((rulesParams) => ({ ...rulesParams, percentage: newVal }))
                             }}
                             onBlur={() => {
                                 let v = rulesParams.percentage;
                                 if (v === "") {
                                     v = "100%";
                                 } else {
                                     v = rulesParams.percentage.replace("%", "") + "%";
                                 }
                                 setRulesParams((rulesParams) => ({ ...rulesParams, percentage: v }))
                             }}
                         />

                        </IonItem>
                    </IonCol>
               
                </IonRow>

        
                <IonRow>
                <IonCol style = {{maxWidth: "49%"}}>
                      <IonLabel style = {labelLeft}> apply to existing untagged transactions in current year ({currentTaxYearPeriod()})?</IonLabel>
        
                      <IonItem lines="none" style = {borderBox}>

                         <IonSelect value={rulesParams.apply_to_existing}
                            style={{ fontWeight: 600 }}
                            interfaceOptions ={customActionSheetOptions}
                            interface="action-sheet"
                            placeholder={"tag transaction"}
                            onIonChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, apply_to_existing: e.detail.value }))}>
                             {/* <IonSelectOption key={1} value={true}>Yes, apply to existing transactions for this year</IonSelectOption> */}
                             <IonSelectOption key={1} value={true}>Yes, apply to existing untagged transactions in current year</IonSelectOption>
                             <IonSelectOption key={2} value={false}>No, only new ones</IonSelectOption>
                         </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol style ={{textAlign: "left", marginTop: "auto", width: "50%", padding: 1.5}}>
                     {flags.saving ? (
                        <div
                                            style = {{
                                                marginBottom: "10px",
                                                marginLeft: "10px"
                                                
                                                }}>
                                            <Dots />
                                            </div>
                     ) : (
                             <IonButton className="ion-button-money"
                                style = {{minWidth: 200, minHeight: 50}}

                                 onClick={() => {
                                    //   do something ...
                                        // if ((rulesParams.desc_op_id != "1" && rulesParams.description === "") || (rulesParams.class_op_id != "1" && rulesParams.classification === "")) {
                                        if (rulesParams.desc_op_id != "1" && rulesParams.description === "") {
                                            setFlags((flags) => ({ ...flags, alertOpen: true }))
                                            }
                                        else{
                                            services.actionsService.saveRuleConfirm()
                                        }
                                 }}
                             >
                                 {rulesParams.id === -1 ? "make rule" : "save rule"}
                             </IonButton>)}
                    </IonCol>
                </IonRow>
            </IonGrid>
            </Fade>
    </IonModal>
    </>
        )
    };

    const services = {
        fetchService: {
            getRules: () => {

                fetch(
                    `https://${global.api_url}/getUserRules.php?ts=${getTimestamp()}&app=web&all=`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } else {
                            setRulesRowData(json.rules);
                            setFlags((flags) => ({ ...flags, readyForGrid: true }));
                        }
                    });
            },
            getAccounts: () => {

                fetch(
                    `https://${global.api_url}/getAccounts.php?ts=${getTimestamp()}`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } else {
                            setAccountsRowData(json.accounts);
                        }
                    });
            },
            getRuleOps: () => {
                fetch(
                    `https://${global.api_url}/getRuleOps.php?ts=${getTimestamp()}`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } else {
                            setRulesParams((rulesParams) => ({ ...rulesParams, ruleOps: json.rule_ops }));
                            setFormDropdownItems({...formDropdownItems, ruleOps: json.rule_ops})
                           
                        }
                    });
            },
            getTaxCats: () => {
                fetch(
                    `https://${global.api_url}/getTaxCategories.php?ts=${getTimestamp()}`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        if (json.error) {
                            if (json.error === "unauthorized") {
                                props.history.push("/logout/logout");
                            }
                        } else {
                            setRulesParams((rulesParams) => ({ ...rulesParams, taxCats: json.tax_categories }));
                            setFormDropdownItems({...formDropdownItems, taxCats: json.tax_categories})
                        }
                    });
            },
            saveRule: () => {
                if (
                    (Number(rulesParams.desc_op_id) !== 1 && rulesParams.description === "") 
                   /* || (Number(rulesParams.class_op_id) !== 1 && rulesParams.classification === "") */
                ) {
                    setFlags((flags) => ({ ...flags, saving: false, alertOpen: true }));
                }

                const rule = {
                    id: rulesParams.id,
                    account: rulesParams.account_id,
                    type_id: rulesParams.type_id,
                    tax_cat: rulesParams.tax_cat_id,
                    custom_tag_id: rulesParams.custom_tag_id,
                    desc_op: rulesParams.desc_op_id,
                    desc_val: rulesParams.desc_op_id !== 1 ? rulesParams.description : "",
                    class_op: rulesParams.class_op_id,
                    class_val: rulesParams.class_op_id !== 1 ? rulesParams.classification : "",
                    percentage: rulesParams.percentage,
                    apply_to_existing: rulesParams.apply_to_existing
                };
                
                fetch(
                    `https://${global.api_url}/createRule.php`,
                    {
                        method: "POST",
                        body: JSON.stringify(rule),
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        services.fetchService.getRules();
                        setFlags((flags) => ({ ...flags, addRuleOpen: false, saving: false }));
                    });
            },
            deleteSelectedRules: () => {
                let ndx = 0;
                gridApi.getSelectedRows().forEach(node => {
                    const id = node.id;
                    ndx = ndx + 1;

                    fetch(
                        `https://${global.api_url}/deleteRule.php?ts=${getTimestamp()}&rule_id=${id}`,
                        {
                            method: "GET",
                            headers: { token: user_atom.token, agentId: user_atom.agent }
                        }
                    )
                        .then(res => res.json())
                        .then(json => {

                            if (ndx === counts.rowCount) {
                                services.fetchService.getRules();
                                gridApi.redrawRows();
                                gridApi.deselectAll();
                            }
                        });
                });
            },
            deleteRule: () => {
                fetch(
                    `https://${global.api_url}/deleteRule.php?ts=${getTimestamp()}&rule_id=${delRuleId}`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {

                        services.fetchService.getRules();
                        gridApi.redrawRows();
                        gridApi.deselectAll();

                    });
            }
        },
        actionsService: {
            prettyDateTime: (dateToProcess) => {
                let date1 = new Date(dateToProcess);
                let longDate = date1.toDateString();
                let timePart = date1.toLocaleTimeString();
                return longDate + " " + timePart;
            },
            openInNewTab: (url) => {
                let win = window.open(url, "_blank");
                win.focus();
            },
            getDefaultCatId: (type) => {
                let code = "TRAI";
                let title = "Trading Income";
                let cat_id = 0;
                if (type === "E") {
                    code = "TRAE";
                    title = "Trading Expense";
                }
                rulesParams.taxCats.forEach(function (element) {
                    if (element.code === code) {
                        cat_id = element.id;
                    }
                });
                setRulesParams((rulesParams) => ({ ...rulesParams, tax_cat_id: cat_id, tax_cat_title: title }))
            },
            saveRuleConfirm: () => {
                if (
                    Number(rulesParams.account_id) === 0 &&
                    Number(rulesParams.desc_op_id) === 1 &&
                    Number(rulesParams.class_op_id) === 1
                ) {
                    setFlags((flags) => ({ ...flags, allTransWarning: true }));
                } else {
                    setFlags((flags) => ({ ...flags, saving: true }));
                    services.fetchService.saveRule();
                }
            },
            setText: () => {
                if (rulesParams.desc_op_id != 1 && rulesParams.description === "") {
                    return "description.";
                }
                if (rulesParams.class_op_id != 1 && rulesParams.classification === "") {
                    return "classification";
                }
            }
        },
        gridService: {
            onGridReady: (params) => {
                setGridApi(params.api);
                setGridColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
                services.fetchService.getRules();
                services.fetchService.getAccounts();
                services.fetchService.getRuleOps();

                if (props.location.state && props.location.state.addRuleOpen) {
                    const data = props.location.state;
                    setRulesParams((rulesParams) => ({
                        ...rulesParams,
                        id: data.id,
                        desc_op_id: data.desc_op_id,
                        account_id: data.account_id,
                        description: data.description,
                        type_id: data.type_id,
                        classification: "",
                        class_op_id: 1,
                        tax_cat_id: 11,
                        percentage: '100%'
                    }));
                    setFlags((flags) => ({ ...flags, addRuleOpen: true }));
                }
            },
            onSelectionChanged: () => {
                let rowCount = gridApi.getSelectedRows().length;
                let showMultiList = false;
                let typeSelected = "";
                if (rowCount > 0) {
                    const selectedGridRows = gridApi.getSelectedRows();
                    typeSelected = selectedGridRows[0].type;
                    selectedGridRows.forEach(element => {
                        if (!showMultiList) {
                            showMultiList = element.type !== typeSelected;
                        }
                    });
                }
                setCounts((counts) => ({ ...counts, taxCat: 0, rowCount: rowCount }));
            },
            onRowDoubleClicked: (row) => {
                const rowData = row.data;
                console.log(rowData)
                setRulesParams({
                    ruleOps: rulesParams.ruleOps,
                    taxCats: rulesParams.taxCats,
                    id: rowData.id,
                    desc_op_id: rowData.description_op,
                    account_id: rowData.account_id,
                    description: rowData.description_value,
                    type_id: rowData.trans_type,
                    classification: rowData.classification_value,
                    class_op_id: rowData.classification_op,
                    tax_cat_id: rowData.tax_cat_id,
                    tax_cat_title: rowData.taxcat,
                    desc_op_title: rowData.desc_op_title,
                    class_op_title: rowData.class_op_title,
                    account_name: rowData.account_name,
                    type_title: rowData.trans_rule_title,
                    percentage: rowData.percentage + "%",
                    apply_to_existing: rulesParams.apply_to_existing
                });
                setFlags((flags) => ({ ...flags, addRuleOpen: true }));
            }
        },
        renderService: {
            displayMainScreen: () => {
                return (<>
                    {!flags.readyForGrid && <Dots />}
                    <div className={`ag-theme-alpine grid-container ${!flags.readyForGrid ? "hide-grid" : "show-grid"}`}>
                        <AgGridReact
                            modules={AllCommunityModules}
                            defaultColDef={constants.defaultColDef}
                            columnDefs={constants.columnDefs}
                            animateRows
                            rowHeight={30}
                            rowSelection={"multiple"}
                            rowDeselection={true}
                            rowData={rulesRowData}
                            suppressRowClickSelection={true}
                            suppressDragLeaveHidesColumns={true}
                            onRowDoubleClicked={row => services.gridService.onRowDoubleClicked(row)}
                            onSelectionChanged={() => services.gridService.onSelectionChanged()}
                            onGridReady={services.gridService.onGridReady}
                        />                    
                    </div>
                    {/* {flags.addRuleOpen && services.renderService.displayRuleForm()} */}
                    {flags.addRuleOpen && rulesTable()}

                </>)
            },
            displayRuleForm: () => {
                let options = formDropdownItems.ruleOps.map((item) => ({ key: item.id, label: item.description }));
    
                let accounts = [{ key: 0, label: "Any" }];
                accountsRowData.forEach(function (element) {
   
                    accounts.push({ key: element.id, label: element.simple_name,  ac: element.ac_number, sort_code: element.sort_code});
                });

                let tax_cats = [];
         
                formDropdownItems.taxCats.forEach(element => {
                    if (element.type === rulesParams.type_id) {
                        tax_cats.push({ key: element.id, label: element.title,});
                    }
                });

                const types = [
                    { key: "E", label: "expense" },
                    { key: "I", label: "income" }
                ];

                return (
                    <div
                        style={{
                            borderColor: "blue",
                            float: "right",
                            textAlign: "right",
                            flex: 1,
                            paddingTop: 10
                        }}
                    >

                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={flags.addRuleOpen}
                            onClose={() => setFlags((flags) => ({ ...flags, addRuleOpen: false }))}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Fade in={flags.addRuleOpen}>
                                <div style={{
                                    backgroundColor: 'white',
                                    border: "2px solid #14A7CC",
                                    boxShadow: 5,
                                    padding: 10,
                                    width: 600,
                                    textAlign: 'center',
                                    fontSize: 16,
                                    height: "97%",
                                    overflowY: 'auto'
                                }}>
                                    <h2 id="transition-modal-title" style={{ color: "#14A7CC" }}>
                                        {rulesParams.id === -1 ? "Create Rule" : "Edit Rule"}
                                    </h2>
                                    <p>
                                        when account is
                                    </p>

                                    <div>
                                        <Select value={rulesParams.account_id}
                                            style={{ fontWeight: 600 }}
                                            onChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, account_id: e.target.value }))}>
                                            {accounts.map(item => (
                                                <MenuItem key={item.key} value={item.key}>{item.label} {item.ac} {item.sort_code}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    <p>
                                        and transaction type is
                                    </p>

                                    <div>
                                        <Select value={rulesParams.type_id}
                                            style={{ fontWeight: 600 }}
                                            onChange={(e) => {
                                                services.actionsService.getDefaultCatId(e.target.value);
                                                setRulesParams((rulesParams) => ({ ...rulesParams, type_id: e.target.value }))
                                            }}
                                        >
                                            {types.map(item => (
                                                <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    <p>
                                        and description
                                    </p>

                                    <div>
                                        <Select 
                                            value={rulesParams.desc_op_id}
                                            style={{ fontWeight: 600 }}
                                            onChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, desc_op_id: e.target.value }))}>
                                            {options.map(item => (
                                                <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    {Number(rulesParams.desc_op_id) !== 1 && (
                                        <TextField
                                            inputProps={{ style: { textAlign: 'center', fontWeight: 600 } }}
                                            value={rulesParams.description}
                                            onChange={e => {
                                                e.persist();
                                                setRulesParams((rulesParams) => ({ ...rulesParams, description: e.target.value }));
                                            }}
                                        />
                                    )}

                                    <p>
                                        and classification
                                    </p>

                                    <div>
                                        <Select value={rulesParams.class_op_id}
                                            style={{ fontWeight: 600 }}
                                            onChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, class_op_id: e.target.value }))}>
                                            {options.map(item => (
                                                <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    {Number(rulesParams.class_op_id) !== 1 && (
                                        <TextField
                                            inputProps={{ style: { textAlign: 'center', fontWeight: 600 } }}
                                            value={rulesParams.classification}
                                            onChange={e => {
                                                e.persist();
                                                setRulesParams((rulesParams) => ({ ...rulesParams, classification: e.target.value }))
                                            }}
                                        />
                                    )}

                                    <p>claim and tag transaction as</p>
                                    <div>
                                        <Select value={rulesParams.tax_cat_id}
                                            style={{ fontWeight: 600 }}
                                            onChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, tax_cat_id: e.target.value }))}>
                                            {tax_cats.map(item => (
                                                <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    <p>allocating</p>

                                    <TextField
                                        value={rulesParams.percentage}
                                        inputProps={{ style: { textAlign: 'center', fontWeight: 600 } }}
                                        onChange={e => {
                                            const value = e.target.value;
                                            let newVal = value;
                                            let lastChar = value[value.length - 1];
                                            if (value !== "") {
                                                let v = value.replace("%", "");
                                                if (!isNaN(parseFloat(v)) && isFinite(Number(v))) {
                                                    if (Number(v) < 101) {
                                                        newVal = v;
                                                        if (lastChar === "%") {
                                                            newVal = v + "%";
                                                        }
                                                    } else {
                                                        newVal = "100%";
                                                    }
                                                } else {
                                                    newVal = "100%";
                                                }
                                            }
                                            setRulesParams((rulesParams) => ({ ...rulesParams, percentage: newVal }))
                                        }}
                                        onBlur={() => {
                                            let v = rulesParams.percentage;
                                            if (v === "") {
                                                v = "100%";
                                            } else {
                                                v = rulesParams.percentage.replace("%", "") + "%";
                                            }
                                            setRulesParams((rulesParams) => ({ ...rulesParams, percentage: v }))
                                        }}
                                    />

                                    <p>Apply to existing transactions in current year?</p>

                                    <Select value={rulesParams.apply_to_existing}
                                        style={{ fontWeight: 600 }}
                                        onChange={(e) => setRulesParams((rulesParams) => ({ ...rulesParams, apply_to_existing: e.target.value }))}>
                                        <MenuItem key={1} value={true}>Yes, apply to existing transactions for this year</MenuItem>
                                        <MenuItem key={2} value={false}>No, only new ones</MenuItem>
                                    </Select>

                                    <div style = {{marginTop: "15px"}}>
                                        {flags.saving ? (
                                            <div
                                            style = {{
                                                marginBottom: "10px",
                                                marginLeft: "10px"
                                                
                                                }}>
                                            <Dots />
                                            </div>
                                        ) : (
                                                <IonButton className="ion-button-money"

                                                    onClick={() => {
                                                        // do something ...
                                                        services.actionsService.saveRuleConfirm()
                                                    }}
                                                >
                                                    {rulesParams.id === -1 ? "make rule" : "save rule"}
                                                </IonButton>)}
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div >
                );
            },
            displayFooter: () => {
                return (
                    <Footer />
                );
            },
            displayAlerts: () => {
                return (<>
                    <IonAlert
                        isOpen={flags.deleteSingleRuleWarning}
                        onDidDismiss={() => setFlags((flags) => ({ ...flags, deleteSingleRuleWarning: false }))}
                        header={"Warning"}
                        message={"Are you sure you want to delete this rule?"}
                        buttons={[
                            {
                                text: 'No',
                                role: 'cancel',
                                cssClass: 'secondary'
                            },
                            {
                                text: 'Yes',
                                handler: () => {
                                    services.fetchService.deleteRule();
                                }
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={flags.allTransWarning}
                        onDidDismiss={() => setFlags((flags) => ({ ...flags, allTransWarning: false }))}
                        header={"Warning"}
                        message={"This rule will claim ALL types in ANY account. Are you sure you want to continue?"}
                        buttons={[
                            {
                                text: 'No',
                                role: 'cancel',
                                cssClass: 'secondary'
                            },
                            {
                                text: 'Yes',
                                handler: () => {
                                    services.fetchService.saveRule();
                                }
                            }
                        ]}
                    />

                    <IonAlert
                        isOpen={flags.alertOpen}
                        onDidDismiss={() => setFlags((flags) => ({ ...flags, alertOpen: false }))}
                        header={'Something is missing'}
                        message={`Please enter something to match the ${services.actionsService.setText()}`}
                        buttons={['OK']}
                    />
                </>)
            }
        }
    };

    const displayMain = () => {
        if (recoilFetched) {
            return (
                <>
                    <React.Fragment>
                        <div className="rules-page-container">
                            <MiniDrawer />
                            <div className="rules-page-content">
                                <div style={{
                                    height: 48,
                                    flexDirection: "row",
                                    display: "flex",
                                    paddingLeft: 0,
                                    paddingBottom: 7
                                }}>
                                    <IonButton className="ion-button-money"
                                        onClick={() => {
                                            setRulesParams((rulesParams) => ({
                                                ...rulesParams,
                                                account_id: 0,
                                                type_id: 'E',
                                                desc_op_id: 1,
                                                description: "",
                                                class_op_id: 1,
                                                classification: "",
                                                tax_cat_id: 11,
                                                percentage: '100%',
                                                apply_to_existing: false,
                                                id: -1
                                            }));
                                            setFlags((flags) => ({ ...flags, addRuleOpen: true }));
                                        }}
                                    >
                                        Add Rule
                        </IonButton>
                                    {counts.rowCount > 0 && (
                                        <span style={{ paddingLeft: 0 }}>

                                            <IonButton className="ion-button-money"
                                                onClick={() => {
                                                    setFlags((flags) => ({ ...flags, deleteRuleWarning: true }))
                                                }}>Delete</IonButton>

                                            <IonAlert
                                                isOpen={flags.deleteRuleWarning}
                                                onDidDismiss={() => setFlags((flags) => ({ ...flags, deleteRuleWarning: false }))}
                                                header={"Warning"}
                                                message={"Are you sure you want to delete the selected rules?"}
                                                buttons={[
                                                    {
                                                        text: 'No',
                                                        role: 'cancel',
                                                        cssClass: 'secondary'
                                                    },
                                                    {
                                                        text: 'Yes',
                                                        handler: () => {
                                                            services.fetchService.deleteSelectedRules();
                                                        }
                                                    }
                                                ]}
                                            />

                                        </span>
                                    )}
                                </div>
                                {services.renderService.displayAlerts()}
                                {services.renderService.displayMainScreen()}
                            
                            </div>
                        </div>
                    </React.Fragment>
                </>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="rules-page-container">
                        <MiniDrawer />
                    </div>
                </React.Fragment>
            )
        }
    }


    return (displayMain());

};


const styles = {
    card: {
        minWidth: 875
    },

    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    itIs: {
        color: "#c7ff00",
        fontSize: 18,
        fontWeight: "500",
        letterSpacing: 0.54,
        textAlign: "center",
        alignSelf: "center"
    },
    buttonContainer: {
        paddingVertical: 12,
        paddingLeft: 15,
        paddingRight: 15,
        height: 44,
        borderRadius: 2,
        color: "#c7ff00",
        backgroundColor: "#14A7CC",
        textTransform: "none",
        fontSize: 18,
        fontWeight: "700"
    },
    btn: {
        color: "green"
    }
};


export default withRouter(Rules)
