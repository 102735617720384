import React, { useState, useEffect, Component } from "react";
import { AgGridReact } from "ag-grid-react";
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
// import TextField from '@material-ui/core/TextField';
import { withRouter } from "react-router-dom";
import MiniDrawer from "./../MiniDrawer";
import { Mixpanel } from "../mixpanel";
// @material-ui stuff

import { Redirect } from "react-router-dom";
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";
//import { PieChart } from 'react-minimal-pie-chart';
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from 'recharts';
import { IonGrid, IonRow, IonCol, IonContent } from '@ionic/react';


import { SelectionBar } from '../components/selectionBar.js';

import { useRecoilState } from 'recoil'
import { userAtom, taxYearsAtom } from "../atom.js"
import { getLocalStorage } from '../components/localstorage.js'
import { getTimestamp } from '../components/timeStamp.js'



export class makeRuleRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            claimed: "N"
        };
        this.handleButtonPress = this.handleButtonPress.bind(this);
        this.handleButtonPress2 = this.handleButtonPress2.bind(this);
    }

    handleButtonPress(event) {
        console.log(this.props.data);
        const state = {
            id: -1,
            addRuleOpen: true,
            description: this.props.data.description,
            account_id: this.props.data.ac_id,
            desc_op_id: 3,
            type_id: this.props.data.type.substring(0, 1).toUpperCase()
        }
        this.props.history.push({
            pathname: '/rules',
            state: state
        })
    }

    handleButtonPress2(event) {
        const state = {
            descriptionFilter: this.props.data.description,
        }
        this.props.history.push({
            pathname: '/transactions',
            state: state
        })
    }

    render() {

        return (
            <span>
                <button onClick={this.handleButtonPress} className="btn-simple">
                    make rule
            </button>{" "}
                <button onClick={this.handleButtonPress2} className="btn-simple">
                    view items
            </button>
            </span>
        );

    }
    logUserIn(id) {
        //
    }
} // end makeRuleRenderer







const Insights = (props) => {
    const [ready, setReady] = useState(true)
    const [averageTotalIncomeMonthly, setAverageTotalIncomeMonthly] = useState(0)
    const columnDefs = [
        {
            headerName: "Description",
            field: "description",
            cellStyle: { textAlign: "left" }
        },
        {
            headerName: "Account",
            field: "ac_name",
            cellStyle: { textAlign: "left" },
            width: 100
        },
        { field: "account_id", hide: true },

        {
            headerName: "Type",
            field: "type",
            //cellStyle: { textAlign: "left" }

            cellStyle: function (params, node) {
                if (params.data.type === "income") {
                    return {
                        color: "#0C90B2",
                        backgroundColor: "#C7FF00",
                        fontWeight: "bold",
                        textAlign: "center"
                    };
                } else {
                    return {
                        textAlign: "center"
                    }
                }
            },
            width: 100,
        },
        {
            headerName: "Frequency",
            field: "frequency",
            cellStyle: { textAlign: "center" },
            width: 100,
        },

        {
            headerName: "Average Amount",
            field: "average_amount",
            cellStyle: { textAlign: "right" },
            width: 120,
            valueFormatter: params => {

                return (
                    "£" +
                    Number(Math.abs(params.value))
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                );

            },
        },
        {
            headerName: "",
            //field: "checkbox",
            checkboxSelection: false,
            suppressFilter: true,
            cellRendererFramework: makeRuleRenderer,
            cellRendererParams: {
                history: props.history
            },
            suppressSorting: true,
            suppressResize: true,
            width: 110
        },
    ]
    const [insights, setInsights] = useState([
        {
            ac_name: "loading...",
            account_id: 0,
            type: "loading...",
            description: "loading...",
            frequency: "loading...",
            average_amount: 0
        }
    ])
    const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)

    const [user_atom, setUserAtom] = useRecoilState(userAtom)
    const [recoilFetched, setRecoilFetched] = useState(false)

    const [taxCalc, setTaxCalc] = useState({})
    const [taxCategories, setTaxCategories] = useState([])
    const [taxTotals, setTaxTotals] = useState([])
    const [updated, setUpdated] = useState(null)
    const [viewGrid, setViewGrid] = useState(null)
    const [viewChart, setViewChart] = useState(null)
    const [rowCount, setRowCount] = useState(null)
    const [showMultiList, setShowMultilist] = useState(null)
    const [typeSelected, setTypeSelected] = useState(null)
    const [taxCat, setTaxCat] = useState(null)
    const [descriptionFilter, setDescriptionFilter] = useState(null)
    const [toTransactions, setToTransactions] = useState(null);
    const [value, setValue] = useState(props.value)
    const [claimed, setClaimed] = useState("N")

    useEffect(() => {
        if (!recoilFetched) {
            if (!user_atom.token || user_atom.agent !== "") {
                let userState = getLocalStorage()
                setUserAtom({
                    ...userState,
                    agent: user_atom.agent
                })

                getRecoilTaxYears(userState.token, userState.agent)
            }
            else if (JSON.stringify(tax_years_atom) === "{}") {
                getRecoilTaxYears()
            }
            else {
                setRecoilFetched(true)
            }
        }
    }, [])


    useEffect(() => {
        if (recoilFetched) {
            //getInsights();
            getTaxData();
        }
    }, [recoilFetched])

    useEffect(()=>{
        if (recoilFetched){
            getTaxData();
        }
    }, [tax_years_atom])

    const idClicked = (id) => {
        window.alert("Account clicked: " + id);
    }


    const makeRuleRenderer = (props) => {

        const handleButtonPress = () => {
            console.log(props.data);
            const state = {
                id: -1,
                addRuleOpen: true,
                description: props.data.description,
                account_id: props.data.ac_id,
                desc_op_id: 3,
                type_id: props.data.type.substring(0, 1).toUpperCase()
            }
            props.history.push({
                pathname: '/rules',
                state: state
            })
        }

        const handleButtonPress2 = () => {
            const state = {
                descriptionFilter: props.data.description,
            }
            props.history.push({
                pathname: '/transactions',
                state: state
            })
        }
        return (
            <span>
                <button onClick={handleButtonPress()} className="btn-simple">
                    make rule
                </button>{" "}
                <button onClick={handleButtonPress2()} className="btn-simple">
                    view items
                </button>
            </span>
        );

    }

    const getRecoilTaxYears = (passed_token = user_atom.token, passed_agentId = user_atom.agentId) => {
        if (JSON.stringify(tax_years_atom) === "{}"){
        fetch(`https://${global.api_url}/getTaxYears.php?ts=${getTimestamp()}`, {
            method: "GET",
            headers: { token: passed_token, agentId: passed_agentId }
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    let today = new Date();
                    let currentYearID = 0;
                    let dateFilterStart = "";
                    let dateFilterEnd = "";
                    json.years.forEach(element => {
                        const start = new Date(element.start_date);
                        const end = new Date(element.end_date);

                        if (today >= start && today <= end) {
                            currentYearID = element.id;
                            dateFilterStart = element.start_date;
                            dateFilterEnd = element.end_date;
                        }
                    });
                    setTaxYearsAtom(
                        {
                            taxYears: json.years,
                            dateFilterStart: dateFilterStart,
                            dateFilterEnd: dateFilterEnd,
                            dateFilter: currentYearID
                        });
                    if (!recoilFetched) {
                        setRecoilFetched(true)
                    }
                }
            });
        }
        else {
            if (!recoilFetched) {
                setRecoilFetched(true)
            }
        }
    }

    const getTaxData = () => {

        // tax_years_atom.taxYears.forEach((element) => {
        //     const start_date = new Date(element.start_date);
        //     const end_date = new Date(element.end_date);
        //     const today = new Date();

        //     if (today >= start_date && today <= end_date) {
        //         //found tax year ... 
        //         const tax_year_id = element.id;

                fetch(`https://${global.api_url}/getTaxCalculation.php?tax_year_id=${tax_years_atom.dateFilter}`,
                    {
                        method: "GET",
                        headers: { token: user_atom.token, agentId: user_atom.agent }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        console.log(json);
                        setTaxCalc(json);


                        fetch(`https://${global.api_url}/getTaxCategories.php`,
                            { method: "GET", headers: { token: user_atom.token, agentId: user_atom.agent } })
                            .then(res => res.json())
                            .then(json => {

                                console.log('tax cats:', json.tax_categories)
                                setTaxCategories(json.tax_categories);

                                fetch(`https://${global.api_url}/getClaimedTransForReview.php?from=${tax_years_atom.dateFilterStart}&to=${tax_years_atom.dateFilterEnd}`,
                                    { method: "GET", headers: { token: user_atom.token, agentId: user_atom.agent } })
                                    .then(res => res.json())
                                    .then(json => {

                                        console.log('tax totals:', json.transaction_totals)
                                        setTaxTotals(json.transaction_totals);

                                    });

                            });


                    })

            // }
        // }
        // )


        // });
    }

    const getInsights = () => {
        fetch(
            `https://${global.api_url}/truelayer/getInsightsCached.php?ts=${getTimestamp()}&app=web&all=`,
            {
                method: "GET",
                headers: { token: user_atom.token, agentId: user_atom.agent }
            }
        )
            .then(res => res.json())
            .then(json => {
                console.log(json);
                if (json.error) {
                    if (json.error === "unauthorized") {
                        //alert('redirect to login page');
                        props.history.push("/logout/logout");
                    }
                } else {
                    setInsights(json.insights)
                    setReady(true)
                    setAverageTotalIncomeMonthly(json.average_total_income_monthly)
                    setUpdated(json.updated)

                    if (props.location.state !== undefined) {
                        if (props.location.state.doGrid !== undefined) {
                            setViewGrid(true);
                        }
                    }
                }
            });
    }



    const openInNewTab = (url) => {
        let win = window.open(url, "_blank");
        win.focus();
    }

    // const onSelectionChanged = (event) => {
    //     //get the row count and initialise
    //     let rowCount = gridApi.getSelectedRows().length;
    //     let showMultiList = false;
    //     let typeSelected = "";
    //     if (rowCount > 0) {
    //         const selectedRows = gridApi.getSelectedRows();
    //         typeSelected = selectedRows[0].type;
    //         //see if all rows have the same type
    //         selectedRows.forEach(element => {
    //             if (!showMultiList) {
    //                 showMultiList = element.type !== typeSelected;
    //             }
    //         });
    //     }

    //     //set state - ONCE
    //     setRowCount(rowCount)
    //     setShowMultilist(showMultiList)
    //     setTypeSelected(typeSelected)
    //     setTaxCat(0)
    // }

    // const onGridReady = (params) => {
    //     gridApi = params.api;
    //     columnApi = params.columnApi;
    //     //getInsights();
    //     gridApi.sizeColumnsToFit();
    // }

    // setFilter = name => event => {
    //     this.setState({
    //         [name]: event.target.value
    //     });
    //     this.gridApi.setQuickFilter(event.target.value);
    // };



    const prettyDateTime = (dtstr) => {
        if (dtstr == null) {
            return "";
        }
        let date1 = new Date(dtstr);
        let longdate = date1.toDateString();
        // let timepart = "";
        let timepart = date1.toLocaleTimeString();
        return longdate + " " + timepart;
    }

    // prettyDateTime(dtstr) {

    //     if (dtstr == null) {
    //         return "";
    //     }

    //     // Split timestamp into [ Y, M, D, h, m, s ]
    //     var t = dtstr.split(/[- :]/);

    //     // Apply each element to the Date function
    //     var date1 = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

    //     var longdate = date1.toDateString();
    //     // var timepart = "";
    //     var timepart = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //     return longdate + " at " + timepart;
    // }

    const doCharts = () => {

        if (viewGrid) {
            return;
        }

        if (!viewChart || viewChart == 0) {
            return (

                < IonContent >
                    <IonGrid>
                        <IonRow>
                            {/* <IonCol onClick={() => this.setState({ dochart: 1 })} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">

                                {this.doBankIncomeExpenditureChart(false)}

                            </IonCol> */}
                            <IonCol onClick={() => setViewChart(2)} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">
                                {doBankTaggedIncomeVsExpenditureChart(false)}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol onClick={() => setViewChart(3)} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">
                                {doTagsChart(false, 'E', 'expenditure')}</IonCol>
                            <IonCol onClick={() => setViewChart(4)} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">
                                {doTagsChart(false, 'I', 'income')}</IonCol>
                        </IonRow>
                    </IonGrid>

                </IonContent >

            )
        }

        if (viewChart == 1) {
            return doBankIncomeExpenditureChart(true, true);
        }
        if (viewChart == 2) {
            return doBankTaggedIncomeVsExpenditureChart(true, true);
        }
        if (viewChart == 3) {
            return doTagsChart(true, 'E', 'expenditure', 3, true)
        }
        if (viewChart == 4) {
            return doTagsChart(true, 'I', 'income', 4, true)
        }

    }

    // convert three r,g,b integers (each 0-255) to a single decimal integer (something between 0 and ~16m)
    const colourToNumber = (r, g, b) => {
        return (r << 16) + (g << 8) + (b);
    }

    // convert it back again (to a string)
    const numberToColour = (number) => {
        const r = (number & 0xff0000) >> 16;
        const g = (number & 0x00ff00) >> 8;
        const b = (number & 0x0000ff);

        //return [r, g, b];
        return `rgb(${r},${g},${b})`;
    }

    const showOpenClose = (showclose, chart) => {
        return (
            <div>
                {showclose && (
                    <p onClick={() => setViewChart(0)} style={{ position: 'absolute', top: 70, right: 10, cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
                    </p>
                )}
                {!showclose && (
                    <p onClick={() => setViewChart(chart)} style={{ position: 'absolute', top: 0, right: 10, cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><rect fill="none" height="24" width="24" /><polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" /></svg>
                    </p>
                )}
            </div>
        )
    }


    const doBankIncomeExpenditureChart = (legend = true, showclose = false) => {

        if (ready) {
            let total_income = 0;
            let total_expense = 0;
            insights.forEach(function (element) {
                if (element.type === 'income') {
                    total_income = total_income + Math.abs(element.average_amount);
                }
                if (element.type === 'expense') {
                    total_expense = total_expense + Math.abs(element.average_amount);
                }
            });
            // const total = total_income + total_expense;
            // var data = []

            let data_expenses = []
            let data_income = []

            insights.forEach(function (element) {
                //if (element.ac_name === 'Monzo') {
                if (element.type === 'expense') {
                    data_expenses.push({
                        name: element.description, value: (Math.round(Math.abs(element.average_amount)))
                    })
                }

                if (element.type === 'income') {
                    data_income.push({
                        name: element.description, value: (Math.round(Math.abs(element.average_amount)))
                    })
                }
            })

            return (
                // <PieChart
                //     data={data}
                //     label={({ dataEntry }) => dataEntry.title}
                //     reveal={99}
                //     labelStyle={{ fontSize: 5, height: 10 }}
                // />

                <div style={{ width: '80%', height: '100%', marginLeft: 10, overflow: "hidden", }}>
                    <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>90 day average bank income &amp; expenditure</p>
                    <ResponsiveContainer width="100%" height="80%" debounce={20}>



                        <PieChart width={9999} height={9999}>
                            {legend == true && (
                                <Legend verticalAlign="bottom" height={36} margin={{ top: 0, left: 50, bottom: 0, right: 50 }} />
                            )}
                            <Pie data={data_expenses} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" label>
                                {
                                    data_expenses.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={numberToColour(colourToNumber(136, 150 + (index * 2), 200 + (index * 20)))} />
                                    ))
                                }

                            </Pie>
                            <Pie data={data_income} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius="45%" fill="#82ca9d" label />
                        </PieChart>

                    </ResponsiveContainer>
                    {showOpenClose(showclose, 1)}

                    {showclose && (
                        <div>
                            <p onClick={() => setViewGrid(true)} style={{ position: 'absolute', top: 0, right: 50, cursor: 'pointer' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z" /></svg>
                            </p>
                        </div>
                    )}

                </div>

            )
        } else {
            return <div />
        }
    }

    const styles = {
        card: {
            minWidth: 875
        },

        title: {
            fontSize: 14
        },
        pos: {
            marginBottom: 12
        },
        itIs: {
            color: "#c7ff00",
            fontSize: 18,
            fontWeight: "500",
            letterSpacing: 0.54,
            textAlign: "center",
            alignSelf: "center"
        },
        buttonContainer: {
            paddingVertical: 12,
            width: 200,
            height: 44,
            borderRadius: 2,
            color: "#c7ff00",
            backgroundColor: "#14A7CC",
            textTransform: "none",
            fontSize: 18,
            fontWeight: "700"
        },
        btn: {
            color: "green"
        }
    };

    const doBankTaggedIncomeVsExpenditureChart = (legend = true, showclose = false) => {


        if (taxCalc) {


            const data = [{ name: 'income', value: Math.round(taxCalc.summary_income) }, { name: 'expenses', value: Math.round(taxCalc.summary_expense) }]
            const colors = ['#82ca9d', '#8895d8']

            return (

                <div style={{ width: '80%', height: '100%', marginLeft: 10, overflow: "hidden", }}>
                    <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Tagged income vs expenditure year to date</p>
                    <ResponsiveContainer width="100%" height="80%" debounce={20}>



                        <PieChart width={9999} height={9999}>
                            {legend == true && (
                                <Legend verticalAlign="bottom" height={36} margin={{ top: 0, left: 50, bottom: 0, right: 50 }} />
                            )}
                            <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" label>
                                {
                                    data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index]} />
                                    ))
                                }

                            </Pie>

                        </PieChart>
                    </ResponsiveContainer>
                    {showOpenClose(showclose, 2)}
                </div>

            )
        } else {
            return <div />
        }
    }



    const doTagsChart = (legend = true, type = 'E', title = 'expenditure', chart, showclose = false) => {


        if (taxTotals) {

            var data = []
            taxTotals.forEach((element) => {
                const tax_cat_id = element.claimed_cat_id;
                const tax_cat = taxCategories.find(function (element) {
                    return element.id === tax_cat_id;
                })
                if (tax_cat && tax_cat.type === type) {
                    data.push({ name: tax_cat.tagname, value: Math.round(element.sub_total) });
                }

            });
            return (

                <div style={{ width: '80%', height: '100%', marginLeft: 10, overflow: "hidden", }}>
                    <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Tagged {title} by tag type year to date</p>
                    <ResponsiveContainer width="100%" height="80%" debounce={20}>

                        <PieChart width={9999} height={9999}>
                            {legend == true && (
                                <Legend verticalAlign="bottom" height={36} margin={{ top: 0, left: 50, bottom: 0, right: 50 }} />
                            )}
                            <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" label>
                                {
                                    data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={numberToColour(colourToNumber(136, 150 + (index * 2), 200 + (index * 40)))} />
                                    ))
                                }

                            </Pie>

                        </PieChart>
                    </ResponsiveContainer>
                    {showOpenClose(showclose, chart)}
                </div>

            )
        } else {
            return <div />
        }
    }

    const onRowDoubleClicked = (row) => {

        setDescriptionFilter(row.data.description)
        setToTransactions(true);

    }


    const doGrid = () => {

        if (toTransactions) {
            return (
                <Redirect
                    to={{
                        pathname: "/transactions",
                        state: { descriptionFilter: descriptionFilter }
                    }}
                />)
        }

        let average_total_income = "£" + Number(Math.abs(averageTotalIncomeMonthly))
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")

        if (ready && viewGrid) {
            return (
                <div style={{ height: '100%' }}>

                    <div
                        style={{
                            width: 900,
                            textAlign: "left",
                            //margin: "0 auto",
                            paddingLeft: 10,
                            marginBottom: 5,
                            height: 80
                        }}
                    >

                        <div
                            style={{
                                //maxWidth: "300px",
                                height: "80px",
                                flexDirection: "row",
                                display: "flex",
                                paddingLeft: 0,
                                paddingBottom: 7
                            }}
                        >

                            <span style={{ lineHeight: '34px', paddingRight: '20px' }}>
                                Updated on {prettyDateTime(updated)}{". "}
                                <strong>Your average monthly income is {average_total_income}</strong><br />
                        untied looks back 90 days to find regular income and expenses which you may want to create rules from. <a href="https://help.untied.io/contact" target="_new">Got feedback?</a>
                            </span>

                        </div>
                    </div>

                    <div
                        style={{
                            height: "100%",
                            flex: 1,
                            textAlign: "left",
                            overflow: "hidden",
                            paddingBottom: 80
                        }}
                        className="ag-theme-balham"
                    >



                        {/* <AgGridReact
                            style={{ alignSelf: "center" }}
                            modules={modules}
                            defaultColDef={defaultColDef}
                            animateRows
                            enableColResize
                            enableFilter
                            enableSorting
                            floatingFilter
                            isExternalFilterPresent={isExternalFilterPresent}
                            doesExternalFilterPass={node =>
                                doesExternalFilterPass(node)
                            }
                            rowSelection={"multiple"}
                            rowDeselection={true}
                            // onGridReady={this.onGridReady}
                            columnDefs={columnDefs}
                            rowData={insights}
                            onGridReady={params => onGridReady(params)}
                            onFilterChanged={() => filterChanged()}
                            suppressRowClickSelection={true}
                            onRowDoubleClicked={row => onRowDoubleClicked(row)}
                            onRowDeSelected={node => onRowDeSelected(node)}
                            onSelectionChanged={event => onSelectionChanged()}
                            onCellClicked={column => onCellClicked(column)}
                            rowClassRules={rowClassRules}
                        ></AgGridReact> */}
                    </div>
                    <div>

                        <p onClick={() => {
                            setViewGrid(false)
                            setViewChart(0)
                        }}
                            style={{ position: 'absolute', top: 0, right: 10, cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
                        </p>

                    </div>
                    <div>
                        <p onClick={() => {
                            setViewGrid(false)
                            setViewChart(1)
                        }} style={{ position: 'absolute', top: 0, right: 50, cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z" /></svg>
                        </p>
                    </div>

                </div>
            )
        } else {
            return <div />
        }
    }

    return (
        <React.Fragment>
            <div
                style={{
                    display: "flex",
                    position: "absolute",
                    top: 90,
                    bottom: 40,
                    width: "100%",
                    //minWidth: '960px',
                    //height: "80vh",
                    backgroundColor: "#FAFAFC",
                    minWidth: 300,
                    flexBasis: 300,
                    flexShrink: 0
                }}
            >
                <MiniDrawer />

                <div
                    style={{
                        justifyContent: "center",
                        textAlign: "center",
                        width: "98%",
                        height: "100%",
                        alignItems: "center",
                        borderWidth: 2,
                        marginLeft: "10px",
                        marginRight: "10px"
                    }}
                >


                    {SelectionBar(recoilFetched)}
                    {ready === false && (<Dots />)}
                    {doCharts()}
                    {doGrid()}

                </div>



            </div>
        </React.Fragment >
    )

}

// function scale(val) {
//   return val;
// }

const styles = {
    card: {
        minWidth: 875
    },

    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    itIs: {
        color: "#c7ff00",
        fontSize: 18,
        fontWeight: "500",
        letterSpacing: 0.54,
        textAlign: "center",
        alignSelf: "center"
    },
    buttonContainer: {
        paddingVertical: 12,
        width: 200,
        height: 44,
        borderRadius: 2,
        color: "#c7ff00",
        backgroundColor: "#14A7CC",
        textTransform: "none",
        fontSize: 18,
        fontWeight: "700"
    },
    btn: {
        color: "green"
    }
};



// class Insights_old extends Component {
//     prettyDateTime(dtstr) {
//         var date1 = new Date(dtstr);
//         var longdate = date1.toDateString();
//         // var timepart = "";
//         var timepart = date1.toLocaleTimeString();
//         return longdate + " " + timepart;
//     }

//     constructor(props) {
//         super(props);
//         this.state = {
//             ready: true,
//             average_total_income_monthly: 0,
//             columnDefs: [
//                 {
//                     headerName: "Description",
//                     field: "description",
//                     cellStyle: { textAlign: "left" }
//                 },
//                 {
//                     headerName: "Account",
//                     field: "ac_name",
//                     cellStyle: { textAlign: "left" },
//                     width: 100
//                 },
//                 { field: "account_id", hide: true },

//                 {
//                     headerName: "Type",
//                     field: "type",
//                     //cellStyle: { textAlign: "left" }

//                     cellStyle: function (params, node) {
//                         if (params.data.type === "income") {
//                             return {
//                                 color: "#0C90B2",
//                                 backgroundColor: "#C7FF00",
//                                 fontWeight: "bold",
//                                 textAlign: "center"
//                             };
//                         } else {
//                             return {
//                                 textAlign: "center"
//                             }
//                         }
//                     },
//                     width: 100,
//                 },
//                 {
//                     headerName: "Frequency",
//                     field: "frequency",
//                     cellStyle: { textAlign: "center" },
//                     width: 100,
//                 },

//                 {
//                     headerName: "Average Amount",
//                     field: "average_amount",
//                     cellStyle: { textAlign: "right" },
//                     width: 120,
//                     valueFormatter: params => {

//                         return (
//                             "£" +
//                             Number(Math.abs(params.value))
//                                 .toFixed(2)
//                                 .toString()
//                                 .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
//                         );

//                     },
//                 },
//                 {
//                     headerName: "",
//                     //field: "checkbox",
//                     checkboxSelection: false,
//                     suppressFilter: true,
//                     cellRendererFramework: makeRuleRenderer,
//                     cellRendererParams: {
//                         history: this.props.history
//                     },
//                     suppressSorting: true,
//                     suppressResize: true,
//                     width: 110
//                 },
//             ],
//             insights: [
//                 {
//                     ac_name: "loading...",
//                     account_id: 0,
//                     type: "loading...",
//                     description: "loading...",
//                     frequency: "loading...",
//                     average_amount: 0
//                 }
//             ],
//             token: localStorage.getItem("token")
//         };

//         this.onGridReady = this.onGridReady.bind(this);
//     }

//     componentDidMount() {
//         this.getInsights();
//         this.getTaxData();
//     }

//     idClicked(id) {
//         window.alert("Account clicked: " + id);
//     }


//     // getUserId() {
//     //     return localStorage.getItem("guid") + " " + localStorage.getItem("userid");
//     // }

//     getTaxData() {

//         // fetch(
//         //     `https://${global.api_url}/getTaxYears.php`,
//         //     {
//         //         method: "GET",
//         //         headers: { token: user_atom.token, agentId: user_atom.agent }
//         //     }
//         // )
//         //     .then(res => res.json())
//         //     .then(json => {

//         //         console.log('tax years:', json)
//                 this.props.auth.taxYears.forEach((element) => {
//                     const start_date = new Date(element.start_date);
//                     const end_date = new Date(element.end_date);
//                     const today = new Date();

//                     if (today >= start_date && today <= end_date) {
//                         //found tax year ... 
//                         const tax_year_id = element.id;


//                         fetch(`https://${global.api_url}/getTaxCalculation.php?tax_year_id=${tax_year_id}`,
//                             {
//                                 method: "GET",
//                                 headers: {token: user_atom.token, agentId: user_atom.agent }
//                             }
//                         )
//                             .then(res => res.json())
//                             .then(json => {
//                                 console.log(json);
//                                 this.setState({ taxCalc: json });


//                                 fetch(`https://${global.api_url}/getTaxCategories.php`,
//                                     { method: "GET", headers: { token: user_atom.token, agentId: user_atom.agent } })
//                                     .then(res => res.json())
//                                     .then(json => {

//                                         console.log('tax cats:', json.tax_categories)
//                                         this.setState({ tax_categories: json.tax_categories });

//                                         fetch(`https://${global.api_url}/getClaimedTransForReview.php?from=${element.start_date}&to=${element.end_date}`,
//                                             { method: "GET", headers: { token: user_atom.token, agentId: user_atom.agent } })
//                                             .then(res => res.json())
//                                             .then(json => {

//                                                 console.log('tax totals:', json)
//                                                 this.setState({ tax_totals: json.transaction_totals });

//                                             });

//                                     });


//                             })

//                     }
//                 })


//             // });
//     }

//     getInsights() {
//         var timeStamp = Math.floor(Date.now() / 1000);

//         fetch(
//             `https://${global.api_url}/truelayer/getInsightsCached.php?ts=${timeStamp}&app=web&all=`,
//             {
//                 method: "GET",
//                 headers: { token: user_atom.token, agentId: user_atom.agent }
//             }
//         )
//             .then(res => res.json())
//             .then(json => {
//                 console.log(json);
//                 if (json.error) {
//                     if (json.error === "unauthorized") {
//                         //alert('redirect to login page');
//                         this.props.history.push("/logout/logout");
//                     }
//                 } else {
//                     this.setState({ insights: json.insights, ready: true, average_total_income_monthly: json.average_total_income_monthly, updated: json.updated });


//                     if (this.props.location.state !== undefined) {
//                         if (this.props.location.state.doGrid !== undefined) {
//                             this.setState({ doGrid: true });
//                         }
//                     }
//                 }
//             });
//     }



//     openInNewTab(url) {
//         var win = window.open(url, "_blank");
//         win.focus();
//     }

//     onSelectionChanged(event) {
//         //get the row count and initialise
//         var rowCount = this.gridApi.getSelectedRows().length;
//         var showMultiList = false;
//         var typeSelected = "";
//         if (rowCount > 0) {
//             const selectedRows = this.gridApi.getSelectedRows();
//             typeSelected = selectedRows[0].type;
//             //see if all rows have the same type
//             selectedRows.forEach(element => {
//                 if (!showMultiList) {
//                     showMultiList = element.type !== typeSelected;
//                 }
//             });
//         }

//         //set state - ONCE
//         this.setState({ rowCount, showMultiList, typeSelected, tax_cat: 0 });
//     }

//     addAccount() {
//         var timeStamp = Math.floor(Date.now() / 1000);
//         Mixpanel.track("add_account_truelayer");
//         fetch(
//             `https://${global.api_url}/truelayer/app_connect.php?ts=${timeStamp}`,
//             {
//                 method: "GET",
//                 headers: { token: user_atom.token, agentId: user_atom.agent }
//             }
//         )
//             .then(res => res.json())
//             .then(json => {
//                 if (json.url != null) {
//                     this.openInNewTab(json.url);
//                     this.setState({ tfurl: json.url });
//                     this.refs.tf.submit();
//                 }
//             });
//     }


//     onCellClicked(column) {
//         //
//     }

//     onGridReady(params) {
//         this.gridApi = params.api;
//         this.columnApi = params.columnApi;
//         //this.getInsights();
//         this.gridApi.sizeColumnsToFit();
//     }

//     setFilter = name => event => {
//         this.setState({
//             [name]: event.target.value
//         });
//         this.gridApi.setQuickFilter(event.target.value);
//     };

//     prettyDateTime(dtstr) {

//         if (dtstr == null) {
//             return "";
//         }

//         // Split timestamp into [ Y, M, D, h, m, s ]
//         var t = dtstr.split(/[- :]/);

//         // Apply each element to the Date function
//         var date1 = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

//         var longdate = date1.toDateString();
//         // var timepart = "";
//         var timepart = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//         return longdate + " at " + timepart;
//     }

//     doCharts() {

//         if (this.state.doGrid) {
//             return;
//         }

//         if (!this.state.dochart || this.state.dochart == 0) {
//             return (

//                 < IonContent >
//                     <IonGrid>
//                         <IonRow>
//                             {/* <IonCol onClick={() => this.setState({ dochart: 1 })} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">

//                                 {this.doBankIncomeExpenditureChart(false)}

//                             </IonCol> */}
//                             <IonCol onClick={() => this.setState({ dochart: 2 })} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">
//                                 {this.doBankTaggedIncomeVsExpenditureChart(false)}
//                             </IonCol>
//                         </IonRow>
//                         <IonRow>
//                             <IonCol onClick={() => this.setState({ dochart: 3 })} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">
//                                 {this.doTagsChart(false, 'E', 'expenditure')}</IonCol>
//                             <IonCol onClick={() => this.setState({ dochart: 4 })} style={{ "box-shadow": "2px 2px 2px 2px silver", paddingLeft: '5%', margin: 10 }} className="ion-align-self-center">
//                                 {this.doTagsChart(false, 'I', 'income')}</IonCol>
//                         </IonRow>
//                     </IonGrid>

//                 </IonContent >

//             )
//         }

//         if (this.state.dochart == 1) {
//             return this.doBankIncomeExpenditureChart(true, true);
//         }
//         if (this.state.dochart == 2) {
//             return this.doBankTaggedIncomeVsExpenditureChart(true, true);
//         }
//         if (this.state.dochart == 3) {
//             return this.doTagsChart(true, 'E', 'expenditure', 3, true)
//         }
//         if (this.state.dochart == 4) {
//             return this.doTagsChart(true, 'I', 'income', 4, true)
//         }

//     }

//     // convert three r,g,b integers (each 0-255) to a single decimal integer (something between 0 and ~16m)
//     colourToNumber(r, g, b) {
//         return (r << 16) + (g << 8) + (b);
//     }

//     // convert it back again (to a string)
//     numberToColour(number) {
//         const r = (number & 0xff0000) >> 16;
//         const g = (number & 0x00ff00) >> 8;
//         const b = (number & 0x0000ff);

//         //return [r, g, b];
//         return `rgb(${r},${g},${b})`;
//     }

//     showOpenClose(showclose, chart) {
//         return (
//             <div>
//                 {showclose && (
//                     <p onClick={() => this.setState({ dochart: 0 })} style={{ position: 'absolute', top: 0, right: 10, cursor: 'pointer' }}>
//                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
//                     </p>
//                 )}
//                 {!showclose && (
//                     <p onClick={() => this.setState({ dochart: chart })} style={{ position: 'absolute', top: 0, right: 10, cursor: 'pointer' }}>
//                         <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><rect fill="none" height="24" width="24" /><polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" /></svg>
//                     </p>
//                 )}
//             </div>
//         )
//     }


//     doBankIncomeExpenditureChart(legend = true, showclose = false) {

//         if (this.state.ready) {
//             var total_income = 0;
//             var total_expense = 0;
//             this.state.insights.forEach(function (element) {
//                 if (element.type === 'income') {
//                     total_income = total_income + Math.abs(element.average_amount);
//                 }
//                 if (element.type === 'expense') {
//                     total_expense = total_expense + Math.abs(element.average_amount);
//                 }
//             });
//             // const total = total_income + total_expense;
//             // var data = []

//             var data_expenses = []
//             var data_income = []

//             this.state.insights.forEach(function (element) {
//                 //if (element.ac_name === 'Monzo') {
//                 if (element.type === 'expense') {
//                     data_expenses.push({
//                         name: element.description, value: (Math.round(Math.abs(element.average_amount)))
//                     })
//                 }

//                 if (element.type === 'income') {
//                     data_income.push({
//                         name: element.description, value: (Math.round(Math.abs(element.average_amount)))
//                     })
//                 }

//                 // const data_element = { title: element.description, value: (Math.abs(element.average_amount) / total) * 100, color: element.type === 'expense' ? '#A800FF' : '#c7ff00' }
//                 // data.push(data_element);
//                 //}
//                 //}
//             })

//             // data = [
//             //     { title: 'income', value: total_income, color: '#c7ff00' },
//             //     { title: 'expenses', value: total_expense, color: '#A800FF' }
//             // ]


//             // let renderLabel = function (entry) {
//             //     return entry.name;
//             // }


//             return (
//                 // <PieChart
//                 //     data={data}
//                 //     label={({ dataEntry }) => dataEntry.title}
//                 //     reveal={99}
//                 //     labelStyle={{ fontSize: 5, height: 10 }}
//                 // />

//                 <div style={{ width: '80%', height: '100%', marginLeft: 10, overflow: "hidden", }}>
//                     <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>90 day average bank income &amp; expenditure</p>
//                     <ResponsiveContainer width="100%" height="80%">



//                         <PieChart width={9999} height={9999}>
//                             {legend == true && (
//                                 <Legend verticalAlign="bottom" height={36} margin={{ top: 0, left: 50, bottom: 0, right: 50 }} />
//                             )}
//                             <Pie data={data_expenses} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" label>
//                                 {
//                                     data_expenses.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={this.numberToColour(this.colourToNumber(136, 150 + (index * 2), 200 + (index * 20)))} />
//                                     ))
//                                 }

//                             </Pie>
//                             <Pie data={data_income} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius="45%" fill="#82ca9d" label />
//                         </PieChart>

//                     </ResponsiveContainer>
//                     {this.showOpenClose(showclose, 1)}

//                     {showclose && (
//                         <div>
//                             <p onClick={() => this.setState({ doGrid: true })} style={{ position: 'absolute', top: 0, right: 50, cursor: 'pointer' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z" /></svg>
//                             </p>
//                         </div>
//                     )}

//                 </div>

//             )
//         } else {
//             return <div />
//         }
//     }

//     doBankTaggedIncomeVsExpenditureChart(legend = true, showclose = false) {


//         if (this.state.taxCalc) {


//             const data = [{ name: 'income', value: Math.round(this.state.taxCalc.summary_income) }, { name: 'expenses', value: Math.round(this.state.taxCalc.summary_expense) }]
//             const colors = ['#82ca9d', '#8895d8']

//             return (

//                 <div style={{ width: '80%', height: '100%', marginLeft: 10, overflow: "hidden", }}>
//                     <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Tagged income vs expenditure year to date</p>
//                     <ResponsiveContainer width="100%" height="80%">



//                         <PieChart width={9999} height={9999}>
//                             {legend == true && (
//                                 <Legend verticalAlign="bottom" height={36} margin={{ top: 0, left: 50, bottom: 0, right: 50 }} />
//                             )}
//                             <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" label>
//                                 {
//                                     data.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={colors[index]} />
//                                     ))
//                                 }

//                             </Pie>

//                         </PieChart>
//                     </ResponsiveContainer>
//                     {this.showOpenClose(showclose, 2)}
//                 </div>

//             )
//         } else {
//             return <div />
//         }
//     }



//     doTagsChart(legend = true, type = 'E', title = 'expenditure', chart, showclose = false) {


//         if (this.state.tax_totals) {

//             var data = []
//             this.state.tax_totals.forEach((element) => {
//                 const tax_cat_id = element.claimed_cat_id;
//                 const tax_cat = this.state.tax_categories.find(function (element) {
//                     return element.id === tax_cat_id;
//                 })
//                 if (tax_cat.type === type) {
//                     data.push({ name: tax_cat.tagname, value: Math.round(element.sub_total) });
//                 }

//             });

//             //const data = [{ name: 'income', value: Math.round(this.state.taxCalc.summary_income) }, { name: 'expenses', value: Math.round(this.state.taxCalc.summary_expense) }]
//             // const colors = ['#82ca9d', '#8895d8']

//             return (

//                 <div style={{ width: '80%', height: '100%', marginLeft: 10, overflow: "hidden", }}>
//                     <p style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Tagged {title} by tag type year to date</p>
//                     <ResponsiveContainer width="100%" height="80%">



//                         <PieChart width={9999} height={9999}>
//                             {legend == true && (
//                                 <Legend verticalAlign="bottom" height={36} margin={{ top: 0, left: 50, bottom: 0, right: 50 }} />
//                             )}
//                             <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" label>
//                                 {
//                                     data.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={this.numberToColour(this.colourToNumber(136, 150 + (index * 2), 200 + (index * 40)))} />
//                                     ))
//                                 }

//                             </Pie>

//                         </PieChart>
//                     </ResponsiveContainer>
//                     {this.showOpenClose(showclose, chart)}
//                 </div>

//             )
//         } else {
//             return <div />
//         }
//     }

//     onRowDoubleClicked(row) {

//         this.setState({ descriptionFilter: row.data.description, toTransactions: true });

//     }


//     doGrid() {

//         if (this.state.toTransactions) {
//             return (
//                 <Redirect
//                     to={{
//                         pathname: "/transactions",
//                         state: { descriptionFilter: this.state.descriptionFilter }
//                     }}
//                 />)
//         }

//         let average_total_income = "£" + Number(Math.abs(this.state.average_total_income_monthly))
//             .toFixed(2)
//             .toString()
//             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")

//         if (this.state.ready && this.state.doGrid) {
//             return (
//                 <div style={{ height: '100%' }}>

//                     <div
//                         style={{
//                             width: 900,
//                             textAlign: "left",
//                             //margin: "0 auto",
//                             paddingLeft: 10,
//                             marginBottom: 5,
//                             height: 80
//                         }}
//                     >

//                         <div
//                             style={{
//                                 //maxWidth: "300px",
//                                 height: "80px",
//                                 flexDirection: "row",
//                                 display: "flex",
//                                 paddingLeft: 0,
//                                 paddingBottom: 7
//                             }}
//                         >

//                             <span style={{ lineHeight: '34px', paddingRight: '20px' }}>
//                                 Updated on {this.prettyDateTime(this.state.updated)}{". "}
//                                 <strong>Your average monthly income is {average_total_income}</strong><br />
//                         untied looks back 90 days to find regular income and expenses which you may want to create rules from. <a href="https://help.untied.io/contact" target="_new">Got feedback?</a>
//                             </span>

//                         </div>
//                     </div>

//                     <div
//                         style={{
//                             height: "100%",
//                             flex: 1,
//                             textAlign: "left",
//                             overflow: "hidden",
//                             paddingBottom: 80
//                         }}
//                         className="ag-theme-balham"
//                     >



//                         <AgGridReact
//                             style={{ alignSelf: "center" }}
//                             modules={this.state.modules}
//                             defaultColDef={this.state.defaultColDef}
//                             animateRows
//                             enableColResize
//                             enableFilter
//                             enableSorting
//                             floatingFilter
//                             isExternalFilterPresent={this.isExternalFilterPresent}
//                             doesExternalFilterPass={node =>
//                                 this.doesExternalFilterPass(node)
//                             }
//                             rowSelection={"multiple"}
//                             rowDeselection={true}
//                             // onGridReady={this.onGridReady}
//                             columnDefs={this.state.columnDefs}
//                             rowData={this.state.insights}
//                             onGridReady={params => this.onGridReady(params)}
//                             onFilterChanged={() => this.filterChanged()}
//                             suppressRowClickSelection={true}
//                             onRowDoubleClicked={row => this.onRowDoubleClicked(row)}
//                             onRowDeSelected={node => this.onRowDeSelected(node)}
//                             onSelectionChanged={event => this.onSelectionChanged()}
//                             onCellClicked={column => this.onCellClicked(column)}
//                             rowClassRules={this.state.rowClassRules}
//                         ></AgGridReact>
//                     </div>
//                     <div>

//                         <p onClick={() => this.setState({ doGrid: false, dochart: 0 })} style={{ position: 'absolute', top: 0, right: 10, cursor: 'pointer' }}>
//                             <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
//                         </p>

//                     </div>
//                     <div>
//                         <p onClick={() => this.setState({ doGrid: false, dochart: 1 })} style={{ position: 'absolute', top: 0, right: 50, cursor: 'pointer' }}>
//                             <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z" /></svg>
//                         </p>
//                     </div>

//                 </div>
//             )
//         } else {
//             return <div />
//         }
//     }

//     render() {

//         return (
//             <React.Fragment>
//                 <div
//                     style={{
//                         display: "flex",
//                         position: "absolute",
//                         top: 90,
//                         bottom: 40,
//                         width: "100%",
//                         //minWidth: '960px',
//                         //height: "80vh",
//                         backgroundColor: "#FAFAFC",
//                         minWidth: 300,
//                         flexBasis: 300,
//                         flexShrink: 0
//                     }}
//                 >
//                     <MiniDrawer />

//                     <div
//                         style={{
//                             justifyContent: "center",
//                             textAlign: "center",
//                             width: "98%",
//                             height: "100%",
//                             alignItems: "center",
//                             borderWidth: 2,
//                             marginLeft: "10px",
//                             marginRight: "10px"
//                         }}
//                     >



//                         {this.state.ready === false && (<Dots />)}
//                         {this.doCharts()}
//                         {this.doGrid()}

//                         <div style={{ paddingBottom: 5, width: "100%" }}>
//                             <div style={styles.footer}>
//                                 <p style={{ fontSize: 12, color: "black" }}>
//                                     © 2019-2021 untied | All rights reserved |{" "}
//                                     <a
//                                         style={{ color: "black" }}
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                         href="https://www.untied.io/untied-privacy-policy/"
//                                     >
//                                         Privacy Policy
//                                     </a>{" "}|{" "}
//                                     <a
//                                         style={{ color: "black" }}
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                         href="https://www.untied.io/untied-app-terms-conditions/"
//                                     >
//                                         Terms of Service
//                                     </a>
//                                 </p>
//                             </div>
//                         </div>

//                     </div>



//                 </div>
//             </React.Fragment >
//         );
//     }
// }

// // function scale(val) {
// //   return val;
// // }

// // const styles = {
// //     card: {
// //         minWidth: 875
// //     },

// //     title: {
// //         fontSize: 14
// //     },
// //     pos: {
// //         marginBottom: 12
// //     },
// //     itIs: {
// //         color: "#c7ff00",
// //         fontSize: 18,
// //         fontWeight: "500",
// //         letterSpacing: 0.54,
// //         textAlign: "center",
// //         alignSelf: "center"
// //     },
// //     buttonContainer: {
// //         paddingVertical: 12,
// //         width: 200,
// //         height: 44,
// //         borderRadius: 2,
// //         color: "#c7ff00",
// //         backgroundColor: "#14A7CC",
// //         textTransform: "none",
// //         fontSize: 18,
// //         fontWeight: "700"
// //     },
// //     btn: {
// //         color: "green"
// //     }
// // };

export default withRouter(Insights)
